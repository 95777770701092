import styled from "styled-components";

export const Signature = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 380px;
  border-radius: 2rem;
  background-color: rgb(231 16 55 / 13%);
  padding: 20px;
  margin-top: 1rem;
  .signatureCanvas {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    background-color: rgba(231, 16, 55, 0.4);
  }
`;
export const DivTrash = styled.div`
  display: flex;
  margin-top: 1%;
  justify-content: end;
`;

export const BoxSignatureWrapper = styled.div`
  height: 100px;
  width: 100%;
  cursor: pointer;

  border-radius: 2rem;
  background-color: rgba(231, 16, 55, 0.4);
  padding: 12px;
`;

export const BoxSignatureButton = styled.button`
  height: 100px;
  width: 100%;
  cursor: pointer;

  border-radius: 2rem;
  background-color: rgba(231, 16, 55, 0.4);
  padding: 12px;
`;

export const LineSignature = styled.div`
  height: 55px;
  width: 80%;
  margin: auto;
  border-bottom: 1px dashed #e71037;
`;

export const ImgURL = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 330px;
  max-height: 60px;
`;

export const BenefitInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 1rem;
  span {
    font-size: 1.6rem;
  }
`;

export const BenefitContract = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  span {
    font-size: 1.4rem;
    text-decoration: underline;

    a {
      color: rgba(74, 75, 74, 1);
    }
  }
`;

export const DateInput = styled.div`
  margin: 1.5rem 0.5rem;
  span {
    font-size: 1.4rem;
    text-decoration: underline;

    a {
      color: rgba(74, 75, 74, 1);
    }
  }
`;

export const Checkbox = styled.div`
  margin: 1.5rem 0.5rem;
  span {
    font-size: 1.4rem;

    a {
      color: rgba(74, 75, 74, 1);
    }
  }
`;

export const ViewTerms = styled.span`
  color: #000;
  margin-right: 3px;
  text-decoration: underline;
  padding-left: 5px;
  cursor: pointer;
`;

export const BenefitWrapper = styled.div``;
