import React, { useRef, useState } from "react";

import * as S from "./styles";
import Button from "components/Button";
import { useEffect } from "react";

export const PasswordPopup = ({
  onClick,
  text,
  slug,
  visibility,
  setVisibility,
  errorMessage,
}) => {
  const [textInput, setTextInput] = useState(Array.from({ length: 6 }));
  const [inputRefs] = useState({
    n1: useRef(),
    n2: useRef(),
    n3: useRef(),
    n4: useRef(),
    n5: useRef(),
    n6: useRef(),
  });

  useEffect(() => {
    if (errorMessage) {
      setTextInput(Array.from({ length: 6 }, () => null));

      if (inputRefs.n1) {
        inputRefs.n1.current.value = null;
        inputRefs.n2.current.value = null;
        inputRefs.n3.current.value = null;
        inputRefs.n4.current.value = null;
        inputRefs.n5.current.value = null;
        inputRefs.n6.current.value = null;
        inputRefs.n1.current.focus();
      }
    }
  }, [
    errorMessage,
    inputRefs,
    inputRefs.n1,
    inputRefs.n2,
    inputRefs.n3,
    inputRefs.n4,
    inputRefs.n5,
    inputRefs.n6,
  ]);

  const onChangeInput = (value, id) => {
    const nextKey = `n${id + 1}`;

    const newTextInput = [...textInput];
    newTextInput[id - 1] = value;

    setTextInput(newTextInput);

    if (value.length) {
      if (id < 6) {
        inputRefs[nextKey].current.focus();
      }
    }
  };

  const handleBack = (evt, id) => {
    const previousKey = `n${id - 1}`;

    if (evt.key === "Backspace" && id <= 6) {
      if (id > 1) {
        inputRefs[previousKey].current.focus();
      }
    }
  };

  return (
    <S.Container open={visibility} onClose={() => setVisibility(false)} modal>
      {(close) => (
        <>
          <div className="content">
            <div className="top-content">
              <p>Senha do cartão</p>
              <S.CloseIcon onClick={close} />
            </div>

            <div className="input-container">
              <input
                onChange={(e) => {
                  onChangeInput(e.target.value, 1);
                }}
                className="input"
                type="text"
                maxLength="1"
                ref={inputRefs.n1}
                onKeyUp={(evt) => handleBack(evt, 1)}
              />
              <input
                onChange={(e) => {
                  onChangeInput(e.target.value, 2);
                }}
                className="input"
                type="text"
                maxLength="1"
                ref={inputRefs.n2}
                onKeyUp={(evt) => handleBack(evt, 2)}
              />
              <input
                onChange={(e) => {
                  onChangeInput(e.target.value, 3);
                }}
                className="input"
                type="text"
                maxLength="1"
                ref={inputRefs.n3}
                onKeyUp={(evt) => handleBack(evt, 3)}
              />
              <input
                onChange={(e) => {
                  onChangeInput(e.target.value, 4);
                }}
                className="input"
                type="text"
                maxLength="1"
                ref={inputRefs.n4}
                onKeyUp={(evt) => handleBack(evt, 4)}
              />
              <input
                onChange={(e) => {
                  onChangeInput(e.target.value, 5);
                }}
                className="input"
                type="text"
                maxLength="1"
                ref={inputRefs.n5}
                onKeyUp={(evt) => handleBack(evt, 5)}
              />
              <input
                onChange={(e) => {
                  setTextInput((textInput[5] = e.target.value));
                  onChangeInput(e.target.value, 6);
                }}
                className="input"
                type="text"
                maxLength="1"
                ref={inputRefs.n6}
                onKeyUp={(evt) => handleBack(evt, 6)}
              />
            </div>
          </div>
          {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}

          <hr />

          <div className="button-container">
            <Button onClick={() => onClick(textInput.join(""))}>{text}</Button>
          </div>
        </>
      )}
    </S.Container>
  );
};

export default PasswordPopup;
