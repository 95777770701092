/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import Layout from "components/OldLayout";
import Loading from "components/Loading";
import Input from "components/Input";
import Button from "components/Button";
import Card from "components/Card/cardLeads";
import ContainerFlex from "components/ContainerFlex";

import useApi from "hooks/useApi";
import constants from "utils/constants";

import * as S from "./styles";
import { cpfMask } from "utils/mask";

const ConsultClients = () => {
  const [cpf, setCpf] = useState({});
  const [client, setClient] = useState();

  const history = useHistory();

  const GetSearchLeads = useApi(
    constants.endpoints.getListObtemLeads.replace("PARAM_CPF", cpf?.unmasked),
    "GET"
  );

  const handleSearch = async () => {
    try {
      const response = await GetSearchLeads.callApi();

      if (response.status >= 200 && response.status < 300) {
        setClient(response.data?.nome && [response.data]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleViewSearch = (data) =>
    history.push("/detail-leads", { cliente: data });

  const handleCardRender = (data) => {
    return <Card onClickSearch={handleViewSearch} key={data.id} data={data} />;
  };

  const handleCardListRender = useMemo(
    () => client?.length > 0 && client?.map(handleCardRender),
    [client, handleCardRender]
  );

  return (
    <S.Container>
      <Loading status={GetSearchLeads.loading} />
      <Layout
        routeBack="/opcoes-acesso"
        title="Central de Notificações e Eventos"
      >
        <S.ContainerFilter>
          <S.ContentInput>
            <Input
              name="cpf"
              label="CPF"
              placeholder="000.000.000-00"
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              value={cpf?.masked}
              max={14}
              onChange={(e) => setCpf(cpfMask(e.target.value))}
            />
          </S.ContentInput>
          <ContainerFlex end>
            <Button
              buttonType="primary"
              text={"Buscar"}
              onClick={handleSearch}
              buttonSize="small"
              style={{ maxWidth: "300px" }}
            />
          </ContainerFlex>
        </S.ContainerFilter>
        <S.TextCadLead>
          Ainda não é cliente?
          <S.HiperLink onClick={() => history.push("/add-leads")}>
            Clique aqui.
          </S.HiperLink>
        </S.TextCadLead>
      </Layout>
      <S.WrapperList>{client && handleCardListRender}</S.WrapperList>

      {!client?.length && (
        <ContainerFlex row horizontal center>
          <S.NotFound>Nenhum cliente encontrado.</S.NotFound>
        </ContainerFlex>
      )}
    </S.Container>
  );
};

export default ConsultClients;
