import styled from "styled-components";
import media from "styled-media-query";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10rem;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const FoundLead = styled.div`
  padding: 2rem 3rem;
  background: #739942;
  border-radius: 2rem;
  color: #fff;
  width: 62%;
  display: flex;
  justify-content: space-between;
  img {
    width: 45px;
    height: 45px;
  }
  div {
    display: flex;
    flex-direction: column;
  }
  p {
    font-size: 1.7rem;
  }
  span {
    font-size: 2rem;
    font-weight: bold;
  }
`;

export const Simulation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  justify-items: end;
  padding: 2rem 3rem;

  border: 2px dashed red;
  border-radius: 2rem;
  margin-top: 2rem;

  div {
    display: flex;
    flex-direction: column;
    width: 160px;
    align-items: center;
    justify-content: center;
    p {
      font-size: 1.7rem;
    }
    span {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
`;
export const ContainerItem = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const Item = styled.span`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;

  ${media.greaterThan("medium")`
width: 65%;
height: 100px;
`}

  &:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`;

export const ImageItem = styled.img`
  height: 40px;
  object-fit: contain;
  margin-left: 30px;
`;

export const TextItem = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  margin-left: 10px;
`;
export const Title = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 10px;
  display: flex;
  justify-content: ${(props) => props.justifyContent};
`;
export const TextItemDesc = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
  margin-left: 10px;
`;

export const Footer = styled.div`
  margin-top: 25px;
  position: fixed;
  bottom: 0;
  width: 40%;

  @media (max-width: 712px) {
    padding: 20px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`;
