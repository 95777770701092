import styled from 'styled-components'
import PropTypes from 'prop-types'

const Grid = styled.div`
  height: auto;
  max-width: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'white'};

  display: grid;
  grid-template-columns: repeat(${(props) => (props.xs ? props.xs : 1)}, 1fr);
  grid-gap: ${(props) => (props.gap ? `${props.gap}px` : '0px')};

  @media (min-width: 901px) {
    grid-template-columns: repeat(${(props) => (props.lg ? props.lg : (props.md || props.sm || props.xs || 1))}, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(${(props) => (props.md ? props.md : (props.sm || props.xs || 1))}, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(${(props) => (props.sm ? props.sm : (props.xs || 1) )}, 1fr);
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(${(props) => (props.xs ? props.xs : 1)}, 1fr);
  }
`

Grid.propTypes = {
  gap: PropTypes.number,
  xs: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  lg: PropTypes.number,
  backgroundColor: PropTypes.string
}

Grid.defaultProps = {
  gap: 0,
  backgroundColor: 'white'
}

export default Grid
