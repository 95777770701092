import React from 'react'
import * as S from './styles'

const Heading = (props) => {
  const { children, ...restProps } = props;
  const { variation } = restProps;

  return (
    <S.Title { ...restProps } className={variation} >
      {children}
    </S.Title>
  )
}

export default Heading
