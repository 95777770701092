import React from 'react'
import * as S from './styles'

export default function Forms(props) {
  const {gridTemplate, children } = props;


  return (
    <S.Form gridTemplate={gridTemplate} >
      {children}
    </S.Form>
  )
}

