import styled from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  grid-area: ${({ name }) => name};
  /* height: 58px; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 15px;

  // Cor do bg do autocomplete
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

  // Cor do texto do autocomplete
  input:-webkit-autofill {
    -webkit-text-fill-color: $darkGray !important;
  }

  /* input {
		border-bottom: 1px solid ${({
    theme: {
      colors: { grayLight }
    }
  }) => grayLight};
		width: 100%;
    padding: 10px 0 3px;
		font-size: ${({
    theme: {
      sizes: { small }
    }
  }) => small};
    color:  ${({
    theme: {
      colors: { blackLight }
    }
  }) => blackLight};
    &::placeholder {
      color:${({
    theme: {
      colors: { grayLight }
    }
  }) => grayLight};
    }

    &:focus {
      outline: none;
      border-bottom: 1px solid ${({
    theme: {
      colors: { grayLight }
    }
  }) => grayLight};
      color:  ${({
    theme: {
      colors: { blackLight }
    }
  }) => blackLight};
    }

    &:focus-within ~ label {
    transform: translateY(-1.1rem) scale(0.85);
    } */

  ${media.greaterThan('medium')`
     font-size: ${({
       theme: {
         sizes: { medium }
       }
     }) => medium};
    `}
`

export const Container = styled.label`
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 5px;
  height: 45px;
  border-bottom: 1px solid
    ${({
      theme: {
        colors: { grayLight }
      }
    }) => grayLight};

  .MuiInputLabel-formControl {
    margin-left: -8px;
  }

  .MuiInput-formControl {
    margin-top: 9px;
  }
  .MuiSelect-nativeInput {
    height: 60px;
  }

  .MuiInputLabel-formControl,
  .MuiInputBase-root {
    margin-left: -9px;
    font-family: ${({
      theme: {
        font: { family }
      }
    }) => family};
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: #e71037;
  }
  .MuiSelect-root {
    color: #4a4b4a;
  }
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected {
    background-color: #e71037;
  }

  .MuiMenuItem-root {
    font-size: 1.6rem;
    font-family: ${({
      theme: {
        font: { family }
      }
    }) => family};
  }

  .MuiPaper-root {
    display: flex;
    align-items: center;
    top: -38px;
    left: 332px;
  }

  .MuiMenuItem-root {
    font-family: ${({
      theme: {
        font: { family }
      }
    }) => family};
    font-size: 16px;
  }

  .MuiListItem-button:hover {
    background-color: #e71037;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputLabel-formControl {
    font-size: ${({
      theme: {
        sizes: { xsmall }
      }
    }) => xsmall};
    color: ${({
      theme: {
        colors: { grayDark }
      }
    }) => grayDark};
    line-height: ${({
      theme: {
        spacings: { small }
      }
    }) => small};
    margin-bottom: 0;
    padding: 0;
    position: absolute;
    transform-origin: 0%;
    transform: translateY(-0.9rem) scale(0.95);

    ${media.greaterThan('large')`
      font-size: 14px;
    `}
  }

  .MuiListItem-button:hover {
    background-color: #e71037;
  }

  .MuiSelect-select:focus {
    background-color: #fff;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #e71037;
  }

  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background-color: #e71037;
  }
  .MuiSvgIcon-root {
    display: none;
  }

  .MuiSelect-select.MuiSelect-select,
  .MuiMenuItem-root {
    padding-bottom: 0;
    font-size: 16px;
  }

  .MuiInput-underline:after {
    border-bottom: none;
  }

  .MuiList-padding {
  }
  .MuiList-root {
    height: 100px;
    padding: 20px;
  }
  .MuiMenu-list {
    font-size: ${({
      theme: {
        sizes: { medium }
      }
    }) => medium};
    width: 70px;
  }
  .MuiInput-underline:before {
    display: none;
  }

  .MuiButtonBase-root {
    display: inline-flex;
  }

  img,
  svg {
    height: 23px;
    margin-top: 19px;
  }

  span {
    right: 0;
    cursor: pointer;
    position: absolute;
    pointer-events: none;
  }
`

export const Division = styled.div`
  display: inline-flex;
`

export const Modal = styled.div``

export const ErrorText = styled.span`
  font-size: 12px;
  color: #ed4848;
  margin-top: 2px;
`
