/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router";

import Button from "components/Button";
import Spinner from "components/Spinner";
import Grid from "components/Grid";
import Layout from "components/OldLayout";
import CarouselCard from "./components/carouselCard";
import Loading from "components/Loading";
import OneButtonPopup from "components/modals/OneButtonPopup";

import { cpfMask, phoneMask } from "utils/mask";
import constants from "utils/constants";
import useApi from "hooks/useApi";

import * as St from "./styles";
import { GlobalContext } from "context/GlobalContext";

const CardDesign = () => {
  const [designSelected, setDesignSeleced] = useState();
  const [msgDialog, setMsgDialog] = useState("");
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [cardDesign, setCardDesign] = useState([]);
  const [cardInformation, setCardInformation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { proposta } = useContext(GlobalContext);
  const [phoneZeuss, setPhoneZeuss] = useState();

  const history = useHistory();
  const { state } = useLocation();
  const handleSelect = (card) => setDesignSeleced(card);
  const handleCardInformation = (cardInfo) => {
    if (
      cardInfo &&
      typeof cardInfo === "object" &&
      cardInfo.hasOwnProperty("categoria")
    ) {
      if (cardInfo.categoria === "Platinum") {
        setCardInformation({
          ...cardInfo,
          nome: "Plano Platinum",
          linkContrato: cardInfo.link,
          valorMensalidade: cardInfo.valor,
        });
      } else {
        setCardInformation(cardInfo);
      }
    } else {
      setCardInformation(cardInfo);
    }
  };

  const PostValidateZeussPhone = useApi(
    constants.endpoints.postValidateZeussPhone
      .replace("PARAM_CPF", cpfMask(state?.cpf).unmasked)
      .replace(
        "PARAM_PHONE",
        !phoneZeuss ? "" : phoneMask(phoneZeuss).unmasked
      ),
    "POST"
  );

  const PostSaveChosenBenefits = useApi(
    constants.endpoints.postSaveChosenBenefits,
    "POST"
  );

  const GetThemeListCreditCard = useApi(
    constants.endpoints.getThemeListCreditCard,
    "GET"
  );

  const getThemeCard = async () => {
    try {
      const response = await GetThemeListCreditCard.callApi();
      if (response.status >= 200 && response.status < 300) {
        setCardDesign(response.data.data);
      }
    } catch (error) {
      setMsgDialog(error.message);
      setDialogIsOpen(true);
    }
  };

  useEffect(() => {
    getThemeCard();
  }, []);

  const activateButton = () => {
    if (designSelected === "PLATINUM" && !phoneZeuss) {
      return true;
    } else if (!designSelected) {
      return true;
    } else {
      return false;
    }
  };
  const saveBenefits = async () => {
    const body = [
      // beneficios
      ...state.benefits.contracted.map((benefit) => ({
        cpf: state.cpf,
        idProposta: state.propostaId,
        idBeneficio: benefit.idBeneficio,
        linkContrato: benefit.linkContrato,
        nomeContrato: benefit.nome,
        valorMensalidadeContrato: benefit.valorMensalidade,
        descricaoContrato: benefit.descricao,
        linkSiteContrato: benefit.link,
      })),
      // contrato padrao teucard
      {
        cpf: state.cpf,
        idProposta: state.propostaId,
        tema: designSelected,
        celularZeuss:
          designSelected === "PLATINUM" ? phoneMask(phoneZeuss).unmasked : "",
        linkContrato: "",
        nomeContrato: "Proposta padrão",
        valorMensalidadeContrato: 0,
      },
      // cartao selecionado
    ];
    if (designSelected === "PLATINUM") {
      body.push({
        cpf: state.cpf,
        idProposta: state.propostaId,
        tema: designSelected,
        celularZeuss: phoneMask(phoneZeuss).unmasked,
        linkContrato: cardInformation.linkContrato,
        nomeContrato: cardInformation.nome,
        valorMensalidadeContrato: cardInformation.valorMensalidade,
        descricaoContrato: cardInformation.descricao,
        linkSiteContrato: cardInformation.linkContrato,
      });
    }
    await PostSaveChosenBenefits.callApi(body);
  };
  console.log("STATE CHOSE CARD", state);
  console.log("CARD INFO", cardInformation);
  const handleSubmitZeuss = async () => {
    setIsLoading(true);

    try {
      const response = await PostValidateZeussPhone.callApi();

      if (response.status >= 200 && response.status < 300) {
        history.push("/proposta", {
          propostaId: state.propostaId,
          benefits: state?.benefits,
          desingResponse: cardDesign,
          cardInfo: cardInformation,
          themeSelected: designSelected,
          phone: phoneMask(phoneZeuss).unmasked,
          cpf: state.cpf,
        });
      } else if (response.status >= 400) {
        setMsgDialog(response.mensagem);
        setDialogIsOpen(true);
      }
    } catch (err) {
      console.error(err.message);
      setMsgDialog(err.message);
      setDialogIsOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitStandard = () => {
    history.push("/proposta", {
      propostaId: state.propostaId,
      benefits: state?.benefits,
      desingResponse: cardDesign,
      cardInfo: cardInformation,
      themeSelected: designSelected,
      cpf: state?.cpf,
    });
  };

  const handleSubmit = async () => {
    await saveBenefits();
    if (designSelected === "PLATINUM") {
      handleSubmitZeuss();
    } else {
      handleSubmitStandard();
    }
  };

  return (
    <St.Wrapper>
      <Loading status={GetThemeListCreditCard.loading} />
      <OneButtonPopup
        defaultButton
        text={msgDialog}
        buttonText="Entendi"
        visibility={dialogIsOpen}
        setVisibility={() => setDialogIsOpen(false)}
      />
      <Layout title="Escolha o seu tipo de cartão" routeBack={"/beneficios"}>
        <St.Infos>
          <St.Text>Por favor, escolha um modelo de cartão:</St.Text>
          <St.CarouselContainer>
            {/* <St.CardSelected>
            {designSelected ? (
              <img src={designSelected.image} alt="big-card"></img>
            ) : (
              <St.GrayText>Nenhum modelo selecionado</St.GrayText>
            )}
          </St.CardSelected> */}

            <CarouselCard
              cards={cardDesign}
              onChange={(e) => handleSelect(e)}
              onCardInformation={(e) => handleCardInformation(e)}
              onSelect={(phone) => setPhoneZeuss(phone)}
            />
            {/* <Grid xs={1} sm={2} md={2} lg={4} gap={20}>
            {CARD_TYPES.map((el) => (
              <St.CardImage
                selected={designSelected?.theme === el.theme}
                src={el.image}
                onClick={() => handleSelect(el)}
                alt="card-design"
              />
            ))}
          </Grid> */}
          </St.CarouselContainer>
        </St.Infos>
        <St.Footer>
          <Button
            buttonType="primary"
            text={isLoading ? <Spinner color="#FFF" /> : "Salvar"}
            onClick={handleSubmit}
            disabled={
              activateButton() ||
              PostSaveChosenBenefits.loading ||
              isLoading ||
              PostValidateZeussPhone.loading
            }
            className="button"
          />
        </St.Footer>
      </Layout>
    </St.Wrapper>
  );
};

export default CardDesign;
