import React from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import Button from "components/Button";
import { useHistory } from "react-router-dom";
import themes from "styles";
import { useLoan } from "hooks/useLoan";

const CreditCardLoanSimulatorFinish = () => {
  const history = useHistory();

  const { finishResponse } = useLoan();
  return (
    <St.SafeView>
      <Layout
        routeBack="/paymentSimulator"
        title={
          finishResponse.error
            ? "Falha na simulação"
            : "Simulação realizada com sucesso!"
        }
      >
        <St.Container>
          <St.TopSpace>
            <St.Content>
              <img
                src={
                  finishResponse.error
                    ? themes.components.png.finishError
                    : themes.components.png.finish
                }
              ></img>
              {finishResponse.error ? (
                <St.Title>
                  Houve um erro com a tua solicitação, tente novamente!
                </St.Title>
              ) : (
                <St.Title>
                  Teus dados estão sendo processados e analisados. Assim que
                  estiver tudo certo, nós te avisaremos! Fique atendo no teu
                  celular. Podemos te ligar ou enviar um SMS.
                </St.Title>
              )}
            </St.Content>
            <St.Footer>
              <Button
                buttonType="primary"
                text={"Voltar à página inicial"}
                onClick={() => history.push("/opcoes-acesso")}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default CreditCardLoanSimulatorFinish;
