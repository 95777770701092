import styled from "styled-components";
import media from "styled-media-query";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const ValueSimulate = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const SimulateEditTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

export const TextItemValue = styled.span`
  font-size: 26px;
  font-weight: 900;
  text-align: end;
  margin-top: 60px;
`;

export const MiddleContainerItems = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  align-items: baseline;
  font-size: 1.5rem;
  margin-top: 5rem;
  margin-bottom: 7rem;
  padding: 10;
`;

export const ValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: center;
`;

export const TextItem = styled.span`
  font-size: 14px;
  font-weight: ${(props) => props.font === "bold" ? 'bold' : 'normal'};;
  margin-left: 5px;
  width: 25rem;
`;
export const TextItemValueResult = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
  width: 15rem;
  text-align: end;
`;

export const Rounded = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #f57e38;
`;

export const MiddleContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 7rem;
`;

export const Footer = styled.div`
margin-top: 25px;
  bottom: 0;
  width: 100%;

  @media (max-width: 712px) {
    padding: 20px;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  }
`;
