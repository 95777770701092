import { dateMask } from "./mask"

export const transformInNormalDate = (value = '') => {
  const [year,month,day] = value.split('-')

  return dateMask(`${day?.substring(0,3)}${month}}${year}`)
}

export const transformInGlobalDate = (value = '') => {
  const [day, month, year] = value.split('/');

  return `${year}-${month}-${day}`;
};

export const returnMonth = (month) => {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  return months[month];
};
