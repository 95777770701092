import React from "react";
import { useHistory } from "react-router-dom";

import Layout from "components/OldLayout";

import { useLoan } from "hooks/useLoan";
import { moneyMask } from "utils/mask";
import Button from "components/Button";

import * as St from "./styles";
import themes from "styles";
const LeadsLoanSeeker = () => {
  const {
    components: { png },
  } = themes;

  const { simulationType } = useLoan();

  const history = useHistory();

  return (
    <St.SafeView>
      <Layout routeBack="/confirm-data-loan-seeker" title="Simulação">
        <St.TopSpace>
          <St.Title justifyContent="center">
            Que legal, encontramos tua última simulação:
          </St.Title>
        </St.TopSpace>
        <St.Container>
          <St.FoundLead>
            <div>
              <p>Valor Simulado</p>
              <span>{moneyMask(simulationType?.valorLiberado)}</span>
            </div>
            <img src={png.ornament} />
          </St.FoundLead>
          <St.Simulation>
            <div style={{ alignItems: "flex-start" }}>
              <p>Tipo de simulação</p>
              <span>Valor do empréstimo</span>
            </div>
            <div style={{ alignItems: "flex-end" }}>
              <St.ImageItem src={png.iconSaque} />
            </div>
            <div style={{ alignItems: "flex-start" }}>
              <p>Parcela</p>
              <span>{`${simulationType.numeroParcelas}x de ${moneyMask(
                simulationType?.valorParcela
              )}`}</span>
            </div>
            <div style={{ alignItems: "flex-end" }}>
              <p>Taxa de Juros</p>
              <span>{`${(simulationType.taxaJuros * 100).toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 }
              )}%`}</span>
            </div>
          </St.Simulation>
          <St.Footer>
            <Button
              buttonType="secondary"
              style={{ marginBottom: "1rem" }}
              text="Quero simular outro valor"
              onClick={() => history.push("/simulation-loan-seeker")}
            />
            <Button
              buttonType="primary"
              text="Contratar"
              onClick={() => history.push("/additional-data-loan-seeker")}
            />
          </St.Footer>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default LeadsLoanSeeker;
