import clickSound from "../data/clickSound.json";

export function getImageSize(videoWidth, videoHeight, sizeFactor) {
  let imageWidth = videoWidth * parseFloat(sizeFactor);
  let ratio = videoWidth / imageWidth;
  let imageHeight = videoHeight / ratio;

  return {
    imageWidth,
    imageHeight,
  };
}

export function getDataURI(video) {
  var canvas = document.createElement("canvas");

  let { imageWidth, imageHeight } = getImageSize(
    video.videoWidth,
    video.videoHeight,
    1
  );

  canvas.width = imageWidth;
  canvas.height = imageHeight;
  canvas.getContext("2d", {}).drawImage(video, 0, 0, imageWidth, imageHeight);

  return canvas.toDataURL("image/jpeg", 0.5);
}

export function playClickAudio() {
  let audio = new Audio("data:audio/mp3;base64," + clickSound.base64);
  audio.play();
}
