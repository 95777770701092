import styled from 'styled-components'
import media from 'styled-media-query'
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack'


export const Wrapper = styled.div`
  margin: 30px;

  .secondary {
    margin-bottom: 0;
  }

  #viewer { 
    #title {
      display: none;
    }
  }
  
  object{
    width: 100%;
    height: 300px;

    &::-webkit-scrollbar {
    border-radius:  ${({ theme: { borderRadius: {  borderRadiusSM }}}) =>   borderRadiusSM };
    width: 5px;
    height: 5px;
  }
    &::-webkit-scrollbar-thumb {
      background:  #C8C8C8; 
      border-radius: 10px;
    }

    embed {

    }
  }
`

export const Infos = styled.div`
  /* height: 500px; */
  /* margin-bottom: ${({ theme: { spacings: {  small }}}) =>  small }; */

  div:nth-child(2) {
    height: 230px;
  
    ${media.greaterThan("medium")`
     height: 300px;
  `}
  }
`

export const ProposalPDF = styled.div `
  height: 300px;
  margin-bottom: 20px;

  iframe {
    width:100%;
    height: 100%;
    border: none;
    margin-bottom: 20px;
  
  }

  iframe:focus {
  outline: none;
}

iframe[seamless] {
  display: block;
}

`

export const Header = styled.div`
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };
  align-items: center;
  margin-bottom: ${({ theme: { spacings: {  medium }}}) =>  medium };
  margin-top: 30px;
`

export const IconArrow = styled(ArrowBack)`
  width: 20px;
  height: 23px;
  position: absolute;

  path {
    fill: ${({ theme: { colors: { gray }}}) => gray };
  }
`

export const TextBold = styled.p`
  text-align: left;
  font-size: ${({theme: {sizes: { small }}}) => small };
  font-weight: ${({theme: { fontWeight: { fontWeightSemiBold }}}) => fontWeightSemiBold };
  color:  ${({ theme: { colors: { blackLight }}}) => blackLight };
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };
  margin-bottom: ${({ theme: { spacings: {  xxlarge }}}) =>  xxlarge };

  ${media.greaterThan("medium")`
    font-size: ${({theme: {sizes: { medium }}}) => medium };
  `}

`

export const Text = styled.p`
  text-align: left;
  font-size: ${({theme: {sizes: { small }}}) => small };
  font-weight: ${({theme: { fontWeight: { fontWeightRegular }}}) => fontWeightRegular };
  color:  ${({ theme: { colors: { blackLight }}}) => blackLight };
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };
  /* margin-bottom: ${({ theme: { spacings: {  small }}}) =>  small }; */

  ${media.greaterThan("medium")`
    /* font-size: ${({theme: {sizes: { medium }}}) => medium }; */
  `}

`
export const Buttons = styled.div`
  .button {
    margin-bottom: ${({ theme: { spacings: {  xsmall }}}) =>  xsmall };
  }

`

export const Modal = styled.div`
  width: 300px;
  height: 250px;
  border-radius: ${({ theme: { borderRadius: { borderRadiusSM }}}) => borderRadiusSM };
  background-color: ${({ theme: { colors: { white }}}) => white };
  z-index: -1;

  ${media.greaterThan("medium")`
    width:400px;
    height: 300px;
    font-size: ${({theme: {sizes: { medium }}}) => medium };
  `}

`
export const ModalInfoDivision = styled.div`
  padding: ${({ theme: { spacings: {  small }}}) =>  small };
  height: 175px;

  ${media.greaterThan("medium")`
    height: 205px;
  `}

`

export const ModalDivision = styled.div`
  border-top: 1px solid #E0E0E0;
  display: flex;
  justify-content: space-between;

  .button {
    margin: 15px;
    width: 50%;
  }

  .buttonTwo {
    width: 100%;
  }

`

export const Img = styled.img`
  margin-bottom: ${({ theme: { spacings: {  small }}}) =>  small };
`
export const TextModal = styled.p`
  text-align: left;
  height: 80px;
  font-size: ${({theme: {sizes: { small }}}) => small };
  font-weight: ${({theme: { fontWeight: { fontWeightRegular }}}) => fontWeightRegular };
  color:  ${({ theme: { colors: { blackLight }}}) => blackLight };
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };
  margin-bottom: ${({ theme: { spacings: {  small }}}) =>  small };

`

// export const ButtonCad = styled.button` 
//   display: flex;
//   flex-direction: row;
//   margin-top: 20px;


  
//   button {
//     width: 45%;
//     margin: 10px;
//     /* padding: 5px; */
//   }
