import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 150px;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #eee;
  border-radius: 27px;
  align-items: center;

  strong {
    color: #e71037;
    font-size: 22px;
  }
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 700;
  color: #444444;
`;

export const ButtonRounded = styled.button`
  border-radius: 50%;
  width: 54px;
  height: 54px;
  background: #d9d9d9;
  align-items: center;
  justify-content: center;

  span {
    color: #e71037;
    font-size: 25px;
    font-weight: 700;
  }

  &:disabled {
    background: rgba(217, 217, 217, 0.5);
    cursor: not-allowed;
  }
`;
