/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useReducer, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Layout from "components/OldLayout";
import InputSelectSearch from "components/SelectSearch/inputSelect";
import InputSelect from "components/InputSelect";
import Input from "components/Input";
import Button from "components/Button";
import OneButtonPopup from "components/modals/OneButtonPopup";
import Loading from "components/Loading";

import { moneyBrazilian } from "utils/mask";
import { useLoan } from "hooks/useLoan";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import moment from "moment";

import * as St from "./styles";
import themes from "styles";

const inputStyle = {
  border: "1px solid #6c6c6c",
  padding: "1.7rem",
  borderRadius: ".5rem",
  width: "100%",
};

const AdditionalLoanSeeker = () => {
  const [openSelect, setOpenSelect] = useState(false);
  const [professions, setProfessions] = useState([]);
  const [filteredProfessions, setFilteredProfessions] = useState([]);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const initialState = {
    occupation: "",
    profession: "",
    income: "",
    payDay: "",
    house: false,
    car: false,
    creditCard: false,
    cellPhoneAndroid: false,
    restriction: false,
  };

  const [data, dispatch] = useReducer(reducer, initialState);
  const {
    occupation,
    profession,
    income,
    payDay,
    house,
    creditCard,
    restriction,
    car,
    cellPhoneAndroid,
  } = data;

  const {
    dispatchUserAditionalData,
    dispatchSolicitationData,
    userInitialData,
    dispatchSimulationData,
  } = useLoan();

  const postClientInclude = useApi(
    constants.endpoints.postIncludeClientEasyCredito,
    "POST"
  );

  const getProfessions = useApi(
    constants.endpoints.getProfessionsByEasyCredito,
    "GET"
  );

  useEffect(() => {
    const getProfession = async () => {
      const { data } = await getProfessions.callApi();
      if (data) {
        const profession = data
          ?.sort((a, b) => Number(b.code) + Number(a.code))
          ?.map((e) => ({
            value: e.code,
            label: e.name,
            name: e.name,
            code: e.code,
          }));
        setProfessions(profession);
        setFilteredProfessions(profession);
      }
    };
    getProfession();
  }, []);

  function reducer(state, action) {
    const { type, payload } = action;
    if (type === "UPDATE_CHECKBOX") {
      return {
        ...state,
        [payload.name]: payload.checked,
      };
    }

    if (type !== "UPDATE_CHECKBOX") {
      const { type, payload } = action;
      return { ...state, [type]: payload };
    }
    return state;
  }

  const occupationList = [
    { value: "disabled", label: "Selecione", hidden: true },
    { value: "APOSENTADO_PENSIONISTA", label: "Aposentado ou Pensionista" },
    { value: "AUTONOMO", label: "Autônomo" },
    { value: "EMPRESARIO", label: "Empresário ou Empregador" },
    { value: "PROFISSIONAL_LIBERAL", label: "Profissional Liberal" },
    { value: "ASSALARIADO", label: "Funcionário com carteira assinada (CLT)" },
    { value: "FUNCIONARIO_PUBLICO", label: "Funcionário Público ou Militar" },
    { value: "DESEMPREGADO", label: "Desempregado" },
  ];

  const payDays = [
    { value: "disabled", label: "Selecione", hidden: true },
    { value: 1, label: "01" },
    { value: 2, label: "02" },
    { value: 3, label: "03" },
    { value: 4, label: "04" },
    { value: 5, label: "05" },
    { value: 6, label: "06" },
    { value: 7, label: "07" },
    { value: 8, label: "08" },
    { value: 9, label: "09" },
    { value: 10, label: "10" },
    { value: 11, label: "11" },
    { value: 12, label: "12" },
    { value: 13, label: "13" },
    { value: 14, label: "14" },
    { value: 15, label: "15" },
    { value: 16, label: "17" },
    { value: 18, label: "18" },
    { value: 19, label: "19" },
    { value: 20, label: "20" },
    { value: 21, label: "21" },
    { value: 22, label: "22" },
    { value: 23, label: "23" },
    { value: 24, label: "24" },
    { value: 25, label: "25" },
    { value: 26, label: "26" },
    { value: 27, label: "27" },
    { value: 28, label: "28" },
    { value: 29, label: "29" },
    { value: 30, label: "30" },
    { value: 31, label: "31" },
  ];
  useEffect(() => {
    if (data) {
      dispatchUserAditionalData(data);
    }
  }, [data]);

  const onChange = (e, mask) => {
    const {
      target: { name, value, type, checked },
    } = e;

    if (type === "checkbox") {
      dispatch({ type: name, payload: checked });
    } else {
      dispatch({ type: name, payload: mask ? mask(value) : value });
    }
  };

  const validInput = () => {
    return !income || !payDay || !occupation || !profession;
  };

  const handleFilterProfessions = (e) => {
    const { value } = e.target;
    dispatch({ type: "profession", payload: value });
    setFilteredProfessions(
      professions.filter((profession) =>
        profession?.label?.toLowerCase().trim().includes(value?.toLowerCase())
      )
    );
  };

  const handleNext = async () => {
    setButtonIsLoading(true);
    const payload = {
      celular:
        userInitialData?.data?.celular?.unmasked ||
        userInitialData?.celular?.unmasked,
      cpf:
        userInitialData?.data?.cpf?.unmasked || userInitialData?.cpf.unmasked,
      dataNascimento:
        userInitialData?.data?.dataNascimento?.unmasked ||
        moment(userInitialData?.dataNascimento?.masked, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
      email: userInitialData?.data?.email || userInitialData?.email,
      nome: userInitialData?.data?.nome || userInitialData?.nome,
      temCartaoCredito: creditCard,
      temCasaPropria: house,
      temCelularAndroid: cellPhoneAndroid,
      temRestricoes: restriction,
      temVeiculo: car,
    };

    try {
      const response = await postClientInclude.callApi(payload);
      if (response.status >= 200 && response.status < 300) {
        setButtonIsLoading(false);
        dispatchSimulationData(response.data);
        dispatchSolicitationData(data);
        history.push("/complementary-data-loan-seeker");
      } else if (response.status >= 400) {
        setDialogOpen(true);
        setErrorMessage(response.mensagem);
        setButtonIsLoading(false);
      }
    } catch (error) {
      setButtonIsLoading(false);
      console.log(error);
    }
  };

  return (
    <St.SafeView>
      {/* <Loading status={getProfessions.loading} /> */}
      <Layout
        routeBack="/confirm-data-loan-seeker"
        title="Informe teus dados adicionais"
      >
        <OneButtonPopup
          text={errorMessage}
          buttonText="Entendi"
          title="Atenção"
          visibility={dialogOpen}
          iconModal={themes.components.png.emojiSad}
          defaultButton
          setVisibility={setDialogOpen}
          onClose={() => setDialogOpen(false)}
        />
        <St.Container>
          <St.TopSpace>
            <St.Inputs style={{ width: "100%" }}>
              <InputSelect
                labelFontWeight="bold"
                label="Ocupação*"
                type="select"
                name="occupation"
                style={{ ...inputStyle, padding: ".5rem 1rem" }}
                values={occupationList}
                value={occupation}
                defaultValue={occupation}
                max={20}
                onChange={(e) => onChange(e)}
              />
              <InputSelectSearch
                labelFontWeight="bold"
                name="profession"
                id="profession"
                style={{ ...inputStyle, padding: ".5rem 1rem" }}
                label="Profissão*"
                setOpen={setOpenSelect}
                onFocus={() => setOpenSelect(true)}
                open={openSelect}
                options={filteredProfessions}
                inputTextValue={profession?.label}
                value={profession?.label}
                defaultValue={profession?.label}
                onChange={handleFilterProfessions}
                onClickSelect={(e) =>
                  dispatch({ type: "profession", payload: e })
                }
              />
              <Input
                boldLabel
                name="income"
                id="income"
                value={income?.masked}
                defaultValue={income?.masked}
                style={inputStyle}
                label="Renda Mensal*"
                fontWeight="bold"
                color="#000"
                max={12}
                onChange={(e) => onChange(e, moneyBrazilian)}
              />
              <InputSelect
                labelFontWeight="bold"
                label="Dia de Pagamento*"
                type="select"
                name="payDay"
                style={{ ...inputStyle, padding: ".5rem 1rem" }}
                values={payDays}
                value={payDay}
                defaultValue={payDay}
                onChange={(e) => onChange(e)}
                max={2}
              />
            </St.Inputs>
            <St.BoxCheckbox>
              <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                <input
                  type="checkbox"
                  name="house"
                  checked={house}
                  value={house}
                  onChange={(event) => onChange(event)}
                />
                <label style={{ fontSize: "20px" }}>Possou casa própria.</label>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                <input
                  type="checkbox"
                  name="car"
                  checked={car}
                  value={car}
                  onChange={(event) => onChange(event)}
                />
                <label style={{ fontSize: "20px" }}>
                  Possuo veículo próprio.
                </label>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                <input
                  type="checkbox"
                  name="creditCard"
                  checked={creditCard}
                  value={creditCard}
                  onChange={(event) => onChange(event)}
                />
                <label style={{ fontSize: "20px" }}>
                  Possuo cartão de crédito.
                </label>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                <input
                  type="checkbox"
                  name="cellPhone"
                  checked={data?.cellPhone}
                  value={cellPhoneAndroid}
                  onChange={(event) => onChange(event)}
                />
                <label style={{ fontSize: "20px" }}>
                  Possuo celular com sistema android.
                </label>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                <input
                  type="checkbox"
                  id="restriction"
                  name="restriction"
                  value={restriction}
                  checked={restriction}
                  onChange={(event) => onChange(event)}
                />
                <label style={{ fontSize: "20px" }}>
                  Possuo restrição no meu nome
                </label>
              </div>
            </St.BoxCheckbox>
            <St.Footer>
              <Button
                buttonType="primary"
                text="Avançar"
                disabled={validInput() || buttonIsLoading}
                loading={buttonIsLoading}
                onClick={() => handleNext()}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default AdditionalLoanSeeker;
