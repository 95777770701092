import React, { useState } from "react";

import * as S from "./styles";
import Tooth from "../../assets/icons/tooth.svg";
import Prestamista from "../../assets/icons/prestamista.svg";
import VidaPrestamista from "../../assets/icons/vidaPrestamista.svg";
import BolsaProtegida from "../../assets/icons/bolsaProtegida.svg";
import TeuCardCuida from "../../assets/icons/Estetoscopio.svg";
import { moneyMask } from "utils/mask";

export default function BenefitCard({
  data = [],
  onClickBenefit,
  propostaId,
  isUserMoreThan65YearsOld,
}) {
  const [checked, setChecked] = useState([]);
  const handleCheck = (event, data) => {
    const found = checked.find(
      (e) => e.idBeneficioAutorizador === data.idBeneficioAutorizador
    );
    let updatedList = [...checked];
    if (event.target.checked) {
      if (found) return;
      updatedList = [
        ...checked,
        {
          beneficio: {
            idBeneficio: event.target.value,
            id: event.target.value,
            idBeneficioAutorizador: data.idBeneficioAutorizador,
            link: data.link,
            idOperador: JSON.parse(localStorage.getItem("@NovaEra:user")).id,
            idProposta: propostaId,
            assinada: true,
            nome: data.nome,
            valorMensalidade: data.valorMensalidade,
            valorAdesao: data.valorAdesao,
            descricao: data.descricao,
            linkContrato: data?.linkContrato,
            beneficioContratoPadrao: data?.beneficioContratoPadrao,
          },
        },
      ];
    } else {
      updatedList.splice(
        updatedList.findIndex(
          (e) => e.beneficio.idBeneficio === event.target.value
        ),
        1
      );
    }

    setChecked(updatedList);
    onClickBenefit(updatedList);
  };

  let isChecked = (item) =>
    checked.includes(item) ? "checked-item" : "not-checked-item";

  const renderIcon = (idAutorizador) => {
    switch (idAutorizador) {
      case 1:
        return Tooth;
      case 2:
        return Prestamista;
      case 3:
        return VidaPrestamista;
      case 4:
        return BolsaProtegida;
      case 5:
        return TeuCardCuida;
      default:
        return null;
    }
  };
  return (
    <>
      {data.map((item, index) => (
        <S.CardBeneift key={index}>
          <header>
            <div>
              <img
                src={renderIcon(item?.idBeneficioAutorizador)}
                alt={item.nome}
              />
              <h2>{item.nome}</h2>
            </div>
            <div>
              <span>{moneyMask(item.valorMensalidade)}</span>
              <span>/mês</span>
            </div>
          </header>
          <S.DivPaiDesc>
            <S.DivDesc>
              <p className="desc">{item.descricao}</p>
              <a
                className="link"
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Clique aqui para mais informações
              </a>
            </S.DivDesc>
            <S.DivCheck>
              <input
                value={item.id}
                type="checkbox"
                className={isChecked(item.id)}
                onChange={(e) => handleCheck(e, item)}
                disabled={
                  ![1, 5].includes(item.idBeneficioAutorizador) && isUserMoreThan65YearsOld
                }
              />
              <span className={isChecked(item.id)}>Aderir</span>
            </S.DivCheck>
          </S.DivPaiDesc>
        </S.CardBeneift>
      ))}
    </>
  );
}
