import React from "react";

import { ReactComponent as IconShape } from "./icons/shape.svg";
import { ReactComponent as IconCalendar } from "./icons/calendar.svg";
import { ReactComponent as IconPrinter } from "./icons/printer.svg";
import { ReactComponent as IconTick } from "./icons/tick.svg";
import { ReactComponent as IconWarning } from "./icons/warning.svg";
import allCards from "./icons/allCards.svg";
//Icones de emoji
import emojiAtivo from "./icons/ativo.svg";
import emojiCancelado from "./icons/cancelado.svg";
import emojiPendente from "./icons/pendente.svg";
import emojiSuspenso from "./icons/suspenso.svg";
import emojiSemAdesao from "./icons/sem-adesao.svg";
import emojiNovaAdesao from "./icons/nova-adesao.svg";

const icons = {
  shape: <IconShape />,
  calendar: <IconCalendar />,
  printer: <IconPrinter />,
  tick: <IconTick />,
  warning: <IconWarning />,
  emojiAtivo,
  emojiCancelado,
  emojiNovaAdesao,
  emojiPendente,
  emojiSemAdesao,
  emojiSuspenso,
  allCards,
};
const png = {
  opah: require("./png/logo.png"),
  calendar: require("./png/calendar.png"),
  shape: require("./png/shape.png"),
  printer: require("./png/printer.png"),
  eye: require("./png/eye.png"),
  tick: require("./png/exclamation.png"),
  warning: require("./png/warning.png"),
  arrow: require("./png/arrowDown.png"),
  user: require("./png/user.png"),
  rgFront: require("./png/rg-front.png"),
  rgBack: require("./png/rg-back.png"),
  redCard: require("./png/red-card.png"),
  platinumCard: require("./png/platinum-card.png"),
  roseCard: require("./png/rose-card.png"),
  whiteCard: require("./png/white-card.png"),
  blueCard: require("./png/blue-card.png"),
  userAvatar: require("./png/user-avatar.png"),
  odonto: require("./png/odonto.png"),
  handshake: require("./png/handshake.png"),
  checkGreen: require("./png/check-green.png"),
  checkYellow: require("./png/check-yellow.png"),
  cancel: require("./png/cancel.png"),
  exclamation: require("./png/exclamation.png"),
  cardSimulator: require("./png/credit-card-simulator.png"),
  logo: require("./png/logo.png"),
  chip: require("./png/chip.png"),
  logoMasterCard: require("./png/logo_mastercard.png"),
  logoVisa: require("./png/logo_visa.png"),
  logoElo: require("./png/logo-elo.png"),
  loan: require("./png/loan.png"),
  americanExpressFull: require("./png/logoAmericanExpressFull.png"),
  dinnersClubFull: require("./png/logoDinnersClubFull.png"),
  eloFull: require("./png/logoEloFull.png"),
  masterCardFull: require("./png/logoMasterCardFull.png"),
  visaFull: require("./png/logoVisaFull.png"),
  hiperCardFull: require("./png/logoHipercardFull.png"),
  iconSaque: require("./png/icon-saque.png"),
  iconParcela: require("./png/icon-parcela.png"),
  iconLimite: require("./png/icon-limite.png"),
  iconEmprestimo: require("./png/icon-emprestimo.png"),
  star: require("./png/star.png"),
  finishIcon: require("./png/finishIcon.png"),
  finishError: require("./png/finishIconError.png"),
  ornament: require("./png/ornament.png"),
  withDraw: require("./png/with-draw.png"),
  teuCardCards: require("./png/teu-card-cards.png"),
  visible: require("./png/visible.png"),
  invisible: require("./png/invisible.png"),
  emojiSuccess: require("./png/emoji-success.png"),
  emojiSad: require("./png/emoji-sad.png"),
  searchLoans: require("./png/search_money.png"),
  teuCredBankings: require("./png/teuCred_bankings.png"),
  documentsPhoto: require("./png/documents_photo.png"),
  checkCircle: require("./png/check_circle.png"),
  information: require("./png/information.png"),
};

export default { icons, png };
