import Spinner from "components/Spinner";
import React from "react";
import * as S from "./styles";

export default function Button({
  text,
  onClick,
  key,
  buttonType,
  name,
  disabled,
  children,
  id,
  className,
  loading,
  ...others
}) {
  const newClassName = [className, buttonType].join(" ");

  return (
    <S.Button
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
      name={name}
      key={key}
      id={id}
      className={newClassName}
      disabled={disabled}
      {...others}
    >
      {children}
      <span>{loading ? <Spinner color="#FFF" /> : text}</span>
    </S.Button>
  );
}
