/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Layout from "components/OldLayout";
import Input from "components/Input";
import InputSelect from "components/InputSelect";
import Button from "components/Button";
import OneButtonPopup from "components/modals/OneButtonPopup";

import { cepMask } from "utils/mask";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import { useLoan } from "hooks/useLoan";
import {
  optionsUfs,
  typeResidenceList,
  residenceTimeList,
} from "../identificationData/constants";

import * as St from "./styles";
import themes from "styles";

const inputStyle = {
  border: "1px solid #6c6c6c",
  padding: "1rem",
  borderRadius: ".5rem",
  width: "100%",
  height: "4rem",
};

const ResidentialDataLoanSeeker = () => {
  const [validate, setValidate] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const initialState = {
    cep: cepMask(),
    endereco: "",
    numero: "",
    cidade: "",
    complemento: "",
    bairro: "",
    uf: "",
    typeResidence: "",
    residenceTime: "",
  };

  const [data, dispatch] = useReducer(reducer, initialState);
  const {
    cep,
    endereco,
    numero,
    complemento,
    uf,
    bairro,
    cidade,
    typeResidence,
    residenceTime,
  } = data;

  const history = useHistory();

  const { userInitialData, userAditionalData, dispatchUserAditionalData } =
    useLoan();

  const [cepRequest, setCepRequest] = useState(
    !userAditionalData?.endereco?.cep
  );

  const GetCep = useApi(
    constants.endpoints.findAddressByCep.replace("PARAM_CEP", cep?.unmasked),
    "GET"
  );

  useEffect(() => {
    if (userInitialData?.userInfo?.endereco) {
      dispatch({
        type: "address",
        payload: userInitialData?.userInfo?.endereco,
      });
      setValidate(false);
    }
  }, []);

  function reducer(data, action) {
    const { type, payload } = action;

    switch (type) {
      case "address":
        return {
          ...data,
          cep: cepMask(payload?.cep),
          uf: payload?.uf,
          endereco: payload?.logradouro,
          cidade: payload?.cidade,
          numero: payload?.numero,
          bairro: payload?.bairro,
          uf: payload?.uf,
        };

      default:
        return { ...data, [type]: payload };
    }
  }

  useEffect(() => {
    const getCep = async () => {
      const { data } = await GetCep.callApi();

      const payload = {
        cep: cep?.unmasked ?? data.cep,
        uf: data?.uf || "",
        logradouro: data?.logradouro || "",
        cidade: data?.localidade || "",
        bairro: data?.bairro || "",
      };

      dispatch({ type: "address", payload });
    };

    if (cep?.unmasked?.length === 8 && cepRequest) {
      setCepRequest(false);
      getCep();
    }
  }, [GetCep, cep, cepRequest]);

  const onChange = (e, mask) => {
    const {
      target: { name, value },
    } = e;

    if (name === "cep") {
      setCepRequest(true);
    }
    dispatch({ type: name, payload: mask ? mask(value) : value });
  };

  const validateInputs = () => {
    return (
      !cep ||
      !endereco ||
      numero?.length < 0 ||
      !cidade ||
      !uf ||
      !bairro ||
      !typeResidence ||
      !residenceTime
    );
  };

  const handleNext = () => {
    try {
      if (!typeResidence && !residenceTime) {
        setDialogOpen(true);
        setErrorMessage(
          "É necessário selecionar o tipo de residência e o tempo de residência"
        );
      } else {
        dispatchUserAditionalData({ ...userAditionalData, data });
        history.push("/banking-data-loan-seeker");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <St.SafeView>
      <Layout
        routeBack="/identification-data-loan-seeker"
        title="Informe teus dados residênciais"
      >
        <OneButtonPopup
          text={errorMessage}
          buttonText="Entendi"
          title={"Atenção!"}
          visibility={dialogOpen}
          iconModal={themes.components.png.emojiSad}
          defaultButton
          setVisibility={setDialogOpen}
          onClose={() => setDialogOpen(false)}
        />
        <St.Container>
          <St.TopSpace>
            <St.Inputs>
              <Input
                boldLabel
                label="CEP*"
                style={inputStyle}
                value={cep?.masked}
                name="cep"
                onChange={(e) => onChange(e, cepMask)}
              />
              <Input
                boldLabel
                label="Endereço"
                style={inputStyle}
                value={endereco}
                name="endereco"
                onChange={(e) => onChange(e)}
              />
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
              >
                <div style={{ width: "20%" }}>
                  <Input
                    style={inputStyle}
                    boldLabel
                    label="Número*"
                    name="numero"
                    value={numero}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div style={{ width: "80%" }}>
                  <Input
                    style={inputStyle}
                    boldLabel
                    name="complemento"
                    label="Complemento (opcional)"
                    value={complemento}
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
              >
                <div style={{ width: "40%" }}>
                  <Input
                    name="bairro"
                    style={inputStyle}
                    boldLabel
                    label="Bairro*"
                    value={bairro}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div style={{ width: "30%" }}>
                  <Input
                    name="cidade"
                    style={inputStyle}
                    boldLabel
                    label="Cidade*"
                    value={cidade}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div style={{ width: "30%" }}>
                  <InputSelect
                    labelFontWeight="bold"
                    style={inputStyle}
                    label="Estado*"
                    name="uf"
                    id="uf"
                    type="select"
                    values={optionsUfs}
                    value={uf}
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
              <InputSelect
                labelFontWeight="bold"
                label="Tipo de Residência*"
                style={inputStyle}
                values={typeResidenceList}
                value={typeResidence}
                type="select"
                id="typeResidence"
                name="typeResidence"
                onChange={(e) => onChange(e)}
              />
              <InputSelect
                labelFontWeight="bold"
                label="Tempo de Residência*"
                style={inputStyle}
                values={residenceTimeList}
                value={residenceTime}
                type="select"
                id="residenceTime"
                name="residenceTime"
                onChange={(e) => onChange(e)}
              />
            </St.Inputs>
            <St.Footer>
              <Button
                buttonType="primary"
                text="Avançar"
                disabled={validateInputs()}
                onClick={() => handleNext()}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default ResidentialDataLoanSeeker;
