import styled from "styled-components";
import media from "styled-media-query";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const Title = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const Inputs = styled.div`
  margin-top: 2rem;
  align-items: center;
  justify-items: center;
`;

export const Footer = styled.div`
margin-top: 25px;
  position: fixed;
  bottom: 0;
  width: 40%;

  @media (max-width: 712px) {
    padding: 20px;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  }
`;
