import styled, { keyframes } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  margin: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  margin: auto;
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
`;

export const Carousel = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  color: #4a4b4a;
  font-size: 1.6em;
  letter-spacing: 0;
  line-height: 27px;
  text-align: flex-start;
`;
export const Text = styled.p`
  text-align: left;
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular },
    },
  }) => fontWeightRegular};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  margin-top: 45px;
`;

export const BoxSignature = styled.button`
  height: 200px;
  width: 100%;
  cursor: pointer;
  border: 1px solid #e71037;
  border-radius: 3px;
  background-color: rgba(231, 16, 55, 0.4);
  border-radius: 1rem;
  padding: 12px;
  margin-bottom: ${({
    theme: {
      spacings: { medium },
    },
  }) => medium};
`;
export const LineSignature = styled.div`
  height: ${(props) => (props.signature ? "90px" : "55px")};
  width: 80%;
  margin: auto;
  border-bottom: 2px dashed #e71037;
`;
export const ImgURL = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 250px;
  max-height: 130px;
`;

export const Footer = styled.div`
  margin-top: 45px;
  position: relative;
  bottom: 0;
  width: 100%;

  @media (max-width: 712px) {
    padding: 20px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`;
