import styled from "styled-components";
import media from "styled-media-query";
import Button from "components/Button";

/* pagination items */

export const PaginationContainerArrow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PaginationArrowImage = styled.img`
  height: 22px;
  width: 22px;
  cursor: pointer;
`;

export const PaginationArrowEmpty = styled.div`
  height: 22px;
  width: 22px;
  border: none;
`;

export const PaginationContainerLimit = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
`;

/* /pagination items */

export const Container = styled.div`
  padding: 30px;
  height: 100vh;
  padding-bottom: 50px;
  background-color: #fff;

  .react-datepicker__navigation {
    display: none;
  }

  .react-datepicker {
    height: 200px;
    left: 110%;

    ${media.greaterThan("large")`
      left:150%;
      margin: none
  `}
  }

  .react-datepicker__header {
    display: none;
  }
`;

export const Header = styled.div`
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  align-items: center;
  margin-bottom: ${({
    theme: {
      spacings: { medium },
    },
  }) => medium};
`;

export const TextBold = styled.p`
  text-align: left;
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold },
    },
  }) => fontWeightSemiBold};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  margin-bottom: ${({
    theme: {
      spacings: { xxlarge },
    },
  }) => xxlarge};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
  `}
`;
export const ContentInput = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonOne = styled(Button)`
  border-radius: ${({
    theme: {
      borderRadius: { borderRadiusSM },
    },
  }) => borderRadiusSM};
  background: ${({
    theme: {
      colors: { primary },
    },
  }) => primary};
  color: ${({
    theme: {
      colors: { white },
    },
  }) => white};
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold },
    },
  }) => fontWeightSemiBold};
`;

export const TitleProposalDate = styled.p`
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;

  margin: 10px 0 15px;
`;

export const ContentInputDate = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  input[name="cpf"] {
    margin-right: 10px;
  }

  button {
    width: auto;
    flex: 1;
  }
`;

export const WrapperFields = styled.div`
  width: 80%;
  margin: 0 auto;

  ${media.greaterThan("medium")`
    width: 70%;
    margin: 30px auto 0 auto;
  `}

  ${media.lessThan("large")`
    width: 100%;
    margin: 30px auto 0 auto;
  `}
`;

export const WrapperList = styled.div`
  margin-top: 30px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
  background: #fff;
  justify-content: center;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;

  header {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .title {
      h1 {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 5px;
        color: #2e3958;
      }

      &__update {
        font-size: 1rem;
        font-weight: 600;
        color: #2e3958;
        padding: 6px;
        background: #ecf1ff;
        border-radius: 5px;
        display: flex;
        align-items: center;
      }

      svg {
        font-size: 40px;
        color: #c9ced6;
        margin-right: 10px;
      }
    }
  }

  .react-icons {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px;
    color: #c9ced6;
    cursor: pointer;

    &:hover {
      color: #828282;
    }
  }

  section {
    display: flex;
    justify-content: space-around;
    align-items: center;

    article {
      text-align: center;

      h2 {
        font-family: "Montserrat", sans-serif;
        font-size: 2rem;
        font-weight: 700;
        color: #2e3958;
      }

      span {
        font-size: 1rem;
        font-weight: 700;
        color: #aeaeae;
      }
    }
  }

  &:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`;

export const ItemsDetails = styled.div`
  article {
    text-align: center;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: #2e3958;
    }

    span {
      font-size: 1rem;
      font-weight: 700;
      color: #aeaeae;
    }
  }
`;

export const CarouselItem = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  article {
    text-align: center;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: #2e3958;
    }

    span {
      font-size: 1rem;
      font-weight: 700;
      color: #aeaeae;
    }
  }
`;

export const Grid = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;

  @media (max-width: 712px) {
    display: flex;
    grid-template-columns: minmax(712px, auto);
    gap: 24px;
  }
`;

export const SubTitle = styled.span`
  margin: 10px 5px;
  font-size: 1.4rem;
  font-weight: 700;
  margin-left: 15px;
`;

export const BoxDetails = styled.div`
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -webkit-transition: 0.2s all;

  header {
    padding: 18px 12px;
    border-bottom: 1px solid #f6f6f6;
    display: flex;
    justify-content: space-between;
    .title {
      h1 {
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 5px;
        color: #2e3958;
      }

      &__update {
        font-size: 0.7rem;
        font-weight: 600;
        color: #2e3958;
        padding: 6px;
        background: #ecf1ff;
        border-radius: 5px;
        display: flex;
        align-items: center;
      }

      svg {
        font-size: 40px;
        color: #c9ced6;
        margin-right: 10px;
      }
    }
    h2 {
      font-size: 1.5rem;
      font-weight: bold;
      color: #2e3958;
      margin-bottom: 3px;
    }

    span {
      font-size: 0.7rem;
      font-weight: 700;
      color: #677383;
    }
  }
`;
export const ChartContainer = styled.div`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
`;
