import axios from "axios";
import Repository from "repository";

const api = axios.create();

api.interceptors.request.use(async (config) => {
  const token = Repository.storage.get(Repository.keys.TOKEN_USER);
  const tokenBearer = Repository.storage.get(Repository.keys.TOKEN_BEARER);
  const xCode = Repository.session.get(Repository.keys.X_CODE);

  if (token) config.headers.apikey = `token ${token}`;
  if (tokenBearer) config.headers.Authorization = `Bearer ${tokenBearer}`;
  if (xCode) config.headers.xcode = xCode;

  return config;
});

api.interceptors.response.use(
  (config) => {
    if (401 === config.status) {
      Repository.storage.removeAll();
      window.location = "/";
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default api;
