/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useContext, useCallback } from "react";
import useReactRouter from "use-react-router";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "context/GlobalContext";

import Layout from "components/Layout";
import ContainerFlex from "components/ContainerFlex";
import Spinner from "components/Spinner";
import OneButtonPopup from "components/modals/OneButtonPopup";

import Repository from "repository";
import constants from "utils/constants";
import useApi from "hooks/useApi";

export default function LoadingProposta() {
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [idPropostaAuth, setIdPropostaAuth] = useState("");
  const [idPropostaNoAuth, setIdPropostaNoAuth] = useState("");
  const [getSpc, setGetSpc] = useState(false);

  const [validationRes, setValidationRes] = useState({
    obterDadosProposta: null,
    temPropostaValida: null,
    mensagem: null,
    idProposta: null,
  });
  const { history } = useReactRouter();
  const { state } = useLocation();
  const { addProposta } = useContext(GlobalContext);

  const { tipoOrigem, cpf, celular, checkWhatsApp, uf, email } = state;
  useEffect(() => {
    (async () => {
      await addProposalBackoffice();
    })();
  }, []);

  const user = Repository.storage.get(Repository.keys.USER);
  const origem = Repository.storage.get(Repository.keys.ID_ORIGEM);

  const newCpf = cpf?.unmasked;
  const newPhone = celular?.unmasked;

  const URL =
    origem?.tipo !== "LOJA"
      ? constants.endpoints.initialProposalNoAuth
      : constants.endpoints.initialProposal;

  const PostInitialProposal = useApi(URL, "POST");

  const GetValidateProposalNoAuth = useApi(
    constants.endpoints.validateProposalNoAuth.replace(
      "PARAM_CPF",
      cpf?.unmasked
    ),
    "GET"
  );
  const GetProposalSpcData = useApi(
    constants.endpoints.spcProposalData.replace(
      "PARAM_ID_PROPOSAL",
      idPropostaAuth
    ),
    "GET"
  );
  const continuarProposta = useCallback(async () => {
    const resObtem = await GetProposalSpcData.callApi();
    if (resObtem)
      Repository.storage.set(Repository.keys.ID_PROPOSTA, idPropostaAuth);

    return Object.assign(resObtem?.data?.spc, {
      id: idPropostaAuth,
    });
  }, [GetProposalSpcData, idPropostaAuth]);

  const criarPropostaLoja = useCallback(async () => {
    const codeVerification = Repository.session.get(
      Repository.keys.CODE_VERIFICATION
    );
    Repository.session.set(Repository.keys.X_CODE, codeVerification);
    const payload = {
      cpf: newCpf,
      celular: newPhone,
      email: email,
      optinTermoDeUso: true,
      checkWhatsApp: checkWhatsApp,
      idUsuario: user?.id,
      origem: origem?.tipo,
      codigoAnfitriao: state?.idAnfitriao,
    };

    const res = await PostInitialProposal.callApi(payload);
    return res;
  }, [PostInitialProposal]);

  useEffect(() => {
    (async () => {
      if (idPropostaAuth) {
        const data = await continuarProposta();
        if (data) {
          addProposta(data);

          history.push("/cadastro-completo", {
            cpf: cpf?.unmasked,
            celular: celular?.unmasked,
            email: state?.email,
            idAnfitriao: state?.idAnfitriao,
          });
        }
      }
    })();
  }, [idPropostaAuth]);

  const iniciarPropostaLoja = useCallback(async () => {
    try {
      const res = await criarPropostaLoja();

      if (res.status >= 200 && res.status <= 300) {
        if (res.data) {
          Repository.storage.set(Repository.keys.ID_PROPOSTA, res.data);
          Repository.session.remove(Repository.keys.CODE_VERIFICATION);
          Repository.session.remove(Repository.keys.X_CODE);

          addProposta(res?.data);
          history.push("/cadastro-completo", {
            cpf: cpf?.unmasked,
            celular: celular?.unmasked,
            email: state?.email,
            idAnfitriao: state?.idAnfitriao,
          });
        }

        return { data: res?.data };
      } else if (res.status === 422) {
        if (res.data?.idProposta) {
          setIdPropostaAuth(res.data.idProposta);
        }
      } else {
        setPopUpMessage(res.mensagem);
        setPopUpIsOpen(true);
        return { status: res.status, mensagem: res.mensagem };
      }
    } catch (error) {
      Repository.session.remove(Repository.keys.CODE_VERIFICATION);
      Repository.session.remove(Repository.keys.X_CODE);
      const customMessage = error?.response?.data?.mensagem;

      throw new Error(customMessage || error?.message);
    }
  }, []);

  //remota
  const comecarPropostaRemota = async () => {
    const codeVerification = Repository.session.get(
      Repository.keys.CODE_VERIFICATION
    );
    Repository.session.set(Repository.keys.X_CODE, codeVerification);
    const payload = {
      cpf: newCpf,
      celular: newPhone,
      optinTermoDeUso: true,
      checkWhatsApp: checkWhatsApp || true,
      email,
      idUsuario: user?.id,
      origem: origem?.tipo,
      uf,
      codigoAnfitriao: state?.idAnfitriao,
    };
    const res = await PostInitialProposal.callApi(payload);
    Repository.session.remove(Repository.keys.CODE_VERIFICATION);
    Repository.session.remove(Repository.keys.X_CODE);

    return res;
  };

  const GetSPCPropostaData = useApi(
    constants.endpoints.spcProposalData.replace(
      "PARAM_ID_PROPOSAL",
      idPropostaNoAuth
    ),
    "GET"
  );
  useEffect(() => {
    (async () => {
      if (idPropostaNoAuth) {
        await GetSPCPropostaData.callApi();
      }
    })();
  }, [idPropostaNoAuth]);

  useEffect(() => {
    if (GetSPCPropostaData?.mensagem) {
      throw new Error(GetSPCPropostaData?.mensagem);
    }
    if (GetSPCPropostaData?.data?.spc) {
      Repository.storage.set(Repository.keys.ID_PROPOSTA, idPropostaNoAuth);

      const proposta = Object.assign(GetSPCPropostaData?.data?.spc, {
        id: idPropostaNoAuth,
      });
      addProposta(proposta);
      history.push("/cadastro-completo", {
        cpf: cpf?.unmasked,
        celular: celular?.unmasked,
        email: state?.email,
        idAnfitriao: state?.idAnfitriao,
      });
    }
  }, [GetSPCPropostaData, idPropostaNoAuth]);

  const addProposalBackoffice = useCallback(async () => {
    if (tipoOrigem) {
      const response = await comecarPropostaRemota();

      if (response.status >= 200 && response.status < 300) {
        Repository.storage.set(Repository.keys.ID_PROPOSTA, response.data);

        addProposta(response?.data);
        history.push("/cadastro-completo", {
          cpf: cpf?.unmasked,
          celular: celular?.unmasked,
          uf,
          email: state?.email,
          idAnfitriao: state?.idAnfitriao,
        });
      } else if (response.status === 422) {
        const { idProposta } = response.data;
        setIdPropostaNoAuth(idProposta);
      } else {
        setPopUpMessage(response.mensagem);
        setPopUpIsOpen(true);
      }
      return;
    } else {
      try {
        await iniciarPropostaLoja();
      } catch (err) {
        setPopUpIsOpen(true);
        setPopUpMessage(err.message);
      }
    }
  }, [GetSPCPropostaData]);

  return (
    <Layout.View
      title={popUpIsOpen ? "" : "Proposta Teucard Loading..."}
      noPage={popUpIsOpen}
    >
      {popUpIsOpen ? (
        <></>
      ) : (
        <ContainerFlex row>
          <Layout.Label>
            Estamos Processando sua solicitação, aguarde um momento...
          </Layout.Label>
          <ContainerFlex marginLeft={15}>
            <Spinner color="#424242" />
          </ContainerFlex>
        </ContainerFlex>
      )}
      <OneButtonPopup
        defaultButton
        text={<div dangerouslySetInnerHTML={{ __html: popUpMessage }} />}
        buttonText="Entendi"
        visibility={popUpIsOpen}
        setVisibility={() => {
          setPopUpIsOpen(false);
          if (tipoOrigem) {
            return (window.location = "http://useteucard.com.br/");
          } else {
            return history.push("/opcoes-acesso");
          }
        }}
      />
    </Layout.View>
  );
}
