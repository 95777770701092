import React, { createContext, useEffect, useReducer } from 'react';
import Repository from 'repository';
import AppReducer from './AppReducer';

const initialState = {
  user: {},
  loggedUser: {},
  proposta: {},
  propostaCompleta: {},
  messageReprov: {},
  errorMessage: '',
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  useEffect(() => {
    const user = Repository.storage.get(Repository.keys.USER)
    addUser(user || {})
  },[])

  function addUser(user) {
    dispatch({
      type: 'ADD_USER',
      payload: user,
    });
  }

  function addProposta(proposta) {
    dispatch({
      type: 'ADD_PROPOSTA',
      payload: proposta,
    });
  }

  function addPropostaCompleta(propostaCompleta) {
    dispatch({
      type: 'ADD_PROPOSTA_COMPLETA',
      payload: propostaCompleta,
    });
  }

  function addErrorMessage(message) {
    dispatch({
      type: 'ADD_ERROR_MESSAGE',
      payload: message,
    });
  }

  function addMessage(messageReprov) {
    dispatch({
      type: 'ADD__MESSAGE_REPROV',
      payload: messageReprov,
    });
  }

  return (
    <GlobalContext.Provider
      value={{
        user: state.user,
        loggedUser: state.loggedUser,
        proposta: state.proposta,
        propostaCompleta: state.propostaCompleta,
        errorMessage: state.errorMessage,
        message: state.message,
        addUser,
        addProposta,
        addPropostaCompleta,
        addErrorMessage,
        addMessage,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
