import React from 'react';
import { MdClose } from 'react-icons/md'

import * as St from './styles';

function PictureModal({children, handleClose}) {
    return (
        <St.Container onClick={handleClose}>
            <St.Modal onClick={e => e.stopPropagation()}>
                <span id="close">
                    <MdClose 
                        size={30} 
                        onClick={handleClose}
                    />
                </span>
                {children}
            </St.Modal>
        </St.Container>
    );
}

export default PictureModal;