import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid ${(props) => props.color};
  border-radius: 12px;
`;

export const Header = styled.header`
  background: ${(props) => props.color};
  color: #fff;
  text-align: center;
  padding: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  h1 {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const Content = styled.div`
  background: #fff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 12px;
`;

export const Title = styled.h1`
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
`;

export const BoxContent = styled.div`
  border-bottom: 1px solid ${(props) => props.color};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;

  p {
    font-size: 18px;
    color: #424242;
    font-weight: 400;
    margin-bottom: 5px;
  }
`;

export const Subtitle = styled.span`
  font-size: 14px;
  color: #424242;
  text-align: center;
`;

export const Value = styled.strong`
  font-size: 34px;
  font-weight: 400;
  color: #424242;
`;

export const Footer = styled.footer`
  text-align: center;
  background-color: #fff;

  button {
    height: 44.016px;
    padding: 8px;
    background: ${(props) => props.color};
    width: 100%;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    border-radius: 8px;
    margin-bottom: 6px;
  }
`;
