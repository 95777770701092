import styled from 'styled-components';
import { BsArrowLeft } from 'react-icons/bs';
import { FiDownload } from 'react-icons/fi';

export const BoxDetails = styled.div`
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -webkit-transition: 0.2s all;

  header {
    padding: 24px;
    border-bottom: 1px solid #f6f6f6;

    h2 {
      font-size: 1.4em;
      font-weight: bold;
      color: #2e3958;
      margin-bottom: 3px;
    }

    span {
      font-size: 0.7rem;
      font-weight: 700;
      color: #677383;
    }
  }
`;

export const ItemGraphic = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
`;