import React from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import { useHistory } from "react-router-dom";
import themes from "styles";
import { MdKeyboardArrowRight } from "react-icons/md";
import constants from "utils/constants";
import { useLoan } from "hooks/useLoan";

const CreditCardLoanSimulatorChooseType = () => {
  const history = useHistory();
  const { dispatchSimulationType } = useLoan();

  const {
    components: { png },
  } = themes;
  const data = [
    {
      title: "Valor do limite disponível",
      desc: "Escolher o quanto quero usar do meu limite no cartão de crédito",
      png: png.iconLimite,
      endpoint: constants.endpoints.postSimulateCreditLimit,
      type: "limit",
    },
    {
      title: "Valor da parcela",
      desc: "Escolher o quanto quer pagar por mês",
      png: png.iconParcela,
      endpoint: constants.endpoints.postSimulateInstallmentValue,
      type: "parcel",
    },
    {
      title: "Valor do saque",
      desc: "Escolher o quanto quero sacar",
      png: png.iconSaque,
      endpoint: constants.endpoints.postSimulateAvailableValue,
      type: "withdraw",
    },
  ];

  return (
    <St.SafeView>
      <Layout
        routeBack="/credit-card-loan-simulator-data-confirmation"
        title="Escolha a simulação que deseja fazer"
      >
        <St.Title>
          Agora é hora de escolher o melhor tipo de simulação para você:
        </St.Title>

        <St.Container>
          {data.map((el) => {
            return (
              <St.Item
                onClick={() => {
                  dispatchSimulationType({
                    type: el.type,
                    endpoint: el.endpoint,
                  });
                  history.push("/credit-card-loan-simulator-value");
                }}
              >
                <St.ContainerItem>
                  <St.ImageItem src={el.png} />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <St.TextItem>{el.title}</St.TextItem>
                    <St.TextItemDesc>{el.desc}</St.TextItemDesc>
                  </div>
                </St.ContainerItem>
                <MdKeyboardArrowRight
                  size={30}
                  color={"#D4313E"}
                  style={{ marginRight: 20 }}
                />
              </St.Item>
            );
          })}
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default CreditCardLoanSimulatorChooseType;
