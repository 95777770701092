export const proposals = [
  {
    id: 1,
    value: '200.000.000',
    name: 'Joaquim Pereira',
    typist: 'Luiza',
    date: '18:40:00',
    status: 'Em fila de proposta'
  },
  {
    id: 2,
    value: '300.000.000',
    name: 'Mariana Calvacante',
    typist: 'Marcos',
    date: '19:20:00',
    status: 'Em fila de proposta'
  },
  {
    id: 3,
    value: '400.000.000',
    name: 'Lucas Lima Silva',
    typist: 'Luiz',
    date: '20:30:00',
    status: 'Em fila de proposta'
  }
]

export const proposalStatus = [
  {
    id: 1,
    value: '200.000.000',
    name: 'Antônio A. de Souzaaaaaaaa aaaaaaa',
    typist: 'Laiara',
    date: '17:30:00',
    status: 'Aprovado s/assinatura'
  }
]
