/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import { useLoan } from "hooks/useLoan";
import constants from "utils/constants";
import useApi from "hooks/useApi";
import { Document, Page, pdfjs } from "react-pdf";
import Button from "components/Button";
import Loading from "components/Loading";
import InputCheckbox from "components/InputCheckbox";
import { useHistory } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const WithDrawTermsSign = () => {
  const { solicitationData } = useLoan();
  const [data, setData] = useState();
  const [numPages, setNumPages] = useState(0);
  const [aderir, setAderir] = useState(false);
  const history = useHistory();
  const [xCode, setXcode] = useState(false);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const GetTerms = useApi(
    constants.endpoints.getContractTerms.replace(
      "ID_LOAN",
      solicitationData?.idSimulacao
    ),
    "GET"
  );

  const getContract = async () => {
    const response = await GetTerms.callApi();
    try {
      if (response.status >= 200 && response.status < 300) {
        setXcode(response.headers.get("x-code"));
        setData(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getContract();
  }, []);

  return (
    <St.SafeView>
      <Loading status={GetTerms.loading} />
      <Layout routeBack="/with-draw-bank-data" title="Termos do empréstimo">
        <St.Container>
          <St.TopSpace>
            <St.Title>
              Confira se teus dados estão corretos nos termos do empréstimo:
            </St.Title>
          </St.TopSpace>
          <St.MiddleContainer>
            <St.Content>
              <St.StyledDocument
                file={`data:application/pdf;base64,${data?.contentPdf}`}
                onLoadError={console.error}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} height={650} />
              </St.StyledDocument>
            </St.Content>
            <St.FooterPages>
              <p>
                Pagína {pageNumber || (numPages ? 1 : "--")} de{" "}
                {numPages || "--"}
              </p>
              <St.FooterPage>
                <St.BackFooter>
                  <Button
                    buttonType="primary"
                    text={"<"}
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                  />
                </St.BackFooter>
                <St.NextFooter>
                  <Button
                    buttonType="primary"
                    text={">"}
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                  />
                </St.NextFooter>
              </St.FooterPage>
            </St.FooterPages>
          </St.MiddleContainer>
          <St.DivCheck>
            <InputCheckbox
              type="checkbox"
              style={{ border: "1px solid black" }}
              id="aderir"
              name="aderir"
              label="Li e concordo com as termos do empréstimo!"
              value={aderir}
              checked={aderir}
              onChange={(status) => {
                setAderir(status);
              }}
            />
          </St.DivCheck>
          <St.Footer>
            <Button
              style={{ width: "100%", justifySelf: "flex-end" }}
              buttonType="primary"
              text={"Avançar"}
              onClick={() => history.push("/liveness", { faceId: xCode })}
              disabled={!aderir || !xCode}
            />
          </St.Footer>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default WithDrawTermsSign;
