import styled from "styled-components";
import themes from "styles/theme";

export const ResendCode = styled.p`
  color: ${themes.colors.blackLight};
  font-size: 15px;
  font-weight: 600;

  > span {
    margin-left: 3px;

    color: ${(props) =>
      props.disabled ? themes.colors.secondary : themes.colors.primary};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-size: 15px;
    font-weight: 600;
    text-decoration: underline;
  }
`;