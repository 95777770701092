import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";

import { Container, ErrorText } from "./styles";

const Select = ({
  error,
  values,
  label,
  id,
  name,
  hide = false,
  disabled = false,
  labelFontWeight,
  ...rest
}) => {
  const [focusSelect, setFocusSelect] = useState(true);

  if (hide) return null;

  return (
    <Container isFocus={focusSelect} name={id}>
      <label style={{ fontWeight: labelFontWeight ?? "" }}>{label}</label>
      <div>
        <select
          disabled={disabled}
          name={name}
          id={name}
          onFocus={() => setFocusSelect(true)}
          onBlur={() => setFocusSelect(false)}
          {...rest}
        >
          {values?.map((value) => (
            <option
              selected={value.selected}
              hidden={value.hidden}
              disabled={value.disabled}
              key={value.value}
              name={value.label}
              value={value.value}
            >
              {value.label}
            </option>
          ))}
        </select>
        <MdKeyboardArrowDown />
      </div>

      {!!error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
};

export default Select;
