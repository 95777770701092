import Button from "components/Button";
import Layout from "components/OldLayout";
import React from "react";
import { useHistory, useLocation } from "react-router";
import * as S from "./styles";
import BenefitTable from "components/BenefitTable";

const ProposalCompleted = () => {
  const history = useHistory();
  const { state } = useLocation();

  return (
    <S.Wrapper>
      <Layout goBack title="Adesão finalizada">
        <div>
          <BenefitTable
            isProposalCompleted={true}
            idProposal={state.idProposal}
            contractedBenefits={state.benefit}
          />
        </div>
        <S.Buttons>
          {/* <Button
            buttonType="secondary"
            text="Enviar no e-mail"
            onClick={() => {
              history.push("/assinatura-beneficio-email", {
                idProposal: state.idProposal,
                email: state.email,
                benefit: state.benefit,
              });
            }}
            className="button"
          /> */}
          <Button
            buttonType="primary"
            text="Voltar"
            onClick={() => history.push("/opcoes-acesso")}
            className="button"
          />
        </S.Buttons>
      </Layout>
    </S.Wrapper>
  );
};

export default ProposalCompleted;
