import React, { useReducer, useState } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import Button from "components/Button";
import { convertDateByBrazillianFormat, moneyMask } from "utils/mask";
import { useHistory, useLocation } from "react-router";

const SimulationResultByTeuCard = (simulationData) => {
  const { state } = useLocation();
  const history = useHistory();
  const [dataList, setDataList] = useState([
    {
      name: "Valor da parcela",
      value: moneyMask(state?.simulationData?.payment_amount),
      font: "current",
    },
    {
      name: "Valor cobrado na próxima fatura",
      value: moneyMask(state?.simulationData?.payment_amount),
      font: "bold",
    },
    {
      name: "Vencimento",
      value: convertDateByBrazillianFormat(
        state?.simulationData?.first_payment_date
      ),
      font: "current",
    },
    {
      name: "Taxa de juros",
      value:
        (state?.simulationData?.interest_rate * 100)
          .toFixed(2)
          .replace(".", ",") + "% a.m.",
    },
    {
      name: "CET",
      value:
        (state?.simulationData?.monthly_effective_interest_rate * 100)
          .toFixed(2)
          .replace(".", ",") + "% a.m.",
    },
  ]);

  return (
    <St.SafeView>
      <Layout routeBack="/with-draw-simulation-by-teu-card" title="Simulação">
        <St.Container>
          <St.TopSpace>
            <St.ValueSimulate>
              <St.SimulateEditTitle>
                Tudo pronto! Libere agora esse valor na tua conta bancária e
                aproveite.
              </St.SimulateEditTitle>
              <St.TextItemValue>
                {moneyMask(
                  state?.simulationData?.disbursement_amount ||
                    state?.simulationData?.requested_amount
                )}
              </St.TextItemValue>
            </St.ValueSimulate>
            <St.MiddleContainerItems>
              {dataList.map((item, idx) => {
                return (
                  <St.ValueWrapper>
                    <St.TextItem font={item.font}>{item?.name}</St.TextItem>
                    <St.TextItemValueResult>
                      {item?.value}
                    </St.TextItemValueResult>
                  </St.ValueWrapper>
                );
              })}
            </St.MiddleContainerItems>
            <St.MiddleContainer>
              <St.Rounded />
              <p style={{ fontSize: "16px" }}>
                Será cobrado o valor integral, em parcela única, na tua{" "}
                <b> próxima fatura.</b>
              </p>
            </St.MiddleContainer>
            <St.Footer>
              <Button
                buttonType="secondary"
                text={"Quero simular novamente"}
                style={{ marginBottom: "1.5rem" }}
                onClick={() => history.goBack()}
              />
              <Button
                buttonType="primary"
                text={"Quero contratar!"}
                onClick={() => history.push("/with-draw-additional-data")}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default SimulationResultByTeuCard;
