import styled from "styled-components";
import media from "styled-media-query";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Title = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  ${media.greaterThan("medium")`
    width: 100%;
    height: 35%;
  `}
`;

export const Inputs = styled.div`
  margin-top: 2rem;
  align-items: center;
  justify-items: center;
`;

export const BoxCheckbox = styled.div`
  height: 80%;
  display: flex;
  justify-content: flex-start;
  gap: 1.8rem;
  flex-direction: column;
`;

export const Footer = styled.div`
  margin-top: 25px;
  position: relative;
  bottom: 0;
  width: 100%;

  @media (max-width: 712px) {
    padding: 20px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`;
