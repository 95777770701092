/* eslint-disable react/jsx-curly-newline */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Repository from 'repository'

export default ({ component: Component, ...rest }) => {
  const isAuthenticated = () => {
    const signIn = Repository.storage.get(Repository.keys.TOKEN_USER)
    const origem = Repository.storage.get(Repository.keys.ID_ORIGEM)

    if (!signIn || origem?.tipo !== 'LOJA') {
      return false
    } else {
      return true
    }
  }

  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        !isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/home', state: { from: props.location } }}
          />
        )
      }
    />
  )
}
