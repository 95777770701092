import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack'
import media from 'styled-media-query'

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Container = styled.div`
display: flex;
height: 100%;
flex-direction: row;

`

export const TopSpace = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}

`

export const ValueSimulate = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
    
  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}

  &:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`

export const TextContainer = styled.div`
  flex-direction: column;
  display: flex;
`;

export const Value = styled.span`
  font-size: 22px;
  font-weight: 600;
  background: linear-gradient(to right, #E71037, #ED933A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const SimulateEditTitle = styled.span`
  font-size: 16px;
  font-weight: 400;
`;

export const InputValue = styled.div`
`

export const Slider = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const InstallmentsSlider = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const InstallmentsSimulationTitle = styled.span`
margin-bottom: 10px;
margin-left: 10px;
font-size: 1.7rem;
font-weight: 700;
`;

export const InstallmentsSimulate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  background: #fff;
  margin-top: 20px;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
    
  ${media.greaterThan("medium")`
  width: 80%;
  height: 250px;
  `}

  &:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`
export const InstallmentsSimulationContainer = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
`;

export const InstallmentsSimulationValue = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #424242;
`;

export const ResultSimulate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin-left: 30px;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
    
  ${media.greaterThan("medium")`
  width: 60%;
  height: 520px;
  `}

  &:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`

export const NoContent = styled.img`
height: 80px;
object-fit: contain;
margin-right: 10px;
`;

export const SimulateNoContent = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: #D4313E;
`;

export const Item = styled.span`
display: flex;
flex-direction: row;
border-radius: 12px;
justify-content: center;
align-items: center;
margin-top: 5px;
background: #fff;
box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
position: relative;
-webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
  -webkit-transform 0.5s;
transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
  -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  
${media.greaterThan("medium")`
width: 95%;
height: 10%;
`}

&:hover {
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-0.75rem);
  transform: translateY(-0.75rem);
}
`;

export const TextItem = styled.span`
  font-size: 12px;
  font-weight: 600;
  margin-left: 5px;
`;

export const Footer = styled.div`
margin-top: 25px;

`
