/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useMemo } from "react";
import Button from "components/Button";
import Layout from "components/OldLayout";
import Loading from "components/Loading";
import OneButtonPopup from "components/modals/OneButtonPopup";

import useReactRouter from "use-react-router";
import { useLocation } from "react-router-dom";

import * as S from "./styles";
import BenefitCard from "components/BenefitCard";

export default function benefitDetail() {
  const [isLoading, setLoading] = useState(false);
  const [msgDialog, setMsgDialog] = useState("");
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [aderir, setAderir] = useState([]);
  const [benefitsContracted, setBenefitsContracted] = useState(null);
  const [benefitsNotContracted, setBenefitsNotContracted] = useState(null);

  const { state } = useLocation();
  const { history } = useReactRouter();

  console.log(state);

  useEffect(() => {
    setBenefitsNotContracted(state?.benefits?.notContracted);

    setBenefitsContracted(state?.benefits?.contracted ?? []);
  }, [state]);

  const handleBenefits = (benefitsIds) => {
    let beneficios = [];
    benefitsIds.map((e) => beneficios.push(e.beneficio));
    benefitsContracted?.length > 0
      ? setAderir([...beneficios, ...benefitsContracted])
      : setAderir([...beneficios]);
  };

  const handleClick = async () => {
    history.push("/escolher-cartao", {
      cpf: state?.cpf,
      propostaId: state.propostaId,
      benefits: {
        contracted: aderir.length ? aderir : benefitsContracted,
        notContracted: benefitsNotContracted,
      },
    });
  };

  return (
    <S.Container>
      <Loading status={isLoading} />

      <OneButtonPopup
        defaultButton
        text={msgDialog}
        buttonText="Entendi"
        visibility={dialogIsOpen}
        setVisibility={() => setDialogIsOpen(false)}
      />

      <Layout routeBack="/opcoes-acesso" title="Lista de Benefícios">
        <S.Text name={"text1"}>
          Por favor, escolha um ou mais benefícios, se desejar:
        </S.Text>
        <S.WrapperList>
          {state.benefits?.notContracted && (
            <BenefitCard
              propostaId={state.propostaId}
              onClickBenefit={handleBenefits}
              data={state.benefits?.notContracted}
              isUserMoreThan65YearsOld={state?.isUserMoreThan65YearsOld}
            />
          )}
        </S.WrapperList>
        <Button text="Continuar" buttonType="primary" onClick={handleClick} />
      </Layout>
    </S.Container>
  );
}
