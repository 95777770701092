/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Layout from "components/OldLayout";
import Input from "components/Input";
import InputSelect from "components/InputSelect";
import InputSelectSearch from "components/SelectSearch/inputSelect";
import OneButtonPopup from "components/modals/OneButtonPopup";
import Button from "components/Button";

import { useLoan } from "hooks/useLoan";
import { optionsUfs, typeDocuments, issuingAgencyList } from "./constants";
import { dateMask } from "utils/mask";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import moment from "moment";

import * as St from "./styles";
import themes from "styles";

const inputStyle = {
  border: "1px solid #6c6c6c",
  padding: "1.5rem",
  borderRadius: ".5rem",
  width: "100%",
};
const IdentificationData = () => {
  const [dialogOpenError, setDialogOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [issuing, setIssuing] = useState([]);
  const [filteredIssuing, setFilteredIssuing] = useState(issuing);
  const [openSelect, setOpenSelect] = useState(false);

  const initialState = {
    typeDocument: "",
    numberDocument: "",
    emissionDate: "",
    issuingAgency: "",
    ufDocument: "",
  };

  const [data, dispatch] = useReducer(reducer, initialState);
  const {
    typeDocument,
    numberDocument,
    emissionDate,
    issuingAgency,
    ufDocument,
  } = data;

  const { userIdentificationData, dispatchUserIdentificationData } = useLoan();

  const history = useHistory();

  function reducer(data, action) {
    const { type, payload } = action;
    return { ...data, [type]: payload };
  }

  const RequestGetIssuing = useApi(
    constants.endpoints.getInssuingByEasyCredito,
    "GET"
  );

  useEffect(() => {
    const getIssuing = async () => {
      const { data } = await RequestGetIssuing.callApi();

      if (data) {
        const orderIssuing = data
          ?.sort((a, b) => Number(b.code) + Number(a.code))
          ?.map((e) => ({
            value: e.code,
            label: e.name,
            name: e.name,
            code: e.code,
          }));
        setIssuing(orderIssuing);
        setFilteredIssuing(orderIssuing);
      }
    };

    getIssuing();
  }, []);

  function isValidDate(datString) {
    const parsedDate = moment(datString, "DD/MM/YYYY", true);

    if (!parsedDate.isValid()) {
      return false;
    }

    const minDate = moment().subtract(80, "years");
    const maxDate = moment().add(1, "day");

    return parsedDate.isBetween(minDate, maxDate, "day", "[]");
  }

  const onChange = (e, mask) => {
    const {
      target: { name, value },
    } = e;
    dispatchUserIdentificationData(data);

    dispatch({ type: name, payload: mask ? mask(value) : value });
  };

  const validateInputs = () => {
    return (
      !typeDocument ||
      !numberDocument ||
      !emissionDate ||
      !issuingAgency ||
      !ufDocument
    );
  };

  const handleFilterIssuing = (e) => {
    const { value } = e.target;
    console.log(value);
    dispatch({ type: "issuing", payload: value });
    setFilteredIssuing(
      issuing.filter((item) =>
        item?.label?.toLowerCase().trim().includes(value?.toLowerCase())
      )
    );
  };

  const handleNext = () => {
    try {
      const isDateValid = isValidDate(emissionDate?.masked);

      if (!isDateValid) {
        setDialogOpenError(true);
        setErrorMessage("Data de emissão é invalida");
        return;
      }
      console.log(data);
      dispatchUserIdentificationData({ ...userIdentificationData, data });
      history.push("/residential-data-loan-seeker");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <St.SafeView>
      <Layout
        routeBack="/additional-data-loan-seeker"
        title="Informe teus dados de identificação"
      >
        <St.Container>
          <OneButtonPopup
            text={errorMessage}
            buttonText="Entendi"
            title={"Atenção!"}
            visibility={dialogOpenError}
            iconModal={themes.components.png.emojiSad}
            defaultButton
            setVisibility={setDialogOpenError}
            onClose={() => setDialogOpenError(false)}
          />

          <St.TopSpace>
            <St.Inputs style={{ width: "100%" }}>
              <InputSelect
                labelFontWeight="bold"
                style={{ ...inputStyle, padding: ".5rem 1rem" }}
                boldLabel
                label="Tipo de documento*"
                type="select"
                name="typeDocument"
                values={typeDocuments}
                value={typeDocument}
                onChange={(e) => onChange(e)}
              />
              <Input
                boldLabel
                style={inputStyle}
                label="Número*"
                value={numberDocument}
                name="numberDocument"
                id="numberDocument"
                type="text"
                max={15}
                onChange={(e) => onChange(e)}
              />
              <Input
                boldLabel
                style={inputStyle}
                label="Data de emissão*"
                value={emissionDate?.masked}
                name="emissionDate"
                id="emissionDate"
                placeholder="00/00/0000"
                type="text"
                max={10}
                onChange={(e) => onChange(e, dateMask)}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "70%" }}>
                  <InputSelectSearch
                    labelFontWeight="bold"
                    label="Orgão expedidor*"
                    style={{ ...inputStyle, padding: ".5rem 1rem" }}
                    id="issuingAgency"
                    name="issuingAgency"
                    options={filteredIssuing}
                    value={issuingAgency?.label}
                    inputTextValue={issuingAgency?.label}
                    defaultValue={issuingAgency?.label}
                    onFocus={() => setOpenSelect(true)}
                    onChange={handleFilterIssuing}
                    onClickSelect={(e) =>
                      dispatch({ type: "issuingAgency", payload: e })
                    }
                    open={openSelect}
                    setOpen={setOpenSelect}
                    placeholder="Selecione"
                    widthOptions="70%"
                  />
                </div>
                <div style={{ width: "30%" }}>
                  <InputSelect
                    labelFontWeight="bold"
                    label="UF*"
                    type="select"
                    name="ufDocument"
                    style={{ ...inputStyle, padding: ".5rem 1rem" }}
                    value={ufDocument}
                    values={optionsUfs}
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
            </St.Inputs>

            <St.Footer>
              <Button
                style={{ width: "100%" }}
                buttonType="primary"
                text="Avançar"
                disabled={validateInputs()}
                onClick={() => handleNext()}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default IdentificationData;
