import Button from "components/Button";
import Input from "components/Input";
import InputRadio from "components/InputRadio";
import OneButtonPopup from "components/modals/OneButtonPopup";
import TwoButtonPopup from "components/modals/TwoButtonPopup";
import Layout from "components/OldLayout";
import Spinner from "components/Spinner";
import useApi from "hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Repository from "repository";
import constants from "utils/constants";
import { emailMask } from "utils/mask";
import { verifyDisabled } from "utils/verifyDisabled";
import styles from "../../styles";
import * as S from "./styles";

const SendByEmail = () => {
  const history = useHistory();
  const [step] = useState(3);
  const [newEmail, setNewEmail] = useState();
  const [sendEmail, setSendEmail] = useState();
  const [disabledButton, setDisabledButton] = useState();
  const [isLoading, setLoading] = useState(false);
  const [confirmationPopupVisibility, setOneButtonPopupVisibility] =
    useState(false);
  const [confirmationTwoPopupVisibility, setTwoButtonPopupVisibility] =
    useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const email = Repository.storage.get(Repository.keys.EMAIL_PROPOSTA);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [messageErrorEmail, setMessageErrorEmail] = useState("");

  const { state } = useLocation();

  const cadObject = {
    sendEmail,
    newEmail,
    setDisabledButton,
    step,
  };

  useEffect(() => {
    verifyDisabled(cadObject);
  }, [cadObject]);

  const SendProposalToEmail = useApi(
    sendEmail !== "otherEmail"
      ? constants.endpoints.sendProposalToEmail
          .replace("PARAM_ID_PROPOSAL", state?.propostaId)
          .replace("PARAM_EMAIL", email ?? state?.email)
      : constants.endpoints.sendProposalToEmail
          .replace("PARAM_ID_PROPOSAL", state?.propostaId)
          .replace("PARAM_EMAIL", newEmail),
    "POST"
  );

  const handleSubmit = async () => {
    await sendEmailWithProposal();
  };

  const sendEmailWithProposal = useCallback(async () => {
    setLoading(true);
    try {
      const { status, mensagem } = await SendProposalToEmail.callApi();
      if (status >= 200 && status <= 300) {
        setPopUpMessage("E-mail enviado com sucesso!");
        setTwoButtonPopupVisibility(true);
      } else {
        setMessageErrorEmail(mensagem);
        setModalIsOpen(true);
      }
    } catch (err) {
      setOneButtonPopupVisibility(true);
    }
    setLoading(false);
  }, [SendProposalToEmail]);

  return (
    <S.Wrapper>
      <Layout title="Envio por e-mail">
        <S.Infos>
          <S.Text>
            Por favor, informe o endereço de e-mail para o envio do contrato.
          </S.Text>
          <S.TextSpan>
            <S.TextBold>Atenção:</S.TextBold>
            Sr. Cliente, a fatura digital será enviada mensalmente para o e-mail
            cadastrado.
          </S.TextSpan>
          <S.RadioGroup
            name="sendEmail"
            value={sendEmail}
            onblur={() => verifyDisabled(cadObject)}
            onChange={(e) => setSendEmail(e.target.value)}
          >
            <InputRadio
              name="sendEmail"
              id="emailRegister"
              type="radio"
              label={`E-mail cadastrado: ${email ? email : state?.email}`}
              value={email ? email : state?.email}
            />
            <InputRadio
              name="sendEmail"
              id="otherEmail"
              label="Outro e-mail"
              value="otherEmail"
            />
          </S.RadioGroup>
          {sendEmail && sendEmail === "otherEmail" && (
            <Input
              className="newEmail"
              name="newEmail"
              id="newEmail"
              type="email"
              label="E-mail"
              placeholder="exemplo@exemplo.com.br"
              value={newEmail}
              onblur={() => verifyDisabled(cadObject)}
              onChange={(e) => setNewEmail(emailMask(e.target.value))}
            />
          )}
        </S.Infos>
        <Button
          buttonType="primary"
          text={isLoading === true ? <Spinner color="#FFF" /> : "Enviar"}
          disabled={disabledButton}
          onClick={() => handleSubmit()}
        />
        <TwoButtonPopup
          defaultButton
          text={popUpMessage}
          visibility={confirmationTwoPopupVisibility}
          setVisibility={setTwoButtonPopupVisibility}
          onClick={() => history.push("/opcoes-acesso")}
        />
        <OneButtonPopup
          defaultButton
          img={styles.components.png.warning}
          text={popUpMessage}
          visibility={confirmationPopupVisibility}
          setVisibility={setOneButtonPopupVisibility}
        />
        <OneButtonPopup
          defaultButton
          text={messageErrorEmail}
          buttonText="Entendi"
          visibility={modalIsOpen}
          setVisibility={() => setModalIsOpen(false)}
        />
      </Layout>
    </S.Wrapper>
  );
};

export default SendByEmail;
