import React, { useEffect, useState } from "react";
import * as S from "./styles";
import Layout from "components/OldLayout";
import constants from "utils/constants";
import Repository from "repository";
import useApi from "hooks/useApi";
import GraficHistoric from "../../../components/graficHistory";
import Loading from "components/Loading";


const MonthlyHistory = () => {

    const [categoriesProp, setCategoriesProp] = useState([]);
    const [serieProp, setSerieProp] = useState([{ name: "", data: [] }]);
    const [categoriesBenef, setCategoriesBenef] = useState([]);
    const [serieBenef, setSerieBenef] = useState([{ name: "", data: [] }]);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // O mês começa em zero, então adicionamos 1
    const day = currentDate.getDate().toString().padStart(2, '0');

    const formattedDate = year + '-' + month + '-' + day;

    const operador = Repository.storage.get(Repository.keys.USER);

    const RequestOperatorBenefit = useApi(
        constants.endpoints.getOperatorBenefit.replace("PARAM_DATA", formattedDate).replace("PARAM_OPERADOR", operador?.nomeTitular),
        "GET"
    );

    const RequestOperatorProposal = useApi(
        constants.endpoints.getOperatorProposal.replace("PARAM_DATA", formattedDate).replace("PARAM_OPERADOR", operador?.nomeTitular),
        "GET"
    );

    const getOperatorBenef = async () => {
        try {
          const response = await RequestOperatorBenefit.callApi();
          if (response.status >= 200 && response.status <= 300) {
            const { categories, series } = response?.data?.beneficios;
            setSerieBenef(series)
            setCategoriesBenef(categories);
          }
        }catch (error) {
          console.log(error);
        }
    };

    const getOperatorProp = async () => {
        try {
          const response = await RequestOperatorProposal.callApi();
          if (response.status >= 200 && response.status <= 300) {
            const { categories, series } = response?.data?.propostas;
            setSerieProp(series);
            setCategoriesProp(categories);
          }
        } catch (error) {
          console.log(error);
        }
    };

    useEffect(() => {
        if(operador, formattedDate){
            getOperatorBenef();
            getOperatorProp();
        }
        
      }, []);

    return(
        <S.Container>
          <Loading
        status={
          RequestOperatorBenefit.loading ||
          RequestOperatorProposal.loading
        }
      />
            <Layout routeBack="/desempenho" title="Histórico mensal">
            <S.ChartContainer>
                <GraficHistoric
                 title={"propostas"}
                 heightGraphic={254}
                 widthGraphic={550}
                 categories={categoriesProp}
                 series={serieProp}
                />
                </S.ChartContainer>
                <S.ChartContainer>
                 <GraficHistoric
                 title={"benefícios"}
                 heightGraphic={254}
                 widthGraphic={550}
                 categories={categoriesBenef}
                 series={serieBenef}
                />
                </S.ChartContainer>
            </Layout>
        </S.Container>
    )

}

export default MonthlyHistory;