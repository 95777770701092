import React, { useCallback, useEffect, useState } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import useWebSocket from "react-use-websocket";
import { useHistory } from "react-router-dom";
import { useLoan } from "hooks/useLoan";
import OneButtonPopup from "components/modals/OneButtonPopup";
import themes from "styles";

const WithDrawListenerStatus = () => {
  const { solicitationData } = useLoan();
  const history = useHistory();
  const [status, setStatus] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [callWebSocketTimeOut, setCallWebSocketTimeOut] = useState(false);

  setTimeout(() => setCallWebSocketTimeOut(true), 20000);

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      if (solicitationData?.idSimulacao) {
        resolve(
          `${process.env.REACT_APP_WEBSOCKET_PAYMENT}/v1/websockets/emprestimos/saque/coleta-assinatura/${solicitationData?.idSimulacao}`
        );
      }
    });
  }, [solicitationData?.idSimulacao]);

  useEffect(() => {
    if (callWebSocketTimeOut) {
      setError(true);
      setErrorMessage("Aguarde alguns segundos e consulte a tua área de empréstimos. Ou procure atendimento em uma das lojas Nova Era.");
    }
  }, [callWebSocketTimeOut]);  

  const { lastMessage } = useWebSocket(getSocketUrl, {
    onOpen: () => console.log("opened"),
    onClose: () => console.log("closed"),
  });

  useEffect(() => {
    (async () => {
      if (lastMessage && lastMessage.data !== "_ready_") {
        const jsonString = lastMessage?.data;
        const parsedObject = await JSON.parse(jsonString);
        setStatus(parsedObject?.status);
      }
    })();
  }, [lastMessage]);

  useEffect(() => {
    if (status === "PENDING_SIGNATURE") {
      history.push("/with-draw-terms-sign")
    }
  }, [status]);


  return (
    <St.SafeView>
      <Layout routeBack="/with-draw-listener-status" title="Termos do empréstimo">
      <OneButtonPopup
            text={errorMessage}
            buttonText="Entendi"
            title={"O teu contrato está sendo preparado e logo estará disponível!"}
            visibility={error}
            iconModal={themes.components.png.emojiSuccess}
            defaultButton
            setVisibility={setError}
            onClose={() => history.push("/opcoes-acesso")}
          />
        <St.Container>
          <St.TopSpace>
            <St.Title>
              Aguarde alguns segundos. Estamos processando teus dados!
            </St.Title>
          </St.TopSpace>
          <St.MiddleContainer>
            <St.Spinner>
              <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </St.Spinner>
          </St.MiddleContainer>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default WithDrawListenerStatus;
