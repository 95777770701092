import styled from "styled-components";
import { ArrowBack } from "@styled-icons/boxicons-regular/ArrowBack";

const marginHorizontal = 22;
const marginVertical = 20;

export const BackButton = styled(ArrowBack)`
  width: 30px;
  height: 30px;
  margin-top: 20px;
  cursor: pointer;
  path {
    fill: ${({
      theme: {
        colors: { gray },
      },
    }) => gray};
  }

  @media (max-width: 420px) {
    margin-left: 16px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 22px;

  #header {
    @media screen and (max-width: 960px) {
      padding: 0 15px;
    }
  }
`;

export const ContainerDivider = styled.div`
  background-color: #e71137;
  width: 100%;
  height: 60px;
  margin-top: ${marginVertical}px;
  margin-bottom: ${marginVertical}px;

  @media (max-width: 420px) {
    background-color: white;
    height: 0px;
    margin-bottom: 0px;
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: ${marginHorizontal}px;
  padding-right: ${marginHorizontal}px;

  @media (max-width: 420px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export const Title = styled.span`
  text-align: left;
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  font-size: 1.6em;
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold },
    },
  }) => fontWeightSemiBold};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  margin-bottom: ${marginHorizontal}px;
  display: block;
`;

export const TitleHeader = styled.span`
  text-align: left;
  font-size: 1.6em;
  margin-top: 24px;
  margin-left: 16px;
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold },
    },
  }) => fontWeightSemiBold};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  display: block;

  @media (min-width: 420px) {
    display: none;
  }
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Logo = styled.img`
  margin-top: ${marginVertical}px;
  margin-left: ${marginHorizontal}px;
  width: 110px;

  @media (max-width: 420px) {
    display: none;
  }
`;

export const Label = styled.span`
  color: #4b4c4b;
  font-size: 14px;
`;

export const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-left: 10px;

  span {
    font-size: 1.6rem;
    white-space: normal;
    @media screen and (max-width: 700px) {
      font-size: 1.3rem;
    }

    @media screen and (max-width: 350px) {
      font-size: 1rem;
    }
  }
`;

export const Logout = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  color: ${({
    theme: {
      colors: { primary },
    },
  }) => primary};
  cursor: pointer;
  margin-left: 10px;

  :hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 700px) {
    font-size: 1.3rem;
  }
`;
