import styled from "styled-components";
import media from "styled-media-query";

export const Title = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
`;
export const Inputs = styled.div`
  margin-top: 2rem;
  align-items: center;
  justify-items: center;
`;

export const DivCheck = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-left: 1rem;

  input[type="checkbox"] {
    width: 1.5rem;
    height: 2rem;
    display: inline-block;
    margin-right: 10px;
    filter: hue-rotate(135deg);
    cursor: pointer;
  }

  span {
    width: 90%;
  }
  label {
    position: relative;
    margin-right: 5px;
    font-size: ${({
      theme: {
        sizes: { small },
      },
    }) => small};
    color: ${({
      theme: {
        colors: { grayDark },
      },
    }) => grayDark};

    &::before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 14px;
      border: 1px solid #afafaf;
      margin-right: 13px;
      margin-top: 8px;
      margin-bottom: -3px;
    }
  }
`;
export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const Footer = styled.div`
  margin-top: 25px;
  position: fixed;
  bottom: 0;
  width: 40%;

  @media (max-width: 712px) {
    padding: 20px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`;
