import styled from 'styled-components';

export const Container = styled.div`
  margin:30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const ContainerFilter = styled.div`
  display: flex;
  margin: 2%;
`;

export const ContentInput = styled.div`
  width: 60%;
  margin-right: 3%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TextCadLead = styled.span`
  font-size: 15px;
`;

export const HiperLink = styled.span`
  color: red;
  margin-right: 3px;
  text-decoration: underline;
  padding-left: 5px;
  cursor: pointer;
  font-size: 15px;
`;

export const WrapperList = styled.div`
  margin-top: 30px;
`;

export const NotFound = styled.span`
  font-size: 3rem;
  color: grey;
  text-align: center;
  margin-top: 30%;
`;