import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import useReactRouter from "use-react-router";

import teuCardLogo from "assets/png/logo.png";
import * as St from "./styles";

import CardView from "components/CardView";
import ContainerFlex from "components/ContainerFlex";
import { GlobalContext } from "context/GlobalContext";
import Repository from "repository";
import { AuthContext } from "context/AuthContext";
import { cpfMask, hiddeCPFNumber } from "utils/mask";

const Layout = (props) => {
  const operador = Repository.storage.get(Repository.keys.USER);
  const { history } = useReactRouter();
  const { user } = useContext(GlobalContext);
  const { signOut } = useContext(AuthContext);
  const [origem, setOrigem] = useState(false);

  useEffect(() => {
    function getOrigemFunc() {
      const ori = Repository.storage.get(Repository.keys.ID_ORIGEM);

      if (ori) {
        const type = ori?.tipo || "";
        const isStore = type === "LOJA";

        setOrigem(isStore);
      } else {
        setOrigem(false);
      }
    }

    getOrigemFunc();
  }, []);

  const onBackPress = () => {
    const origem = Repository.storage.get(Repository.keys.ID_ORIGEM);

    if (origem.tipo !== "LOJA") {
      window.location = "http://useteucard.com.br/";
    } else {
      console.log("Layout onBackPress() props.goBack: ", props.goBack);
      console.log("Layout onBackPress() props.routeBack: ", props.routeBack);
      if (props.goBack) return history.goBack();

      history.push(`${props.routeBack}`);
    }
  };

  const handleLogout = () => {
    signOut();
  };

  return (
    <St.Container>
      <ContainerFlex row space align="flex-end" id="header" marginTop={10}>
        <ContainerFlex row center>
          {(props.routeBack || props.goBack) && (
            <St.BackButton onClick={onBackPress} />
          )}
          {props.title && <St.TitleHeader>{props.title}</St.TitleHeader>}
          <St.Logo src={teuCardLogo} />
        </ContainerFlex>
        {origem && (
          <ContainerFlex center flex={"initial"} align="flex-end">
            <St.Logout onClick={handleLogout}>Sair</St.Logout>
            <St.Info>
              <span>
                <b>Usuário:</b> {hiddeCPFNumber(cpfMask(user.cpf).masked)} -
              </span>
              <span>&nbsp;{user.nomeTitular} - </span>
              <span style={{ color: "#e71037", fontWeight: "bold" }}>
                &nbsp; Versão: {process.env.REACT_APP_VERSAO_APP}
              </span>
            </St.Info>
          </ContainerFlex>
        )}
      </ContainerFlex>

      <St.ContainerDivider />

      {props.noPage ? (
        props.children
      ) : (
        <St.Box>
          <CardView padding={32} space>
            <St.TitleDiv>
              {props.title && <St.Title>{props.title}</St.Title>}
              {props.host && <St.Title>{props.host}</St.Title>}
            </St.TitleDiv>
            {props.children}
          </CardView>
        </St.Box>
      )}
    </St.Container>
  );
};

Layout.propTypes = {
  title: PropTypes.string,
  routeBack: PropTypes.string,
  goBack: PropTypes.bool,
  noPage: PropTypes.bool,
};

Layout.defaultProps = {
  title: null,
  routeBack: null,
  goBack: false,
  noPage: false,
};

export default {
  View: Layout,
  Title: St.Title,
  Label: St.Label,
};
