import styled from 'styled-components'
// import { Link as RouterLink } from 'react-router-dom'
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  width: 80%;
  margin: auto;
  /* height:100%;   */
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  ${media.greaterThan('medium')`
    width: 70%;
  `}

  .react-datepicker__navigation {
    display: none;
  }

  .react-datepicker {
    height: 200px;
    left: 110%;

    ${media.greaterThan('large')`
      left:150%;
  `}
  }

  .react-datepicker__header {
    display: none;
  }
`

export const RadioGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`

export const Header = styled.div`
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL }
    }
  }) => lineHeightUL};
  align-items: center;
  margin-bottom: ${({
    theme: {
      spacings: { medium }
    }
  }) => medium};
`

export const IconArrow = styled(ArrowBack)`
  width: 20px;
  height: 23px;
  position: absolute;

  path {
    fill: ${({
      theme: {
        colors: { gray }
      }
    }) => gray};
  }
`

export const TextBold = styled.p`
  text-align: left;
  font-size: ${({
    theme: {
      sizes: { small }
    }
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold }
    }
  }) => fontWeightSemiBold};
  color: ${({
    theme: {
      colors: { blackLight }
    }
  }) => blackLight};
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL }
    }
  }) => lineHeightUL};
  margin-bottom: ${({
    theme: {
      spacings: { xxlarge }
    }
  }) => xxlarge};

  ${media.greaterThan('medium')`
    font-size: ${({
      theme: {
        sizes: { medium }
      }
    }) => medium};
  `}
`

export const Text = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: ${({
    theme: {
      sizes: { small }
    }
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular }
    }
  }) => fontWeightRegular};
  color: ${({
    theme: {
      colors: { blackLight }
    }
  }) => blackLight};

  ${media.greaterThan('medium')`
    font-size: ${({
      theme: {
        sizes: { medium }
      }
    }) => medium};
  `}
`
