/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo, useState } from "react";
import Layout from "components/OldLayout";
import Loading from "components/Loading";
import ContainerFlex from "components/ContainerFlex";
import Button from "components/Button";
import Input from "components/Input";
import Card from "components/Card/cardClient";

import { useHistory } from "react-router-dom";
import constants from "utils/constants";
import { cpfMask } from "utils/mask";
import * as S from "./styles";
import useApi from "hooks/useApi";

export default function attendance() {
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState({});
  const [error] = useState();
  const [client, setClient] = useState([]);

  const history = useHistory();

  const createQueryString = () => {
    let url = "";

    if (cpf.unmasked?.trim()?.length === 11) url = url + `cpf=${cpf?.unmasked}`;

    if (name.trim()?.length) url = url + `&nome=${name}`;

    return url;
  };
  const GetActiveClient = useApi(
    constants.endpoints.getClient.replace(
      "PARAM_QUERY_STRING",
      createQueryString()
    ),
    "GET"
  );
  const handleSearch = async () => {
    try {
      setLoading(true);
      const response = await GetActiveClient.callApi();

      setClient(response.data);
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleViewSearch = (data) =>
    history.push("/attendance-detail", {
      id: data.propostaId,
      cpf: data.cpf,
      idUsuario: data.idUsuario,
    });

  const handleCardRender = (data) => {
    return <Card onClickSearch={handleViewSearch} key={data.id} data={data} />;
  };

  const handleCardListRender = useMemo(
    () => client?.length > 0 && client?.map(handleCardRender),
    [client, handleCardRender]
  );

  return (
    <S.Container>
      <Loading status={isLoading} />

      <Layout routeBack="/opcoes-acesso" title="Busca cliente">
        <div style={{ display: "flex", margin: "2%" }}>
          <S.ContentInput>
            <Input
              id="name"
              name="userFullName"
              label="Nome completo"
              placeholder=""
              type="text"
              defaultValue={name}
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={error}
            />
            <Input
              name="cpf"
              label="CPF"
              placeholder="000.000.000-00"
              type="number"
              pattern="[0-9]*"
              inputmode="numeric"
              value={cpf?.masked}
              max={14}
              onChange={(e) => setCpf(cpfMask(e.target.value))}
            />
          </S.ContentInput>
          <ContainerFlex end>
            <Button
              buttonType="primary"
              text={"Buscar"}
              disabled={isLoading}
              onClick={handleSearch}
              buttonSize="small"
              style={{ maxWidth: "300px" }}
            />
          </ContainerFlex>
        </div>
      </Layout>
      <S.WrapperList>{client && handleCardListRender}</S.WrapperList>

      {!client?.length && (
        <ContainerFlex row horizontal center>
          <S.NotFound>Nenhum cliente encontrado.</S.NotFound>
        </ContainerFlex>
      )}
    </S.Container>
  );
}
