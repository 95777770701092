import React from 'react'
import * as S from './styles'

export default function InputCheckbox({
  id,
  strongs,
  value,
  type,
  key,
  label,
  checked,
  onChange,
  name,
  labelClick,
  labelOnClick,
  labelClickSite,
  labelOnClickSite
}) {
  return (
    <S.Container name={id}>
      <S.Field name={id} className={type}>
        <input
          type="checkbox"
          id={name}
          name={name}
          value={value}
          checked={checked}
          onChange={(e) => onChange && onChange(!value)}
        />
        <label htmlFor={name} onClick={(e) => e.stopPropagation()}>
          <span>{label}</span>
          <a
            onClick={labelOnClick}
            target="_blank"
            style={{ color: 'black', textDecoration: 'underline black' }}
          >
            {labelClick}
          </a>
          <a
            href={labelOnClickSite}
            target="_blank"
            style={{ color: 'black', textDecoration: 'underline black' }}
          >
            {labelClickSite}
          </a>
          {/* <a href="https://opahitconsulting.sharepoint.com/:b:/s/NovaEra-Banking/Edn9wAefgndCj-taVNJI0mMBm6L56wQ-EfKds0XVVpTTRQ?e=q8jCkP" target="_blank" style={{ color: 'black', textDecoration: 'underline black'}}>{labelClick}</a> */}
        </label>
      </S.Field>
    </S.Container>
  )
}
