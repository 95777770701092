import styled, { css } from "styled-components";

const DefaultTabStyle = css`
  padding: 10px 16px;
  margin: 0 2px 20px 2px;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 400;
  border-radius: 5px;
`;

export const Tabs = styled.div`
  ${DefaultTabStyle}
  border: 1px solid colors: {whiteLigth};
  color: ${({ selected }) => (selected ? "white" : "black")};
  background-color: ${({
    selected,
    theme: {
      colors: { primary },
    },
  }) => (selected ? primary : "white")};

  :hover {
    background-color: ${({ selected }) =>
      selected ? "filter: brightness(3);" : "#e6e6e6"};
  }
  font-weight: bold;
`;

export const CustomTabs = styled.div`
  border: 1px solid
    ${({
      theme: {
        colors: { whiteLigth },
      },
    }) => whiteLigth};
  ${DefaultTabStyle}

  :hover {
    background-color: #e6e6e6;
  }
`;
