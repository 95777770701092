import React, { useCallback, useRef, useState } from "react";
import * as S from "./styles";
import SignaturePad from "react-signature-canvas";
import themes from "styles";
import TwoButtonPopup from "components/modals/TwoButtonPopup";
import { moneyMask } from "utils/mask";
import Input from "components/Input";
import moment from "moment";
import InputCheckbox from "components/InputCheckbox";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FaRegTrashAlt } from "react-icons/fa";

const BenefitSignature = ({ benefit, setAllSignatures }) => {
  const {
    idBeneficio,
    idProposta,
    linkContrato,
    nomeContrato,
    statusAssinaturaContrato,
    tipoContrato,
    valorMensalidadeContrato,
    assinaturaBase64,

    // quando o usuario chega nessa parte selecionando os beneficios ao inves de recuperar os salvos
    valorMensalidade,
    nome,
  } = benefit;
  const ref = useRef();
  const contratoJaAssinado = statusAssinaturaContrato === "ASSINADO";
  const [empty, setEmpty] = useState(() => !assinaturaBase64);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState(() => {
    if (contratoJaAssinado) return `data:image/png;base64,${assinaturaBase64}`;
    return "";
  });
  const isContratoPadrao = tipoContrato === "TERMO_TEUCARD";
  const [termsAccepted, setTermsAccepted] = useState(false);
  const PostSaveSignature = useApi(
    constants.endpoints.postSaveBenefitSignature,
    "POST"
  );
  const { state } = useLocation();
  const [hasNewSignature, setHasNewSignature] = useState(false);

  const isContinueFlux = !!state.selectedContracts;
  const confirmSignature = useCallback(async () => {
    const response = await PostSaveSignature.callApi({
      cpf: state?.cpf,
      idBeneficio: benefit.idBeneficio,
      idProposta: state?.propostaId,
      assinaturaBase64: signatureUrl?.split(",")[1],
      tipoContrato,
      idOperador: JSON.parse(localStorage.getItem("@NovaEra:user")).id,
    });
    setEmpty(false);
    if (response.status === 200) {
      setAllSignatures((prev) => {
        if (isContinueFlux) {
          return prev.map((e) => {
            if (e.nomeContrato === nome || e.nomeContrato === nomeContrato) {
              return {
                ...e,
                statusAssinaturaContrato: "ASSINADO",
                assinaturaBase64: signatureUrl?.split(",")[1],
              };
            }
            return e;
          });
        } else {
          return prev.map((e) => {
            if (e.nome === nome || e.nome === nomeContrato) {
              return {
                ...e,
                statusAssinaturaContrato: "ASSINADO",
                assinaturaBase64: signatureUrl?.split(",")[1],
              };
            }
            return e;
          });
        }
      });
      setHasNewSignature(false);

      setTermsAccepted(false);
      setModalConfirmOpen(false);
      setModalOpen(false);
    }
  }, [
    PostSaveSignature,
    state.cpf,
    state.propostaId,
    benefit.idBeneficio,
    signatureUrl,
    tipoContrato,
    setAllSignatures,
    isContinueFlux,
    nome,
    nomeContrato,
  ]);

  const cancelSignature = () => {
    if (contratoJaAssinado) {
      setSignatureUrl(`data:image/png;base64,${assinaturaBase64}`);
    } else {
      setSignatureUrl("");
      setEmpty(true);
    }
    ref.current.clear();
    setHasNewSignature(false);
    setTermsAccepted(false);
    setModalOpen(false);
  };

  const handleClickTrash = () => {
    ref.current.clear();
  };

  return (
    <S.BenefitWrapper className={isContratoPadrao ? "bigger" : ""}>
      <S.BenefitInfo>
        <span>{nomeContrato || nome}</span>
        {!isContratoPadrao ? (
          <span>{moneyMask(valorMensalidadeContrato || valorMensalidade)}</span>
        ) : null}
      </S.BenefitInfo>
      <TwoButtonPopup
        width={50}
        text={`Tem certeza que finalizou a assinatura do  benefício ${
          nomeContrato || nome
        }?`}
        visibility={modalConfirmOpen}
        buttonCancelText="Não"
        buttonText="Sim"
        onClick={() => {
          confirmSignature();
        }}
        loadingApi={PostSaveSignature.loading}
        onClickCancel={() => {
          if (contratoJaAssinado) {
            setSignatureUrl(`data:image/png;base64,${assinaturaBase64}`);
          } else {
            setSignatureUrl("");
            setEmpty(true);
          }
          setHasNewSignature(false);

          setModalConfirmOpen(false);
          setTermsAccepted(false);
        }}
        setVisibility={setModalConfirmOpen}
        closeOnDocumentClick={false}
      />
      <TwoButtonPopup
        width={95}
        text={`Assinar benefício ${nomeContrato || nome}`}
        visibility={modalOpen}
        buttonCancelText="Cancelar"
        buttonText="Confirmar"
        onClick={() => {
          setSignatureUrl(ref.current.toDataURL("base64"));
          setModalConfirmOpen(true);
        }}
        onClickCancel={() => cancelSignature()}
        setVisibility={setModalOpen}
        buttonDisabledPositive={
          isContratoPadrao ? false : !termsAccepted || !hasNewSignature
        }
        closeOnDocumentClick={false}
      >
        <S.Signature>
          <SignaturePad
            clearOnResize={false}
            ref={ref}
            canvasProps={{
              className: "signatureCanvas",
            }}
            onEnd={() => setHasNewSignature(true)}
          />
        </S.Signature>
        <S.DivTrash>
          <FaRegTrashAlt
            size={25}
            onClick={() => handleClickTrash()}
            style={{ cursor: "pointer" }}
          />
        </S.DivTrash>

        <S.DateInput>
          <Input
            id="dateSignature"
            name="dateSignature"
            label="Data"
            type="text"
            disabled
            value={moment(new Date()).format("DD/MM/YYYY")}
          />
        </S.DateInput>

        {!isContratoPadrao ? (
          <S.Checkbox>
            <InputCheckbox
              type="checkbox"
              id="termsAccepted"
              name="termsAccepted"
              label={
                <>
                  Li e concordo com os
                  <S.ViewTerms onClick={() => window.open(linkContrato)}>
                    Termos do benefício
                  </S.ViewTerms>
                </>
              }
              value={termsAccepted}
              onChange={setTermsAccepted}
            />
          </S.Checkbox>
        ) : null}
      </TwoButtonPopup>

      <S.BoxSignatureButton onClick={() => setModalOpen(true)}>
        <S.LineSignature>
          {empty ? (
            themes.components.icons.shape
          ) : (
            <S.ImgURL src={signatureUrl} alt="Assinatura Digital" />
          )}
        </S.LineSignature>
      </S.BoxSignatureButton>
      {tipoContrato !== "TERMO_TEUCARD" ? (
        <S.BenefitContract>
          <span>
            <a href={linkContrato} target="_blank">
              Visualizar termo
            </a>{" "}
          </span>
        </S.BenefitContract>
      ) : null}
    </S.BenefitWrapper>
  );
};

export default BenefitSignature;
