export const PROPOSAL_STATUS = {
  APROVADOP1: "APROVADA - P1",
  APROVADOP2_ASSINADO: "APROVADA - ASSINADA",
  APROVADOP2_NAO_ASSINADO: "APROVADA - P2",
  APROVADA_MESA_NAO_ASSINADO: "APROVADA - MESA_CREDITO",
  PENDENTE_MESA_CREDITO: "PENDENTE - MESA_CREDITO",
  REPROVADO_P2: "REPROVADA - P2",
  REPROVADO_P1: "REPROVADA - P1",
};

export const FILTER_OPTIONS = {
  APROVADA: "APROVADA",
  REPROVADA: "REPROVADA",
  FILTRO: "FILTRO",
};
