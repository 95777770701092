import styled from "styled-components";
// import { Link as RouterLink } from 'react-router-dom'
import { ArrowBack } from "@styled-icons/boxicons-regular/ArrowBack";
import media from "styled-media-query";
import Spinner from "components/Spinner";

export const RadioGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

export const Header = styled.div`
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  align-items: center;
  margin-bottom: ${({
    theme: {
      spacings: { medium },
    },
  }) => medium};
`;

export const IconArrow = styled(ArrowBack)`
  width: 20px;
  height: 23px;
  position: absolute;

  path {
    fill: ${({
      theme: {
        colors: { gray },
      },
    }) => gray};
  }
`;

export const TextBold = styled.p`
  text-align: left;
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  margin-bottom: ${({
    theme: {
      spacings: { xxlarge },
    },
  }) => xxlarge};
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold },
    },
  }) => fontWeightSemiBold};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
  `}
`;

export const TextBoldRef = styled.p`
  text-align: left;
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  margin-bottom: 5%;
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold },
    },
  }) => fontWeightSemiBold};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
  `}
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular },
    },
  }) => fontWeightRegular};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
  `}
`;

export const WrapperCamera = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;

  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;

export const Picture = styled.div`
  grid-area: camera;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;

  @media screen and (max-width: 500px) {
    justify-content: center;
  }

  #picture-modal {
    margin-right: 20px;
  }

  button {
    display: flex;
    align-items: center;
    width: auto !important;
    height: 40px !important;
    margin: 0 20px 0 0 !important;
    svg {
      margin-right: 10px;
    }
  }
`;

export const ErrorMessage = styled.span`
  color: ${({
    theme: {
      colors: { red },
    },
  }) => red};
  font-size: 1.5rem;
`;

export const SuccessMessage = styled.span`
  color: ${({
    theme: {
      colors: { green },
    },
  }) => green};
  font-size: 1.5rem;
  text-align: center;
`;

export const SpinnerCusmotized = styled(Spinner).attrs((props) => ({
  color: props.theme.colors.primary,
}))``;

export const WrapperSpinner = styled.div`
  width: 200px;
  height: 200px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
