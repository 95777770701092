import styled from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  margin: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 30px;
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular },
    },
  }) => fontWeightRegular};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
  `}
`;

export const ErrorMessage = styled.span`
  color: ${({
    theme: {
      colors: { red },
    },
  }) => red};
  font-size: 1.5rem;
`;

export const RadioGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

export const ClientContent = styled.div`
  display: flex;
`;

export const WrapperClient = styled.div`
  display: flex;
  margin: 5%;
`;

export const WrapperList = styled.div`
  margin-top: 30px;
`;

export const labelTitle = styled.span`
  margin-right: 5px;
  font-size: 15px;
  color: gray;
`;

export const labelValue = styled.span`
  font-size: 17px;
  color: black;
  /* font-weight: bold; */
`;

export const NotFound = styled.span`
  font-size: 3rem;
  color: grey;
  text-align: center;
  margin-top: 30%;
`;

export const divClient = styled.div`
  grid-area: teste1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 15px 10px 0;
`;

export const divRadioBtn = styled.div`
  grid-area: divRadioBtn;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
`;

export const Picture = styled.div`
  grid-area: camera;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;

  @media screen and (max-width: 500px) {
    justify-content: center;
  }

  #picture-modal {
    margin-right: 20px;
  }

  button {
    display: flex;
    align-items: center;
    width: auto !important;
    height: 40px !important;
    margin: 0 20px 0 0 !important;
    svg {
      margin-right: 10px;
    }
  }
`;

export const TextArea = styled.textarea`
  padding: 16px;
  font-size: 1.1em;
  font-weight: 300;
  border: 1px solid;
  width: 100%;
  outline: none;
`;

export const CardBeneift = styled.div`
  width: 100%;
  background-color: #f9fafb;
  border-radius: 16px;
  padding: 24px 30px;
  margin-bottom: 20px;

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 20px;
    margin-bottom: 16px;

    .title {
      display: flex;
      align-items: center;

      img {
        margin-right: 16px;
      }

      h2 {
        font-size: 18px;
        font-weight: bold;
        color: #4a4b4a;
      }
    }

    .fee {
      .price {
        font-size: 18px;
        font-weight: bold;
        color: #4a4b4a;
      }

      .month {
        font-size: 12px;
        font-weight: normal;
        color: #4a4b4a;
      }
    }
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .desc {
      font-size: 14px;
      font-weight: normal;
      color: #4a4b4a;
    }
  }
`;

export const CheckForm = styled.div`
  display: flex;
  align-content: center;
  justify-content: start;
  margin-left: 7%;

  margin-bottom: 18px;

  div {
    display: flex;
    background: ${(props) => (props.active ? "#E71037" : "#727272")};
    height: 18px;
    width: 18px;
    border-radius: 4px;

    svg {
      width: 18px;
      height: 12px;
      color: #fff;
      margin: auto;
    }
  }
  p {
    margin-left: 8px;
    color: #4a4b4a;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
`;
