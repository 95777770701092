import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  span {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
  }
  .icon {
    display: flex;
    justify-content: center;
  }
  .button {
    flex: 1;
  }
  .details {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 4rem;
    text-align: start;
    margin-left: 20%;
    color: rgba(74, 75, 74, 1);

    h2 {
      font-weight: 500;
      font-size: 20px;
      margin: 2rem 0;
    }
    div {
      margin: 8px 0px;
      font-size: 18px;
      font-weight: 400;
    }
  }
`;
