import styled from 'styled-components'
// import { Link as RouterLink } from 'react-router-dom'
import { HiLogout } from 'react-icons/hi'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  width: 80%;
  margin: auto;
  height:100%;  
  display: flex;
  flex-direction: column;

  ${media.greaterThan("medium")`
    width: 60%;
  `}

  ${media.greaterThan("huge")`
   
  `}

`

export const WrapperDivision = styled.div`
  margin-top:30px;
`

export const Header = styled.div`
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };
  align-items: center;
  margin-bottom: ${({ theme: { spacings: {  medium }}}) =>  medium };
`

export const IconLogout = styled(HiLogout)`
  width: 28px;
  height: 28px;
  position: absolute;

  path {
    fill: ${({ theme: { colors: { gray }}}) => gray };
  }
`


export const Text = styled.p`
  text-align: left;
  font-size: ${({theme: {sizes: { small }}}) => small };
  font-weight: ${({theme: { fontWeight: { fontWeightSemiBold }}}) => fontWeightSemiBold };
  color:  ${({ theme: { colors: { blackLight }}}) => blackLight };
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };
  margin-bottom: ${({ theme: { spacings: {  xlarge }}}) =>  xlarge };

  ${media.greaterThan("medium")`
    font-size: ${({theme: {sizes: { medium }}}) => medium };
  `}
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: ${({ theme: { spacings: {  xsmall }}}) =>  xsmall };

  ${media.greaterThan("large")`
   max-width: 600px;
  `}
`

export const Terms = styled.div`
  grid-area: ${({ name }) => name};
  margin-bottom: ${({ theme: { spacings: {  xlarge }}}) =>  xlarge };

  > div {
    display: contents
  }
`

export const TextSpan =  styled.span`
  font-size: ${({theme: {sizes: { small }}}) => small };
  color: ${({ theme: { colors: {  grayDark }}}) =>  grayDark };
  font-weight: ${({theme: { fontWeight: { fontWeightRegular }}}) => fontWeightRegular };
  margin-right: 4px;

  ${media.greaterThan("medium")`
    font-size: ${({theme: {sizes: { medium }}}) => medium };
  `}
`

export const TextBold = styled.strong`
  font-size: ${({theme: {sizes: { small }}}) => small };
  color: ${({ theme: { colors: {  primary }}}) =>  primary };
  font-weight: ${({theme: { fontWeight: { fontWeightSemiBold }}}) => fontWeightSemiBold };
  margin-right: 4px;
  border-bottom: 1px solid ${({ theme: { colors: {  primary }}}) =>  primary };

  ${media.greaterThan("medium")`
    font-size: ${({theme: {sizes: { medium }}}) => medium };
  `}

  ${media.greaterThan("large")`
    font-size: ${({theme: {sizes: { medium }}}) => medium };
  `}
`
