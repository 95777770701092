import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { ArrowBack } from "@styled-icons/boxicons-regular/ArrowBack";
import media from "styled-media-query";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 40px;
  align-items: center;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const ValueSimulate = styled.div`
  display: flex;
  padding: 40px;
  border-radius: 12px;
  background: #fff;
  gap: 2rem;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}

  &:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`;

export const TextContainer = styled.div`
  flex-direction: column;
  display: flex;
`;

export const Value = styled.span`
  font-size: 22px;
  font-weight: 600;
  background: linear-gradient(to right, #e71037, #ed933a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  align-self: center;
  margin-top: 1rem;
`;

export const SimulateEditTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
`;

export const InputValue = styled.div`
  width: 100%;
`;

export const Slider = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const InstallmentsSlider = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const InstallmentsSimulationTitle = styled.span`
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 1.7rem;
  font-weight: 700;
`;

export const InstallmentsSimulate = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  background: #fff;
  margin-top: 20px;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;

  ${media.greaterThan("medium")`
  width: 80%;
  height: 250px;
  `}

  &:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`;
export const InstallmentsSimulationContainer = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
`;

export const InstallmentsSimulationValue = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #424242;
`;

export const ResultSimulate = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;

  background: #fff;
  margin-left: 30px;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;

  ${media.greaterThan("medium")`
  width: 50%;
  height: 450px;
  `}
  ${media.lessThan("medium")`
  width: 50%;
  height: 450px;
  `}

  &:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`;

export const NoContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const NoContentImg = styled.img`
  height: 80px;
  object-fit: contain;
  margin-right: 10px;
`;

export const SimulateNoContent = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: #d4313e;
`;

export const Item = styled.span`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  background: #fff;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;

  ${media.greaterThan("medium")`
width: 95%;
height: 10%;
`}

  &:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`;

export const TextItem = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
  width: 20rem;
`;
export const TextItemValue = styled.span`
  font-size: 14px;
  font-weight: 600;
  margin-left: 5px;
  width: 15rem;
  text-align: end;
`;

export const Footer = styled.div`
  margin-top: 25px;
  bottom: 0;
  width: 65%;

  @media (max-width: 712px) {
    padding: 20px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`;

export const Rounded = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #f57e38;
`;

export const MiddleContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: baseline;
  font-size: 1.5rem;
  margin-top: 5rem;
  margin-bottom: 7rem;
`;
export const MiddleContainerItems = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  align-items: baseline;
  font-size: 1.5rem;
  margin-top: 5rem;
  margin-bottom: 7rem;
  padding: 10;
`;
export const ValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: center;
`;

export const Divider = styled.div`
  border-right: 1px solid #979797;
  align-self: center;
  height: 40rem;
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
