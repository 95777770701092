import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  width: 80%;
  margin: auto;
  height:100%;  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${media.greaterThan("large")`
    width: 70%;
  `}

`

export const TitleBold = styled.span`
  font-weight: ${({theme: { fontWeight: { fontWeightSemiBold }}}) => fontWeightSemiBold };
`

export const LoginForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: ${({ theme: { spacings: {  xsmall }}}) =>  xsmall };

  ${media.greaterThan("large")`
   max-width: 600px;
  `}

  &&.password {
    margin-bottom: 70px;
  }
`
export const HiperlinkPass = styled.span`
  border: 0;
  background: transparent;
  padding-left: 3px;
  float: right;
  cursor:pointer;
  color: #9D9D9D;
  font-size: 14px;
  font-weight: 600;
`;

export const LoginInfo = styled.div`
  height: 200px;
  max-height: 280px;

`

export const LoginRegister = styled.small`
  color: ${({ theme: { colors: { blackLight }}}) => blackLight };
  font-weight: ${({theme: { fontWeight: { fontWeightRegular }}}) => fontWeightRegular };
  font-size: ${({theme: {sizes: { xsmall }}}) => xsmall };
  display: inline-block;
  letter-spacing: 0.0357143em;
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };
  text-align:center;
  width: 100%;

  ${media.greaterThan("medium")`
    font-size: ${({theme: {sizes: { medium }}}) => medium };
  `}
`

export const Link = styled(RouterLink)`
  color: ${({ theme: { colors: { primary }}}) => primary };
  font-weight: ${({theme: { fontWeight: { fontWeightSemiBold }}}) => fontWeightSemiBold };
  font-size: ${({theme: {sizes: { xsmall }}}) => xsmall };

  ${media.greaterThan("medium")`
    font-size: ${({theme: {sizes: { medium }}}) => medium };
  `}
`


export const WrapperPassword = styled.div`
  position: relative;
`


export const Error = styled.span`
  font-size: 12px;
  color: ${({ theme: { colors: {  red }}}) =>  red };
  margin-top: -257px;
  border-top: 1px solid #ED4848;

`;

export const WrapperLogo = styled.div`
  margin-bottom: 30px;
`

export const Version = styled.span`
  position:fixed;
  bottom: 5px;
  margin: 0 auto;
  color:red;
  font-size: 1rem;
`