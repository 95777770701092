import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const Img = styled.img`
  width: max(50%, 150px);
  margin: 0 auto;
`
