import styled, {keyframes} from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const Container = styled.div`
  margin:30px;
  padding-bottom: 20px;
`

export const ContentInput = styled.div`
  flex-direction: column;
  animation: ${fadeIn} .8s;
`

export const WrapperList = styled.div`
  margin-top: 30px;
`

export const NotFound = styled.span`
  font-size: 3rem;
  color: grey;
  text-align: center;
  margin-top: 30%;
`