import styled from 'styled-components'
import media from 'styled-media-query'
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack'


export const Wrapper = styled.div`
  margin: 30px;

  .newEmail {
    width: 85%;
    float: right;
    margin-top: -28px;
    margin-bottom: 30px;

    ${media.greaterThan("medium")`
      width: 92%;
    `}
    ${media.greaterThan("large")`
      width: 93%;
    `}
  }
`

export const Infos = styled.div`
 margin-bottom: ${({ theme: { spacings: {  small }}}) =>  small };

`

export const Header = styled.div`
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };
  align-items: center;
  margin-bottom: ${({ theme: { spacings: {  medium }}}) =>  medium };
  margin-top: 30px;
`

export const IconArrow = styled(ArrowBack)`
  width: 20px;
  height: 23px;
  position: absolute;

  path {
    fill: ${({ theme: { colors: { gray }}}) => gray };
  }
`

export const Text = styled.p`
  text-align: left;
  font-size: ${({theme: {sizes: { small }}}) => small };
  font-weight: ${({theme: { fontWeight: { fontWeightSemiBold }}}) => fontWeightSemiBold };
  color:  ${({ theme: { colors: { blackLight }}}) => blackLight };
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };
  margin-bottom: ${({ theme: { spacings: {  small }}}) =>  small };

  ${media.greaterThan("medium")`
    font-size: ${({theme: {sizes: { medium }}}) => medium };
  `}

`
export const TextSpan =  styled.span`
  font-size: ${({theme: {sizes: { small }}}) => small };
  color: ${({ theme: { colors: {  grayDark }}}) =>  grayDark };
  font-weight: ${({theme: { fontWeight: { fontWeightRegular }}}) => fontWeightRegular };
  margin-right: 4px;

  ${media.greaterThan("medium")`
    font-size: ${({theme: {sizes: { medium }}}) => medium };
  `}
`

export const TextBold = styled.strong`
  font-size: ${({theme: {sizes: { small }}}) => small };
  color: ${({ theme: { colors: {  blackLight }}}) =>  blackLight };
  font-weight: ${({theme: { fontWeight: { fontWeightSemiBold }}}) => fontWeightSemiBold };
  margin-right: 4px;

  ${media.greaterThan("medium")`
    font-size: ${({theme: {sizes: { medium }}}) => medium };
  `}

  ${media.greaterThan("large")`
    font-size: ${({theme: {sizes: { medium }}}) => medium };
  `}
`
export const RadioGroup = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 30px;

  >div {
    width:100%;
  }
`