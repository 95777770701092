import React from 'react';
import * as St from './styles';
import Spinner from 'components/Spinner';
import themes from 'styles/theme';

function Button({
  children,
  icon,
  size = 'medium',
  fullWidth = false,
  fitContent = false,
  minimal = false,
  type,
  outlined,
  marginBottom,
  marginLeft,
  loading = false,
  ...props
}) {
    return (
        <St.Container
          type={type}
          size={size}
          fullWidth={fullWidth}
          hasIcon={!!icon}
          minimal={minimal}
          outlined={outlined}
          fitContent={fitContent}
          marginBottom={marginBottom}
          marginLeft={marginLeft}
          {...props}
        >
          {loading ? (
            <Spinner color={outlined ? themes.colors.primary : '#FFF'} />
          ) : (
            <>
              {icon}
              {!!children && <span>{children}</span>}
            </>
          )}
        </St.Container>
    );
}

export default Button;
