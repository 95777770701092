/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import useReactRouter from "use-react-router";
import { useLocation } from "react-router-dom";

import Button from "components/Button";
import Input from "components/Input";
import Spinner from "components/Spinner";
import OneButtonPopup from "components/modals/OneButtonPopup";
import Layout from "components/Layout";
import Grid from "components/Grid";

import * as S from "./styles";
import { codeVerificationMask } from "utils/mask";

import Repository from "repository";
import useApi from "hooks/useApi";
import constants from "utils/constants";

const PushSMS = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [resendButtonIsDisabled, setResendButtonIsDisabled] = useState(false);
  const [countResendSMS, setCountResendSMS] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [chronometer, setChronometer] = useState(60);
  const [chronometerInterval, setChronometerInterval] = useState(0);
  const [goBack, setGoBack] = useState(false);
  const [sendWhatsApp, setSendWhatsApp] = useState(true);

  const { history } = useReactRouter();
  const { state } = useLocation();

  const PostSendSMS = useApi(constants.endpoints.postSendPushSMS, "POST");

  const PostSendWhatsApp = useApi(
    constants.endpoints.postSendTokenWhatsApp,
    "POST"
  );

  const postSendPushSMS = async () => {
    const payload = {
      numeroCelular: state?.celular?.unmasked,
    };

    try {
      const res = await PostSendSMS.callApi(payload);

      return res;
    } catch (error) {
      const customMessage = error?.response?.data?.mensagem;

      throw new Error(customMessage || error?.message);
    }
  };

  const postSendPushWhatsApp = async () => {
    const payload = {
      cpf: state?.cpf?.unmasked || state?.cpf,
      celular: state?.celular?.unmasked || state?.celular,
    };

    try {
      const response = await PostSendWhatsApp.callApi(payload);

      return response;
    } catch (error) {
      const customMessage = error?.response?.data?.mensagem;

      throw new Error(customMessage || error?.message);
    }
  };

  useEffect(() => {
    async function SendPush() {
      try {
        await postSendPushWhatsApp();
        // await postSendPushSMS();
        handleDisableButtonResendSMS();
      } catch (error) {
        setPopUpMessage(
          error.response.data.mensagem || "Falha ao enviar Whatsapp!"
        );
        setPopUpIsOpen(true);
        setGoBack(true);
      }
    }
    SendPush();
  }, []);

  useEffect(() => {
    if (countResendSMS === 4) {
      reSendSMS();
    }
  }, [countResendSMS]);

  useEffect(() => {
    if (chronometer === 0) {
      clearInterval(chronometerInterval);
      setChronometer(60);
    }
  }, [chronometer, chronometerInterval]);

  const reSendSMS = useCallback(async () => {
    if (resendButtonIsDisabled) return;

    if (countResendSMS === 3) {
      setPopUpMessage(
        "Não é possível reenviar mais de 3 vezes o SMS! Verifique se o celular está correto e tente novamente."
      );
      setPopUpIsOpen(true);
      setGoBack(true);
    }

    handleDisableButtonResendSMS();
    setLoading(true);

    try {
      const response = await postSendPushSMS();

      if (response.status >= 200 && response.status < 300) {
        setPopUpMessage("SMS re-enviado com sucesso!");
      } else {
        setPopUpMessage("Falha ao re-enviar SMS!");
      }
    } catch (err) {
      setPopUpMessage("Falha ao re-enviar SMS!");
    } finally {
      setLoading(false);
      setSendWhatsApp(false);
      setPopUpIsOpen(true);
      setCountResendSMS((prev) => ++prev);
    }
  });

  const handleDisableButtonResendSMS = () => {
    setResendButtonIsDisabled(true);
    setTimeout(() => setResendButtonIsDisabled(false), 60000);
    const interval = setInterval(() => setChronometer((prev) => --prev), 1000);
    setChronometerInterval(interval);
  };

  const addProposalService = async () => {
    Repository.session.set(
      Repository.keys.CODE_VERIFICATION,
      verificationCode?.unmasked
    );

    history.push("/loading-proposta", {
      cpf: state?.cpf,
      celular: state?.celular,
      tipoOrigem: state?.tipoOrigem,
      checkWhatsapp: state?.checkWhatsapp,
      uf: state?.uf,
      email: state?.email,
      idAnfitriao: state?.idAnfitriao,
    });
  };

  function handleDisabledButton() {
    const code = verificationCode?.unmasked || "";
    return code.length !== 6;
  }

  return (
    <Layout.View
      title={"Proposta Teucard"}
      host={state?.idAnfitriao ? state?.idAnfitriao : ""}
      routeBack="/opcoes-acesso"
    >
      <Layout.Label>
        Insira o código enviado por {sendWhatsApp ? "Whatsapp" : "SMS"} no seu
        celular.
      </Layout.Label>
      <br />
      <section>
        <Input
          id="verificationCode"
          name="verificationCode"
          label="Código de verificação"
          placeholder="000 000"
          value={verificationCode?.masked}
          max={7}
          onChange={(e) =>
            setVerificationCode(codeVerificationMask(e.target.value))
          }
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
        />

        {/* <S.ResendCode disabled={resendButtonIsDisabled}>
          Não recebeu o {sendWhatsApp ? "SMS" : "SMS"}? Você pode
          <span onClick={reSendSMS}>reenviar</span>{" "}
          {sendWhatsApp ? "por SMS" : ""}.{" "}
          {chronometer !== 60 && `Aguarde ${chronometer}.`}
        </S.ResendCode> */}
        <S.ResendCode disabled={resendButtonIsDisabled}>
          Não recebeu o {sendWhatsApp ? "Whatsapp" : "SMS"}? Você pode
          <span onClick={reSendSMS}>reenviar</span>{" "}
          {sendWhatsApp ? "por SMS" : ""}.{" "}
          {chronometer !== 60 && `Aguarde ${chronometer}.`}
        </S.ResendCode>
      </section>

      <Grid xs={1} md={2} sm={1}>
        <div />
        <div>
          <Button
            text={isLoading ? <Spinner color="#FFF" /> : "Validar código"}
            buttonType="primary"
            onClick={addProposalService}
            disabled={handleDisabledButton()}
          />
          <OneButtonPopup
            defaultButton
            text={<div dangerouslySetInnerHTML={{ __html: popUpMessage }} />}
            buttonText="Entendi"
            visibility={popUpIsOpen}
            setVisibility={() => {
              setPopUpIsOpen(false);
              if (goBack) {
                return (window.location = "http://useteucard.com.br/");
              }
            }}
          />
        </div>
      </Grid>
    </Layout.View>
  );
};

export default PushSMS;
