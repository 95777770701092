import styled, { css } from 'styled-components'
import media from 'styled-media-query'

const primary = css`
  font-size: ${({theme: {sizes: { small }}}) => small };
  font-weight: ${({theme: { fontWeight: { fontWeightRegular }}}) => fontWeightRegular };
  color:  ${({ theme: { colors: { blackLight }}}) => blackLight };
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };
  margin-bottom: ${({ theme: { spacings: {  xsmall }}}) =>  xsmall };

  ${media.greaterThan("medium")`
    font-size: ${({theme: {sizes: { xlarge }}}) => xlarge };
  `}
`

const secondary = css`
  font-size: ${({theme: {sizes: { xsmall }}}) => xsmall };
  font-weight: ${({theme: { fontWeight: { fontWeightRegular }}}) => fontWeightRegular };
  color:  ${({ theme: { colors: { blackLight }}}) => blackLight };
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };
  margin-bottom: ${({ theme: { spacings: {  large }}}) =>  large };

  ${media.greaterThan("medium")`
    font-size: ${({theme: {sizes: { large }}}) => large };
  `}
`

const tertiary = css`
  font-size: ${({theme: {sizes: { small }}}) => small };
  font-weight: ${({theme: { fontWeight: { fontWeightSemiBold }}}) => fontWeightSemiBold };
  color:  ${({ theme: { colors: { blackLight }}}) => blackLight };
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };

  ${media.greaterThan("medium")`
    font-size: ${({theme: {sizes: { large }}}) => large };
  `}
`

const title = css`
  text-align: center;

  &.primary {
    ${primary}
  }

  &.secondary {
    ${secondary}
  }

  &.tertiary {
    ${tertiary}
  }

`

export const Title = styled.h1`
  ${title}
`

