import styled from "styled-components";
import Popup from "reactjs-popup";

export const Container = styled(Popup)`
  &-content {
    width: 350px;
    padding: 0;
    background-color: #fff;
    border: 1px solid #e71037;
    /* top: -110px; */

    .content-container {
      padding: 25px;

      h3 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 7px;
      }

      p {
        margin-top: 20px;
        font-size: 14px;
      }
    }

    img {
      cursor: pointer;
    }

    hr {
      opacity: 0.5;
    }

    .button-container {
      display: flex;
      justify-content: space-between;

      padding: 25px;

      button {
        width: 100%;
        font-size: 1.2em;
        background: #e71037;
        border: 2px solid #e71037;
        border-radius: 5px;
        color: #fff;
        height: 35px;
        padding: 7px 0px;
      }
    }
  }
`;
