/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Layout from "components/OldLayout";
import Input from "components/Input";
import Loading from "components/Loading";
import Button from "components/Button";
import OneButtonPopup from "components/modals/OneButtonPopup";

import { useLoan } from "hooks/useLoan";
import { cpfMask, maskDateInput, phoneMask } from "utils/mask";
import useApi from "hooks/useApi";
import constants from "utils/constants";

import * as St from "./styles";
import themes from "styles";

const inputStyle = {
  border: "1px solid #6c6c6c",
  padding: "1rem",
  borderRadius: ".5rem",
  width: "100%",
  height: "40%",
};

const ConfirmDataLoanSeeker = () => {
  const initialState = {
    cpf: cpfMask(""),
  };
  const [data, setData] = useState({
    nome: "",
    email: "",
    celular: "",
    dataNascimento: "",
  });

  const history = useHistory();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { cpf, nome, email, celular, dataNascimento } = state;
  const { dispatchUserInitialData, dispatchFinishResponse, finishResponse } =
    useLoan();
  const [userInfo, setUserInfo] = useState();
  const [enabledFields, setEnabledFields] = useState(false);
  const [validate, setValidate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");

  function reducer(state, action) {
    const { type, payload } = action;
    return { ...state, [type]: payload };
  }

  const getClientDetails = useApi(
    constants.endpoints.searchClientByCpf.replace("PARAM_CPF", cpf.unmasked),
    "GET"
  );

  const getProposalsLoan = useApi(
    constants.endpoints.getLastProposalsByEasyCredito.replace(
      "PARAM_CPF",
      cpf.unmasked
    ),
    "GET"
  );

  const getClientInfo = async () => {
    setLoading(true);
    try {
      const response = await getClientDetails.callApi();
      if (response.status >= 200 && response.status < 300) {
        setLoading(false);
        setUserInfo(response.data);
        setEnabledFields(true);
        setValidate(false);
      }

      if (response.status === 404) {
        setLoading(false);
        setEnabledFields(true);
        setUserInfo(null);
        setData(null);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const validateInput = () => {
    return (
      cpf?.unmasked.length < 11 ||
      nome?.split("").length < 2 ||
      dataNascimento?.masked.length < 10 ||
      celular?.masked.length < 14 ||
      !validateEmail(email)
    );
  };

  useEffect(() => {
    if (data) {
      dispatchUserInitialData({ ...state, data, userInfo });
    } else {
      setOpenDialog(true);
      setMessage("O CPF informado não é de um cliente TeuCard!");
    }
  }, [data]);

  //TESTE Pipeline

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  useEffect(() => {
    if (cpf?.unmasked.length === 11) {
      getClientInfo();
    }
  }, [cpf?.unmasked?.length]);

  useEffect(() => {
    if (userInfo) {
      setData((prevData) => ({
        ...prevData,
        nome: userInfo?.nome,
      }));
      setData((prevData) => ({
        ...prevData,
        email: userInfo?.email,
      }));
      setData((prevData) => ({
        ...prevData,
        dataNascimento: {
          masked: maskDateInput(
            userInfo?.dataNascimento.slice(8, 10) +
              userInfo?.dataNascimento.slice(5, 7) +
              userInfo?.dataNascimento.slice(0, 4)
          ).masked,
          unmasked: userInfo?.dataNascimento,
        },
      }));
      setData((prevData) => ({
        ...prevData,
        celular: {
          masked: phoneMask(userInfo?.celular).masked,
          unmasked: userInfo?.celular,
        },
      }));
    }
  }, [userInfo]);

  const onChange = (e, mask) => {
    const {
      target: { name, value },
    } = e;

    const updateValue = mask ? mask(value) : value;

    dispatch({ type: name, payload: updateValue });

    dispatchUserInitialData(state);
  };

  const handleNext = async () => {
    setLoading(true);
    try {
      const response = await getProposalsLoan.callApi();
      const { data } = response;

      if (Object.keys(data).length === 0 || response.status >= 400) {
        history.push("/additional-data-loan-seeker");
        setLoading(false);
      } else {
        const propostas = data[0]?.propostas ? data[0].propostas : data;

        dispatchFinishResponse(propostas);

        history.push("/proposal-result-loan-seeker");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <St.SafeView>
      <Loading status={loading} />
      <Layout title="Informe teus dados" routeBack="/loan-seeker">
        <OneButtonPopup
          text={message}
          title="Buscando propostas"
          visibility={openDialog}
          buttonText="Voltar a página inicial"
          iconModal={themes.components.png.emojiSad}
          defaultButton
          setVisibility={setOpenDialog}
          onClose={() => history.push("/loan-seeker")}
        />
        <St.Container>
          <St.TopSpace>
            <St.Title>
              Para buscar as melhores ofertas de empréstimo, precisamos que você
              confirme alguns dados.
            </St.Title>
            <St.Inputs>
              <Input
                boldLabel
                style={inputStyle}
                id="cpfLoan"
                name="cpf"
                placeholder="000.000.000-00"
                type="text"
                value={cpf?.masked}
                max={14}
                onChange={(e) => onChange(e, cpfMask)}
                label="CPF*"
              />

              {enabledFields && (
                <>
                  <Input
                    boldLabel
                    style={inputStyle}
                    id="nameLoan"
                    name="nome"
                    label="Nome completo*"
                    value={nome ? nome : data?.nome}
                    onChange={(e) => onChange(e)}
                    disabled={userInfo}
                  />

                  <Input
                    boldLabel
                    name="dataNascimento"
                    style={inputStyle}
                    label="Data de Nascimento*"
                    autoComplete="off"
                    max={10}
                    value={
                      dataNascimento?.masked
                        ? dataNascimento?.masked
                        : data?.dataNascimento.masked
                    }
                    onChange={(e) => onChange(e, maskDateInput)}
                    placeholder="00/00/0000"
                    disabled={userInfo}
                  />
                  <Input
                    name="email"
                    boldLabel
                    value={email ? email : data?.email}
                    onChange={(e) => onChange(e)}
                    autoComplete="off"
                    style={inputStyle}
                    placeholder="example@example.com"
                    label="E-mail*"
                    disabled={userInfo}
                  />
                  <Input
                    label="Celular*"
                    boldLabel
                    name="celular"
                    style={inputStyle}
                    max={15}
                    value={celular?.masked || data?.celular.masked}
                    autoComplete="off"
                    placeholder="(99) 99999-9999"
                    onChange={(e) => onChange(e, phoneMask)}
                    disabled={userInfo}
                  />
                </>
              )}
            </St.Inputs>

            <St.Footer>
              <Button
                buttonType="primary"
                text="Avançar"
                disabled={!validate ? validate : validateInput()}
                onClick={() => handleNext()}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default ConfirmDataLoanSeeker;
