import styled from 'styled-components';

export const Container = styled.div`
  margin:30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const ContentInput = styled.div`
  display: flex;
  margin: 2%;
`;

export const ContentLeft = styled.div`
  width: 50%;
  margin-right: 3%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentRight = styled.div`
  width: 50%;
  margin-right: 3%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;