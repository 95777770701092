import styled from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.div`
  padding: 0px 16px 24px 10px;
  box-sizing: border-box;
  display: flex;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 15%;
  `}
`;

export const ItemTeuCard = styled.div`
width: 140px;
height: 200px;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  position: relative;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-radius: 10px;
  background: linear-gradient(to bottom, #e71037, #ed933a);
  margin-left: 10px;
  margin-right: 10px;
  ${media.greaterThan("medium")`
  width: 120px;
  height: 170px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
  `}
`;

export const ItemBandeirado = styled.div`
width: 140px;
height: 200px;
display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-radius: 10px;
  background: linear-gradient(to top, #4b6cb7, #182848);
  margin-left: 10px;
  margin-right: 10px;
  ${media.greaterThan("medium")`
  width: 120px;
  height: 170px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1);
  `}
`;

export const ImageChip = styled.img`
  height: 25px;
  margin-left: 100px;
  margin-top: 20px;
  object-fit: contain;
  position: absolute;
`;

export const ImageCard = styled.img`
  height: 28px;
  margin-left: 5px;
  object-fit: contain;
  margin-top: 2px;
`;

export const RadioButtonLabelTeuCard = styled.label`
  position: absolute;
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #ccc;
  margin-top: 20px;
  margin-left: 15px;
`;

export const Footer = styled.label`
  width: 100%;
  height: 3vh;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 15%;
  `}
`;

export const ImageTeuCard = styled.img`
  height: 16px;
  object-fit: contain;
  margin-right: 10px;
`;

export const RadioButtonLabelBandeirado = styled.label`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #ccc;
  margin-top: 20px;
  margin-left: 15px;
`;

export const ItemCircle = styled.label`
  position: absolute;
  top: 31%;
  left: 7.5px;
  width: 8.3px;
  height: 8.3px;
  border-radius: 50%;
  background: white;
`;

export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  align-items: center;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-top: 15px;
  &:hover ~ ${RadioButtonLabelTeuCard} {
    background: #ccc;
  }
  &:hover ~ ${RadioButtonLabelBandeirado} {
    background: #ccc;
  }
  &:checked + ${ItemTeuCard} + ${ItemBandeirado} {
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, darkblue, darkorchid) 1;
  }
  &:checked + ${RadioButtonLabelTeuCard} {
    background: linear-gradient(to right, #e71037, #ed933a);
    border: 1px solid linear-gradient(to right, #e71037, #ed933a);
  }
  &:checked + ${RadioButtonLabelBandeirado} {
    background: linear-gradient(to top, #4b6cb7, #182848);
    border: 1px solid linear-gradient(to top, #4b6cb7, #182848);
  }
`;
