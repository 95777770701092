import styled from "styled-components";
import PropTypes from "prop-types";

const CardView = styled.div`
  height: ${(props) => (props.height ? `${props.height}px` : "auto")};
  width: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.widthPixel
      ? `${props.widthPixel}px`
      : props.width
      ? `${props.width}%`
      : "100%"};

  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : "0px")};
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}px` : "0px"};
  margin-left: ${(props) =>
    props.marginLeft ? `${props.marginLeft}px` : "0px"};
  margin-right: ${(props) =>
    props.marginRight ? `${props.marginRight}px` : "0px"};

  display: flex;
  flex-direction: column;

  background-color: white;

  -webkit-align-items: ${(props) => (props.center ? "center" : "none")};
  align-items: ${(props) => (props.center ? "center" : "none")};

  padding: ${(props) => (props.padding ? `${props.padding}px` : "0px")};

  -webkit-justify-content: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.space
      ? "space-between"
      : props.center && props.horizontal
      ? "center"
      : "none"};

  justify-content: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.space
      ? "space-between"
      : props.center && props.horizontal
      ? "center"
      : "none"};

  border: 0.5px solid #e8e8e8;
  border-radius: 4px;
  box-shadow: 0 1px 7px 0 rgba(201, 201, 201, 0.5);
`;

CardView.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  widthPixel: PropTypes.number,
  center: PropTypes.bool,
  horizontal: PropTypes.bool,
  space: PropTypes.bool,
  padding: PropTypes.number,
  marginTop: PropTypes.number,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
};

CardView.defaultProps = {
  height: null,
  width: 100,
  widthPixel: null,
  center: false,
  horizontal: false,
  space: false,
  padding: 0,
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
};

export default CardView;
