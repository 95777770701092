import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import Button from "components/buttonNew";
import CardView from "components/CardView";
import ContainerFlex from "components/ContainerFlex";

import themes from "styles";

import * as St from "./styles";

function Dialog({
  children,
  icon = themes.components.icons.tick,
  open,
  message,
  positiveLabel,
  positiveAction,
  negativeLabel,
  negativeAction,
  widthInPercent,
  widthInPixel,
  title,
  closeButton = false,
  buttonDisabledPositive = false,
  customButton,
}) {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (open) setIsOpened(true);
    else setIsOpened((prev) => (prev === true ? false : null));
  }, [open]);

  const close = () => {
    if (negativeAction) negativeAction();
    setIsOpened(false);
  };

  const confirm = () => {
    if (positiveAction) positiveAction();
  };

  return (
    <St.Container show={isOpened}>
      <CardView space widthPixel={widthInPixel} width={widthInPercent}>
        <ContainerFlex center>
          {/* Header */}
          <ContainerFlex padding={16}>
            <ContainerFlex row align="center">
              {icon && <St.Icon src={icon} />}
              {title && <St.TitleHeader>{title}</St.TitleHeader>}
            </ContainerFlex>
          </ContainerFlex>

          {/* Content */}
          <ContainerFlex align="center" padding={16}>
            {message && (
              <St.Message alignCenter={closeButton}>
                {message}
                {closeButton ? (
                  <St.CloseIcon onClick={close}>
                    <MdClose />
                  </St.CloseIcon>
                ) : (
                  ""
                )}
              </St.Message>
            )}
            {children}
          </ContainerFlex>

          <St.DividerLine />

          {/* Buttons */}
          <ContainerFlex row spaceAround padding={16}>
            {customButton ? (
              customButton
            ) : (
              <>
                {negativeLabel && (
                  <Button fitContent onClick={close} outlined marginBottom={8}>
                    <span>{negativeLabel || "Fechar"}</span>
                  </Button>
                )}

                <Button
                  fitContent={!!negativeLabel || widthInPercent != null}
                  fullWidth={!negativeLabel}
                  onClick={confirm}
                  disabled={buttonDisabledPositive}
                >
                  <span>{positiveLabel || "Confirmar"}</span>
                </Button>
              </>
            )}
          </ContainerFlex>
        </ContainerFlex>
      </CardView>
    </St.Container>
  );
}

export default Dialog;
