/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useContext } from "react";
import useReactRouter from "use-react-router";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "context/GlobalContext";

import Layout from "components/Layout";
import ContainerFlex from "components/ContainerFlex";
import Spinner from "components/Spinner";
import OneButtonPopup from "components/modals/OneButtonPopup";

import constants from "utils/constants";
import useApi from "hooks/useApi";
import Repository from "repository";

export default function LoadingPush() {
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [idProposta, setIdProposta] = useState("");
  const { history } = useReactRouter();
  const { state } = useLocation();
  const { addProposta, proposta } = useContext(GlobalContext);

  const { cpf, celular, uf, email, idAnfitriao } = state;
  useEffect(() => {
    handleInitProposal();
  }, []);
  const user = Repository.storage.get(Repository.keys.USER);
  const origem = Repository.storage.get(Repository.keys.ID_ORIGEM);

  const newCpf = cpf?.replace(/[.|-]/g, "");
  const newPhone = celular?.replace(/[(|)|' '|-]/g, "");

  const URL_Service =
    origem?.tipo !== "LOJA"
      ? constants.endpoints.validateProposalNoAuth
      : constants.endpoints.validateProposal;

  const URL =
    origem?.tipo !== "LOJA"
      ? constants.endpoints.initialProposalNoAuth
      : constants.endpoints.initialProposal;

  const GetValidate = useApi(URL_Service.replace("PARAM_CPF", newCpf), "GET");
  const GetProposalSpcData = useApi(
    constants.endpoints.spcProposalData.replace(
      "PARAM_ID_PROPOSAL",
      idProposta
    ),
    "GET"
  );
  const PostInitialProposal = useApi(URL, "POST");

  const initialProposal = async (checkWhatsApp, termsAccepted) => {
    try {
      const resValidate = await GetValidate.callApi();

      const { obterDadosProposta, temPropostaValida, mensagem, idProposta } =
        resValidate.data;

      setIdProposta(idProposta);

      if (!obterDadosProposta && !temPropostaValida) {
        const codeVerification = Repository.session.get(
          Repository.keys.CODE_VERIFICATION
        );
        Repository.session.set(Repository.keys.X_CODE, codeVerification);

        const payload = {
          cpf: newCpf,
          celular: newPhone,
          optinTermoDeUso: termsAccepted || true,
          checkWhatsApp: checkWhatsApp,
          idUsuario: user?.id,
          origem: origem?.tipo,
          codigoAnfitriao: idAnfitriao,
        };

        const res = await PostInitialProposal.callApi(payload);

        if (res.data) {
          Repository.storage.set(Repository.keys.ID_PROPOSTA, res.data);
          Repository.session.remove(Repository.keys.CODE_VERIFICATION);
          Repository.session.remove(Repository.keys.X_CODE);
        }

        return res;
      } else {
        if (mensagem) {
          throw new Error(mensagem);
        } else {
          if (obterDadosProposta && temPropostaValida) {
            const resObtem = await GetProposalSpcData.callApi();

            if (resObtem.data)
              Repository.storage.set(Repository.keys.ID_PROPOSTA, idProposta);

            return Object.assign(resObtem.data.spc, { id: idProposta });
          } else {
            throw new Error("Algo deu errado!");
          }
        }
      }
    } catch (error) {
      Repository.session.remove(Repository.keys.CODE_VERIFICATION);
      Repository.session.remove(Repository.keys.X_CODE);
      const customMessage = error?.response?.data?.mensagem;

      throw new Error(customMessage || error?.message);
    }
  };
  const postInitProposal = async (termsAccepted, checkWhatsApp) => {
    try {
      const codeVerification = Repository.session.get(
        Repository.keys.CODE_VERIFICATION
      );
      Repository.session.set(Repository.keys.X_CODE, codeVerification);

      const payload = {
        cpf: newCpf,
        celular: newPhone,
        email: email,
        optinTermoDeUso: termsAccepted || true,
        checkWhatsApp: checkWhatsApp || true,
        idUsuario: user?.id,
        origem: origem?.tipo,
        uf: uf,
        codigoAnfitriao: idAnfitriao,
      };

      const res = await PostInitialProposal.callApi(payload);

      if (res.data) {
        Repository.storage.set(Repository.keys.ID_PROPOSTA, res.data);
        Repository.session.remove(Repository.keys.CODE_VERIFICATION);
        Repository.session.remove(Repository.keys.X_CODE);
      }

      return res;
    } catch (error) {
      Repository.session.remove(Repository.keys.CODE_VERIFICATION);
      Repository.session.remove(Repository.keys.X_CODE);

      const customMessage = error?.response?.data?.mensagem;

      throw new Error(customMessage || error?.message);
    }
  };
  async function handleInitProposal() {
    try {
      if (Object.keys(proposta).length === 0) {
        const proposta = await postInitProposal(true, true, uf, email.trim());

        if (proposta?.status >= 200 && proposta?.status < 300) {
          addProposta(proposta?.data);

          history.push("/cadastro-completo", {
            cpf: cpf,
            celular: celular,
            idAnfitriao: idAnfitriao ?? "",
          });
        } else {
          setPopUpMessage(proposta.mensagem);
          setPopUpIsOpen(true);
        }
      } else {
        const proposta = await initialProposal(true, true);

        if (proposta?.status >= 200 && proposta?.status < 300) {
          addProposta(proposta?.data);

          history.push("/cadastro-completo", {
            cpf: cpf,
            celular: celular,
            idAnfitriao: idAnfitriao ?? "",
          });
        } else {
          setPopUpMessage(proposta.mensagem);
          setPopUpIsOpen(true);
        }
      }
    } catch (err) {
      setPopUpMessage(err.message);
      setPopUpIsOpen(true);
    }
  }

  return (
    <Layout.View
      title={popUpIsOpen ? "" : "Proposta Teucard Loading..."}
      noPage={popUpIsOpen}
    >
      {popUpIsOpen ? (
        <></>
      ) : (
        <ContainerFlex row>
          <Layout.Label>
            Estamos Processando sua solicitação, aguarde um momento...
          </Layout.Label>
          <ContainerFlex marginLeft={15}>
            <Spinner color="#424242" />
          </ContainerFlex>
        </ContainerFlex>
      )}
      <OneButtonPopup
        defaultButton
        text={<div dangerouslySetInnerHTML={{ __html: popUpMessage }} />}
        buttonText="Entendi"
        visibility={popUpIsOpen}
        setVisibility={() => {
          setPopUpIsOpen(false);

          window.location = "http://useteucard.com.br/";
        }}
      />
    </Layout.View>
  );
}
