import React from "react";

import Layout from "components/Layout";
import Spinner from "components/Spinner";

const LoadingMessage = ({ title, subtitle }) => {
  return (
    <Layout.View title={title}>
      <Layout.Label>
        {subtitle} <Spinner color="#424242" />
      </Layout.Label>
    </Layout.View>
  );
};

export default LoadingMessage;
