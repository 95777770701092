import styled, { css } from "styled-components";

const primary = css`
  color: ${({
    theme: {
      colors: { white },
    },
  }) => white};
  background: ${({
    theme: {
      colors: { primary },
    },
  }) => primary};
  margin-bottom: ${({
    theme: {
      spacings: { xsmall },
    },
  }) => xsmall};

  span {
    color: ${({
      theme: {
        colors: { white },
      },
    }) => white};
    font-size: ${({
      theme: {
        sizes: { small },
      },
    }) => small};
  }

  &:hover {
    background: ${({
      theme: {
        colors: { primary },
      },
    }) => primary};
  }

  &:disabled {
    background: ${({
      theme: {
        colors: { secondary },
      },
    }) => secondary};
    cursor: not-allowed;
  }
`;

const secondary = css`
  border: 1px solid
    ${({
      theme: {
        colors: { primary },
      },
    }) => primary};
  color: ${({
    theme: {
      colors: { primary },
    },
  }) => primary};
  background: ${({
    theme: {
      colors: { white },
    },
  }) => white};

  span {
    font-size: ${({
      theme: {
        sizes: { small },
      },
    }) => small};
  }

  &:disabled {
    background: ${({
      theme: {
        colors: { secondary },
      },
    }) => secondary};
    cursor: not-allowed;
  }
`;

const button = css`
  text-align: center;
  cursor: pointer;
  height: 48px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold },
    },
  }) => fontWeightSemiBold};
  letter-spacing: 0.9px;
  width: 100%;
  &.primary {
    ${primary}
  }

  &.secondary {
    ${secondary}
  }
`;

export const Button = styled.button`
  ${button}
`;
