import styled from "styled-components";

export const CarouselItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 315px;
    height: 315px;
    border-radius: 8px;
  }
`;

export const ArrowContainer = styled.div`
  border: ${(props) => (props.isEdge ? "3px solid #333" : "3px solid #eee")};
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
`;

export const ContainerDots = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  position: relative;
  bottom: 15px;
`;

export const Dots = styled.div`
  background-color: ${(props) => (props.active ? "#E4003A" : "#eee")};
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  label {
    font-size: 1.8rem;
    text-align: center;
    font-weight: 400;
    line-height: normal;
    color: #4a4b4a;
  }

  .radio {
    cursor: pointer;
    appearance: none;
    border: 2px solid #555655;

    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .radio:before {
    content: "";
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background: #e4003a;
    border-radius: 50%;

    opacity: 0;
  }

  .radio:checked {
    border: 2px solid #e4003a;
  }

  .radio:checked:before {
    opacity: 1;
  }
`;
