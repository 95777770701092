import React from "react";
import { useHistory } from "react-router-dom";

import Layout from "components/OldLayout";
import Button from "components/Button";

import { moneyMask } from "utils/mask";
import { useLoan } from "hooks/useLoan";
import moment from "moment";

import * as St from "./styles";
import themes from "styles";
const WithdrawActiveLeads = () => {
  const {
    components: { png },
  } = themes;

  const history = useHistory();

  const { simulationData } = useLoan();

  return (
    <St.SafeView>
      <Layout routeBack={"/confirm-data-by-teu-card"} title="Simulação">
        <St.TopSpace>
          <St.Title>Que legal, encontramos tua última simulação:</St.Title>
        </St.TopSpace>

        <St.Container>
          <St.FoundLead>
            <div>
              <p>Valor Simulado</p>
              <span>{moneyMask(simulationData?.valorLiberado)}</span>
            </div>
            <img src={png.ornament} />
          </St.FoundLead>

          <St.Simulation>
            <div style={{ alignItems: "flex-start" }}>
              <p>Tipo de simulação</p>
              <span>Valor do saque</span>
            </div>
            <div style={{ alignItems: "flex-end" }}>
              <St.ImageItem src={png.iconSaque} />
            </div>
            <div style={{ alignItems: "flex-start" }}>
              <p>Parcela</p>
              <span>{`${simulationData.numeroParcelas}x de ${moneyMask(
                simulationData?.valorParcela
              )}`}</span>
            </div>
            <div style={{ alignItems: "flex-end" }}>
              <p>Taxa de Juros</p>
              <span>{`${(simulationData.taxaJuros * 100).toLocaleString(
                "pt-br",
                { minimumFractionDigits: 2 }
              )}%`}</span>
            </div>
            <div style={{ alignItems: "flex-start" }}>
              <p>CET</p>
              <span>{`${(
                parseFloat(simulationData?.taxaJurosEfetivaMensal) * 100
              )
                .toFixed(2)
                .toString()
                .replace(".", ",")}% a.m.`}</span>
            </div>
            <div style={{ alignItems: "flex-end" }}>
              <p>Data de vencimento</p>
              <span>
                {moment(
                  simulationData.dataPrimeiroPagamento,
                  "YYYY-MM-DD"
                ).format("DD/MM/YYYY")}
              </span>
            </div>
          </St.Simulation>
          <St.Footer>
            <Button
              buttonType="secondary"
              style={{ marginBottom: "1rem" }}
              text="Quero simular outro valor"
              onClick={() => history.push("/with-draw-simulation-by-teu-card")}
            />
            <Button
              buttonType="primary"
              text="Contratar"
              onClick={() => history.push("/with-draw-additional-data")}
            />
          </St.Footer>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default WithdrawActiveLeads;
