import React from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import Button from "components/Button";
import { moneyMask } from "utils/mask";
import { useHistory } from "react-router-dom";
import themes from "styles";
import { useLoan } from "hooks/useLoan";
import constants from "utils/constants";
import useApi from "hooks/useApi";

const CreditCardLoanSimulatorActiveLeads = () => {
  const history = useHistory();
  const {
    components: { png },
  } = themes;

  const {
    simulationData,
    dispatchSimulationData,
    userInitialData,
    dispatchUserAditionalData,
  } = useLoan();

  const simulationType = {
    CREDITO_LIMITE: "Valor do limite disponível",
    VALOR_LIBERADO: "Valor do saque",
    VALOR_PARCELA: "Valor da parcela",
  };

  const simulationIcon = {
    CREDITO_LIMITE: png.iconLimite,
    VALOR_LIBERADO: png.iconSaque,
    VALOR_PARCELA: png.iconParcela,
  };

  const RequestUserData = useApi(
    constants.endpoints.getClientDataCpf.replace(
      "PARAM_CPF",
      userInitialData?.cpf?.unmasked
    ),
    "GET"
  );

  const handleNext = async () => {
    const data = await RequestUserData.callApi();
    dispatchUserAditionalData(data.data);
    history.push("/credit-card-loan-simulator-aditional-data");
  };
  return (
    <St.SafeView>
      <Layout
        routeBack="/credit-card-loan-simulator-data-confirmation"
        title="Simulação"
      >
        <St.TopSpace>
          <St.Title>Que legal, encontramos tua última simulação:</St.Title>
        </St.TopSpace>

        <St.Container>
          <St.FoundLead>
            <div>
              <p>Valor Simulado</p>
              <span>{moneyMask(simulationData?.valorLiberado)}</span>
            </div>
            <img src={png.ornament}></img>
          </St.FoundLead>

          <St.Simulation>
            <div style={{ alignItems: "flex-start" }}>
              <p>Tipo de simulação</p>
              <span>{simulationType[simulationData?.tipoSimulacao]}</span>
            </div>
            <div style={{ alignItems: "flex-end" }}>
              <St.ImageItem
                src={simulationIcon[simulationData?.tipoSimulacao]}
              />
            </div>
            <div style={{ alignItems: "flex-start" }}>
              <p>Limite de crédito</p>
              <span>{moneyMask(simulationData?.limiteCredito)}</span>
            </div>
            <div style={{ alignItems: "flex-end" }}>
              <p>Parcela</p>
              <span>{moneyMask(simulationData?.valorParcela)}</span>
            </div>
            <div style={{ alignItems: "flex-start" }}>
              <p>Taxa de Juros</p>
              <span>{`${simulationData.taxaJuros}%`}</span>
            </div>
          </St.Simulation>
          <div
            style={{ marginTop: "20px", width: "55%", marginBottom: "20px" }}
          >
            <span
              style={{ fontSize: "1.4rem", color: "#E4003A", fontWeight: 700 }}
            >
              ** O vencimento será de acordo com o vencimento da fatura do teu
              cartão de crédito
            </span>
          </div>
          <St.Footer>
            <Button
              buttonType="secondary"
              style={{ marginBottom: "1rem" }}
              text={"Quero simular outro valor"}
              onClick={() => {
                dispatchSimulationData({});
                history.push("/credit-card-loan-simulator-choose-type");
              }}
            />
            <Button
              buttonType="primary"
              text={"Simular"}
              onClick={() => handleNext()}
            />
          </St.Footer>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default CreditCardLoanSimulatorActiveLeads;
