import React, { useState } from "react";
import InputMask from "react-input-mask";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import * as S from "./styles";

const Input = ({
  marginBottom,
  error = "",
  className,
  label,
  name,
  id,
  mask,
  max,
  type,
  placeholder,
  onChange,
  icon = false,
  defaultValue,
  value,
  disabled,
  iconRight,
  errorDiv = false,
  boldLabel,
  ...others
}) => {
  const [focusInput, setFocusInput] = useState(false);
  const [showText, setShowText] = useState(false);

  return (
    <S.Container
      marginBottom={marginBottom}
      error={errorDiv}
      isFocus={focusInput}
      name={id}
      className={id}
    >
      <label style={{ fontWeight: boldLabel && "bold", color: "black" }}>
        {label}
      </label>

      <InputMask
        disabled={disabled}
        name={name}
        id={name}
        mask={mask}
        maxLength={max}
        type={icon ? (showText ? "text" : "password") : "text"}
        onFocus={() => setFocusInput(true)}
        onBlur={() => setFocusInput(false)}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        {...others}
      />
      {icon ? (
        !showText ? (
          <AiOutlineEyeInvisible
            onClick={() => setShowText(!showText)}
            className="icon"
          />
        ) : (
          <AiOutlineEye
            onClick={() => setShowText(!showText)}
            className="icon"
          />
        )
      ) : null}

      {/* <S.IconRight>{iconRight}</S.IconRight> */}
      {!!error && <S.ErrorText>{error}</S.ErrorText>}
    </S.Container>
  );
};

export default Input;

// import React, { Fragment} from 'react'
// import { useHistory } from 'react-router-dom'
// import InputMask from 'react-input-mask'
// import * as S from './styles'

// export default function Input({ name, label, checked,value, placeholder, min, id, max, onblur, type, disabled, mask, strongs, onChange, error }) {
// 	const history = useHistory();
// 	return (
// 		<S.Container name={id}>
// 		<S.Wrapper name={id} className={type}>
// 			<InputMask
// 				name={name}
// 				id={name}
// 				type={type}
// 				value={value}
// 				placeholder={placeholder}
// 				minLength={min}
// 				maxLength={max}
// 				mask={mask}
// 				checked={checked}
// 				disabled={disabled}
// 				onBlur={() => {
// 					onblur && onblur();
// 				}}
// 				onChange={onChange}
// 			/>
// 				{label && <S.Label  htmlFor={name} >{label}</S.Label>}
// 				{strongs && strongs.map(strong => (
// 				<Fragment>
// 						{strong.type === 'text' && <span>{strong.name}</span>}
// 						{strong.type !== 'text' && <strong onClick={() => history.push(strong.link)}>{strong.name}</strong>}
// 					</Fragment>
// 					))}
// 				{/* {error && <S.Error>{error}</S.Error>} */}
// 		</S.Wrapper>
// 		</S.Container>

// 	);
// }
