// import cardValidator from 'card-validator';
import { differenceInYears } from 'date-fns';
// import ddds from '../assets/mock/ddd.json';

const messagesErrors = {
    cardNumber: 'Cartão de crédito inválido.',
    name: 'Informe seu nome.',
    cpf: 'CPF inválido.',
    phone: 'Número inválido',
    phoneDD: 'DDD informador inválido.',
    email: 'E-mail inválido.',
    date: 'Data inválida.',
    dateMin: 'Idade abaixo do necessário.',
    verificationCode: 'Código inválido.',
};

// export const cardNumberValidator = ( value ) => {
//     const RegExpNumberCard = new RegExp(/[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}/g);
//     // Valida o formato do número com a expressão regular
//     if(!RegExpNumberCard.test(value))
//         return { error: true, message: messagesErrors.cardNumber };
//     // Verifica se é um número de cartão válido
//     const validation = cardValidator.number(value)
//     if(!validation.isPotentiallyValid) return { error: true, message: messagesErrors.cardNumber };

//     return { error: false };
// };

export const nameValidator = ( value ) => {
    const [firstName] = value.split(' ');
    // Valida se existe o nome e sobrenome
    if(firstName.length > 2)return { error: false };

    return { error: true, message: messagesErrors.name };
};

export const documentCPFValidator = ( value ) => {
    const RegExpCPF = new RegExp(/[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}/g);
    // Valida o formato do CPF com a expressão regular
    if(!RegExpCPF.test(value))
        return { error: true, message: messagesErrors.cpf };

    const firstDigitCPF = Number(value.split('-')[1][0]);
    const newValue = value.replace(/\D/g, '');

    let result = 0;

    for (let k = 0; k < newValue.length - 2; k++) {
        result += Number(newValue.charAt(k)) * (10 - k);
    }
    // Nível 1: Calcúlo com os nove primeiros números
    let isValid = (result*10) % 11 === firstDigitCPF ? true : false;
    if(isValid){
        result = 0;
        for (let k = 0; k < newValue.length - 1; k++) {
            result += Number(newValue.charAt(k)) * (10 - k);
        }
        // Nível 2: Calcúlo com os nove primeiros números + o primeiro dígito
        const isValid = (result*10) % 11 === 0 ? true : false;
        if(isValid) return { error: false }; 
    }
    return { error: true, message: messagesErrors.cpf };
};

// export const phoneValidator = ( value ) => {
//     const RegExpPhone = new RegExp(/[0-9]{2}\)? [0-9]{5}-?[0-9]{4}/g);
//     // Valida o formato do telefone com a expressão regular
//     if(!RegExpPhone.test(value))
//         return { error: true, message: messagesErrors.phone };
//     // Isola o DDD do número
//     const phoneDDD = Number(value.slice(1,3));
//     // const isExistDDD = ddds.some(ddd => ddd === phoneDDD);

//     if(!isExistDDD) return { error: true, message: messagesErrors.phoneDD };

//     return { error: false };
// };

export const emailValidator = ( value ) => {
    const RegExpEmail = new RegExp(/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+/g);
    // Valida se a string não está vazia
    if(!RegExpEmail.test(value))
        return { error: true, message: messagesErrors.email };

    const [firstDomain, secondDomain] = value.split('@')[1].split('.');

    if(firstDomain.length > 2 && secondDomain) 
        if(secondDomain.length > 1) return { error: false };

    return { error: true, message: messagesErrors.email };
};

export const dateBirthValidator = (value) => {
    const regularExpressionDate = new RegExp(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/g);
    if(!regularExpressionDate.test(value))
        return { error: true, message: messagesErrors.date, a: 1 };

    const [day, month, year] = value.split('/');
    if(Number(month) - 1 > 13 || day > 32) return { error: true, message: messagesErrors.date, a: 2 };

    const dateFormat = new Date(year, Number(month) - 1, day);
    const ageUser = differenceInYears(new Date(), dateFormat);
    if(ageUser < 18) return { error: true, message: messagesErrors.dateMin, a: 3 };

    return { error: false };
};

export const dateValidator = (value) => {
    const regularExpressionDate = new RegExp(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/g);
    if(!regularExpressionDate.test(value))
        return { error: true, message: messagesErrors.date, a: 1 };

    const [day, month, year] = value.split('/');
    if(Number(month) - 1 > 13 || day > 32) return { error: true, message: messagesErrors.date, a: 2 };

    return { error: false };
};

export const verificationCodeValidator = ( value ) => {
    const RegExpVerificationCode = new RegExp(/[0-9]{4} [0-9]{4}/g);
    // Valida o formato da string com uma expressão regular
    if(!RegExpVerificationCode.test(value))
        return { error: true, message: messagesErrors.verificationCode };

    return { error: false };
};

export const isSequencialNumbers = (value) => {
    for(let position = 0; position < value.length - 2; position++) {
        // Avança uma unidade de código UTF-16
        const firstChart = value[position].charCodeAt() + 1;
        const secondChart = value[position + 1].charCodeAt();
        // Volta uma unidade de código UTF-16
        const thirdChart = value[position + 2].charCodeAt() - 1;
        
        if(firstChart === secondChart && secondChart === thirdChart) 
            return true;
    };
    return false;
}

export const passwordValidator = (value) => {
    // Validando o tamanho mínimo da senha
    if(value.length < 6)  return { error: true, message: 'Senha mínima' };
    // Validado se a string não é sequencial
    if(isSequencialNumbers(value)) return { message: 'Números sequenciais' };

    return false;
};

export const passwordConfirmationValidator = (password, passwordConfirmation) => {
    if(password === passwordConfirmation)
        return false;

    return { message: 'Senhas não compatíveis' };
};

export const userPersonalDataValidator = (dataForm, dataApi) => {
    const cpfApiValidate = dataForm.document.replace(/\D/g, '') !== dataApi.cpf 
        && 'CPF incorreto com o registrado';

    const nameApiValidate = dataForm.name.toLowerCase() !== dataApi.nome.toLowerCase() 
        && 'Nome incorreto com o registrado';

    const [day, mount, year] = dataForm.birthdate.split('/');
    const birthdateFormatted = `${year}-${mount}-${day}`;
    const birthdateApiValidate = birthdateFormatted !== dataApi.dataNascimento 
        && 'Data de nastimento incorreta com o registrado';

    // Os validators foram comentados pois a api só retorna dados estáticos e esses dados são inválidos
   return {
        name: nameApiValidate || '',

        // document: documentCPFValidator(document).message || cpfApiValidate || '',
        document: cpfApiValidate || '',

        // birthdate: dateBirthValidator(birthdate).message || birthdateApiValidate || '',
        birthdate: birthdateApiValidate || '',
    };

};

export const isDateValid = (dateString) => {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!regex.test(dateString)) {
        return { error: true };
    }
  
    const [day, month, year] = dateString.split('/').map(Number);
  
    if (month < 1 || month > 12) {
        return { error: true };
    }
  
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
        return { error: true };
    }
  
    if (year < 0) {
        return { error: true };
    }
  
    return { error: false };
  };
  