import styled from "styled-components";
import Popup from "reactjs-popup";

export const Container = styled(Popup)`
  &-content {
    width: ${(props) => (props.width ? `${props.width}%` : "315px")};
    /* width: 315px; */
    padding: 0;
    background-color: #fff;
    border: 1px solid #e71037;
    /* top: -110px; */

    .content-container {
      padding: 25px;

      p {
        font-size: 2rem;
      }
    }

    hr {
      opacity: 0.5;
    }

    .button-container {
      display: flex;
      justify-content: space-between;

      padding: 25px;

      button {
        height: 35px;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .button-cancel {
        width: 100%;
        font-size: 1.2em;
        background: #e71037;
        border: 2px solid #e71037;
        border-radius: 5px;
        color: #fff;

        padding: 7px 0px;
      }
    }
  }
`;
