import React, { useState, useEffect } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import themes from "styles";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useHistory } from "react-router-dom";

const data = [
  {
    title: "Compra pela carteira digital",
    png: themes.components.png.creditSimulator,
    url: "/payment-simulator",
  },
  // {
  //   title: "Empréstimo com cartão de crédito",
  //   png: themes.components.png.loan,
  //   url: "/credit-card-loan-simulator",
  // },
  {
    title: "Empréstimo com Cartão TeuCard",
    png: themes.components.png.withDraw,
    url: "/with-draw-by-teu-card",
  },
  {
    title: "Buscador de empréstimos",
    png: themes.components.png.searchLoans,
    url: "/loan-seeker",
  },
];

const ChooseSimulator = () => {
  const history = useHistory();

  return (
    <St.SafeView>
      <Layout
        routeBack="/chooseSimulator"
        title="Escolha a simulação que deseja fazer"
      >
        <St.Container>
          {data.map((el, index) => {
            return (
              <St.Item onClick={() => history.push(el.url)}>
                <St.ContainerItem>
                  <St.ImageItem src={el.png} />
                  <St.TextItem>{el.title}</St.TextItem>
                </St.ContainerItem>
              </St.Item>
            );
          })}
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default ChooseSimulator;
