import Button from "components/Button";
import Layout from "components/OldLayout";
import { SignatureContext } from "context/SignatureContext";
import React, { useCallback, useContext, useState } from "react";
import Repository from "repository";
import { useHistory, useLocation } from "react-router";
import constants from "utils/constants";
import * as S from "./styles";
import api from "services/api";
import BenefitTable from "components/BenefitTable";
import OneButtonPopup from "components/modals/OneButtonPopup";
import useApi from "hooks/useApi";

const ProposalCompleted = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { setImageURL } = useContext(SignatureContext);

  const data = constants.endpoints.getProposalPdfById.replace(
    "PARAM_ID_PROPOSAL",
    state.propostaId
  );

  const proposal = () => {
    api.get(window.open(data, "PRINT", "_target", "height=500,width=600"));
  };

  const newProposal = useCallback(() => {
    Repository.storage.remove(Repository.keys.ID_PROPOSTA);
    history.push("/cadastro-minimo");
    setImageURL(null);
  }, [history, setImageURL]);
  console.log("state", state);
  return (
    <S.Wrapper>
      <Layout goBack title="Proposta do cartão">
        <S.Infos>
          <S.ProposalPDF>
            <iframe
              src={data}
              style={{ border: "none" }}
              title="proposta"
            ></iframe>
          </S.ProposalPDF>
        </S.Infos>
        <div>
          {state?.selectedContracts ? (
            <BenefitTable
              isProposalCompleted={true}
              idProposal={state.propostaId}
              contractedBenefits={state.selectedContracts
                ?.filter((e) => e.tipoContrato !== "TERMO_TEUCARD")
                .map((e) => ({
                  nome: e.nomeContrato,
                  descricao: e.descricaoContrato,
                  linkContrato: e.linkContrato,
                  valorMensalidade: e.valorMensalidadeContrato,
                  categoria: e?.tema === "PLATINUM" ? "Platinum" : e?.tema,
                  link: e.linkSiteContrato,
                  id: e.idBeneficio,
                }))}
              notContractedBenefit={state.data?.notContracted || ""}
              categoryCard={
                state?.selectedContracts?.find(
                  (e) => e.tipoContrato === "TERMO_TEUCARD"
                )?.tema === "PLATINUM"
                  ? "Platinum"
                  : state?.selectedContracts?.find(
                      (e) => e.tipoContrato === "TERMO_TEUCARD"
                    )?.tema
              }
            />
          ) : (
            <BenefitTable
              isProposalCompleted={true}
              idProposal={state.propostaId}
              contractedBenefits={state.benefits || ""}
              notContractedBenefit={state.data?.notContracted || ""}
              categoryCard={state?.cardInfo.categoria}
            />
          )}
        </div>

        <S.TextEmailSent>
          Enviamos no teu e-mail da proposta os documentos assinados. <br />{" "}
          Clique no botão "Enviar no E-mail" para receber em outro!
        </S.TextEmailSent>

        <S.Buttons>
          <Button
            buttonType="primary"
            text="Imprimir"
            onClick={() => proposal()}
            className="button"
          />
          <Button
            buttonType="secondary"
            text="Enviar no e-mail"
            onClick={() => {
              history.push("/envio-email", {
                propostaId: state.propostaId,
                email: state.email,
                benefits: state.benefits,
              });
            }}
            className="button"
          />
          <Button
            buttonType="primary"
            text="Cadastrar Nova Proposta"
            onClick={() => newProposal()}
            className="button"
          />
        </S.Buttons>
      </Layout>
    </S.Wrapper>
  );
};

export default ProposalCompleted;
