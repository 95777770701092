import React, { useState } from 'react';
import { MdClose, MdCrop, MdCropFree, MdPhotoCamera } from 'react-icons/md'

import PictureModal from 'components/PictureModal'
import * as St from './styles';

function PictureCard({
    source,
    onDelete,
    width,height, 
    expand,
    crop,
    id,
    isLoading,
    onClickCamera,
    showOptions = false
}) {
    const [isExpanded,setIsExpanded] = useState(false)
    
    const handleToggleExpansion = () => setIsExpanded(prev => !prev)

    const handleToggleCrop = () => setIsExpanded(prev => !prev)

    return (
        <St.Container 
            width={width} 
            height={height} 
            id={id}
            showOptions={showOptions}
        >
            {isLoading ? (
                <St.WrapperSpinner>
                    <St.SpinnerCustomized />
                </St.WrapperSpinner>
            ) : (
                <>
                    <div id="options">
                        {onDelete && (
                            <span id="icon" onClick={onDelete}>
                                <MdClose size={20}/>
                            </span>
                        )}

                        {expand && (
                            <span id="icon" onClick={handleToggleExpansion}>
                                <MdCropFree size={20}/>
                            </span>
                        )}

                        {crop && (
                            <span id="icon" onClick={handleToggleCrop}>
                                <MdCrop size={20}/>
                            </span>
                        )}

                        {onClickCamera && (
                            <span id="icon" onClick={onClickCamera}>
                                <MdPhotoCamera size={20}/>
                            </span>
                        )}
                    </div>

                    <img src={source} alt="card"/>

                    {isExpanded && (
                        <PictureModal handleClose={handleToggleExpansion}>
                            <St.ModalImage src={source} />
                        </PictureModal>
                    )}
                </>
            )}
        </St.Container>
    );
}

export default PictureCard;