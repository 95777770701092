/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";

import Layout from "components/OldLayout";
import OneButtonPopup from "components/modals/OneButtonPopup";

import useWebSocket from "react-use-websocket";
import { useHistory, useLocation } from "react-router-dom";
import { useLoan } from "hooks/useLoan";

import * as St from "./styles";
import themes from "styles";

const SearchProposalLoanSeeker = () => {
  const { userInitialData, finishResponse, dispatchFinishResponse } = useLoan();
  const [status, setStatus] = useState("");
  const [time, setTime] = useState(59);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState("");
  const [idEmprestimo, setIdEmprestimo] = useState(
    finishResponse?.idEmprestimo
  );

  const history = useHistory();

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      resolve(
        `${process.env.REACT_APP_WEBSOCKET_PAYMENT}/v1/websockets/emprestimos/buscador/${idEmprestimo}`
      );
    });
  }, [idEmprestimo]);

  const { lastMessage } = useWebSocket(getSocketUrl, {
    onOpen: () => console.log("opened"),
    onClose: () => console.log("closed"),
  });

  useEffect(() => {
    (async () => {
      if (lastMessage && lastMessage.data !== "_ready_") {
        console.log(lastMessage);
        const jsonString = lastMessage?.data;
        const parsedObject = await JSON.parse(jsonString);
        dispatchFinishResponse(parsedObject);
        setStatus(parsedObject?.status);
      }
    })();
  }, [lastMessage]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }

      if (time < 2) {
        setOpenDialog(true);
        setMessage(
          "Aguarde mais alguns minutos e consulte novamente! Estamos buscando as melhores ofertas para você."
        );
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  useEffect(() => {
    if (status === "PROPOSAL_CREATED") {
      history.push("/proposal-result-loan-seeker");
    }
  }, [status]);

  return (
    <St.SafeView>
      <Layout
        routeBack="/simulation-loan-seeker"
        title="Buscando as melhores ofertas de crédito"
      />
      <OneButtonPopup
        text={message}
        title="Buscando propostas"
        visibility={openDialog}
        buttonText="Voltar a página inicial"
        iconModal={themes.components.png.emojiSuccess}
        defaultButton
        setVisibility={setOpenDialog}
        onClose={() => history.push("/opcoes-acesso")}
      />
      <St.Container>
        <St.TopSpace>
          <St.Title>
            Olá,{userInitialData.data?.nome || userInitialData?.nome} Agora
            estamos analisando o teu perfil e buscando as propostas disponíveis.
          </St.Title>
        </St.TopSpace>
        <St.MiddleContainer>
          <St.Spinner>
            <h2 className="text">{`${
              time < 10 ? `00:0${time}` : `00:${time}`
            }`}</h2>
            <div class="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </St.Spinner>
        </St.MiddleContainer>
        <St.MiddleContainer>
          <St.Subtitle>
            Atenção! <b>NÃO</b> solicitamos depósito antecipado para a liberação
            de empréstimos e outros produtos.
          </St.Subtitle>
        </St.MiddleContainer>
      </St.Container>
    </St.SafeView>
  );
};

export default SearchProposalLoanSeeker;
