import styled from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.div`
  margin: 30px;
`;

export const Infos = styled.div`
  margin-top: 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const Text = styled.p`
  align-self: flex-start;
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold },
    },
  }) => fontWeightSemiBold};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  margin-bottom: ${({
    theme: {
      spacings: { small },
    },
  }) => small};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
  `}
`;

export const CarouselContainer = styled.div`
width: 100%;
display: flex;
flex-flow: column;
align-items: center;
margin-top: 30px;
`;

export const CardSelected = styled.div`
  width: 100%;
  height: max(100%, 631px);
  border: 2px dashed #e0e0e0;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
  }
`;

export const GrayText = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #c4c4c4;
  margin: 220px 0;
  text-align: center;
`;

export const CardImage = styled.img`
  margin: 0 auto;
  /* max-height: ${(props) => (props.selected ? "126px" : "116px")}; */
  width: 150px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  transform: ${(props) =>
    props.selected ? "scale(1.1) translateY(-5px)" : "inherit"};
  transition: transform 0.5s;

  filter: ${(props) =>
    props.selected ? "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25))" : "none"};
`;

export const Footer = styled.div`
  margin-top: 25px;
  position: relative;
  bottom: 0;
  width: 100%;

  @media (max-width: 712px) {
    padding: 20px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`;
