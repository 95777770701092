import styled from 'styled-components'
import media from 'styled-media-query'

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Container = styled.div`
display: flex;
height: 100%;
margin-top: 100px;
align-items: center;
justify-content: center;
flex-direction: column;
`

export const ImageItem = styled.img`
height: 20vh;
object-fit: contain;
`;

export const MiddleContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  font-size: 1.5rem;
  margin-top: 5rem;
  margin-bottom: 7rem;
`;

export const Rounded = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #f57e38;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const Footer = styled.div`
margin-top: 25px;
  bottom: 0;
  width: 100%;

  @media (max-width: 712px) {
    padding: 20px;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  }
`;
