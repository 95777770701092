import React from "react";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import ButtonBack from "components/ButtonBack";
import Layout from "components/OldLayout";
import Logo from "components/Logo";

import * as S from "./styles";
import Repository from "repository";

const AcessPoints = () => {
  const history = useHistory();

  const operador = Repository.storage.get(Repository.keys.USER);

  return (
    <S.Container>
      <Layout>
        <S.Content>
          <Logo />
          <div style={{ width: "100%" }}>
            <S.Title>Bem vindo ao Cadastro da Proposta do TeuCard</S.Title>
            <S.Subtitle>Escolha uma das opções abaixo, por favor.</S.Subtitle>
          </div>
          <Button
            buttonType="primary"
            text="Novo Cadastro da Proposta"
            onClick={() => history.push("/cadastro-minimo")}
          />
          {/* <Button
            buttonType="primary"
            text="Acompanhamento da Proposta"
            onClick={() => history.push("/acompanhamento-proposta")}
          /> */}
          <Button
            buttonType="primary"
            text="Consulta Status da Proposta"
            onClick={() => history.push("/consulta-status-proposta")}
          />
          <Button
            buttonType="primary"
            text="Atendimento"
            onClick={() => history.push("/attendance")}
          />
          <Button
            buttonType="primary"
            text="Meu desempenho"
            onClick={() => history.push("/desempenho")}
          />
          <Button
            buttonType="primary"
            text="Central de Notificação e Eventos"
            onClick={() => history.push("/consult-leads", {
              operador,
            })}
          />
          <Button
            buttonType="primary"
            text="Simulações"
            onClick={() => history.push("/choose-simulator")}
          />
        </S.Content>
      </Layout>
    </S.Container>
  );
};

export default AcessPoints;
