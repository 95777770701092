import React from 'react';
import Chart from 'react-apexcharts';

import * as S from './styles';


const GraficHistoric = ({
  title,
  categories,
  series,
  widthGraphic = 760,
  heightGraphic = 200,
  graphicType,
  type,
}) => {
  const buildSeries = () => {
    let response = null;
    if (series === undefined || series.length <= 0) {
      response = [
        {
          name: '',
          data: [0],
        },
      ];
    } else {
      response = series;
    }
    return response;
  };

  const options = {
    series: series || [0],
    chart: {
      height: 300,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    colors: ['#E2EFDA', '#FCE4D6', '#FFF2CC', '#D9E1F2'],
    labels: [0],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontWeight: 400,
      labels: {
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        radius: 12,
      },
    },
    stroke: {
      show: true,
      curve: 'smooth',
      lineCap: 'butt',
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    xaxis: {
      categories: categories || ['0'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: true,
      },
      tooltip: {
        enabled: true,
      },
      position: 'bottom',
    },
    yaxis: {
      series,
      min: 1,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
      },
    },
    theme: {
      monochrome: {
        enabled: false,
        color: '#E697FF',
        shadeTo: 'light',
        shadeIntensity: 0.65,
      },
    },
    responsive: [
      {
        breakpoint: 900,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  }

  return (
    <>
      <S.BoxDetails>
        <header>
          <h2>
            {`Histórico de
            ${title}
            ${graphicType ? ` por ${graphicType}` : ''}`}
          </h2>
        </header>
        <S.ItemGraphic>
          <Chart
            categories={categories}
            options={options}
            series={buildSeries()}
            type="line"
            height={heightGraphic}
            width={widthGraphic}
          />
        </S.ItemGraphic>
      </S.BoxDetails>
    </>
  );
};

export default GraficHistoric;
