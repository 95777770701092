const defaultReturn = (masked, unmasked) => ({
  masked: masked,
  unmasked: unmasked,
});

export const hiddeCPFNumber = (value) => {
  const valueSplited = value.split('');

  return valueSplited.map((el, index) => {
    if (index > 3 && index < 11) return '*';
    return el;
  });
};

export const verificationCodeMask = (value) => {
  return value.replace(/\D/g, '').replace(/^(\d{3})(\d{1,3})/g, '$1 $2');
};

export const verificationCodeUnmask = (value) => value.replace(' ', '');


export const cpfUnmask = (value) => value.replace(/\D/gim, "");

export const moneyInputUnmask = (value) => value.replace(/\D/gim, "");

export const phoneUnmask = (value) =>
  value.replace(/\D/gim, "").replace(/\D/g, "");

export const dateUnmask = (value) => value.replace(/\D/gim, "");

export const cepUnmask = (value = "") => value.replace(/\D/gim, "");

export const creditCardUnmask = (value) => value.replace(/\D/g, "");

export const codeVerificationUnmask = (value) => value.replace(/\D/g, "");

export const cpfMask = (value = "") => {
  const maskedValue = value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");

  return defaultReturn(maskedValue, cpfUnmask(value));
};
export const rgMask = (value = "") => {
  const maskedValue = value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{1})\d+?$/, "$1");

  return defaultReturn(maskedValue, cpfUnmask(value));
};

export const cepMask = (value = "") => {
  const maskedValue = value
    .replace(/\D/g, "")
    .replace(/^([\d]{2})\.*([\d]{3})-*([\d]{3})/, "$1$2-$3");

  return defaultReturn(maskedValue, cepUnmask(value));
};
export const accountBankMask = (value) => {
  const maskedValue = value
    .replace(/[^a-zA-Z0-9]/g, "")
    .replace(/^(.*)(.{1})$/, "$1-$2");

  return defaultReturn(maskedValue, value.replace(/[^a-zA-Z0-9]/g, ""));
};

export const phoneMask = (value = "") => {
  const maskedValue = value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");

  return defaultReturn(maskedValue, phoneUnmask(value));
};

export const agencyBankMask = (value) => {
  const maskedValue = value
    .replace(/\D/g, "")
    .replace(/^(\d{4})(\d{1})$/, "$1-$2");

  return defaultReturn(maskedValue, value.replace(/\D/g, ""));
};

export const dateMask = (value = "") => {
  const newValue = value.replace(/\D/g, "").replace(/^(\d{2})(\d)/g, "$1/$2");

  if (newValue.length > 4)
    return defaultReturn(
      newValue.replace(/(\d{2})(\d{1,2})/, "$1/$2"),
      dateUnmask(value)
    );
  else return defaultReturn(newValue, dateUnmask(value));
};

export const creditCardMask = (value = "") => {
  const newValue = value.replace(/\D/g, "");
  const sizeValue = value.length;

  if (sizeValue <= 9)
    return defaultReturn(
      newValue.replace(/(\d{4})(\d{1,4})/g, "$1 $2"),
      creditCardUnmask(value)
    );
  else if (sizeValue <= 14)
    return defaultReturn(
      newValue.replace(/(\d{4})(\d{4})(\d{1,4})/g, "$1 $2 $3"),
      creditCardUnmask(value)
    );
  else
    return defaultReturn(
      newValue.replace(/(\d{4})(\d{4})(\d{4})(\d{1,4})/g, "$1 $2 $3 $4"),
      creditCardUnmask(value)
    );
};

export const codeVerificationMask = (value = "") => {
  const maskedValue = value
    .replace(/\D/g, "")
    .replace(/^(\d{3})(\d{3})/g, "$1 $2")
    .replace(/( \d{3})\d+?$/, "$1");

  return defaultReturn(maskedValue, codeVerificationUnmask(value));
};

export const numericMask = (value = "") => value.replace(/\D/g, "");

export const moneyMask = (value = 0.0) =>
  new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
  }).format(value);

export const formatNumber = (
  amount,
  decimalCount = 2,
  decimal = ",",
  thousands = "."
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};

export const emailMask = (value) =>
  value
    .replace(/[^a-zA-Z0-9-_.@]/g, "")
    .trim()
    .toLowerCase();

export const hideEmailMask = (value = "") => {
  const [prefix] = value.split("@");

  const prefixToHide = prefix.slice(2);

  return value.replace(prefixToHide, "*".repeat(prefixToHide.length));
};

export const nameMask = (value = "") => {
  return value
    .toUpperCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^\w\s]/gi, "")
    .replace(/[0-9]/g, "");
};

export const moneyBrazilian = (value) => {
  const maskedValue = value
    ?.replace(/\D/g, "")
    ?.replace(/^0+/, "")
    ?.replace(/(\d{1,})(\d{2})$/, "$1,$2")
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  return defaultReturn("R$ " + maskedValue, value?.replace(/\D/g, ""));
};

export const convertDateByBrazillianFormat = (date) => {
  var data = new Date(date);

  var dia = data.getDate();
  var mes = data.getMonth() + 1;
  var ano = data.getFullYear();

  return ("0" + (dia + 1)).slice(-2) + "/" + ("0" + mes).slice(-2) + "/" + ano;
};

export const maskDateInput = (value) => {
  const maskedValue = value
    ?.replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1");

  return defaultReturn(maskedValue, value?.replace(/\D/g, ""));
};
