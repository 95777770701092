import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { ArrowBack } from "@styled-icons/boxicons-regular/ArrowBack";
import media from "styled-media-query";
import Popup from "reactjs-popup";

export const Wrapper = styled.div`
  margin: 30px;
  display: flex;
  justify-content: center;

  .dateSignature {
    margin-bottom: 44px;
  }

  .primary {
    margin-bottom: 0;
  }
`;

export const Header = styled.div`
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  align-items: center;
  margin-bottom: ${({
    theme: {
      spacings: { medium },
    },
  }) => medium};
`;

export const IconArrow = styled(ArrowBack)`
  width: 20px;
  height: 23px;
  position: absolute;

  path {
    fill: ${({
      theme: {
        colors: { gray },
      },
    }) => gray};
  }
`;

export const CustomPopup = styled(Popup)`
  &-content {
    border-radius: 10px;
    .container {
      padding: 1.5rem;
      text-align: center;

      p {
        font-size: 2rem;
        margin-bottom: 1rem;
      }
      span {
        margin: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
      }

      .counterContainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const TextBold = styled.p`
  text-align: left;
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold },
    },
  }) => fontWeightSemiBold};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  margin-bottom: ${({
    theme: {
      spacings: { xxlarge },
    },
  }) => xxlarge};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
  `}
`;

export const Text = styled.p`
  text-align: left;
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular },
    },
  }) => fontWeightRegular};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  margin-bottom: 5px;
`;
export const BoxSignature = styled.button`
  height: 100px;
  width: 100%;
  cursor: pointer;
  border-radius: 2rem;
  background-color: rgba(231, 16, 55, 0.4);
  padding: 12px;
  margin-bottom: ${({
    theme: {
      spacings: { medium },
    },
  }) => medium};
`;
export const Link = styled(RouterLink)``;
export const LineSignature = styled.div`
  height: 55px;
  width: 80%;
  margin: auto;
  border-bottom: 1px dashed #e71037;
`;
export const ImgURL = styled.img`
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 70px;
`;

export const ImgShape = styled.img`
  margin-top: 10px;
`;

export const Signature = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  border-radius: 2rem;
  background-color: rgba(231, 16, 55, 0.4);
  padding: 20px;
`;
export const Modal = styled.div`
  width: 300px;
  height: 250px;
  border-radius: ${({
    theme: {
      borderRadius: { borderRadiusSM },
    },
  }) => borderRadiusSM};
  background-color: ${({
    theme: {
      colors: { white },
    },
  }) => white};
  z-index: -1;

  ${media.greaterThan("medium")`
    width:400px;
    height: 300px;
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
  `}
`;
export const ModalInfoDivision = styled.div`
  padding: ${({
    theme: {
      spacings: { medium },
    },
  }) => medium};
  height: 220px;
`;

export const ModalDivision = styled.div`
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;

  .button {
    margin: 15px;
    width: 50%;
  }

  .buttonTwo {
    width: 100%;
  }
`;

export const Img = styled.img`
  margin-bottom: ${({
    theme: {
      spacings: { small },
    },
  }) => small};
`;
export const TextModal = styled.p`
  text-align: left;
  height: 80px;
  font-size: ${({
    theme: {
      sizes: { medium },
    },
  }) => medium};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular },
    },
  }) => fontWeightRegular};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  margin-bottom: ${({
    theme: {
      spacings: { small },
    },
  }) => small};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { large },
      },
    }) => large};
  `}
`;

export const ConfirmProfile = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const Picture = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 190px;
  height: 190px;
  padding: 10px 0;

  @media screen and (max-width: 500px) {
    justify-content: center;
  }

  #picture-modal {
    margin-right: 20px;
  }

  button {
    display: flex;
    align-items: center;
    width: auto !important;
    height: 40px !important;
    margin: 0 20px 0 0 !important;
    svg {
      margin-right: 10px;
    }
  }
`;

export const divClient = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 10px 15px 10px 0; */
  width: 100%;
  /* margin-left: 5%; */
`;

export const SignatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  .bigger {
    grid-column: 1 / 3;
  }
`;
export const LoadingSpinner = styled.div`
  /* border: 8px solid rgba(255, 255, 255, 0.1); */
  border: 1rem dashed #e71037;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 5s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const Loader2 = styled.div`
  .lds-default {
    display: inline-block;
    position: relative;
    width: 150px;
    height: 150px;
  }
  .lds-default div {
    position: absolute;
    width: 12px;
    height: 12px;
    background: #e71037;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 74px;
    left: 132px;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 44px;
    left: 124px;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 22px;
    left: 104px;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 14px;
    left: 74px;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 22px;
    left: 44px;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 44px;
    left: 22px;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 74px;
    left: 14px;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 104px;
    left: 22px;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 124px;
    left: 44px;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 132px;
    left: 74px;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 124px;
    left: 104px;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 104px;
    left: 124px;
  }
  @keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
`;
