import React, { useEffect } from "react";
import { hot } from "react-hot-loader";
import Router from "./router";
import axios from 'axios';
import AppProvider from "context";
function App() {

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get('https://api.ipify.org/?format=json');
      localStorage.setItem("@NovaEra:ipAdress", res.data.ip);
    };
    getData();
  }, []);

  return (
    <AppProvider>
      <Router />
    </AppProvider>
  );
}

export default hot(module)(App);
