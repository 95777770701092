import { createGlobalStyle, css } from 'styled-components'

const GlobalStyles = createGlobalStyle`
 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  ${({ theme }) => css`
    html {
      font-size: 1.6rem;
    }
    body, html, #root {
      height: 100%;
      font-family: ${theme.font.family};
      font-size: 10px
      };
   `}

  #root {
    /* min-height: 100vh;
    max-height: 700px; */
    max-width: 960px;
    margin: auto;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  button {
    border: 0;
    outline: none;
    cursor: pointer;
    background-color: transparent;
  }

  ul {
    list-style-type: none;
    display: inline-flex;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    outline: none;
  }

  input, textarea {
    border: none;
    background-color: transparent;
    font-family: ${({ theme: { font: {  family }}}) =>  family };
  }

  .MuiPaper-root {
    padding:20px;
    font-size: 16px;
    width: auto;
    margin-top: -92px;
   
    @media(min-width:425px) {
      margin-left: 50px;
    }
    @media(min-width: 768px) {
      margin-top: -80px;
      margin-left: 280px;
    }
    @media(min-width: 1024px) {
      margin-top: -80px;
      margin-left: 415px;
    }

    ul li {
      font-size: 12px;
      font-family: Open Sans,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    }

    .MuiListItem-root.Mui-selected, 
    .MuiListItem-root.Mui-selected:hover {
      background-color: #E71037;
      border-radius:  2px;
      color: #FFF;
    }
  }
`



export default GlobalStyles
