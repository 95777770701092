import styled from 'styled-components'
import media from 'styled-media-query'


export const Container = styled.div`
  grid-area: ${({ name }) => name};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : '15px'};
  position: relative;

  /* Cor do bg do autocomplete */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }

 // Cor do texto do autocomplete
  input:-webkit-autofill {
   -webkit-text-fill-color: $darkGray !important;
  }

  label {
    font-size: 12px;
    color: ${(props) =>
      props.isFocus || props.error ? '#E71037' : '#757575' };

    ${media.greaterThan("large")`
      font-size: 14px;
    `}

  }

  input {
    border: 0;
    font-size: 16px;

    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${(props) =>
     props.isFocus || props.error ? '#E71037' : '#AFAFAF' };

    color: #4A4B4A;
    height: 30px;


    &:focus {
      outline: none;
     }

     &::placeholder {
      color: #AFAFAF
     }
  }

  .icon {
    position: absolute;
    right: 0;
    height: 25px;
    width: 25px;
    top: 20px;
    color: gray;
    cursor: pointer;
  }
`;

export const ErrorText = styled.span`
  font-size: 12px;
  color:#ED4848;
  margin-top: 2px;
`;

export const IconRight = styled.div`
  position:absolute;
  right: 0;
  bottom: 1px;
`


















// import styled from 'styled-components'
// import media from 'styled-media-query'

// export const Container = styled.div`
//   grid-area: ${({ name }) => name};
//   height: 58px;
// `
// export const Wrapper = styled.div`
// 	display: flex;
//   flex-direction: column;
//   align-items: stretch;
//   margin-bottom: 25px;

//   // Cor do bg do autocomplete
//   input:-webkit-autofill {
//   -webkit-box-shadow: 0 0 0 30px white inset;
//   }

//   // Cor do texto do autocomplete
//   input:-webkit-autofill {
//   -webkit-text-fill-color: $darkGray !important;
//   }

//   input {
// 		border-bottom: 1px solid ${({ theme: { colors: { grayLight }}}) => grayLight };
// 		width: 100%;
//     padding: 10px 0 3px;
// 		font-size: ${({theme: {sizes: { small }}}) => small };
//     color:  ${({ theme: { colors: { blackLight }}}) => blackLight };
//     &::placeholder {
//       color:${({ theme: { colors: { grayLight }}}) => grayLight };
//     }

//     &:focus {
//       outline: none;
//       border-bottom: 1px solid ${({ theme: { colors: { grayLight }}}) => grayLight };
//       color:  ${({ theme: { colors: { blackLight }}}) => blackLight };
//     }

//     &:focus-within ~ label {
//       transform: translateY(-0.9rem) scale(0.95);
//     }

//     ${media.greaterThan("medium")`
//      font-size: ${({theme: {sizes: { medium }}}) => medium };
//     `}
//   }
// `

// export const Label = styled.label`
//   font-size: ${({theme: {sizes: { xsmall }}}) => xsmall };
//   color:  ${({ theme: { colors: { grayDark }}}) => grayDark };
//   line-height:  ${({ theme: { spacings: { small }}}) => small };
//   margin-bottom: 0;
//   padding: 0;
//   position: absolute;
//   transform-origin: 0%;
//   transform: translateY(-0.9rem) scale(0.95);

//   ${media.greaterThan("medium")`
//     font-size: ${({theme: {sizes: { small }}}) => small };
//   `}
// `

// export const Text =  styled.span`
//   font-size: ${({theme: {sizes: { small }}}) => small };
//   color: ${({ theme: { colors: {  grayDark }}}) =>  grayDark };
//   font-weight: ${({theme: { fontWeight: { fontWeightRegular }}}) => fontWeightRegular };
//   margin-right: 4px;

//   ${media.greaterThan("medium")`
//    font-size: ${({theme: {sizes: { medium }}}) => medium };
//   `}
// `

// export const TextBold = styled.strong`
//   font-size: ${({theme: {sizes: { small }}}) => small };
//   color: ${({ theme: { colors: {  primary }}}) =>  primary };
//   font-weight: ${({theme: { fontWeight: { fontWeightSemiBold }}}) => fontWeightSemiBold };
//   margin-right: 4px;
//   border-bottom: 1px solid ${({ theme: { colors: {  primary }}}) =>  primary };

//   ${media.greaterThan("medium")`
//   font-size: ${({theme: {sizes: { medium }}}) => medium };
// `}
// `

// export const Error = styled.span`
//   font-size: 12px;
//   color: ${({ theme: { colors: {  red }}}) =>  red };
//   margin-top: -1px;
//   border-top: 1px solid #ED4848;

// `;

