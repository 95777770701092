import React from "react";
import { useState } from "react";
import * as St from "./styles";
import SliderBar from "components/CustomSliderBar";
import { moneyMask } from "utils/mask";
import Layout from "components/OldLayout";
import useApi from "hooks/useApi";
import Button from "components/Button";
import CurrencyInput from "components/CurrencyInput";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useLoan } from "hooks/useLoan";
import { get } from "repository/storage";
import OneButtonPopup from "components/modals/OneButtonPopup";

const CreditCardLoanSimulatorValueCalculation = () => {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [title, setTitle] = useState(
    "Conta para a gente o valor da  que deseja simular"
  );
  const [error, setError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const { dispatchSimulationData, simulationType, userInitialData } = useLoan();

  useEffect(() => {
    if (simulationType.type) {
      switch (simulationType.type) {
        case "limit":
          setValue(100);
          setMaxValue(50000);
          setTitle(`Valor do Limite Disponível`);
          break;
        case "parcel":
          setValue(10);
          setMaxValue(5000);
          setTitle(" Valor da Parcela ");

          break;
        case "withdraw":
          setValue(100);
          setMaxValue(50000);
          setTitle(" Valor do Saque ");

          break;
        default:
          break;
      }
    }
  }, [simulationType]);

  const RequestSimulateRules = useApi(simulationType?.endpoint, "POST");

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const handleValueInputChange = (value) => {
    if (value.toString().length > 5) {
      setValue(maxValue.toString());
    } else {
      setValue(value.toString());
    }
  };
  const operator = get("@NovaEra:user");

  const simulateLoan = async () => {
    try {
      const body = {
        simulacao: {
          installments: 12,
          interest_rate: 0,
          value: value,
        },
        operador: {
          cpf: operator.cpf,
          id: operator?.id,
          nome: operator?.nomeTitular,
        },
        cliente: {
          celular: userInitialData?.celular?.unmasked,
          cpf: userInitialData?.cpf?.unmasked,
          email: userInitialData?.email,
          nome: userInitialData?.nome,
        },
      };
      const response = await RequestSimulateRules.callApi(body);
      if (response.status >= 200 && response.status <= 300) {
        dispatchSimulationData(response?.data);
        history.push("/credit-card-loan-simulator-value-result");
      } else {
        setDialogOpen(true);
        setError(response.mensagem);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <St.SafeView>
      <Layout
        routeBack="/credit-card-loan-simulator-choose-type"
        title={
          <p>
            Conta para a gente o <b>{title}</b> que deseja simular
          </p>
        }
      >
        <St.Container>
          <OneButtonPopup
            text={error}
            buttonText="Entendi"
            title={"Houve um erro!"}
            visibility={dialogOpen}
            defaultButton
            setVisibility={setDialogOpen}
          />
          <St.TopSpace>
            <St.ValueSimulate>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <St.SimulateEditTitle>Valor da Simulação</St.SimulateEditTitle>
                <St.Value>{moneyMask(value)}</St.Value>

                <St.Slider>
                  <SliderBar
                    value={value}
                    handleChange={handleValueChange}
                    min={10}
                    max={maxValue}
                    step={10}
                  />
                </St.Slider>
                <St.TextContainer></St.TextContainer>
                <St.SimulateEditTitle>Valor</St.SimulateEditTitle>
                <St.InputValue>
                  <CurrencyInput
                    max={maxValue}
                    width={"100%"}
                    valueDefault={moneyMask(value)}
                    valueChange={handleValueInputChange}
                  />
                </St.InputValue>

                <St.MiddleContainer>
                  <St.Rounded />
                  <p style={{ width: "100%" }}>
                    Lembre-se, essa simulação só funciona com valores dentro do
                    Teu limite de crédito disponível hoje.
                  </p>
                </St.MiddleContainer>
                <St.Footer>
                  <Button
                    buttonType="primary"
                    text={"Simular"}
                    loading={RequestSimulateRules.loading}
                    onClick={() => simulateLoan()}
                  />
                </St.Footer>
              </div>
            </St.ValueSimulate>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default CreditCardLoanSimulatorValueCalculation;
