/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";

import Heading from "components/Heading";
import Form from "components/Forms";
import Input from "components/Input";
import InputSelect from "components/InputSelect";
import InputMoney from "components/InputMoney";
import ButtonBack from "components/ButtonBack";
import Loading from "components/Loading";

import { groups, gridTemplateCadComplete } from "./constants";

import * as S from "./styles";
import { cepMask, cpfMask, phoneMask } from "utils/mask";
import { transformInNormalDate } from "utils/date";
import { useLocation } from "react-router-dom";
import constants from "utils/constants";
import useApi from "hooks/useApi";

const ProposalQuery = () => {
  const [email, setEmail] = useState("");
  const [nome, setNome] = useState();
  const [cpf, setCpf] = useState("");
  const [celular, setCelular] = useState("");
  const [cep, setCep] = useState();
  const [logradouro, setLogradouro] = useState();
  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState();
  const [cidade, setCidade] = useState();
  const [bairro, setBairro] = useState();
  const [genero, setGenero] = useState();
  const [dataNascimento, setDataNascimento] = useState();
  const [profissao, setProfissao] = useState();
  const [company, setCompany] = useState();
  const [status, setStatus] = useState();
  const [typist, setTypist] = useState();
  const [renda, setRenda] = useState();
  const [dateRegister, setDateRegister] = useState();
  const [loading, setLoading] = useState(false);
  const [error] = useState();

  const { state } = useLocation();
  const { history } = useReactRouter();

  const GetSpcProposalData = useApi(
    constants.endpoints.spcProposalData.replace("PARAM_ID_PROPOSAL", state?.id),
    "GET"
  );
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await GetSpcProposalData.callApi();

        setRenda(data.spc.rendaPresumida);
        setNome(data.proposta.nome);
        setCpf(cpfMask(data.proposta.cpf));
        setCelular(phoneMask(data.proposta.celular));
        setDataNascimento(transformInNormalDate(data.spc.dataNascimento));
        setEmail(data.proposta.cliente?.email);
        setCep(cepMask(data.proposta.endereco?.cep));
        setLogradouro(data.proposta.endereco?.logradouro);
        setNumero(data.proposta.endereco?.numero);
        setComplemento(data.proposta.endereco?.complemento);
        setCidade(data.proposta.endereco?.cidade);
        setBairro(data.proposta.endereco?.bairro);
        setProfissao(data.proposta?.profissao);
        setCompany(data.proposta.empresa);
        setStatus(data.status);
        setGenero(data.proposta.cliente?.genero);
        setTypist(data.proposta.atendente?.nomeOperador);
        setDateRegister(transformInNormalDate(data.proposta.dataInclusao));
      } catch (err) {
        console.error(err.response.data.mensagem);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      setRenda(null);
      setNome(null);
      setCpf(null);
      setCelular(null);
      setCep(null);
      setLogradouro(null);
      setNumero(null);
      setComplemento(null);
      setBairro(null);
      setCidade(null);
      setDataNascimento(null);
      setEmail(null);
      setProfissao(null);
      setCompany(null);
      setStatus(null);
      setTypist(null);
      setDateRegister(null);
    };
  }, []);

  return (
    <S.Wrapper>
      <Loading status={loading} />
      <S.Header>
        <ButtonBack onClick={() => history.goBack()} />
        <Heading variation="tertiary">Consulta Status da Proposta</Heading>
      </S.Header>
      <S.TextBold>Verifique seus dados pessoais abaixo, por favor.</S.TextBold>
      <Form gridTemplate={gridTemplateCadComplete}>
        <Input
          id="userFullName"
          name="userFullName"
          label="Nome completo"
          placeholder=""
          type="text"
          defaultValue={nome}
          value={nome}
          error={error}
          readOnly
        />
        <Input
          id="cpf"
          name="cpf"
          label="CPF"
          placeholder="000.000.000-00"
          type="text"
          value={cpf?.masked}
          max={14}
          readOnly
        />
        <Input
          id="mobilePhone"
          name="mobilePhone"
          label="Celular"
          type="text"
          value={celular?.masked}
          max={15}
          placeholder="(00) 00000-0000"
        />
        <Input
          name="birthDate"
          id="birthDate"
          label="Data de nascimento"
          placeholder="dd/mm/aaaa"
          value={dataNascimento?.masked}
          max={10}
          error={error}
          readOnly
        />

        <Input
          id="email"
          name="email"
          label="E-mail"
          type="text"
          value={email}
          placeholder="exemplo@exemplo.com.br"
          mask=""
          error={error}
          readOnly
        />
        <Input
          name="zipCode"
          id="zipCode"
          label="CEP"
          type="text"
          defaultValue={cep?.masked}
          value={cep?.masked}
          placeholder="00000-000"
          max={8}
          error={error}
          readOnly
        />
        <Input
          name="address"
          id="address"
          label="Endereço"
          type="text"
          defaultValue={logradouro}
          value={logradouro}
          error={error}
          readOnly
        />
        <Input
          name="number"
          id="number"
          label="Número"
          type="text"
          defaultValue={numero}
          value={numero}
          error={error}
          readOnly
        />
        <Input
          name="complement"
          id="complement"
          label="Complemento"
          type="text"
          defaultValue={complemento}
          value={complemento}
          error={error}
          readOnly
        />
        <Input
          name="city"
          id="city"
          label="Cidade"
          type="text"
          defaultValue={cidade}
          value={cidade}
          error={error}
          readOnly
        />
        <Input
          name="neighborhood"
          id="neighborhood"
          label="Bairro"
          type="text"
          defaultValue={bairro}
          value={bairro}
          error={error}
          readOnly
        />

        <InputSelect
          name="gender"
          id="gender"
          label="Sexo"
          type="select"
          value={genero}
          values={groups}
          error={error}
          disabled
        />

        <Input
          name="occupation"
          id="occupation"
          label="Profissão"
          type="select"
          value={profissao}
          error={error}
          disabled
        />

        <InputMoney
          id="income"
          label="Renda estimada"
          value={(renda || 0).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            style: "currency",
            currency: "BRL",
          })}
          readOnly
        />

        <Input
          name="company"
          id="company"
          label="Empresa"
          type="text"
          defaultValue={company}
          value={company}
          error={error}
          readOnly
        />

        <Input
          name="status"
          id="status"
          label="Status"
          type="select"
          value={status}
          error={error}
          disabled
        />

        <Input
          name="typist"
          id="typist"
          label="Operador(a) de Venda"
          type="text"
          defaultValue={typist}
          value={typist}
          error={error}
          readOnly
        />

        <Input
          name="dateRegister"
          id="dateRegister"
          label="Data do Cadastro/Proposta"
          type="text"
          value={dateRegister?.masked}
          readOnly
        />
      </Form>
    </S.Wrapper>
  );
};

export default ProposalQuery;
