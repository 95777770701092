import React, { createContext, useEffect, useReducer } from "react";
import Repository from "repository";
import AppReducer from "./AppReducer";

const initialState = {
  userInitialData: {},
  userAditionalData: {},
  userIdentificationData: {},
  complementaryData: {},
  solicitationData: {},
  simulationData: {},
  simulationType: {},
  bankData: {},
  finishResponse: {},
  dispatchUserAditionalData: () => {},
  dispatchUserIdentificationData: () => {},
  dispatchComplementaryData: () => {},
  dispatchSolicitationData: () => {},
  dispatchUserInitialData: () => {},
  dispatchSimulationData: () => {},
  dispatchSimulationType: () => {},
  dispatchBankData: () => {},
  dispatchFinishResponse: () => {},
};

export const LoanContext = createContext(initialState);

const LoanReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "USER_INITIAL_DATA":
      return { ...state, userInitialData: payload };
    case "USER_ADITIONAL_DATA":
      return { ...state, userAditionalData: payload };
    case "COMPLEMENTARY_DATA":
      return { ...state, complementaryData: payload };
    case "USER_IDENTIFICATION_DATA":
      return { ...state, userIdentificationData: payload };
    case "SOLICITATION_DATA":
      return { ...state, solicitationData: payload };
    case "SIMULATION_DATA":
      return { ...state, simulationData: payload };
    case "SIMULATION_TYPE":
      return { ...state, simulationType: payload };
    case "BANK_DATA":
      return { ...state, bankData: payload };
    case "FINISH_RESPONSE":
      return { ...state, finishResponse: payload };
    default:
      return state;
  }
};

export const LoanProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LoanReducer, initialState);
  const {
    userInitialData,
    userAditionalData,
    userIdentificationData,
    complementaryData,
    solicitationData,
    simulationData,
    simulationType,
    bankData,
    finishResponse,
  } = state;

  const dispatchUserInitialData = (payload) => {
    dispatch({
      type: "USER_INITIAL_DATA",
      payload,
    });
  };
  const dispatchUserAditionalData = (payload) => {
    dispatch({
      type: "USER_ADITIONAL_DATA",
      payload,
    });
  };
  const dispatchUserIdentificationData = (payload) => {
    dispatch({
      type: "USER_IDENTIFICATION_DATA",
      payload,
    });
  };
  const dispatchComplementaryData = (payload) => {
    dispatch({
      type: "COMPLEMENTARY_DATA",
      payload,
    });
  };
  const dispatchSolicitationData = (payload) => {
    dispatch({
      type: "SOLICITATION_DATA",
      payload,
    });
  };
  const dispatchSimulationData = (payload) => {
    dispatch({
      type: "SIMULATION_DATA",
      payload,
    });
  };

  const dispatchSimulationType = (payload) => {
    dispatch({
      type: "SIMULATION_TYPE",
      payload,
    });
  };

  const dispatchBankData = (payload) => {
    dispatch({
      type: "BANK_DATA",
      payload,
    });
  };
  const dispatchFinishResponse = (payload) => {
    dispatch({
      type: "FINISH_RESPONSE",
      payload,
    });
  };

  return (
    <LoanContext.Provider
      value={{
        userInitialData,
        complementaryData,
        userAditionalData,
        userIdentificationData,
        solicitationData,
        simulationData,
        simulationType,
        bankData,
        finishResponse,
        dispatchBankData,
        dispatchSimulationData,
        dispatchSimulationType,
        dispatchUserInitialData,
        dispatchUserAditionalData,
        dispatchUserIdentificationData,
        dispatchComplementaryData,
        dispatchSolicitationData,
        dispatchFinishResponse,
      }}
    >
      {children}
    </LoanContext.Provider>
  );
};
