/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Layout from "components/OldLayout";
import Slider from "../components/sliderLoanSeeker";
import InputOnboarding from "components/InputOnboarding";
import Installment from "../components/installments";
import Button from "components/Button";
import OneButtonPopup from "components/modals/OneButtonPopup";

import { moneyBrazilian, moneyMask } from "utils/mask";
import { useLoan } from "hooks/useLoan";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import moment from "moment";

import * as St from "./styles";
import themes from "styles";
const SimulationByLoanSeeker = () => {
  const [installments, setInstallments] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [operador, setOperador] = useState(null);
  const [values, setValues] = useState({
    min: 500,
    max: 50000,
    selected: 500,
  });
  const [installmentValue, setInstallmentValue] = useState("");

  const [input, setInput] = useState({
    formatted: "",
    unFormatted: "",
  });

  useEffect(() => {
    const getOperador = () => {
      const operador = JSON.parse(localStorage.getItem("@NovaEra:user"));
      setOperador(operador);
    };
    getOperador();
  }, []);

  const history = useHistory();

  const updateSliderField = (value) => {
    setValues({ ...values, selected: value.masked });
  };

  const postSearchProposals = useApi(
    constants.endpoints.postSearchProposalsEasyCredito,
    "POST"
  );

  useEffect(() => {
    setInstallmentValue(
      moneyBrazilian(calculateInstallment(input.unFormatted, installments))
    );
  }, [installments, input.unFormatted, values.selected]);

  const {
    userInitialData,
    complementaryData,
    simulationData,
    userAditionalData,
    residentialData,
    userIdentificationData,
    bankData,
    solicitationData,
    dispatchFinishResponse,
  } = useLoan();

  useEffect(() => {
    setInput({
      unFormatted: 500,
      formatted: moneyMask(500),
    });
    setInstallmentValue(
      moneyMask(calculateInstallment(input.unFormatted, installments))
    );
  }, []);

  useEffect(() => {
    const getInitialValues = () => {
      setInput({
        formatted: moneyMask(500),
        unFormatted: "500",
      });
      setValues({
        min: 500,
        max: 50000,
        selected: 500,
      });
    };
    getInitialValues();
  }, []);

  const updateFormField = (value) => {
    const valueFloat =
      value.unmasked.toString().slice(0, value.unmasked.length - 2) +
      "." +
      value.unmasked.toString().slice(value.unmasked.length - 2);
    setInput({
      formatted: value.masked,
      unFormatted: parseFloat(valueFloat).toFixed(2),
    });
  };

  const calculateInstallment = (selectedValue, numberInstallments) => {
    const interestRate = 4.49 / 100; // Taxa de juros de 4,49%

    const monthlyInterestFactor = Math.pow(
      1 + interestRate,
      numberInstallments
    );
    const monthlyPayment =
      (selectedValue * interestRate * monthlyInterestFactor) /
      (monthlyInterestFactor - 1);

    return monthlyPayment.toFixed(2);
  };

  const handleInstallments = (value) => {
    setInstallments(value);
    calculateInstallment(parseFloat(values.selected), value);
  };

  const handleSubmitLoanSeeker = async () => {
    setButtonIsLoading(true);
    const payload = {
      banco: {
        agencia: bankData.state?.agency,
        banco: bankData.state?.bank?.value,
        conta: bankData.state?.account,
        tipo: bankData.state?.accountType,
      },
      celular: userInitialData?.data?.celular.unmasked,
      cpf: userInitialData?.cpf?.unmasked,
      dataNascimento: userInitialData?.data?.dataNascimento.unmasked,
      educacao: complementaryData?.education,
      endereco: {
        bairro: userAditionalData?.data?.bairro,
        cep: userAditionalData?.data?.cep.unmasked,
        cidade: userAditionalData?.data?.cidade,
        complemento: userAditionalData?.data?.complemento,
        endereco: userAditionalData?.data?.endereco,
        estado: userAditionalData?.data?.uf,
        numero: userAditionalData?.data?.numero
          ? userAditionalData?.data?.numero
          : userInitialData.userInfo?.endereco?.numero,
        tempoResidencia: userAditionalData?.data?.residenceTime,
        tipoResidencia: userAditionalData?.data?.typeResidence,
      },
      estadoCivil: complementaryData?.maritalStatus,
      genero: complementaryData?.gender,
      idEasyCredito: simulationData?.idEasyCredito,
      identidade: {
        dataEmissao: moment(
          userIdentificationData?.data?.emissionDate?.masked,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        emissor: userIdentificationData?.data?.issuingAgency?.code,
        estado: userIdentificationData?.data?.ufDocument,
        numero: userIdentificationData?.data?.numberDocument,
        tipo: userIdentificationData?.data?.typeDocument,
      },
      nome: userInitialData?.data?.nome,
      nomeMae: complementaryData?.mother,
      operador: {
        cpf: operador?.cpf,
        id: operador?.id,
        nome: operador?.nomeTitular,
      },
      simulacao: {
        parcelas: installments,
        valor: parseFloat(Number(input.unFormatted).toFixed(2)),
      },
      trabalho: {
        diaPagamento: Number(solicitationData?.payDay),
        ocupacao: solicitationData?.occupation,
        profissao: solicitationData?.profession.value,
        salario: parseInt(
          String(solicitationData?.income?.masked)
            .replace("R$", "")
            .replace(".", "")
        ).toFixed(2),
      },
    };

    try {
      const response = await postSearchProposals.callApi(payload);
      if (response.status >= 200 && response.status < 300) {
        dispatchFinishResponse(response.data);
        setButtonIsLoading(false);
        history.push("/search-proposal-loan-seeker");
      } else {
        setButtonIsLoading(true);
        setDialogOpen(true);
        setErrorMessage(response.mensagem);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <St.SafeView>
      <Layout routeBack="/banking-data-loan-seeker" title="Valor da Simulação">
        <OneButtonPopup
          text={errorMessage}
          buttonText="Entendi"
          title="Que pena!"
          iconModal={themes.components.png.emojiSad}
          visibility={dialogOpen}
          defaultButton
          onClose={() => history.push("/loan-seeker")}
          setVisibility={setDialogOpen}
        />
        <St.Container>
          <St.TopSpace>
            <St.Title justify="center">
              Conta pra gente o Valor do Empréstimo que você deseja simular:
            </St.Title>
            <St.Slider>
              <Slider
                min={values.min}
                max={values.max}
                selected={String(values.selected).replace("R$", "")}
                value={input.unFormatted}
                handleChange={(value) => {
                  updateSliderField(
                    moneyBrazilian(Number(value.target.value).toFixed(2))
                  );
                  updateFormField(
                    moneyBrazilian(Number(value.target.value).toFixed(2))
                  );
                }}
                step={1}
              />
            </St.Slider>
            <St.InputValue>
              <InputOnboarding
                type="text"
                label="Valor"
                placeholder="digite o valor"
                largeLabel
                value={input.formatted}
                maxLength={15}
                defaultValue={0}
                style={{ height: "54px" }}
                step={1}
                onChange={(e) => {
                  updateSliderField(moneyBrazilian(e.target.value));
                  updateFormField(moneyBrazilian(e.target.value));
                }}
              />
              <St.ParcelBox>
                <div>
                  <InputOnboarding
                    type="text"
                    label="Valor da parcela"
                    largeLabel
                    style={{ height: "54px" }}
                    value={installmentValue.masked}
                    disabled
                  />
                </div>
                <div>
                  <Installment
                    installment={installments}
                    onChange={handleInstallments}
                    label="Parcelas"
                  />
                </div>
              </St.ParcelBox>
              <St.Title justify="flex-start" style={{ marginTop: 27 }}>
                Taxa de juros a partir de 1,99% a.m.{" "}
              </St.Title>
            </St.InputValue>

            <St.MiddleContainer>
              <St.Rounded />
              <p style={{ width: "100%" }}>
                Lembre-se, essa estes valores podem variar de acordo com o
                critério de cada instituição financeira!
              </p>
            </St.MiddleContainer>
          </St.TopSpace>
          <St.Footer>
            <Button
              buttonType="primary"
              text="Buscar empréstimos"
              disabled={postSearchProposals.loading}
              isLoading={buttonIsLoading}
              onClick={handleSubmitLoanSeeker}
            />
          </St.Footer>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default SimulationByLoanSeeker;
