import styled from "styled-components";
import media from "styled-media-query";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Title = styled.p`
  font-size: 2.5rem;
  font-weight: bold;
  align-items: center;
`;

export const Subtitle = styled.div`
  font-size: 1.6rem;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  ${media.greaterThan("medium")`
    width: 100%;
    height: 35%;
  `}
`;

export const Picture = styled.div`
  grid-area: camera;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;

  @media screen and (max-width: 500px) {
    justify-content: center;
  }

  #picture-modal {
    margin-right: 20px;
  }

  button {
    display: flex;
    align-items: center;
    width: auto !important;
    height: 40px !important;
    margin: 0 20px 0 0 !important;
    svg {
      margin-right: 10px;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular },
    },
  }) => fontWeightRegular};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
  `}
`;

export const WrapperCamera = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;

  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;

export const ErrorMessage = styled.span`
  color: ${({
    theme: {
      colors: { red },
    },
  }) => red};
  font-size: 1.5rem;
`;

export const BoxCenter = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 5rem;

  img {
    width: 300px;
    height: 300px;
  }
`;

export const BoxDescription = styled.div`
  background: #f7f7f7;
  height: 200px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 16px;

  div {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    gap: 1rem;

    span {
      font-size: 1.5rem;
      align-items: center;
      line-height: 24px;
    }
  }
`;

export const Footer = styled.div`
  margin-top: 25px;
  position: relative;
  bottom: 0;
  width: 100%;

  @media (max-width: 712px) {
    padding: 20px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`;
