/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import { useHistory } from "react-router-dom";
import { useLoan } from "hooks/useLoan";
import OneButtonPopup from "components/modals/OneButtonPopup";
import themes from "styles";
import constants from "utils/constants";
import useApi from "hooks/useApi";

const WithDrawKYCValidation = () => {
  const { solicitationData, userInitialData, dispatchFinishResponse } =
    useLoan();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [count, setCount] = useState(0);
  const { cpf, data } = userInitialData;
  let timeoutId;

  console.log(userInitialData);

  const GetStatusKYC = useApi(
    constants.endpoints.getStatusClientKYC.replace("PARAM_CPF", cpf?.unmasked),
    "GET"
  );

  const PostCreateSolicitationKYC = useApi(
    constants.endpoints.postCreateSolicitationKYC,
    "POST"
  );

  const PostContractRequest = useApi(
    constants.endpoints.postWithDrawByTeuCard.replace(
      "ID_LOAN",
      solicitationData.idSimulacao
    ),
    "POST"
  );

  const PostCreateTimeOut = useApi(
    constants.endpoints.postCreateTimeout,
    "POST"
  );

  const createSolicitationKYC = async () => {
    const response = await PostCreateSolicitationKYC.callApi({
      cpf: cpf?.unmasked,
      nome: data?.nome,
    });
    try {
      if (response.status >= 200 && response.status < 300) {
        await getStatus();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const createTimeOutLog = async (createdTime) => {
    const response = await PostCreateTimeOut.callApi({
      cpf: cpf?.unmasked,
      dataCriacao: createdTime + "Z",
    });
    try {
      if (response.status >= 200 && response.status < 300) {
        console.log("TimeOut");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getStatus = useCallback(async () => {
    const response = await GetStatusKYC.callApi();
    try {
      if (response.status >= 200 && response.status < 300) {
        if (response.data.status === "REJEITADO") {
          setError(true);
          setErrorMessage(response.data.descricao);
        }
        if (response.data.status === "APROVADO") {
          const body = {
            origem: "LOJA",
          };
          const responseData = await PostContractRequest.callApi(body);

          if (responseData.status >= 200 && responseData.status < 300) {
            dispatchFinishResponse({ error: responseData.status !== 200 });
            history.push("/with-draw-listener-status");
          }

          if (response.status > 400) {
            setError(true);
            setErrorMessage(response.mensagem);
          }
        }
        if (response.data.status === "PROCESSANDO") {
          if (count < 2) {
            setCount(count + 1);
          } else {
            setError(true);
            setErrorMessage(response.data.descricao);
            await createTimeOutLog(response.data.dataCriacao);
            setCount(0);
          }
        }
      }
      if (response.status === 404) {
        setCount(count + 1);
        await createSolicitationKYC();
      }
    } catch (err) {
      console.error(err);
    }
  }, [count]);

  useEffect(() => {
    getStatus();
    return () => clearTimeout(timeoutId);
  }, [timeoutId]);

  useEffect(() => {
    if (count) {
      timeoutId = setTimeout(() => {
        getStatus();
      }, 20000);
      return () => clearTimeout(timeoutId);
    }
  }, [timeoutId]);

  return (
    <St.SafeView>
      <Layout
        routeBack="/with-draw-listener-status"
        title="Liberação do empréstimo"
      >
        <OneButtonPopup
          text={errorMessage}
          buttonText="Entendi"
          title={"Atenção!"}
          visibility={error}
          iconModal={themes.components.png.emojiSad}
          defaultButton
          setVisibility={setError}
          onClose={() => history.push("/opcoes-acesso")}
        />
        <St.Container>
          <St.TopSpace>
            <St.Title>
              Estamos consultando a liberação para o teu empréstimo!
            </St.Title>
          </St.TopSpace>
          <St.MiddleContainer>
            {!error && (
              <St.Spinner>
                <div class="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </St.Spinner>
            )}
          </St.MiddleContainer>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default WithDrawKYCValidation;
