/* eslint-disable react-hooks/exhaustive-deps */
import Button from "components/Button";
import Camera, { FACING_MODES } from "components/NewCamera";
import Layout from "components/Layout";
import OneButtonPopup from "components/modals/OneButtonPopup";
import PictureModal from "components/PictureCard";
import Spinner from "components/Spinner";
import React, { useEffect, useRef, useState } from "react";
import { MdPhotoCamera } from "react-icons/md";
import { useLocation } from "react-router-dom";
import FaceCaptchaService, {
  getCertifaceResultMessage,
  getImageEnc,
} from "../../../services/facecaptcha/facecaptcha.js";

import useReactRouter from "use-react-router";
import * as S from "./styles";
import constants from "utils/constants";
import useApi from "hooks/useApi.js";
import { useLoan } from "hooks/useLoan.js";
import themes from "styles/index.js";
import Loading from "components/Loading/index.js";

const IMAGE_TYPES = {
  CERTIFACE: "CERTIFACE",
  FRENTE: "FRENTE",
  VERSO: "VERSO",
};

const Liveness = () => {
  const [nome, setNome] = useState("");
  const [dataNascimento, setDataNascimento] = useState({});
  const [errorPopUpUpload, setErrorPopUpUpload] = useState(false);
  const [certiface, setCertiface] = useState({});
  const [currentChallenge, setCurrentChallenge] = useState(null);
  const [certifaceMessagebase64, setCertifaceMessagebase64] = useState(null);
  const [certifaceImages, setCertifaceImages] = useState([]);
  const [certifaceErrorMessage, setCertifaceErrorMessage] = useState("");
  const [certifacePopupVisibility, setCertifacePopupVisibility] =
    useState(false);
  const [userPicture, setUserPicture] = useState();
  const [camUserPictureIsOpen, setCamUserPictureIsOpen] = useState(false);
  const [firstPictureCertiface, setFirstPictureCertiface] = useState(null);
  const [errorUserPicture] = useState(null);
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const [errorUploadUserPicture, setErrorUploadUserPicture] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [auxImage, setAuxImage] = useState("");
  const [livenessActive] = useState(
    process.env.REACT_APP_LIVENESS_ACTIVE === "true" ? true : false
  );
  const { state } = useLocation();
  const { history } = useReactRouter();
  const livenessRef = useRef();
  const { userInitialData, solicitationData } = useLoan();
  const [ipAddress, setIPAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [failed, setFailed] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");
  const [camUserPictureIsClose, setCamUserPictureIsClose] = useState(false);
  const PostSignTerms = useApi(constants.endpoints.postSignTerms, "POST");

  let countrSnapEnd = 0;

  useEffect(() => {
    sessionStorage.setItem("@NovaEra:faceID", state?.faceId);
  }, []);

  const handleUploadPicture = async (image, type) => {
    setImageIsLoading(type);
    setAuxImage(image);
    const response = await PostSignTerms.callApi({
      geolocation: `${latitude},${longitude}` || "",
      idEmprestimo: solicitationData?.idSimulacao,
      ipAddress: ipAddress,
      selfieImage: image.split(",")[1],
      userAgent: navigator.userAgent,
    });
    try {
      if (response.status >= 200 && response.status < 300) {
        setDialogOpen(true);
      } else {
        setFailed(true);
        setDialogOpen(true);
        setFailedMessage(response.mensagem);
      }
    } catch (err) {
      setErrorUploadUserPicture(true);
      setErrorPopUpUpload(true);
    } finally {
      localStorage.removeItem("@NovaEra:faceID");
      setImageIsLoading(null);
    }
  };

  useEffect(() => {
    if (userInitialData) {
      setNome(userInitialData?.data?.nome);
      setDataNascimento(userInitialData?.data?.dataNascimento);
    }
  }, [userInitialData]);

  useEffect(() => {
    const getIpAddress = async () => {
      try {
        const response = await fetch("https://api64.ipify.org?format=json");
        const data = await response.json();
        console.log(data);
        setIPAddress(data.ip);
      } catch (error) {
        console.log(error);
      }
    };

    getIpAddress();
  }, []);

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []);

  const toggleCameraUserPicture = async () => {
    const isFormValid = checkIfLivenessFormValid();

    if (!isFormValid.valid) {
      setCertifaceErrorMessage(
        isFormValid.field + " obrigatório para iniciar a Prova de Vida"
      );
      setCertifacePopupVisibility(true);
      return;
    }
    countrSnapEnd = 0;
    setCamUserPictureIsClose(false);

    setCamUserPictureIsOpen((prev) => !prev);
  };

  const forceCameraTakePicture = (time, obj) => {
    setTimeout(() => {
      let count = 0;
      setCurrentChallenge(obj);
      setCertifaceMessagebase64(obj.message);
      const image = setInterval(async () => {
        count++;

        if (
          count === obj.numberSnap &&
          obj.numberOfChallenges - 1 === countrSnapEnd
        ) {
          setCamUserPictureIsClose(true);
        }

        if (livenessRef.current) {
          await livenessRef.current.takePicture();
        }
      }, 4000);

      const challenge = setInterval(function () {
        clearInterval(image);
        clearInterval(challenge);
        countrSnapEnd++;
      }, time);
    }, (time + 1000) * obj.index);
  };

  const checkIfLivenessFormValid = () => {
    if (!nome) return { field: "Nome Completo ", valid: false };
    if (!dataNascimento?.masked)
      return { field: "Data de Nascimento ", valid: false };

    return { field: "", valid: true };
  };

  //iniciar o certiface
  const startLiveness = async () => {
    setImageIsLoading(IMAGE_TYPES.CERTIFACE);
    const service = FaceCaptchaService();
    setCertifaceImages([]);

    const startResponse = await service.start(
      nome || "",
      dataNascimento?.masked || "00/00/0000",
      userInitialData?.cpf?.unmasked || ""
    );

    // setCamUserPictureIsOpen((prev) => !prev);

    if (startResponse.status === 200) {
      const challenges = startResponse.data?.challenge?.challenges || [];

      if (challenges.length > 0) {
        challenges.forEach((ch, index) => {
          const code = ch.tipoFace.codigo;
          const time = ch.tempoEmSegundos * 1000;
          const size = challenges.length - 1;
          const totalTime = startResponse.data?.challenge?.totalTime;
          const snapInMillis =
            startResponse.data?.challenge?.snapFrequenceInMillis;
          const numberSnap = startResponse.data?.challenge?.snapNumber;
          const numberOfChallenges =
            startResponse.data?.challenge?.numberOfChallenges;

          forceCameraTakePicture(time, {
            code,
            time,
            index,
            size,
            message: ch.mensagem,
            startResponse,
            totalTime,
            snapInMillis,
            numberSnap,
            numberOfChallenges,
          });
        });
      }
    }
    setImageIsLoading(null);
  };

  const invokeCameraHandle = (image) => {
    if (currentChallenge === null) return;

    const { code, index, size, message, startResponse } = currentChallenge;

    if (index === 0) {
      setFirstPictureCertiface(image);
    }

    const imageBase64 = image;

    const currentImages = certifaceImages;

    const newImage = getImageEnc(imageBase64, code);

    setCertifaceMessagebase64(message);

    currentImages.push(newImage);
    setCertifaceImages(currentImages);

    if (index === size && camUserPictureIsClose) {
      if (livenessRef.current) {
        livenessRef.current.stopCamera();
      }

      if (index === 0) {
        sendChallenges(startResponse, currentImages, imageBase64);
      } else {
        sendChallenges(startResponse, currentImages);
      }
      setCurrentChallenge(null);
      setCertifaceMessagebase64(null);
    }
  };

  const sendChallenges = async (startResponse, images, firstImage) => {
    setImageIsLoading(IMAGE_TYPES.CERTIFACE);

    const service = FaceCaptchaService();

    const challengeResponse = await service.captcha(startResponse, images);

    setImageIsLoading(null);

    setCertiface(challengeResponse.data?.result);

    setCertifaceErrorMessage("");

    if (challengeResponse.success === true) {
      const codeResult = challengeResponse.data.result.codID;
      if ([1.1, 1.2].includes(codeResult)) {
        setUserPicture(firstPictureCertiface);
        handleUploadPicture(
          firstImage || firstPictureCertiface,
          IMAGE_TYPES.CERTIFACE
        );
      } else if ([200.1, 200.2].includes(codeResult)) {
        setUserPicture(firstImage || firstPictureCertiface);
        if (livenessActive) {
          setCertifaceErrorMessage(
            getCertifaceResultMessage(challengeResponse.data.result.codID || 0)
          );
          setCertifacePopupVisibility(true);
        } else {
          handleUploadPicture(
            firstImage || firstPictureCertiface,
            IMAGE_TYPES.CERTIFACE
          );
        }
      } else {
        setCertifaceErrorMessage(
          getCertifaceResultMessage(challengeResponse.data.result.codID || 0)
        );
        setCertifacePopupVisibility(true);
      }
    }
  };

  const successMessage = () => {
    return (
      <div>
        <p style={{ textAlign: "center" }}>
          Parabéns! Em breve o dinheiro estará na tua conta. Acompanhe o status
          e fique atento!
        </p>
        <br /> {/* Adicionando um salto de linha */}
        <p style={{ textAlign: "center" }}>
          O prazo máximo para pagamento é de até 24 horas!
        </p>
      </div>
    );
  };

  return (
    <Layout.View title={"Assinatura digital"} routeBack="/opcoes-acesso">
      <Loading status={PostSignTerms.loading} />
      <OneButtonPopup
        defaultButton
        text={certifaceErrorMessage}
        buttonText="OK"
        visibility={certifacePopupVisibility}
        setVisibility={(val) => {
          setCertifacePopupVisibility(false);
        }}
        iconModal={themes.components.png.emojiSad}
      />
      <OneButtonPopup
        text={failed ? failedMessage : successMessage()}
        buttonText="Entendi"
        title={failed ? "Ops!" : "Contratação realizada com sucesso"}
        visibility={dialogOpen}
        iconModal={
          failed
            ? themes.components.png.emojiSad
            : themes.components.png.emojiSuccess
        }
        defaultButton
        setVisibility={setDialogOpen}
        onClose={() => history.push("/opcoes-acesso")}
      />
      <S.Picture>
        {userPicture && (
          <div>
            <PictureModal
              id="picture-modal"
              source={userPicture}
              width="150"
              height="150"
              expand
            />
          </div>
        )}

        <Button
          buttonType="primary"
          onClick={toggleCameraUserPicture}
          disabled={!ipAddress}
        >
          Tirar selfie &nbsp;
          <MdPhotoCamera />
          {imageIsLoading === IMAGE_TYPES.CERTIFACE && <Spinner color="#FFF" />}
        </Button>

        <S.ErrorMessage>{formErrors.userPicture}</S.ErrorMessage>

        <S.WrapperCamera show={camUserPictureIsOpen}>
          <Camera
            open={camUserPictureIsOpen}
            facingMode={FACING_MODES.ENVIRONMENT}
            isStartLiveness={startLiveness}
            isLiveness
            onTakePhoto={invokeCameraHandle}
            certifaceMessagebase64={certifaceMessagebase64}
            stopWatch
            doNotCloseAfterTakePicture
            disableCloseButton
            ref={livenessRef}
            handleClose={() => setCamUserPictureIsOpen(false)}
            disableTakePictureButton
            mask
          />
        </S.WrapperCamera>

        {errorUserPicture && (
          <S.ErrorMessage>{errorUserPicture}</S.ErrorMessage>
        )}
      </S.Picture>

      {certiface?.codID !== 1.1 && certiface?.codID !== 200.1 && (
        <S.ErrorMessage>{certifaceErrorMessage}</S.ErrorMessage>
      )}
    </Layout.View>
  );
};

export default Liveness;
