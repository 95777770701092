import styled from 'styled-components';
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack'

export const Button = styled(ArrowBack)`
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
    path {
        fill: ${({
        theme: {
            colors: { gray }
        }
        }) => gray};
    }
`;
