import React from 'react';

import IconSearch from 'assets/icons/search.svg';
import IconWriting from 'assets/icons/writing.svg';

import * as S from './styles';

function CardClient({
  data,
  onClickSearch,
  onClickWriting,
  isLoading = false
}) {
  return (
    <S.Container>
      <S.Content>
        <S.ContentStatus>
          <S.Title>Nome completo</S.Title>
          <S.Subtitle>{data?.nome}</S.Subtitle>
        </S.ContentStatus>
      </S.Content>
      <S.Line />

      <S.Content>
        <S.ContentStatus>
          <S.Title>Data da cadastro</S.Title>
          <S.Subtitle>{data?.dataCadastro}</S.Subtitle>
        </S.ContentStatus>
      </S.Content>
      <S.Line />

      <S.Content>
        <S.ContentStatus>
          <S.Title>Origem do cadastro</S.Title>
          <S.Subtitle>{data?.origem}</S.Subtitle>
        </S.ContentStatus>
      </S.Content>
      <S.Line />

      <S.Content>
        <S.ContentStatus>
          <S.Title>Status do cartão</S.Title>
          <S.Subtitle>{data?.cartoes[data?.cartoes.length - 1]?.status}</S.Subtitle>
        </S.ContentStatus>
        {isLoading ? (
          <S.ContentIcon>
            <S.SpinnerCusmotized />
          </S.ContentIcon>
        ): (
          <S.ContentIcon>
            {onClickSearch && <S.Search src={IconSearch} onClick={() => onClickSearch(data)} />} 
            {onClickWriting && <S.Writing src={IconWriting} onClick={() => onClickWriting(data)} />}
          </S.ContentIcon>
        )}
      </S.Content>
    </S.Container>
  )
}

export default CardClient
