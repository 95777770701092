export const options = [
  { id: "formalJobYes", value: "Sim", name: "formalJob", label: "Sim" },
  { id: "formalJobNot", value: "Não", name: "formalJob", label: "Não" },
];

export const CERTIFACE_RESULTS = {
  APROVADO: "APROVADO",
  NEGADO: "NEGADO",
  ERROR: "ERR",
};

export const optionsUfs = [
  { value: "disabled", label: "Selecione" },
  { value: "AC", label: "Acre" },
  { value: "AL", label: "Alagoas" },
  { value: "AP", label: "Amapá" },
  { value: "AM", label: "Amazonas" },
  { value: "BA", label: "Bahia" },
  { value: "CE", label: "Ceará" },
  { value: "ES", label: "Espírito Santo" },
  { value: "GO", label: "Goiás" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PA", label: "Pará" },
  { value: "PB", label: "Paraíba" },
  { value: "PR", label: "Paraná" },
  { value: "PE", label: "Pernambuco" },
  { value: "PI", label: "Piauí" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RN", label: "Rio Grande do Norte" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "RO", label: "Rondônia" },
  { value: "RR", label: "Roraima" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "SE", label: "Sergipe" },
  { value: "TO", label: "Tocantins" },
  { value: "DF", label: "Distrito Federal" },
];

export const groups = [
  { value: "disabled", label: "Selecione" },
  { value: "M", label: "Masculino" },
  { value: "F", label: "Feminino" },
];

export const optionsDate = [
  { id: "1", value: "1", label: "1" },
  { id: "5", value: "5", label: "5" },
  { id: "10", value: "10", label: "10" },
  { id: "20", value: "20", label: "20" },
  { id: "25", value: "25", label: "25" },
];

export const groupsRef = [
  { value: "disabled", label: "Selecione" },
  { value: "Cônjuge", label: "Cônjuge" },
  { value: "Pai ou mãe", label: "Pai ou mãe" },
  { value: "Irmão(a)", label: "Irmão(a)" },
  { value: "Filho(a)", label: "Filho(a)" },
  { value: "Tio(a)", label: "Tio(a)" },
  { value: "Primo(a)", label: "Primo(a)" },
  { value: "Sogro(a)", label: "Sogro(a)" },
  { value: "Genro ou nora", label: "Genro ou nora" },
  { value: "Neto(a)", label: "Neto(a)" },
  { value: "Avô(ó)", label: "Avô(ó)" },
  { value: "Sobrinho(a)", label: "Sobrinho(a)" },
  { value: "Amigo(a)", label: "Amigo(a)" },
  { value: "Vizinho(a)", label: "Vizinho(a)" },
];

export const gridTemplateCadComplete = (theme) => {
  const {
    screen: { screenXS, screenSM },
  } = theme;
  return `
    grid-template: ${`
      "userFullName"
      "email"
      "zipCode"
      "address"
      "number"
      "complement"
      "neighborhood"
      "city"
      "uf"
      "gender"
      "birthDate"
      "income"
      "invoiceExpiration"
      "career"
      "text1"
      "formalJobYes"
      "formalJobNot"
      "propertiesYes"
      "propertiesNot"
      "text4"
      "familyProgramYes"
      "familyProgramNot"
      "text5"
      "revalidationYes"
      "revalidationNot"
      "creditCard"
    `};

    @media screen {
      @media (min-width: ${screenXS}) {
        grid-template: ${`
          "userFullName userFullName"
          "email email"
          "zipCode zipCode"
          "address address"
          "number number"
          "complement complement"
          "neighborhood neighborhood"
          "city city"
          "uf uf"
          "gender gender"
          "birthDate birthDate"
          "income income"
          "invoiceExpiration invoiceExpiration"
          "formalJob formalJob"
          "career career"
        `};
        grid-template-columns: 1fr 1fr;
        justify-content: start;
      }

      @media screen {
        @media (min-width: ${screenSM}) {
          grid-template: ${`
          "userFullName userFullName userFullName userFullName userFullName"
          "email email email email email"
          "zipCode zipCode zipCode zipCode zipCode"
          "address address address address address"
          "number number complement complement complement"
          "neighborhood neighborhood city city city"
          "uf uf gender gender gender"
          "birthDate birthDate birthDate birthDate birthDate"
          "income income income income income"
          "invoiceExpiration invoiceExpiration invoiceExpiration invoiceExpiration invoiceExpiration"
          "formalJob formalJob formalJob formalJob formalJob"
          "career career career career career"
          `};
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          justify-content: start;
        }
      }
   `;
};
