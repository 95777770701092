export default (state, action) => {
  switch(action.type) {
    case 'ADD_USER':
      return {
        ...state,
        user: action.payload
      }
    
    case 'ADD_PROPOSTA':
      return {
        ...state,
        proposta: action.payload
      }
    case 'ADD_PROPOSTA_COMPLETA':
      return {
        ...state,
        propostaCompleta: action.payload
      }
    case 'ADD_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: action.payload
      }

    case 'ADD__MESSAGE_REPROV':
      return {
        ...state,
        messageReprov: action.payload
      }
    default:
      return state;
  }
}