import React from "react";

import { AuthProvider } from "./AuthContext";
import { GlobalProvider } from "./GlobalContext";
import { LoanProvider } from "./LoanContext";
import { EmailProvider } from "./EmailContext";
import { SignatureProvider } from "./SignatureContext";
import AttendanceContext from "./AttendanceContext";

const AppProvider = ({ children }) => (
  <GlobalProvider>
    <AuthProvider>
      <AttendanceContext>
        <LoanProvider>
          <EmailProvider>
            <SignatureProvider>{children}</SignatureProvider>
          </EmailProvider>
        </LoanProvider>
      </AttendanceContext>
    </AuthProvider>
  </GlobalProvider>
);

export default AppProvider;
