import React from "react";

import IconSearch from "assets/icons/search.svg";
import IconWriting from "assets/icons/writing.svg";

import * as S from "./styles";

function Card({
  data,
  onClickSearch,
  onClickWriting,
  onClickShowReasons,
  isLoading = false,
}) {
  return (
    <S.Container>
      <S.Content>
        <S.ContentStatus>
          <S.Title>ID da Proposta</S.Title>
          <S.Subtitle>{data?.id}</S.Subtitle>
        </S.ContentStatus>
      </S.Content>
      <S.Line />

      <S.Content>
        <S.ContentStatus>
          <S.Title>Nome completo</S.Title>
          <S.Subtitle>{data?.nomeCliente}</S.Subtitle>
        </S.ContentStatus>
      </S.Content>
      <S.Line />

      <S.Content>
        <S.ContentStatus>
          <S.Title>Operador(a) de Venda</S.Title>
          {data.atendente === "" ? (
            <S.Subtitle>{data?.origem}</S.Subtitle>
          ) : (
            <S.Subtitle>{data?.atendente}</S.Subtitle>
          )}
        </S.ContentStatus>
      </S.Content>
      <S.Line />

      <S.Content>
        <S.ContentStatus>
          <S.Title>Horário</S.Title>
          <S.Subtitle>{data?.duracao}</S.Subtitle>
        </S.ContentStatus>
      </S.Content>
      <S.Line />

      <S.Content>
        <S.ContentStatus>
          <S.Title>Status</S.Title>
          <S.Subtitle>{data?.status}</S.Subtitle>
        </S.ContentStatus>
        {isLoading ? (
          <S.ContentIcon>
            <S.SpinnerCusmotized />
          </S.ContentIcon>
        ) : (
          <S.ContentIcon>
            {onClickSearch && (
              <S.Search src={IconSearch} onClick={() => onClickSearch(data)} />
            )}
            {onClickWriting && (
              <S.Writing
                src={IconWriting}
                onClick={() => onClickWriting(data)}
              />
            )}
            {onClickShowReasons && (
              <S.Reasons
                src={IconSearch}
                onClick={() => onClickShowReasons(data)}
              />
            )}
          </S.ContentIcon>
        )}
      </S.Content>
    </S.Container>
  );
}

export default Card;
