import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Layout from "components/OldLayout";
import Button from "components/Button";
import InputCheckbox from "components/InputCheckbox";

import Termos from "services/terms";

import * as St from "./styles";
import themes from "styles";

const LoanSeekerWelcome = () => {
  const [termsSCR, setTermsSCR] = useState(false);
  const history = useHistory();

  const handleTermsConditions = async () => {
    const filePath = Termos.termosSCR;

    window.open(filePath);
  };

  return (
    <St.SafeView>
      <Layout
        routeBack="/choose-simulator"
        title="Bem-vindo ao teu buscador de empréstimos."
      >
        <St.Container>
          <St.ImageItem src={themes.components.png.teuCredBankings} />
          <St.Title>Aqui você encontra várias opções em um só lugar.</St.Title>
          <St.MiddleContainer>
            <St.Rounded />
            <p>
              Atenção! NÃO solicitamos depósito antecipado para a liberação de
              empréstimos e outros produtos.
            </p>
          </St.MiddleContainer>
          <St.MiddleBox>
            <InputCheckbox
              type="checkbox"
              id="termsSCR"
              name="termsSCR"
              value={termsSCR}
              onChange={setTermsSCR}
              label={
                <>
                  Aceito o{" "}
                  <St.ViewTerms onClick={() => handleTermsConditions()}>
                    Termo de Autorização de Consulta (SCR)
                  </St.ViewTerms>
                </>
              }
            />
          </St.MiddleBox>

          <St.Footer>
            <Button
              buttonType="primary"
              text="Avançar"
              onClick={() => history.push("/confirm-data-loan-seeker")}
              disabled={!termsSCR}
            />
          </St.Footer>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default LoanSeekerWelcome;
