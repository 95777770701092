import styled, { keyframes } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  margin: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  margin: auto;
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
`

export const Carousel = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`

export const Title = styled.p`
  color: #4a4b4a;
  font-size: 1.6em;
  letter-spacing: 0;
  line-height: 27px;
  text-align: flex-start;
`

export const Footer = styled.div`
  margin-top: 45px;
  position: relative;
  bottom: 0;
  text-align: center;
  width: 100%;

  @media (max-width: 712px) {
    padding: 20px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`;