import React from "react";
import Chart from "react-apexcharts";

export const BoxGraphic = ({ categories, series, width, height }) => {
  const buildCategories = () => {
    let response = null;
    if (categories === undefined || categories.length >= 0) {
      response = {
        chart: {
          type: "bar",
        },
        colors: ["#0074BD", "#29A87C", "#FF0000", "#F8A72D"],
        plotOptions: {
          bar: {
            columnWidth: "50%",
            horizontal: false,
            distributed: true,
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          series,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter(val) {
              return val;
            },
          },
        },
      };
    }
    return response;
  };

  const buildSeries = () => {
    let response = null;
    if (series === undefined || series.length <= 0) {
      response = [
        {
          name: "Propostas",
          data: [0],
        },
      ];
    } else {
      response = series;
    }
    return response;
  };
  return (
    <Chart
      options={buildCategories()}
      series={buildSeries()}
      type="bar"
      width={width}
      height={height}
    />
  );
};
