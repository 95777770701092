import React, { useState, useEffect } from "react";
import * as St from "./styles";

function CurrencyInput({ valueDefault, valueChange, width, max, ...props }) {
  const [value, setValue] = useState(valueDefault);

  useEffect(() => {
    setValue(valueDefault);
  }, [valueDefault]);

  const handleChange = (event) => {
    const unformattedValue = event.target.value.replace(/\D/g, ""); // remove tudo que não é número
    const formattedValue = (Number(unformattedValue) / 100).toLocaleString(
      "pt-BR",
      { style: "currency", currency: "BRL" }
    );
    setValue(formattedValue);
    const subValue = formattedValue
      .replace("R$", "")
      .replace(".", "")
      .replace(",", ".");
    valueChange(parseFloat(subValue));
  };

  return (
    <St.Input
      {...props}
      max={max}
      type="text"
      value={value}
      onChange={handleChange}
      style={{ fontSize: 16, paddingLeft: 5, width: width }}
    />
  );
}

export default CurrencyInput;
