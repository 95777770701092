import React, { useContext, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import useReactRouter from "use-react-router";
import SignaturePad from "react-signature-canvas";

import { SignatureContext } from "context/SignatureContext";

import Button from "components/Button";
import Spinner from "components/Spinner";
import Layout from "components/OldLayout";
import TwoButtonPopup from "components/modals/TwoButtonPopup";

import * as S from "./styles";
import BenefitTable from "components/BenefitTable";
import ContainerFlex from "components/ContainerFlex";

const Signature = () => {
  const { imageURL, setImageURL } = useContext(SignatureContext);
  const [isLoading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [openDialog, setopenDialog] = useState(false);

  const { history } = useReactRouter();
  const sigCanvas = useRef();
  const { state } = useLocation();

  const clear = () => {
    sigCanvas.current.clear();
    setDisabledButton(true);
  };

  const save = () => {
    setLoading(true);
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    goToProposal();
    setLoading(false);
  };

  const goToProposal = () =>
    history.push("/proposta", {
      ...state,
      previousPath: "/assinatura-proposta",
    });

  return (
    <S.Wrapper>
      <Layout title="Assinatura" goBack={goToProposal}>
        <TwoButtonPopup
          text="Tem certeza que finalizou a assinatura?"
          visibility={openDialog}
          buttonText="Sim"
          onClick={save}
          buttonCancelText="Não"
          setVisibility={(val) => {
            setopenDialog(val);
          }}
        />
        <ContainerFlex marginTop={20} marginBottom={20}>
          <BenefitTable
            idProposal={state.propostaId}
            contractedBenefits={state?.benefitsContracteds || ""}
            notContractedBenefit={state.benefits?.notContracted || ""}
          />
        </ContainerFlex>
        <S.Infos>
          <S.Signature>
            <SignaturePad
              clearOnResize={false}
              ref={sigCanvas}
              canvasProps={{
                className: "signatureCanvas",
              }}
              onEnd={() => setDisabledButton(false)}
              onBegin={() => setDisabledButton(true)}
            />
          </S.Signature>
          <S.Text>Assine dentro da caixa</S.Text>

          {imageURL ? (
            <img
              src={imageURL}
              alt="Assinatura Digital"
              style={{
                display: "none",
                margin: "0 auto",
              }}
            />
          ) : null}
        </S.Infos>
        <S.Buttons>
          <Button
            buttonType="secondary"
            text="Limpar"
            onClick={clear}
            className="button"
          />
          <Button
            buttonType="primary"
            text={isLoading === true ? <Spinner color="#FFF" /> : "Concluir"}
            onClick={() => setopenDialog(true)}
            disabled={disabledButton}
            className="button"
          />
        </S.Buttons>
      </Layout>
    </S.Wrapper>
  );
};

export default Signature;
