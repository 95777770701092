import styled from "styled-components";
import media from "styled-media-query";
import Button from "components/Button";

export const Container = styled.div`
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;

  .react-datepicker__navigation {
    display: none;
  }

  .react-datepicker {
    height: 200px;
    left: 110%;

    ${media.greaterThan("large")`
      left:150%;
      margin: none
  `}
  }

  .react-datepicker__header {
    display: none;
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  padding: 6px 12px;
  justify-content: center;
`;