import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import Layout from "components/OldLayout";
import Button from "components/Button";
import Spinner from "components/Spinner";
import Camera from "components/NewCamera";
import TwoButtonPopup from "components/modals/TwoButtonPopup";
import OneButtonPopup from "components/modals/OneButtonPopup";
import Loading from "components/Loading";

import useApi from "hooks/useApi";
import constants from "utils/constants";
import { useLoan } from "hooks/useLoan";

import * as St from "./styles";
import assets from "assets/index";
import themes from "styles";

const IMAGE_TYPES = {
  RESIDENTIAL: "ENDERECO",
  INCOME: "RENDA",
};

const RequestDocumentsLoanSeeker = () => {
  const [cam1DocumentsIsOpen, setCam1DocumentsIsOpen] = useState(false);
  const [cam2DocumentsIsOpen, setCam2DocumentsIsOpen] = useState(false);
  const [documentsPicture1, setDocumentsPicture1] = useState(null);
  const [documentsPicture2, setDocumentsPicture2] = useState(null);
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const [auxImage, setAuxImage] = useState("");
  const [errorUploadDocumentResidential, setErrorUploadDocumentResidential] =
    useState(false);
  const [errorUploadDocumentIncome, setErrorUploadDocumentIncome] =
    useState(false);
  const [errorPopUpUpload, setErrorPopUpUpload] = useState(false);
  const [lastPictureUploaded, setLastPictureUploaded] = useState();
  const [success, setSuccess] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleCameraDocuments = (index) => {
    if (index === 1) {
      setCam1DocumentsIsOpen(true);
    } else {
      setCam2DocumentsIsOpen(true);
    }
  };

  const history = useHistory();

  const { state } = useLocation();

  const PostSendDocuments = useApi(
    constants.endpoints.postSendDocumentsByEasyCredito.replace(
      "ID_LOAN",
      state?.idEmprestimo
    ),
    "POST"
  );

  const onTakeDocumentPicture = async (imageBase64, index) => {
    if (index === 1) {
      setLastPictureUploaded(IMAGE_TYPES.RESIDENTIAL);
      await handleUploadPicture(imageBase64, IMAGE_TYPES.RESIDENTIAL);
    } else {
      console.log(index);
      setLastPictureUploaded(IMAGE_TYPES.INCOME);
      await handleUploadPicture(imageBase64, IMAGE_TYPES.INCOME);
    }
  };

  const handleUploadPicture = async (image, type) => {
    setImageIsLoading(type);
    setAuxImage(image);

    try {
      switch (type) {
        case IMAGE_TYPES.RESIDENTIAL:
          setErrorUploadDocumentResidential(false);
          await PostSendDocuments.callApi({
            base64: image.split(",")[1],
            tipo: IMAGE_TYPES.RESIDENTIAL,
          });
          setDocumentsPicture1(image);
          break;
        case IMAGE_TYPES.INCOME:
          setErrorUploadDocumentIncome(false);
          const responseDocuments = await PostSendDocuments.callApi({
            base64: image.split(",")[1],
            tipo: IMAGE_TYPES.INCOME,
          });
          if (responseDocuments.status === 200) {
            setDialogOpen(true);
            setSuccess("Acompanhe na tua área de empréstimos!");
          } else {
            setErrorPopUpUpload(true);
          }
          setDocumentsPicture2(image);
          break;
        default:
          return;
      }
    } catch (error) {
      switch (type) {
        case IMAGE_TYPES.RESIDENTIAL:
          setErrorUploadDocumentResidential(true);
          break;
        case IMAGE_TYPES.INCOME:
          setErrorUploadDocumentIncome(true);
          break;
        default:
          return;
      }
      setErrorPopUpUpload(true);
    } finally {
      setImageIsLoading(null);
    }
  };

  const handleReUploadPicture = async () => {
    setErrorPopUpUpload(false);

    switch (lastPictureUploaded) {
      case IMAGE_TYPES.RESIDENTIAL:
        await handleReUploadPicture(auxImage, IMAGE_TYPES.RESIDENTIAL);
        break;
      case IMAGE_TYPES.INCOME:
        await handleReUploadPicture(auxImage, IMAGE_TYPES.INCOME);
        break;
      default:
        return;
    }
  };

  return (
    <St.SafeView>
      <Loading status={PostSendDocuments.loading} />
      <Layout title="Documentos" routeBack="/opcoes-acesso">
        <TwoButtonPopup
          text="Erro ao fazer upload dos documentos!"
          visibility={errorPopUpUpload}
          buttonText="Tentar novamente"
          onClick={handleReUploadPicture}
          buttonCancelText="Voltar"
          setVisibility={(val) => setErrorPopUpUpload(val)}
        />
        <OneButtonPopup
          text={success}
          buttonText="Voltar para a página inicial"
          title={"Documento enviado com sucesso"}
          visibility={dialogOpen}
          iconModal={themes.components.png.emojiSuccess}
          defaultButton
          setVisibility={setDialogOpen}
          onClose={() => history.push("/opcoes-acesso")}
        />
        <St.Container>
          <St.Picture>
            <St.WrapperCamera show={cam1DocumentsIsOpen}>
              <Camera
                open={cam1DocumentsIsOpen}
                onTakePhoto={(img) => onTakeDocumentPicture(img, 1)}
                handleClose={() => setCam1DocumentsIsOpen(false)}
                maskDocumentIncomeAddress
              />
            </St.WrapperCamera>
          </St.Picture>
          <St.Picture>
            <St.WrapperCamera show={cam2DocumentsIsOpen}>
              <Camera
                open={cam2DocumentsIsOpen}
                onTakePhoto={(img) => onTakeDocumentPicture(img, 2)}
                handleClose={() => setCam2DocumentsIsOpen(false)}
                maskDocumentIncomeAddress
              />
            </St.WrapperCamera>
          </St.Picture>
          <St.TopSpace>
            <St.Title>Foto dos documentos</St.Title>
            <St.Subtitle>
              Precisamos que você tire uma foto de alguns documentos . Isso
              aumenta tuas chances para aprovar as ofertas de empréstimo.{" "}
            </St.Subtitle>
          </St.TopSpace>
          <St.BoxCenter>
            <St.Title
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {!documentsPicture1
                ? "Prepare o teu comprovante de endereço"
                : "Prepare o teu comprovante de renda"}
            </St.Title>
            <img src={assets.png.documentsPhoto} alt="foto documento" />
          </St.BoxCenter>
          <St.BoxDescription>
            <div>
              <img src={assets.png.checkCircle} alt="check" />
              <span>Escolha um local bem iluminado.</span>
            </div>
            <div>
              <img src={assets.png.checkCircle} alt="check" />
              <span>Posicione-se no local indicado pela câmara.</span>
            </div>
            <div>
              <img src={assets.png.checkCircle} alt="check" />
              <span>Gire a camera na diagonal, se for preciso. </span>
            </div>
          </St.BoxDescription>
          <St.Footer>
            <Button
              buttonType="primary"
              text="Tirar foto"
              onClick={() =>
                !documentsPicture1
                  ? toggleCameraDocuments(1)
                  : toggleCameraDocuments(2)
              }
            >
              {imageIsLoading === IMAGE_TYPES.RESIDENTIAL ||
                (imageIsLoading === IMAGE_TYPES.INCOME && (
                  <Spinner color="#fff" />
                ))}
            </Button>
          </St.Footer>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default RequestDocumentsLoanSeeker;
