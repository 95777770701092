export const options = [
  { id: 'formalJobYes', value: 'Sim', name: 'formalJob', label: 'Sim' },
  { id: 'formalJobNot', value: 'Não', name: 'formalJob', label: 'Não' }
]

export const optionsUfs = [
  { value: 'disabled', label: 'Selecione' },
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
  { value: 'DF', label: 'Distrito Federal' }
]

export const groups = [
  // { value: 'disabled', label: 'Selecione' },
  { value: 'M', label: 'Masculino' },
  { value: 'F', label: 'Feminino' }
]

export const optionsDate = [
  { id: '1', value: '1', label: '1' },
  { id: '5', value: '5', label: '5' },
  { id: '10', value: '10', label: '10' },
  { id: '20', value: '20', label: '20' },
  { id: '25', value: '25', label: '25' }
]
export const gridTemplateCadComplete = (theme) => {
  const {
    screen: { screenXS, screenSM }
  } = theme
  return `
    grid-template: ${`
      "userFullName"
      "cpf"
      "mobilePhone"
      "birthDate"
      "email"
      "zipCode"
      "address"
      "number"
      "complement"
      "city"
      "neighborhood"
      "uf"
      "gender"
      "occupation"
      "income"
      "company"
      "originRegister"
      "store"
      "status"
      "typist"
      "dateRegister"
      "career"
      "text1"
      "formalJobYes"
      "formalJobNot"
      "text2"
      "vehiclesYes"
      "vehiclesNot"
      "text3"
      "propertiesYes"
      "propertiesNot"
      "text4"
      "familyProgramYes"
      "familyProgramNot"
      "text5"
      "revalidationYes"
      "revalidationNot"
      "creditCard"
      "termsAccepted"
    `};

    @media screen {
      @media (min-width: ${screenXS}) {
        grid-template: ${`
          "userFullName userFullName"
          "cpf cpf"
          "mobilePhone mobilePhone"
          "birthDate birthDate"
          "email email"
          "zipCode zipCode"
          "address address"
          "number number"
          "complement complement"
          "city city"
          "neighborhood neighborhood"
          "uf uf"
          "gender gender"
          "occupation occupation"
          "income income"
          "company company"
          "originRegister originRegister"
          "store store"
          "status status"
          "typist typist"
          "dateRegister dateRegister"
          "formalJob formalJob"
          "career career"
          "termsAccepted termsAccepted"
        `};
        grid-template-columns: 1fr 1fr;
        justify-content: start;
      }

      @media screen {
        @media (min-width: ${screenSM}) {
          grid-template: ${`
          "userFullName userFullName userFullName userFullName userFullName"
          "cpf cpf cpf cpf cpf"
          "mobilePhone mobilePhone mobilePhone mobilePhone mobilePhone"
          "birthDate birthDate birthDate birthDate birthDate"
          "email email email email email"
          "zipCode zipCode zipCode zipCode zipCode"
          "address address address address address"
          "number number complement complement complement"
          "city city neighborhood neighborhood neighborhood"
          "gender gender gender gender gender"
          "occupation occupation occupation occupation occupation"
          "income income income income income"
          "company company company company company"
          "originRegister originRegister originRegister originRegister originRegister"
          "store store store store store"
          "status status status status status"
          "typist typist typist typist typist"
          "dateRegister dateRegister dateRegister dateRegister dateRegister"
          "formalJob formalJob formalJob formalJob formalJob"
          "career career career career career"
          "termsAccepted termsAccepted termsAccepted termsAccepted termsAccepted "
          `};
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          justify-content: start;
        }
      }
   `
}
