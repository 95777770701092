import React, { useEffect, useState } from "react";
import * as St from "./styles";

const Chronometer = ({ reset }) => {
  const [chronometer, setChronometer] = useState(8);
  const [chronometerInterval, setChronometerInterval] = useState(0);

  const startChronometer = () => {
    setChronometer(8);
    const interval = setInterval(() => {
      setChronometer((prev) => prev - 1);
    }, 1000);

    setChronometerInterval(interval);
  };

  useEffect(() => {
    if (reset) {
      startChronometer();
    }
  }, [reset]);

  useEffect(() => {
    if (chronometer === 0) {
      clearInterval(chronometerInterval);
    }
  }, [chronometer, chronometerInterval]);

  return <St.Chronometer>{chronometer}</St.Chronometer>;
};

export default Chronometer;
