import styled from "styled-components";
import media from "styled-media-query";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 3rem;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const Title = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const BoxGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  width: 100%;
  gap: 3rem;
`;

export const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;

  width: 400px;
  height: 410px;
  border: 1px solid #739942;
  border-radius: 8px;

  header {
    display: flex;
    justify-content: center;
    color: #fff;
    background: #739942;
    padding: 8px 12px;

    h1 {
      font-size: 16px;
    }
  }

  div {
    padding: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title-box {
      h2 {
        font-size: 24px;
        font-weight: 400;
      }
    }

    .value-box {
      border-bottom: 1px solid #739942;

      span {
        font-size: 18px;
        color: #424242;
      }

      strong {
        font-size: 34px;
        color: #424242;
        font-weight: 400;
      }

      p {
        font-size: 18px;
        font-weight: 400;
      }
    }

    .footer {
      margin-top: 20px;
      width: 100%;

      .button {
        height: 44px;
        font-size: 16px;
        font-weight: bold;
        background: #739942;
        border-radius: 8px;
        color: #fff;
        width: 90%;
        padding: 8px 12px;
      }
    }
  }
`;

export const Footer = styled.div`
  margin-top: 25px;
  position: relative;
  bottom: 0;
  width: 100%;

  @media (max-width: 712px) {
    padding: 20px;
    text-align: center;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`;
