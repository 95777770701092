import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  grid-area: ${({ name }) => name};
  /* height: 58px; */
  /* margin-bottom: 50px; */
`

export const Field = styled.div`
  grid-area: ${({ name }) => name};
  height: 58px;

  input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  }

  input:-webkit-autofill {
  -webkit-text-fill-color: $darkGray !important;
  }

  &&.checkbox {
    position: relative;
    align-items: center;
    display: inline;
    cursor: pointer;

    .termsAccepted {
      opacity: 0;
      margin-bottom: ${({
        theme: {
          spacings: { medium }
        }
      }) => medium};

      ${media.greaterThan('medium')`
        font-size: ${({
          theme: {
            sizes: { medium }
          }
        }) => medium};
    `}
    }


  input[type="checkbox"] {
    width: 1px;
    height: 1px;
    overflow: hidden;
    visibility: hidden;
    display: inline-block;
    margin: 0;
  }

  label {
    position: relative;
    margin-right: 5px;
    font-size: ${({
      theme: {
        sizes: { medium }
      }
    }) => medium};
    color:  ${({
      theme: {
        colors: { grayDark }
      }
    }) => grayDark};
    /* line-height:  ${({
      theme: {
        spacings: { small }
      }
    }) => small}; */

    &::before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 14px;
      border: 1px solid #AFAFAF;
      margin-right: 13px;
      margin-top: 8px;
      margin-bottom: -3px;
}
    }

    ${media.greaterThan('medium')`
    font-size: ${({
      theme: {
        sizes: { medium }
      }
    }) => medium};
  `}
  }

  input[type="checkbox"]:checked + label::before {
    background: #E71037;
    border: 1px solid #E71037;
  }

  input[type=checkbox]:checked + label::after {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    top:5px;
    left: 5px;
    height: 10px;
    /* top: 5px; */
    border: 2px solid #fff;
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
    box-sizing: border-box;

    ${media.greaterThan('medium')`
     top: 5px;
  `}
    }
}

`

export const Label = styled.label`
  display: block;
  font-size: ${({
    theme: {
      sizes: { small }
    }
  }) => small};
  color: ${({
    theme: {
      colors: { grayDark }
    }
  }) => grayDark};
  /* line-height: ${({
    theme: {
      spacings: { small }
    }
  }) => small}; */
  margin-bottom: 0;
  padding: 0;
  position: absolute;
  transform-origin: 0%;
  transform: translateY(-1.5rem) scale(0.95);

  ${media.greaterThan('medium')`
    font-size: ${({
      theme: {
        sizes: { small }
      }
    }) => small};
  `}
`

export const Text = styled.span`
  font-size: ${({
    theme: {
      sizes: { small }
    }
  }) => small};
  color: ${({
    theme: {
      colors: { grayDark }
    }
  }) => grayDark};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular }
    }
  }) => fontWeightRegular};
  margin-right: 4px;

  ${media.greaterThan('medium')`
    font-size: ${({
      theme: {
        sizes: { medium }
      }
    }) => medium};
  `}
`

export const TextBold = styled.strong`
  font-size: ${({
    theme: {
      sizes: { small }
    }
  }) => small};
  color: ${({
    theme: {
      colors: { primary }
    }
  }) => primary};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold }
    }
  }) => fontWeightSemiBold};
  margin-right: 4px;
  border-bottom: 1px solid
    ${({
      theme: {
        colors: { primary }
      }
    }) => primary};

  ${media.greaterThan('medium')`
    font-size: ${({
      theme: {
        sizes: { medium }
      }
    }) => medium};
  `}

  ${media.greaterThan('large')`
    font-size: ${({
      theme: {
        sizes: { medium }
      }
    }) => medium};
  `}
`
