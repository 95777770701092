import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 2%;
  position: relative;

  label {
    font-size: ${(props) => (props.largeLabel ? "18px" : "12px")};
    font-weight: ${(props) => (props.largeLabel ? 700 : 400)};
    color: ${(props) =>
      props.isFocus ? "#E71037" : "#444444"};
  }

  input {
    font-size: 16px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) =>
      props.isFocus ? "#E71037" : "#444444"};

    padding: 6px;
    color: #4A4B4A;
    height: 40px;
    min-width: 258px;
    background: #FFFFFF;
    border-radius: 7px;
  }

  .icon {
    position: absolute;
    max-width: 18px;
    right: 10px;
    top: 20px;
    cursor: pointer;
  }
`;

export const ErrorText = styled.span`
  font-size: 12px;
  color: #ED4848;
  margin-top: 2px;
`;

