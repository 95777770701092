/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as S from "./styles";
import Layout from "components/OldLayout";
import moment from "moment";
import Carousel from "components/Carousel";
import { useLocation } from "react-router-dom";
import { BoxGraphic } from "./components/boxGraphic";
import { returnMonth } from "utils/date";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import Loading from "components/Loading";
import { IconContext } from "react-icons";
import { GrRefresh } from "react-icons/gr";
import Button from "components/Button";
import { useHistory } from "react-router-dom";
import Repository from "repository";

const MyPerformance = () => {
  const [nameOperator, setNameOperator] = useState("");
  const history = useHistory();
  const [proposalRealTime, setProposalRealTime] = useState({
    dataHoraColeta: moment().format("DD/MM/YYYY"),
    analise: 0,
    aprovadas: 0,
    preAprovadas: 0,
    reprovadas: 0,
    cadastradas: 0,
  });

  const [categories, setCategories] = useState([]);
  const [serie, setSerie] = useState([{ name: "", data: [] }]);
  const [proposalMonth, setProposalMonth] = useState([]);
  const [proposalMonthSerie, setProposalMonthSerie] = useState([
    { name: "", data: [] },
  ]);
  const [benefitListDay, setBenefitListDay] = useState({
    beneficioOdonto: 0,
    beneficioSeguroAssistencia: 0,
    beneficioSeguroBolsaProtegida: 0,
    beneficioSeguroPerdaRenda: 0,
    beneficioTeucardCuida: 0,
    quantidadeTotal: 0,
    beneficioPlatinum: 0,
  });
  const [benefitListMonth, setBenefitListMonth] = useState({
    beneficioOdonto: 0,
    beneficioSeguroAssistencia: 0,
    beneficioSeguroBolsaProtegida: 0,
    beneficioSeguroPerdaRenda: 0,
    beneficioTeucardCuida: 0,
    quantidadeTotal: 0,
    beneficioPlatinum: 0,
  });
  const [benefitListRealTime, setBenefitRealTime] = useState({
    beneficioOdonto: 0,
    beneficioSeguroAssistencia: 0,
    beneficioSeguroBolsaProtegida: 0,
    beneficioSeguroPerdaRenda: 0,
    beneficioTeucardCuida: 0,
    quantidadeTotal: 0,
    beneficioPlatinum: 0,
    dataColeta: moment().format("DD/MM/YYYY"),
  });

  useEffect(() => {
      const operator = Repository.storage.get(Repository.keys.USER);
      setNameOperator(operator?.nomeTitular);
  }, []);

  const buildDateInit = () => {
    if (moment().format("DD") === "01") {
      return moment().subtract(1, "month").format("YYYY-MM-DD");
    }
    return moment().startOf("month").format("YYYY-MM-DD");
  };

  const buildDateMonth = () => {
    if (moment().format("DD") === "01") {
      return returnMonth(moment().subtract(1, "month").month());
    }
    return returnMonth(moment().month());
  };

  const RequestProposalRealTime = useApi(
    constants.endpoints.getListProposalRealTime.replace(
      "PARAM_NAME",
      nameOperator
    ),
    "GET"
  );

  const RequestProposalDay = useApi(
    constants.endpoints.getListProposalDay
      .replace("PARAM_NAME", nameOperator)
      .replace("PARAM_DATA", moment().subtract(1, "days").format("YYYY-MM-DD")),
    "GET"
  );

  const RequestProposalMonth = useApi(
    constants.endpoints.getListProposalMonth
      .replace("PARAM_NAME", nameOperator)
      .replace(
        "PARAM_DATA_FINAL",
        moment().subtract(1, "days").format("YYYY-MM-DD")
      )
      .replace("PARAM_DATA_INICIAL", buildDateInit()),
    "GET"
  );

  const RequestBenefitListDay = useApi(
    constants.endpoints.getListBenefitDay.replace("PARAM_NAME", nameOperator),
    "GET"
  );

  const RequestBenefitListMonth = useApi(
    constants.endpoints.getListBenefitMonth.replace("PARAM_NAME", nameOperator),
    "GET"
  );

  const RequestBenefitListRealTime = useApi(
    constants.endpoints.getListBenefitRealTime.replace(
      "PARAM_NAME",
      nameOperator
    ),
    "GET"
  );

  const searchBenefitListDay = async () => {
    try {
      const response = await RequestBenefitListDay.callApi();
      if (response.status === 200 && response.status < 300) {
        setBenefitListDay(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchBenefitListMonth = async () => {
    try {
      const response = await RequestBenefitListMonth.callApi();
      if (response.status === 200 && response.status < 300) {
        setBenefitListMonth(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchBenefitListRealTime = async () => {
    try {
      const response = await RequestBenefitListRealTime.callApi();
      if (response.status === 200 && response.status < 300) {
        setBenefitRealTime(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchProposalMonth = async () => {
    try {
      const response = await RequestProposalMonth.callApi();
      const { categories, series } = response?.data;
      setProposalMonth(
        categories.filter((item) => item.replace("Reprovadas", ""))
      );
      setProposalMonthSerie([
        {
          name: series[0].name,
          data: series[0].data.filter((item, index) => index !== 3),
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const searchProposalRealTime = async () => {
    try {
      const response = await RequestProposalRealTime.callApi();
      if (response.status >= 200 && response.status <= 300) {
        setProposalRealTime(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchProposalDay = async () => {
    try {
      const response = await RequestProposalDay.callApi();
      if (response.status >= 200 && response.status <= 300) {
        const { categories, series } = response?.data;
        setSerie([
          {
            name: series[0].name,
            data: series[0].data.filter((item, index) => index !== 3),
          },
        ]);
        setCategories(
          categories.filter((item) => item.replace("Reprovadas", ""))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if(nameOperator){
    searchProposalRealTime();
    searchProposalDay();
    searchProposalMonth();
    searchBenefitListDay();
    searchBenefitListMonth();
    searchBenefitListRealTime();
    }
  }, [nameOperator]);

  return (
    <S.Container>
      <Loading
        status={
          RequestProposalRealTime.loading ||
          RequestProposalDay.loading ||
          RequestProposalMonth.loading ||
          RequestBenefitListDay.loading ||
          RequestBenefitListMonth.loading ||
          RequestBenefitListRealTime.loading ||
          !nameOperator
        }
      />
      <Layout routeBack="/opcoes-acesso" title="Meu desempenho">
        <S.SubTitle>Atualização em tempo real (hoje)</S.SubTitle>
        <S.Grid>
          <S.Box style={{ width: "90%", marginLeft: 15 }}>
            <header>
              <div className="title">
                <h1>Propostas</h1>
                <span className="title__update">
                  {`Atualizado hoje às: ${moment(
                    proposalRealTime?.dataHoraColeta
                  ).format("HH:mm")}`}
                </span>
              </div>
            </header>
            <IconContext.Provider value={{ className: "react-icons" }}>
              <GrRefresh size={18} onClick={() => searchProposalRealTime()} />
            </IconContext.Provider>
            <section>
              <S.ItemsDetails>
                <article>
                  <h2>{proposalRealTime?.cadastradas}</h2>
                  <span>Cadastradas</span>
                </article>
              </S.ItemsDetails>

              <S.ItemsDetails>
                <article>
                  <h2>{proposalRealTime?.preAprovadas}</h2>
                  <span>Pré Aprovadas</span>
                </article>
              </S.ItemsDetails>

              <S.ItemsDetails>
                <article>
                  <h2>{proposalRealTime?.aprovadas}</h2>
                  <span>Aprovadas</span>
                </article>
              </S.ItemsDetails>

              {/* <S.ItemsDetails>
                <article>
                  <h2>{proposalRealTime?.reprovadas}</h2>
                  <span>Reprovadas</span>
                </article>
              </S.ItemsDetails> */}

              <S.ItemsDetails>
                <article>
                  <h2>{proposalRealTime?.analise}</h2>
                  <span>Em análise</span>
                </article>
              </S.ItemsDetails>
            </section>
          </S.Box>
          <S.Box style={{ width: '30%', marginRight: 15 }}>
            <header>
              <div className="title">
                <h1>Adesões</h1>
                <span className="title__update">
                  {`Atualizado hoje às: ${moment(
                    benefitListRealTime?.dataColeta
                  ).format("HH:mm")}`}
                </span>
              </div>
            </header>
            <IconContext.Provider value={{ className: "react-icons" }}>
              <GrRefresh
                size={18}
                onClick={() => searchBenefitListRealTime()}
              />
            </IconContext.Provider>
            <Carousel>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListRealTime?.beneficioOdonto}</h2>
                  <span>Odonto</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListRealTime?.beneficioSeguroAssistencia}</h2>
                  <span>Protege Prestamista</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListRealTime?.beneficioSeguroPerdaRenda}</h2>
                  <span>Protege Perda Renda</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListRealTime?.beneficioSeguroBolsaProtegida}</h2>
                  <span>Protege Bolsa</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListRealTime?.beneficioTeucardCuida}</h2>
                  <span>Cuida</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListRealTime?.beneficioPlatinum}</h2>
                  <span>Platinum</span>
                </article>
              </S.CarouselItem>
            </Carousel>
          </S.Box>
        </S.Grid>
        <S.SubTitle style={{marginTop: 20}}>Atualização diária (ontem)</S.SubTitle>
        <S.Grid>
          <S.BoxDetails style={{ width: "90%", marginLeft: 15 }}>
            <header>
              <h2>Propostas</h2>
              <div className="title">
                <span className="title__update">
                  {`Referente ao dia: ${moment()
                    .subtract(1, "days")
                    .format("DD/MM/YYYY")}`}
                </span>
              </div>
            </header>
            <S.ChartContainer>
              {/* <Chart
                options={options}
                series={series}
                type="bar"
                height={200}
                width={460}
              /> */}
              <BoxGraphic
                series={serie}
                categories={categories}
                height={160}
                width={380}
              />
            </S.ChartContainer>
          </S.BoxDetails>
          <S.Box style={{width: "30%", height: "50%", marginRight: 15 }}>
            <header>
              <div className="title">
                <h1>Adesões</h1>
                <span className="title__update">
                  {`Referente ao dia: ${moment()
                    .subtract(1, "days")
                    .format("DD/MM/YYYY")}`}
                </span>
              </div>
            </header>
            <IconContext.Provider value={{ className: "react-icons" }}>
              <GrRefresh size={18} onClick={() => searchBenefitListDay()} />
            </IconContext.Provider>
            <Carousel>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListDay?.beneficioOdonto}</h2>
                  <span>Odonto</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListDay?.beneficioSeguroAssistencia}</h2>
                  <span>Protege Prestamista</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListDay?.beneficioSeguroPerdaRenda}</h2>
                  <span>Protege Perda Renda</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListDay?.beneficioSeguroBolsaProtegida}</h2>
                  <span>Protege Bolsa Protegida</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListDay?.beneficioTeucardCuida}</h2>
                  <span>Cuida</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListDay?.beneficioPlatinum}</h2>
                  <span>Platinum</span>
                </article>
              </S.CarouselItem>
            </Carousel>
          </S.Box>
        </S.Grid>
        <br/>
        <S.SubTitle>Atualização mensal (acumulado até ontem)</S.SubTitle>
        <S.Grid>
          <S.BoxDetails style={{ width: "90%", marginLeft: 15 }}>
            <header>
              <h2>Propostas</h2>
              <span className="title__update">
                {`Referente do mês de ${buildDateMonth()}`}
              </span>
            </header>

            <S.ChartContainer>
              {/* <Chart
                options={options}
                series={series}
                type="bar"
                height={200}
                width={460}
              /> */}
              <BoxGraphic
                series={proposalMonthSerie}
                categories={proposalMonth}
                height={160}
                width={380}
              />
            </S.ChartContainer>
          </S.BoxDetails>
          <S.Box style={{ height: "70%", width: '30%', marginRight: 15 }}>
            <header>
              <div className="title">
                <h1>Adesões</h1>
                <span className="title__update">
                  {`Referente do mês de ${buildDateMonth()}`}
                </span>
              </div>
            </header>

            <IconContext.Provider value={{ className: "react-icons" }}>
              <GrRefresh size={18} onClick={() => searchBenefitListMonth()} />
            </IconContext.Provider>

            <Carousel>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListMonth.beneficioOdonto}</h2>
                  <span>Odonto</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListMonth?.beneficioSeguroAssistencia}</h2>
                  <span>Protege Prestamista</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListMonth?.beneficioSeguroPerdaRenda}</h2>
                  <span>Protege Perda Renda</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListMonth?.beneficioSeguroBolsaProtegida}</h2>
                  <span>Protege Bolsa</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListMonth?.beneficioTeucardCuida}</h2>
                  <span>Cuida</span>
                </article>
              </S.CarouselItem>
              <S.CarouselItem>
                <article>
                  <h2>{benefitListMonth?.beneficioPlatinum}</h2>
                  <span>Platinum</span>
                </article>
              </S.CarouselItem>
            </Carousel>
          </S.Box>
        </S.Grid>
          <br/>
          <br/>
          <br/>
          <br/>
        <Button
            buttonType="primary"
            text="Histórico Mensal"
            onClick={() => history.push("/historico-mensal")}
          />
      </Layout>
    </S.Container>
  );
};

export default MyPerformance;
