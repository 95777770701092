/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import Button from "components/Button";
import Input from "components/Input";
import { useHistory } from "react-router-dom";
import InputSelect from "components/InputSelect";
import { useState } from "react";
import { useEffect } from "react";
import { useLoan } from "hooks/useLoan";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import InputSelectSearch from "components/SelectSearch/inputSelect";
import { accountBankMask } from "utils/mask";
import OneButtonPopup from "components/modals/OneButtonPopup";
import themes from "styles";

const inputStyle = {
  border: "1px solid #6c6c6c",
  padding: "1.7rem",
  borderRadius: ".5rem",
  width: "100%",
};

const WithDrawBankData = () => {
  const {
    userInitialData,
    dispatchFinishResponse,
    userAditionalData,
    solicitationData,
  } = useLoan();

  const [checked, setChecked] = useState(false);
  const [banks, setBanks] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dialogOpenError, setDialogOpenError] = useState(false);

  const [filteredBanks, setFilteredBanks] = useState(banks);
  const history = useHistory();
  const initialState = {
    banco: "",
    agencia: "",
    conta: "",
    contaDigito: "",
    tipoChave: "CPF_CNPJ",
    chave: userInitialData?.cpf?.unmasked,
    numberBank: "",
    tipoConta: "",
  };

  const [data, dispatch] = useReducer(reducer, initialState);
  const { banco, agencia, conta, contaDigito, tipoChave, chave, tipoConta } =
    data;
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  function reducer(data, action) {
    const { type, payload } = action;
    return { ...data, [type]: payload };
  }

  useEffect(() => {
    if (checked) {
      dispatch({ type: "tipoChave", payload: "" });
      dispatch({ type: "chave", payload: "" });
    }
  }, [checked]);

  const putUpdateBaninkingData = useApi(
    constants.endpoints.putUpdateBankingDataWithdraw.replace(
      "ID_LOAN",
      solicitationData?.idSimulacao
    ),
    "PUT"
  );

  useEffect(() => {
    if (tipoChave !== "CPF") {
      dispatch({ type: "chave", payload: "" });
    } else {
      dispatch({ type: "chave", payload: userInitialData?.cpf?.unmasked });
    }
  }, [tipoChave]);

  const onChange = (e, mask) => {
    const {
      target: { name, value },
    } = e;
    dispatch({ type: name, payload: mask ? mask(value) : value });
  };

  const validateInputs = () => {
    return (
      !banco ||
      !agencia ||
      !conta ||
      !contaDigito ||
      (!tipoChave && !checked) ||
      (!chave && !checked)
    );
  };
  const pixType = [
    { value: "nenhuma", label: "", hidden: true },
    { value: "CPF", label: "CPF / CNPJ", selected: true },
    { value: "EMAIL", label: "E-Mail" },
    { value: "CELULAR", label: "Celular" },
    { value: "CHAVE_ALEATORIA", label: "Aleatória" },
  ];
  const accountType = [
    { value: "nenhuma", label: "", hidden: true },
    { value: "CONTA_CORRENTE", label: "Conta Corrente" },
    { value: "CONTA_POUPANCA", label: "Conta Poupança" },
  ];

  const GetBanks = useApi(constants.endpoints.getBanks, "GET");

  useEffect(() => {
    const getBanks = async () => {
      const { data } = await GetBanks.callApi();
      if (data.bancos) {
        const ordenedBanks = data?.bancos
          ?.sort((a, b) => Number(b.code) + Number(a.code))
          ?.map((e) => ({
            value: e.code,
            label: `${e.code} - ${e.fullName}`,
            name: e.name,
            code: e.code,
          }));
        setBanks(ordenedBanks);
        setFilteredBanks(ordenedBanks);
      }
    };
    getBanks();
  }, []);

  const { cpf } = userInitialData;

  const handleSubmitLoan = async () => {
    setButtonIsLoading(true);

    try {
      const payload = {
        agencia: agencia?.split("-")[0],
        chavePix: checked ? "" : chave,
        codigoBanco: banco?.code,
        contaDigito: contaDigito,
        contaSemDigito: conta,
        tipoChavePix: checked ? null : tipoChave,
        tipoConta: tipoConta,
      };

      const response = await putUpdateBaninkingData.callApi(payload);

      if (response.status >= 200 && response.status < 300) {
        setButtonIsLoading(false);
        dispatch({ type: "banco", payload: payload });
        history.push("/with-draw-kyc-validation");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleFilterBanks = (e) => {
    const { value } = e.target;
    dispatch({ type: "banco", payload: value });
    setFilteredBanks(
      banks.filter((bank) =>
        bank?.label?.toLowerCase().trim().includes(value?.toLowerCase())
      )
    );
  };
  return (
    <St.SafeView>
      <Layout routeBack="/with-draw-additional-data" title="Dados bancários">
        <St.Container>
          <OneButtonPopup
            text={errorMessage}
            buttonText="Entendi"
            title={"Atenção!"}
            visibility={dialogOpenError}
            iconModal={themes.components.png.emojiSad}
            defaultButton
            setVisibility={setDialogOpenError}
            onClose={() => history.push("/opcoes-acesso")}
          />
          <St.TopSpace>
            <St.Title>
              Agora precisamos de informações bancárias (pra onde o Teu
              empréstimo vai!):
            </St.Title>
            <St.Inputs>
              <InputSelectSearch
                labelFontWeight="bold"
                name="banco"
                disabled={checked}
                options={filteredBanks}
                value={banco?.label}
                id="banco"
                inputTextValue={banco?.label}
                defaultValue={banco?.label}
                onFocus={() => setOpenSelect(true)}
                style={inputStyle}
                label="Código e Descrição do Banco*"
                onChange={handleFilterBanks}
                onClickSelect={(e) => dispatch({ type: "banco", payload: e })}
                open={openSelect}
                setOpen={setOpenSelect}
              />

              <Input
                boldLabel
                style={inputStyle}
                name="agencia"
                placeholder=""
                type="text"
                value={agencia}
                onChange={(e) => onChange(e)}
                label="Agência (sem dígito)*"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "65%" }}>
                  <Input
                    name="conta"
                    value={conta}
                    onChange={(e) => onChange(e)}
                    boldLabel
                    style={inputStyle}
                    label="Conta*"
                    max={12}
                  />
                </div>
                <div>
                  <Input
                    name="contaDigito"
                    value={contaDigito}
                    onChange={(e) => onChange(e)}
                    boldLabel
                    style={inputStyle}
                    label="Digito*"
                    max={1}
                  />
                </div>
              </div>
              <InputSelect
                labelFontWeight="bold"
                type="select"
                name="tipoConta"
                values={accountType}
                value={tipoConta}
                defaultValue={tipoConta}
                style={{ ...inputStyle, padding: ".5rem 1rem" }}
                label="Tipo de conta*"
                max={15}
                onChange={(e) => onChange(e)}
              />

              <InputSelect
                labelFontWeight="bold"
                type="select"
                name="tipoChave"
                disabled={checked}
                values={pixType}
                value={tipoChave}
                defaultValue={tipoChave}
                style={{ ...inputStyle, padding: ".5rem 1rem" }}
                label="Tipo de chave PIX*"
                onChange={(e) => onChange(e)}
              />

              <Input
                disabled={checked}
                boldLabel
                name="chave"
                style={inputStyle}
                label="Chave PIX*"
                value={chave}
                onChange={(e) => onChange(e)}
              />
            </St.Inputs>

            <St.Footer>
              <Button
                loading={buttonIsLoading}
                style={{ width: "100%", justifySelf: "flex-end" }}
                buttonType="primary"
                text={"Finalizar"}
                onClick={() => handleSubmitLoan()}
                disabled={validateInputs() || putUpdateBaninkingData.loading}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default WithDrawBankData;
