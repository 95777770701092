import React, { useState, createContext} from 'react'

export const SignatureContext = createContext({});

export const SignatureProvider = ({ children }) => {
  const [ imageURL, setImageURL ] = useState(null);

  return (
      <SignatureContext.Provider value={{ imageURL, setImageURL }} >
          {children}
      </SignatureContext.Provider>
  );
};
