/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useReactRouter from "use-react-router";

import Button from "components/Button";
import ButtonBack from "components/ButtonBack";
import Form from "components/Forms";
import Heading from "components/Heading";
import Input from "components/Input";
import OneButtonPopup from "components/modals/OneButtonPopup";
import Spinner from "components/Spinner";

import useApi from "hooks/useApi";
import constants from "utils/constants";
import { hideEmailMask } from "utils/mask";
import styles from "../../styles";
import { gridTemplateForgotEmail } from "./constants";
import * as S from "./styles";

const ForgotPassEmail = () => {
  const { history } = useReactRouter();
  const [msgDailog, setMsgDailog] = useState("");
  const [confirmationPopupVisibility, setOneButtonPopupVisibility] =
    useState(false);
  const [confirmationPopupVisibilityFeed, setOneButtonPopupVisibilityFeed] =
    useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { state } = useLocation();

  const GetClientByCpf = useApi(
    constants.endpoints.getClientByCpf.replace("PARAM_CPF", state?.cpf),
    "GET"
  );
  const SendLinkRedefinePassword = useApi(
    constants.endpoints.sendLinkRedefinePassword.replace("PARAM_EMAIL", data?.email).replace("PARAM_ID", data?.id),
    "POST"
  );

  useEffect(() => {
    (async () => {
      try {
        const { data } = await GetClientByCpf.callApi();
        setData(data);
      } catch (err) {
        setOneButtonPopupVisibilityFeed(true);
        setMsgDailog(err.response?.data.mensagem || err.message);
      }
    })();
  }, []);

  async function handleSubmit() {
    setLoading(true);

    try {
      SendLinkRedefinePassword.callApi();

      setLoading(false);
      setOneButtonPopupVisibility(true);
    } catch (err) {
      setOneButtonPopupVisibilityFeed(true);
      setMsgDailog(err.response?.data.mensagem || err.response?.status);
    } finally {
      setLoading(false);
    }
  }

  return (
    <S.Wrapper>
      <S.WrapperDivision>
        <S.Header>
          <ButtonBack onClick={() => history.goBack()} />
          <Heading variation="tertiary">Esqueci minha senha</Heading>
        </S.Header>
        <S.Text>
          Orientações serão enviadas para o e-mail de cadastro abaixo:
        </S.Text>
        <Form gridTemplate={gridTemplateForgotEmail}>
          <Input
            id="email"
            name="email"
            label="E-mail"
            type="text"
            value={hideEmailMask(data.email)}
            disabled
          />
        </Form>
      </S.WrapperDivision>
      <Button
        text={isLoading === true ? <Spinner color="#FFF" /> : "Continuar"}
        buttonType="primary"
        onClick={handleSubmit}
      />
      <OneButtonPopup
        defaultButton
        text={"E-mail enviado com sucesso!"}
        buttonText="Entendi"
        visibility={confirmationPopupVisibility}
        setVisibility={(val) => {
          setOneButtonPopupVisibility(val);
          history.push("/");
        }}
      />
      <OneButtonPopup
        defaultButton
        img={styles.components.png.warning}
        text={msgDailog}
        buttonText="Entendi"
        visibility={confirmationPopupVisibilityFeed}
        setVisibility={(e) => {
          setOneButtonPopupVisibilityFeed(e);
          history.push("/");
        }}
      />
    </S.Wrapper>
  );
};

export default ForgotPassEmail;
