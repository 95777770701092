import styled from 'styled-components'
// import { Link as RouterLink } from 'react-router-dom'
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack'
import media from 'styled-media-query'

export const Wrapper = styled.div`
  padding: 30px;
`

export const Infos = styled.div`

`

export const Header = styled.div`
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };
  align-items: center;
  margin-bottom: ${({ theme: { spacings: {  medium }}}) =>  medium };
`

export const IconArrow = styled(ArrowBack)`
  width: 20px;
  height: 23px;
  position: absolute;

  path {
    fill: ${({ theme: { colors: { gray }}}) => gray };
  }
`

export const Signature = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 220px;
  border: 1px solid #E71037;
  border-radius: 3px;
  background-color: rgba(205, 46, 75, 0.2);
  padding: 20px;
  margin-bottom: ${({ theme: { spacings: {  xsmall }}}) =>  xsmall };

  .signatureCanvas {
    width: 100%;
    margin: auto;
    height: 80%;
    border-bottom: 1px dashed  #E71037;
  }
`

export const Text = styled.p`
  text-align: center;
  font-size: ${({theme: {sizes: { small }}}) => small };
  font-weight: ${({theme: { fontWeight: { fontWeightRegular }}}) => fontWeightRegular };
  color:  ${({ theme: { colors: { blackLight }}}) => blackLight };
  line-height:${({ theme: { lineHeight: {  lineHeightUL }}}) =>  lineHeightUL };
  margin-bottom: ${({ theme: { spacings: {  xxlarge }}}) =>  xxlarge };

  ${media.greaterThan("medium")`
    font-size: ${({theme: {sizes: { medium }}}) => medium };
  `}

`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  .button {
    width: 48%;
    margin: 10px;
  }

`
