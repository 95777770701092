const theme = {
  borderRadius: {
    borderRadiusNone: '0',
    borderRadiusSM: '4px',
    borderRadiusMD: '8px',
    borderRadiusLG: '16px',
    borderRadiusXL: '24px',
    borderRadiusPill: '500px',
    borderRadiusCircle: '50%',
  },
  font: {
    family:
      "Open Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  },
  fontWeight: {
    fontWeightLight: '300',
    fontWeightRegular: '400',
    fontWeightSemiBold: '600',
    fontWeightBold: '700',
  },
  sizes: {
    xsmall: '1.2rem',
    small: '1.4rem',
    medium: '1.6rem',
    large: '1.8rem',
    xlarge: '2.0rem',
    xxlarge: '2.2rem',
    xxxlarge: '2.8rem'
  },
  lineHeight: {
    lineHeightSM: '100%',
    lineHeightMD: '110%',
    lineHeightLG: '120%',
    lineHeightUL: '150%'
  },
  colors: {
    primary: '#E71037',
    secundary: '#f3879b',
    secondary: '#f3879b',
    tertiary: '#FFFFFF',
    black: '#000',
    blackLight: '#4A4B4A',
    grayLight: '#AFAFAF',
    gray: '#828282',
    grayDark: '#757575',
    white: '#FFF',
    whiteLigth: '#DCDCDC',
    red: '#ED4848',
    error: '#ff1a1a',
    green:'#00A857'
  },
  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem'
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50
  },
  screen: {
    screenXS: '425px',
    screenSM: '768px',
    screenMD: '1000px',
    screenLG: '1400px',
    screenXL: '2560px'
  },
}

export default theme
