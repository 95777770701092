import React from 'react'
import * as S from './styles'
import themes from 'styles'

const Logo = () => {
  return(
    <S.Wrapper>
      <S.Img src={themes.components.png.opah} alt='Logo Opah It' />

    </S.Wrapper>
  )
}

export default Logo
