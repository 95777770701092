import React from "react";
import { useState } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import Button from "components/Button";
import themes from "styles";
import { useHistory } from "react-router-dom";
import InputSelect from "components/InputSelect";
import OneButtonPopup from "components/modals/OneButtonPopup";

const inputStyle = {
  border: "1px solid #6c6c6c",
  borderRadius: ".5rem",
  width: "100%",
  height: "3vh",
};

const CreditCardLoanSimulator = () => {
  const [checked, setChecked] = useState(false);
  const [flags, setFlags] = useState("disabled");
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    components: { png },
  } = themes;
  const history = useHistory();

  const flagsOptions = [
    { value: "disabled", label: "Selecione" },
    { value: "visa", label: "Visa" },
    { value: "mastercard", label: "Mastercard" },
    { value: "elo", label: "Elo" },
    { value: "hipercard", label: "Hipercard" },
    { value: "amex", label: "American Express" },
    { value: "teucard", label: "TeuCard" },
  ];

  // Teste pipline
  const handleClick = () => {
    if (flags === "teucard") {
      setDialogOpen(true);
    } else {
      history.push("/credit-card-loan-simulator-data-confirmation");
    }
  };
  return (
    <St.SafeView>
      <Layout
        routeBack="/paymentSimulator"
        title="Bem-vindo(a) a tua área de empréstimo com cartão de crédito"
      >
        <St.Container>
          <OneButtonPopup
            text="Ops!! Empréstimo não disponível com TeuCard. Utilize um cartão bandeirado. Exemplo: Mastercard, Visa e Elo."
            buttonText="Entendi"
            title="Que pena!"
            visibility={dialogOpen}
            defaultButton
            onClose={() => setDialogOpen(false)}
            setVisibility={setDialogOpen}
          />
          <St.TopSpace>
            <St.Cards>
              <img style={{ marginLeft: "50%" }} src={png.masterCardFull}></img>
              <img src={png.visaFull}></img>
              <img style={{ marginRight: "50%" }} src={png.eloFull}></img>
              <img style={{ marginLeft: "120%" }} src={png.hiperCardFull}></img>
              <img src={""}></img>
              <img
                style={{ marginRight: "120%" }}
                src={png.americanExpressFull}
              ></img>
            </St.Cards>

            <St.MiddleContainer>
              <St.Rounded />
              <p style={{ width: "30vw" }}>
                É de extrema importância que você possua{" "}
                <b>Limite de Crédito Disponível hoje</b> para continuar a
                operação!{" "}
              </p>
            </St.MiddleContainer>
            <St.SelectContainer>
              <St.Select>
                <InputSelect
                  name="flag"
                  id="flag"
                  style={{ ...inputStyle, padding: ".5rem 1rem" }}
                  label="Qual cartão você vai utilizar?"
                  type="select"
                  values={flagsOptions}
                  value={flags}
                  defaultValue={flags}
                  onChange={(e) => setFlags(e.target.value)}
                />
              </St.Select>
            </St.SelectContainer>

            <div>
              <St.DivCheck>
                <input
                  value={checked}
                  type="checkbox"
                  onChange={(e) => setChecked(e.target.checked)}
                />
                <span>
                  Declaro que possuo cartão de crédito com limite disponível
                  para o dia de hoje.
                </span>
              </St.DivCheck>
            </div>

            <St.Footer>
              <Button
                buttonType="primary"
                text={"Avançar"}
                onClick={handleClick}
                disabled={checked && flags !== "disabled" ? false : true}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default CreditCardLoanSimulator;
