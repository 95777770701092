import React from "react";
import * as St from "./styles";
const Installment = ({ onChange, installment, label }) => {
  return (
    <St.Container>
      <St.Label>{label}</St.Label>
      <St.Content>
        <St.ButtonRounded
          onClick={() => {
            if (installment > 1) onChange(installment - 1);
          }}
        >
          <span>-</span>
        </St.ButtonRounded>
        <strong>{installment}</strong>
        <St.ButtonRounded
          onClick={() => {
            if (installment < 12) onChange(installment + 1);
          }}
        >
          <span>+</span>
        </St.ButtonRounded>
      </St.Content>
    </St.Container>
  );
};

export default Installment;
