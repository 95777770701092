import React, { useReducer } from "react";
import { useHistory } from "react-router-dom";

import Layout from "components/OldLayout";
import Input from "components/Input";
import InputSelect from "components/InputSelect";
import Button from "components/Button";

import { useLoan } from "hooks/useLoan";

import * as St from "./styles";

const inputStyle = {
  border: "1px solid #6c6c6c",
  padding: "1.7rem",
  borderRadius: ".5rem",
  width: "100%",
};

const ComplementaryData = () => {
  const initialState = {
    mother: "",
    maritalStatus: "",
    education: "",
    gender: "",
    loanReason: "",
  };

  const [data, dispatch] = useReducer(reducer, initialState);
  const { mother, maritalStatus, education, loanReason, gender } = data;

  const { dispatchComplementaryData, userAditionalData } = useLoan();

  console.log(userAditionalData);

  const history = useHistory();

  function reducer(state, action) {
    const { type, payload } = action;
    return { ...state, [type]: payload };
  }

  const maritalStatusList = [
    { value: "disabled", label: "Selecione", hidden: true },
    { value: "CASADO", label: "Casado(a)" },
    { value: "DIVORCIADO", label: "Divorciado(a)" },
    { value: "VIUVO", label: "Viuvo(a)" },
    { value: "SEPARADO", label: "Separado(a)" },
    { value: "SOLTEIRO", label: "Solteiro(a)" },
    { value: "COMPANHEIRO", label: "Companheiro(a)" },
    { value: "UNIAO_ESTAVEL", label: "União estavel" },
    { value: "SEPARADO_JUDICIALMENTE", label: "Separado Judicialmente" },
  ];

  const educationList = [
    { value: "disabled", label: "Selecione", hidden: true },
    { value: "NAO_ALFABETIZADO", label: "Não alfabetizado" },
    {
      value: "ENSINO_FUNDAMENTAL_INCOMPLETO",
      label: "Ensino Fundamental Incompleto",
    },
    {
      value: "ENSINO_FUNDAMENTAL_COMPLETO",
      label: "Ensino Fundamental Completo",
    },
    { value: "ENSINO_MEDIO_INCOMPLETO", label: "Ensino Médio Incompleto" },
    { value: "ENSINO_MEDIO_COMPLETO", label: "Ensino Médio Completo" },
    {
      value: "ENSINO_SUPERIOR_INCOMPLETO",
      label: "Ensino superior incompleto",
    },
    { value: "ENSINO_SUPERIOR_COMPLETO", label: "Ensino superior completo" },
    { value: "POS_GRADUACAO", label: "Pós-graduação" },
  ];

  const reasonLoanList = [
    { value: "disabled", label: "Selecione", hidden: true },
    { value: "PAGAR_CONTAS", label: "Pagar Contas" },
    { value: "NOVO_NEGOCIO", label: "Começar um novo negócio" },
    { value: "DIVIDAS", label: "Pagar outras dívidas" },
    { value: "COMPRAS", label: "Fazer compras" },
    { value: "COMPRAR/REFORMAR_CASA", label: "Comprar ou reformar uma casa" },
    { value: "EDUCACAO", label: "Educação" },
    { value: "AJUDA", label: "Ajudar outra pessoa da família" },
    {
      value: "COMPRAR/CONSERTAR_CARRO",
      label: "Comprar ou consertar um carro",
    },
    { value: "SAUDE/BELEZA", label: "Beleza e saúde" },
    { value: "FERIAS/VIAGENS", label: "Férias e viagens" },
    { value: "EVENTOS/FESTAS", label: "Eventos e festas" },
  ];

  const genderList = [
    { value: "disabled", label: "Selecione", hidden: true },
    { value: "MASCULINO", label: "Masculino" },
    { value: "FEMININO", label: "Feminino" },
  ];

  const onChange = (e) => {
    const {
      target: { name, value },
    } = e;

    const updateValue = value;
    dispatchComplementaryData(data);

    dispatch({ type: name, payload: updateValue });
  };

  const valid = () => {
    return !mother || !maritalStatus || !loanReason || !gender || !education;
  };

  const handleNext = () => {
    try {
      dispatchComplementaryData(data);
      history.push("/identification-data-loan-seeker");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <St.SafeView>
      <Layout
        routeBack="/additional-data-loan-seeker"
        title="Informe teus dados complementares"
      >
        <St.Container>
          <St.TopSpace>
            <Input
              boldLabel
              style={inputStyle}
              label="Nome da Mãe*"
              value={mother}
              id="mother"
              name="mother"
              onChange={(e) => onChange(e)}
            />
            <InputSelect
              labelFontWeight="bold"
              label="Estado Civil*"
              type="select"
              name="maritalStatus"
              style={{ ...inputStyle, padding: ".5rem 1rem" }}
              values={maritalStatusList}
              value={maritalStatus}
              defaultValue={maritalStatus}
              onChange={(e) => onChange(e)}
            />
            <InputSelect
              labelFontWeight="bold"
              label="Escolaridade"
              type="select"
              name="education"
              style={{ ...inputStyle, padding: ".5rem 1rem" }}
              values={educationList}
              value={education}
              defaultValue={education}
              onChange={(e) => onChange(e)}
            />
            <InputSelect
              labelFontWeight="bold"
              label="Gênero"
              type="select"
              name="gender"
              style={{ ...inputStyle, padding: ".5rem 1rem" }}
              values={genderList}
              value={gender}
              defaultValue={gender}
              onChange={(e) => onChange(e)}
            />
            <InputSelect
              labelFontWeight="bold"
              label="Motivo do empréstimo*"
              type="select"
              name="loanReason"
              style={{ ...inputStyle, padding: ".5rem 1rem" }}
              values={reasonLoanList}
              value={loanReason}
              defaultValue={loanReason}
              onChange={(e) => onChange(e)}
            />

            <St.Footer>
              <Button
                buttonType="primary"
                text="Avançar"
                disabled={valid()}
                onClick={() => handleNext()}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default ComplementaryData;
