import React, { useState } from "react";
import * as St from './styles'

function SliderBar({ value, handleChange, min, max, step }) {

    return (
      <St.Container>
        <St.Slider
          type="range"
          defaultValue={value}
          onChange={handleChange}
          min={min}
          max={max}
          step={step}
        />
      </St.Container>
    );
  }
  
export default SliderBar;