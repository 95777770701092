import styled from 'styled-components'
import media from 'styled-media-query'
import { ArrowBack } from '@styled-icons/boxicons-regular/ArrowBack'
import Button from 'components/Button'

export const Container = styled.div`
  width: 80%;
  margin: 80px 30px;
  display: flex;
  flex-direction: column;

  ${media.greaterThan('medium')`
    width: 70%;
    margin: none
  `}

  .react-datepicker__navigation {
    display: none;
  }

  .react-datepicker {
    height: 200px;
    left: 110%;

    ${media.greaterThan('large')`
      left:150%;
      margin: none
  `}
  }

  .react-datepicker__header {
    display: none;
  }
`

export const Header = styled.div`
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL }
    }
  }) => lineHeightUL};
  align-items: center;
  margin-bottom: ${({
    theme: {
      spacings: { medium }
    }
  }) => medium};
`

export const IconArrow = styled(ArrowBack)`
  width: 20px;
  height: 23px;
  position: absolute;

  path {
    fill: ${({
      theme: {
        colors: { gray }
      }
    }) => gray};
  }
`

export const TextBold = styled.p`
  text-align: left;
  font-size: ${({
    theme: {
      sizes: { small }
    }
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold }
    }
  }) => fontWeightSemiBold};
  color: ${({
    theme: {
      colors: { blackLight }
    }
  }) => blackLight};
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL }
    }
  }) => lineHeightUL};
  margin-bottom: ${({
    theme: {
      spacings: { xxlarge }
    }
  }) => xxlarge};

  ${media.greaterThan('medium')`
    font-size: ${({
      theme: {
        sizes: { medium }
      }
    }) => medium};
  `}
`
export const ContentInput = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ButtonOne = styled(Button)`
  height: 50px;
  width: 212px;
  border-radius: ${({
    theme: {
      borderRadius: { borderRadiusSM }
    }
  }) => borderRadiusSM};
  background: ${({
    theme: {
      colors: { primary }
    }
  }) => primary};
  color: ${({
    theme: {
      colors: { white }
    }
  }) => white};
  font-size: ${({
    theme: {
      sizes: { small }
    }
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold }
    }
  }) => fontWeightSemiBold};

  ${media.greaterThan('small')`
    width: 100%;
    margin: 10px 0;
  `}

  ${media.greaterThan('medium')`
    width: 70%;
    margin: 55px 45px 0;
  `}

  ${media.greaterThan('large')`
    width: 70%;
    margin: 65px 45px 0;
  `}
`
