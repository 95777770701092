/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo, useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { v4 as uuidv4 } from 'uuid';
import Button from "components/Button";
import Heading from "components/Heading";
import Input from "components/Input";
import Logo from "components/Logo";
import OneButtonPopup from "components/modals/OneButtonPopup";
import Spinner from "components/Spinner";

import { cpfMask, verificationCodeMask } from "utils/mask";
import styles from "../../styles";
import * as S from "./styles";
import { AuthContext } from "context/AuthContext";
import Repository from "repository";
import TwoButtonPopup from "components/modals/TwoButtonPopup";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import { useCallback } from "react";
import ReactCodeInput from "react-code-input";

const Login = () => {
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationPopupVisibility, setOneButtonPopupVisibility] =
    useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [focus, setFocus] = useState(false);
  const [confirmationPopupVisibilityFeed, setOneButtonPopupVisibilityFeed] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [twoFactorAuthDialog, setTwoFactorAuthDialog] = useState(false);
  const [twoFactorAuthMessage, setTwoFactorAuthMessage] = useState('');
  const [codeModal, setCodeModal] = useState(false);
  const [verificationCode, setVerificationCode] = useState(false);
  const { history } = useReactRouter();
  const { signInNew } = useContext(AuthContext);

  useEffect(() => {
    Repository.storage.remove(Repository.keys.TOKEN_USER);
    Repository.storage.remove(Repository.keys.TOKEN_BEARER);
  }, []);

  const CallApiSendCode = useApi(constants.endpoints.sendCode, "POST");

  const handleSubmitUseApi = async () => {
    setLoading(true);
    const sessionId = uuidv4();
    localStorage.setItem("@NovaEra:idSessao", sessionId);
    try {
      const response = await signInNew(cpf?.unmasked, password);

      if (response.status >= 200 && response.status <= 300) {
        if (
          !response?.data?.perfis?.some(
            (a) => a === "OPR" || a === "ADM" || a === "SUP"
          )
        ) {
          setErrorMessage("Você não possui permissão.");
          setOneButtonPopupVisibility(true);
        } else {
          history.push("/opcoes-acesso");
        }
      } 
      else if(response.status === 422){
        setTwoFactorAuthDialog(true);
        setTwoFactorAuthMessage(response.mensagem);
      }
      else {
        setVerificationCode('');
        localStorage.setItem("@NovaEra:idSessao", '');
        localStorage.setItem("@NovaEra:code", '');
        setErrorMessage(
          response.status === 401
            ? "Ops! Serviço indisponível no momento. Tente novamente mais tarde."
            : response.mensagem
        );
        setOneButtonPopupVisibility(true);
      }
    } catch (err) {
      setVerificationCode('');
      setCodeModal(false);
      localStorage.setItem("@NovaEra:idSessao", '');
      localStorage.setItem("@NovaEra:code", '');
      setErrorMessage(err.error);
      setOneButtonPopupVisibility(true);
    } finally {
      setCodeModal(false);
      setVerificationCode('');
      setLoading(false);
    }
  };

  const handleToggleDialog = useCallback(() => {
    setOneButtonPopupVisibility((prev) => !prev);
  }, []);


  const handleSendWhatsAppCode = async () => {
    try {
      const response = await CallApiSendCode.callApi({
        cpf: cpf?.unmasked,
      });
      if (response.status >= 200 && response.status < 300) setCodeModal(true);
      else {
        handleToggleDialog();
        setErrorMessage(response.mensagem);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTwoFactorAuthDialog(false);
    }
  };
  

  const activeButtonLogin = useMemo(
    () => cpf.masked?.length === 14 && password.length === 6,
    [cpf.masked?.length, password.length]
  );

  function handleValidForgot(evt) {
    evt.preventDefault();
    if (!cpf) {
      setError("Informar o CPF");
      setFocus(true);
      setErrorMessage(
        "Favor informar o CPF, para continuar com a recuperação de senha."
      );
      setOneButtonPopupVisibilityFeed(true);
    } else {
      setError("");
      setFocus(false);
      history.push("/email-forgot-password", { cpf: cpf.unmasked });
    }
  }

  return (
    <S.Wrapper>
      <S.WrapperLogo>
        <Logo />
      </S.WrapperLogo>
      <Heading variation="primary">
        <S.TitleBold>Bem-vindo </S.TitleBold>ao Cadastro da Proposta da TeuCard!
      </Heading>
      <Heading variation="secondary">Faça seu login para ter acesso</Heading>
      <S.LoginForm>
        <S.LoginInfo>
          <Input
            name="cpf"
            label="CPF"
            placeholder="000.000.000-00"
            value={cpf.masked}
            onChange={(e) => setCpf(cpfMask(e.target.value))}
            error={error}
            onFocus={focus}
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
          />
          <Input
            name="password"
            label="Senha"
            type="password"
            maxLength={6}
            icon={true}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            pattern="[0-9]*"
            inputmode="numeric"
          />
          <S.HiperlinkPass onClick={handleValidForgot}>
            Esqueci minha senha
          </S.HiperlinkPass>
        </S.LoginInfo>
        <Button
          text={isLoading === true ? <Spinner color="#FFF" /> : "Entrar"}
          buttonType="primary"
          disabled={!activeButtonLogin}
          active={activeButtonLogin}
          onClick={() => handleSubmitUseApi()}
        />
        <TwoButtonPopup
          text={twoFactorAuthMessage}
          visibility={twoFactorAuthDialog}
          buttonText="Sim"
          onClick={() => handleSendWhatsAppCode()}
          buttonCancelText="Não"         
          setVisibility={(val) => {
            setTwoFactorAuthDialog(val);
          }}
          loadingApi={CallApiSendCode.loading}
        />
        <OneButtonPopup
          defaultButton
          img={styles.components.png.warning}
          text={errorMessage}
          buttonText="Entendi"
          visibility={confirmationPopupVisibility}
          setVisibility={setOneButtonPopupVisibility}
        />
        <OneButtonPopup
          defaultButton
          text={"Insira o código de 6 dígitos enviado para o teu E-mail para prosseguir com o login!"}
          buttonText="Confirmar"
          visibility={codeModal}
          setVisibility={setCodeModal}
          onClose={() => {
            localStorage.setItem("@NovaEra:code", verificationCode.replace(/ /g, ''));
            handleSubmitUseApi()
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 35,
            }}
          >
            <ReactCodeInput
              type="text"
              fields={6} // número de campos no código
              value={verificationCode}
              onChange={(e) => setVerificationCode(verificationCodeMask(e))}
              name="Código de verificação"
              inputMode="tel"
            />
          </div>
        </OneButtonPopup>
        <OneButtonPopup
          defaultButton
          img={styles.components.png.warning}
          text={errorMessage}
          buttonText="Entendi"
          visibility={confirmationPopupVisibilityFeed}
          setVisibility={setOneButtonPopupVisibilityFeed}
        />
      </S.LoginForm>
      <S.Version>{process.env.REACT_APP_VERSAO_APP}</S.Version>
    </S.Wrapper>
  );
};

export default Login;
