import React from "react";
import Layout from "components/Layout";
import * as S from "./styles";
import icons from "assets";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { moneyMask } from "utils/mask";
import Button from "components/buttonNew";
export default function ProposalResume() {
  const { state } = useLocation();
  const history = useHistory();
  const propostaAprovada = state?.status === "APROVADA";
  const propostaAnalise = state?.status === "ANALISE";
  return (
    <Layout.View>
      <S.Container>
        <Layout.Title>
          {propostaAprovada
            ? "Parabéns! A tua proposta foi aprovada"
            : "Proposta em análise"}
        </Layout.Title>
        <div className="icon">
          <img alt="cardsIcon" src={icons.icons.allCards}></img>
        </div>
        {propostaAnalise && (
          <div className="details" style={{ margin: "0px 20%" }}>
            <h2>
              Aguarde os próximos passos em teu e-mail ou entre em contato pelo
              número 4003-3203
            </h2>
            <h2 style={{ textAlign: "center" }}>TeuCard agradece!</h2>
          </div>
        )}
        {propostaAprovada && (
          <div className="details">
            <>
              <h2>Confira o resumo da tua proposta:</h2>
              <div>Nome: {state?.nome}</div>
              <div>E-mail: {state?.email}</div>
              <div>Dia do vencimento da fatura: {state?.vencimento}</div>
              <div>
                Limite de crédito total:{" "}
                {moneyMask(state?.limiteSaque + state?.limiteCompras)}
              </div>
              <div>
                Limite de crédito para compras:{" "}
                {moneyMask(state?.limiteCompras)}
              </div>
              <div>
                Limite de crédito para empréstimo:{" "}
                {moneyMask(state?.limiteSaque)}
              </div>
            </>
          </div>
        )}
        <div className="button">
          <Button
            style={{ padding: "24px" }}
            size="large"
            fullWidth
            onClick={() => {
              if (propostaAprovada) {
                history.push("/beneficios", {
                  ...state,
                });
                return;
              }
              if (propostaAnalise) {
                history.push("/opcoes-acesso");
              }
            }}
          >
            OK
          </Button>
        </div>
      </S.Container>
    </Layout.View>
  );
}
