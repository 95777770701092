import React, { useReducer, useState } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import Button from "components/Button";
import Input from "components/Input";
import { cpfMask, phoneMask } from "utils/mask";
import { useHistory } from "react-router-dom";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import { useCallback } from "react";
import { useLoan } from "hooks/useLoan";
import OneButtonPopup from "components/modals/OneButtonPopup";

const inputStyle = {
  border: "1px solid #6c6c6c",
  padding: "1rem",
  borderRadius: ".5rem",
  width: "100%",
  height: "3vh",
};

const CreditCardLoanSimulatorDataConfirmation = () => {
  const history = useHistory();
  const initialState = {
    cpf: cpfMask(""),
    nome: "",
    email: "",
    celular: phoneMask(""),
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageDialog, setMessageDialog] = useState("");

  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    const { type, payload } = action;
    return { ...state, [type]: payload };
  }

  const onChange = (e, mask) => {
    const {
      target: { name, value },
    } = e;
    dispatch({ type: name, payload: mask ? mask(value) : value });
  };

  const { cpf, nome, email, celular } = state;

  const { dispatchUserInitialData, dispatchSimulationData } = useLoan();

  const validateInputs = () => {
    const [primeiroNome, ultimoNome] = nome?.split(" ");
    return (
      cpf?.unmasked.length < 11 ||
      nome?.split(" ").length < 2 ||
      !primeiroNome.length ||
      !ultimoNome.length ||
      !email.includes("@") ||
      celular?.unmasked.length < 11
    );
  };

  const PostIncludeLead = useApi(constants.endpoints.postAddLeads, "POST");
  const PostCheckClientCpfEmail = useApi(
    constants.endpoints.checkClientCpfEmail
      .replace("PARAM_EMAIL", email)
      .replace("PARAM_CPF", cpf?.unmasked),
    "POST"
  );

  const handleSubmit = useCallback(async () => {
    const checkResponse = await PostCheckClientCpfEmail.callApi();
    if (checkResponse.status === 404) {
      setMessageDialog(checkResponse.mensagem);
      setDialogOpen(true);
      return;
    }
    const body = {
      celular: celular?.unmasked,
      cpf: cpf?.unmasked,
      email,
      produto: "EMPRESTIMO",
      tipoEvento: "LEAD",
      nome,
      origemEmprestimo: "NOVO_SAQUE",
    };

    dispatchUserInitialData(state);

    const { data } = await PostIncludeLead.callApi(body);

    if (data?.status === "PENDENTE") {
      dispatchSimulationData({
        ...data,
        credit_limit: data?.limiteCredito,
        interest_rate: data?.taxaJuros,
        installment_value: data?.valorParcela,
        iof_total: data?.valorIOF,
        released_amount: data?.valorLiberado,
      });
      history.push("/credit-card-loan-simulator-active-leads");
    } else {
      history.push("/credit-card-loan-simulator-choose-type");
    }
  }, [celular, cpf, email, nome]);
  return (
    <St.SafeView>
      <Layout routeBack="/paymentSimulator" title="Confirme teus dados">
        <St.Container>
          <OneButtonPopup
            text={messageDialog}
            buttonText="Ok"
            title={""}
            visibility={dialogOpen}
            defaultButton
            onClose={() => dispatch({ type: "email", payload: "" })}
            setVisibility={setDialogOpen}
          />
          <St.TopSpace>
            <St.Title>
              Para simular Teu empréstimo, precisamos que você confirme alguns
              dados antes:{" "}
            </St.Title>
            <St.Inputs>
              <Input
                boldLabel
                style={inputStyle}
                id="nameLoanCreditCard"
                label="Nome completo"
                value={nome}
                name="nome"
                onChange={(e) => onChange(e)}
              />
              <Input
                boldLabel
                style={inputStyle}
                id="cpfLoanCreditCard"
                name="cpf"
                placeholder="000.000.000-00"
                type="text"
                value={cpf?.masked}
                max={14}
                onChange={(e) => onChange(e, cpfMask)}
                label="CPF"
              />
              <Input
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                boldLabel
                style={inputStyle}
                label="E-mail"
              />
              <Input
                boldLabel
                name="celular"
                style={inputStyle}
                label="Celular"
                max={15}
                value={celular?.masked}
                placeholder={"(99) 9999-9999"}
                onChange={(e) => onChange(e, phoneMask)}
              />
            </St.Inputs>

            <St.Footer>
              <Button
                style={{ width: "100%", justifySelf: "flex-end" }}
                loading={PostIncludeLead.loading}
                buttonType="primary"
                text={"Avançar"}
                onClick={() => handleSubmit()}
                disabled={validateInputs()}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default CreditCardLoanSimulatorDataConfirmation;
