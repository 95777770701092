import styled from "styled-components";

export const Chronometer = styled.div`
  position: absolute;
  top: 3vh;
  left: 7vw;
  z-index: 10000;
  font-size: 5rem;
  font-weight: bold;
  color: red;
`;
