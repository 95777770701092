import styled from "styled-components";

export const TableTitle = styled.h4`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Table = styled.table`
  color: ${({ theme }) => theme.colors.primary};
  border-spacing: 0;
  text-align: center;

  th {
    background-color: #f5f5f5;
    padding: 10px 0;
  }

  th,
  td {
    border-bottom: 1px solid gray;
  }

  th {
    border-top: 1px solid gray;
  }

  td a {
    text-decoration: underline;
    color: inherit;
  }

  .icon-table {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const BtnPlus = styled.button`
  border: none;
  background-color: #fff;
  font-size: 13px;
  height: 2.5em;
  width: 2.5em;
  position: relative;

  &:after,
  &:before {
    content: "";
    display: block;
    background-color: green;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    height: 1em;
    width: 0.2em;
  }

  &:after {
    height: 0.2em;
    width: 1em;
  }
`;
export const BtnCancel = styled.button`
  border: none;
  font-size: 13px;
  height: 2.5em;
  width: 2.5em;
  position: relative;
  rotate: 135deg;

  &:after,
  &:before {
    content: "";
    display: block;
    background-color: #e71037;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &:before {
    height: 1em;
    width: 0.2em;
  }

  &:after {
    height: 0.2em;
    width: 1em;
  }
`;

export const Writing = styled.img`
  position: relative;
`;

export const LegendLabel = styled.span`
  font-size: 0.875rem;
  margin-left: 5px;
  line-height: 24px;
`;

export const Divider = styled.hr`
  border: 1px solid #ddd;
`;