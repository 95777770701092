import React, { useEffect, useState } from "react";
import themes from "styles";
import * as St from './styles'


const RadioButton = ({ options = [2], onSelect = 1, handleChange, value }) => {
  
  const [select, setSelect] = useState("TEUCARD");

  useEffect(() => {
    if(value)
      setSelect(value);
  }, [value]);

  return (
    <St.Wrapper>
      <St.ItemTeuCard>
        <St.RadioButton
          type="radio"
          name="radio"
          value="TEUCARD"
          checked={select === "TEUCARD"}
          onChange={handleChange}
        />
        <St.RadioButtonLabelTeuCard><St.ItemCircle/></St.RadioButtonLabelTeuCard>
        <St.ImageChip src={themes.components.png.chip}/>
        <St.Footer>
          <St.ImageTeuCard src={themes.components.png.logo}/>
        </St.Footer>
      </St.ItemTeuCard>
      <St.ItemBandeirado>
        <St.RadioButton
          type="radio"
          name="radio"
          value="MASTERCARD"
          checked={select === "MASTERCARD"}
          onChange={handleChange}
        />
        <St.RadioButtonLabelBandeirado>
        <St.ItemCircle/>
        </St.RadioButtonLabelBandeirado>
        <St.ImageChip src={themes.components.png.chip}/>
        <St.Footer>
        <St.ImageCard src={themes.components.png.visa}/>
        <St.ImageCard src={themes.components.png.elo}/>
        <St.ImageCard src={themes.components.png.mastercard}/>
        </St.Footer>
      </St.ItemBandeirado>
    </St.Wrapper>
  );
};
  
  export default RadioButton;