import React, { createContext, useCallback, useContext } from "react";

import { GlobalContext } from "context/GlobalContext";
import { SignatureContext } from "context/SignatureContext";
import { get } from "repository/storage";
import Constants from "utils/constants";
import Repository from "repository";
import useApi from "hooks/useApi";
const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const CallApiLogin = useApi(Constants.endpoints.login, "POST");
  const CallApiSignOut = useApi(Constants.endpoints.signOut, "POST");
  const operator = get("@NovaEra:user");
  const { addUser } = useContext(GlobalContext);
  const { imageURL } = useContext(SignatureContext);

  const signInNew = async (cpf, password) => {
    const payload = {
      cpf: cpf.replace(/\.|-/g, ""),
      senha: password,
    };
    const res = await CallApiLogin.callApi(payload);
    if (res.status >= 200 && res.status <= 300) {
      const { token, novoToken } = res.data;

      if (res.status >= 200 && res.status <= 300) {
        if (
          res?.data?.perfis?.some(
            (a) => a === "OPR" || a === "ADM" || a === "SUP"
          )
        ) {
          Repository.storage.set(Repository.keys.TOKEN_USER, token);
          Repository.storage.set(Repository.keys.TOKEN_BEARER, novoToken);
          Repository.storage.set(Repository.keys.USER, res.data);

          setIDOrigem(Constants.externalAccess.originStore);
          addUser(res.data);
        }
      }

      return res;
    } else {
      return res;
    }
  };

  const signOut = async () => {
    try {
      const response = await CallApiSignOut.callApi({ cpf: operator.cpf });
      if (response.status >= 200 && response.status < 300) {
        window.location = "/";
        Repository.storage.remove(Repository.keys.USER);
        Repository.storage.remove(Repository.keys.TOKEN_USER);
        Repository.storage.remove(Repository.keys.TOKEN_BEARER);
        Repository.storage.remove(Repository.keys.TOKEN_CARTAO);
        Repository.storage.remove(Repository.keys.ID_ORIGEM);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const newProposal = () => {
    Repository.storage.remove(Repository.keys.ID_PROPOSTA);
    imageURL(null);
  };

  const setIDOrigem = async (idOrigem) => {
    var valor = {};
    switch (idOrigem) {
      case Constants.externalAccess.originSite:
        valor = {
          idOrigem,
          tipo: "SITE",
        };
        break;
      case Constants.externalAccess.originMobile:
        valor = {
          idOrigem,
          tipo: "APP",
        };
        break;
      case Constants.externalAccess.originStore:
        valor = {
          idOrigem,
          tipo: "LOJA",
        };
        break;
      case Constants.externalAccess.originTotem:
        valor = {
          idOrigem,
          tipo: "TOTEM",
        };
        break;
      default:
        break;
    }

    Repository.storage.set(Repository.keys.ID_ORIGEM, valor);
  };

  const signInGuest = async (cpf, password) => {
    const response = await CallApiLogin.callApi({
      cpf: cpf.replace(/\.|-/g, ""),
      senha: password,
    });
    const { token, novoToken } = response.data;
    Repository.storage.set(Repository.keys.TOKEN_USER, token);
    Repository.storage.set(Repository.keys.TOKEN_BEARER, novoToken);
    Repository.storage.set(Repository.keys.USER, response.data);

    addUser(response.data);
  };

  return (
    <AuthContext.Provider
      value={{
        signInNew,
        signOut,
        newProposal,
        setIDOrigem,
        signInGuest,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
