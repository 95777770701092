import { Document } from "react-pdf";
import styled from "styled-components";
import media from "styled-media-query";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Content = styled.div`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Adicione outros estilos desejados aqui */
`;

export const Title = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 15px;
`;

export const StyledDocument = styled(Document)`
  height: 650px; /* Defina a altura desejada aqui */
  position: relative;
  overflow: hidden;
`;

export const MiddleContainer = styled.div`
  width: 65%;
  justify-content: center;
  gap: 15px;
  align-items: center;
  font-size: 1.5rem;
  margin-top: 1.5rem;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const FooterPages = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const FooterPage = styled.div`
  width: 15%;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  justify-content: space-between;
`;

export const BackFooter = styled.div``;

export const NextFooter = styled.div``;

export const DivCheck = styled.div`
  display: contents;
  width: 100%;
`;

export const Footer = styled.div`
  margin-top: 35px;
  bottom: 0;
  width: 100%;

  /* @media (max-width: 712px) {
    padding: 20px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  } */
`;
