export default {
  IP_ADRESS: "@NovaEra:ipAdress",
  ID_ORIGEM: "@NovaEra:idOrigem",
  ID_PROPOSTA: "@NovaEra:idProposta",
  ID_ANFITRIAO: "@NovaEra:idAnfitriao",
  PROPOSTA: "@NovaEra:proposta",
  EMAIL_PROPOSTA: "@NovaEra:emailProposta",
  USER: "@NovaEra:user",
  TOKEN_USER: "@NovaEra:tokenUser",
  TOKEN_BEARER: "@NovaEra:tokenBearer",
  CODE_VERIFICATION: "@NovaEra:codeVerification",
  X_CODE: "@NovaEra:xCode",
  TOKEN_CARTAO: "@NovaEra:tokenCartao",
  FACE_ID: "@NovaEra:faceID",
  SESSION_ID: "@NovaEra:idSessao",
  CODE_TWO_FACTOR: "@NovaEra:code",
};
