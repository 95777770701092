import React, { useEffect, useReducer, useState } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import Button from "components/Button";
import { useHistory, useLocation } from "react-router-dom";
import { moneyMask, moneyBrazilian } from "utils/mask";
import { useLoan } from "hooks/useLoan";
import constants from "utils/constants";
import useApi from "hooks/useApi";
import Slider from "../components/sliderWithDraw";
import InputOnboarding from "components/InputOnboarding";
import { get } from "repository/storage";
import OneButtonPopup from "components/modals/OneButtonPopup";
import themes from "styles";

const WithDrawSimulationByTeuCard = () => {
  const history = useHistory();
  const [values, setValues] = useState({
    min: "",
    max: "",
    selected: "100",
    title: "",
    text: "",
    indicator: "",
    default: "100",
  });
  const [input, setInput] = useState({
    formatted: "",
    unFormatted: "",
  });
  const [errorsInputs, setErrorsInputs] = useState({});
  const { userInitialData, dispatchSolicitationData } = useLoan();
  const [userLimits, setUserLimits] = useState([]);
  const [error, setError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogValueOpen, setDialogValueOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [limitError, setLimitError] = useState(false);
  const operator = get("@NovaEra:user");

  const { state } = useLocation();

  const updateFormField = (value) => {
    const valueFloat =
      value.unmasked.toString().slice(0, value.unmasked.length - 2) +
      "." +
      value.unmasked.toString().slice(value.unmasked.length - 2);
    setInput({
      formatted: value.masked,
      unFormatted: parseFloat(valueFloat).toFixed(2),
    });
  };

  const updateSliderField = (value) => {
    setValues({
      ...values,
      selected: value.masked,
    });
  };

  const RequestUserLimits = useApi(
    constants.endpoints.getavailableWithDrawLimit.replace(
      "PARAM_CPF",
      userInitialData?.cpf?.unmasked
    ),
    "GET"
  );

  const PostSimulateWithDraw = useApi(
    constants.endpoints.postSimulateWithDraw,
    "POST"
  );

  const postSimulateWithDraw = async () => {
    const payload = {
      cpf: userInitialData?.cpf?.unmasked,
      operador: {
        cpf: operator?.cpf,
        id: operator?.id,
        nome: operator?.nomeTitular,
      },
      valorSolicitado: input.unFormatted,
    };

    if (input.unFormatted < userLimits?.valorSaqueMinimo) {
      setDialogValueOpen(true);
      setError("O valor inserido não corresponde ao valor mínimo.");
    } else if (input.unFormatted > userLimits?.limiteSaqueDisponivel) {
      setDialogValueOpen(true);
      setError("O valor inserido ultrapassou valor máximo.");
    } else {
      const response = await PostSimulateWithDraw.callApi(payload);
      try {
        if (response.status >= 200 && response.status < 300) {
          dispatchSolicitationData(response.data);
          history.push("/simulation-result-by-teu-card", {
            simulationData: response.data,
          });
        } else {
          setError(response.mensagem);
          setDialogOpen(true);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    const getLimitAvailable = async () => {
      try {
        const response = await RequestUserLimits.callApi();
  
        if (response.status >= 200 && response.status < 300) {
          setUserLimits(response.data);
        }}catch(err){
          console.log(err.message);
        }
    };
    getLimitAvailable();
  }, []);

  useEffect(() => {
    if (status) {
      if (status != "ATIVO") {
        setError(
          "Ops...! No momento não podemos te oferecer o empréstimo com cartão TeuCard. Procure uma de nossas lojas!"
        );
        setDialogOpen(true);
      }
    }
  }, [status]);

  useEffect(() => {
    if (userLimits?.valorSaqueMinimo) {
      setInput({
        formatted: moneyMask(userLimits?.valorSaqueMinimo),
        unFormatted: userLimits?.valorSaqueMinimo,
      });
      setStatus(userLimits?.statusCartao);
    }
  }, [userLimits.statusCartao, userLimits.valorSaqueMinimo]);

  const availableLimitError = () => {
    return (
      <div>
        <p style={{ textAlign: "center" }}>
          Ops...! Teu valor disponível para saque é de{" "}
          <strong>{moneyMask(userLimits?.limiteSaqueDisponivel)}</strong> e não
          atinge o mínimo de{" "}
          <strong>{moneyMask(userLimits?.valorSaqueMinimo)}</strong>.
        </p>
        <br /> {/* Adicionando um salto de linha */}
        <p style={{ textAlign: "center" }}>
          Pague a tua fatura e reestabeleça teu limite de saque!
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (userLimits?.limiteSaqueDisponivel < userLimits?.valorSaqueMinimo) {
      setLimitError(true);
    }
  }, [userLimits.limiteSaqueDisponivel, userLimits.valorSaqueMinimo]);

  return (
    <St.SafeView>
      <Layout
        routeBack="/confirm-data-by-teu-card"
        title="Valor da Simulação"
      >
        <St.Container>
          <OneButtonPopup
            text={error}
            buttonText="Entendi"
            title={"Atenção!"}
            visibility={dialogOpen}
            iconModal={themes.components.png.emojiSad}
            defaultButton
            setVisibility={setDialogOpen}
            onClose={() => history.push("/opcoes-acesso")}
          />
          <OneButtonPopup
            text={error}
            buttonText="Entendi"
            title={"Atenção!"}
            visibility={dialogValueOpen}
            iconModal={themes.components.png.emojiSad}
            defaultButton
            setVisibility={setDialogValueOpen}
            onClose={() => setDialogOpen(false)}
          />
          <OneButtonPopup
            text={availableLimitError()}
            buttonText="Entendi"
            title={"Ops!"}
            visibility={limitError}
            iconModal={themes.components.png.emojiSad}
            defaultButton
            setVisibility={setLimitError}
            onClose={() => history.push("/opcoes-acesso")}
          />
          <St.TopSpace>
            <St.Title>
              Conta pra gente o Valor do Saque que você deseja simular:{" "}
            </St.Title>
            <St.Slider>
              <Slider
                min={userLimits?.valorSaqueMinimo}
                max={userLimits?.limiteSaqueDisponivel}
                selected={String(values.selected).replace("R$", "")}
                value={input.unFormatted}
                handleChange={(value) => {
                  updateSliderField(
                    moneyBrazilian(Number(value.target.value).toFixed(2))
                  );
                  updateFormField(
                    moneyBrazilian(Number(value.target.value).toFixed(2))
                  );
                }}
                step={1}
              />
            </St.Slider>
            <St.InputValue>
              <InputOnboarding
                type="text"
                label="Valor"
                placeholder="digite o valor"
                largeLabel
                value={input.formatted}
                maxLength={15}
                defaultValue={0}
                error={errorsInputs.values}
                step={1}
                onChange={(e) => {
                  updateSliderField(moneyBrazilian(e.target.value));
                  updateFormField(moneyBrazilian(e.target.value));
                }}
              />
            </St.InputValue>
            <St.MiddleContainer>
              <St.Rounded />
              <p style={{ width: "100%" }}>
                Lembre-se, essa simulação só funciona com valores dentro do teu
                limite de saque disponível hoje.
              </p>
            </St.MiddleContainer>
          </St.TopSpace>
          <St.Footer>
            <Button
              buttonType="primary"
              text={"Avançar"}
              onClick={() => postSimulateWithDraw()}
              loading={PostSimulateWithDraw.loading}
            />
          </St.Footer>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default WithDrawSimulationByTeuCard;
