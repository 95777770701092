export const gridTemplateForgotEmail = (theme) => {
    const { screen: { screenXS, screenSM }} = theme
    return `
      grid-template: ${`
        "password"
        "mobilePhone"
        "email"
        "termsAccepted"
      `};
  
      @media screen {
        @media (min-width: ${screenXS}) {
          grid-template: ${`
                  "cpf"
                  "mobilePhone"
                  "email"
                  "termsAccepted"
          `};
          grid-template-columns: 1fr;
          justify-content: start;
        }
  
        @media screen {
          @media (min-width: ${screenSM}) {
            grid-template: ${`
                      "cpf"
                      "mobilePhone"
                      "email"
                      "termsAccepted"
            `};
            grid-template-columns: 1fr;
            justify-content: start;
          }
        }
     `
  };