/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { usePagination } from "hooks/usePagination";

import * as St from "./styles";
import { useEffect } from "react";
import { memo } from "react";

const Pagination = ({ totalPages, onCurrentPageChange }) => {
  const {
    firstRender,
    currentPage,
    pages,
    onClickPage,
    goBack,
    goNext,
    goToFirstPage,
    goToLastPage,
  } = usePagination(totalPages);

  useEffect(() => {
    if (!firstRender) onCurrentPageChange(currentPage);
  }, [currentPage]);

  return (
    <div style={{ display: "flex" }}>
      <St.CustomTabs onClick={goToFirstPage}>Início</St.CustomTabs>
      <St.CustomTabs onClick={goBack}>{"<"}</St.CustomTabs>

      {pages.map((page) => (
        <St.Tabs
          onClick={() => onClickPage(page)}
          selected={currentPage === page}
        >
          {page}
        </St.Tabs>
      ))}

      <St.CustomTabs onClick={goNext}>{">"}</St.CustomTabs>
      <St.CustomTabs onClick={goToLastPage}>Último</St.CustomTabs>
    </div>
  );
};

export default memo(Pagination);
