import React, { useEffect, useReducer } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import Button from "components/Button";
import Input from "components/Input";
import { dateMask, rgMask, cepMask } from "utils/mask";
import { useHistory } from "react-router-dom";
import InputSelect from "components/InputSelect";
import { optionsUfs } from "views/cadComplete/constants";
import { useLoan } from "hooks/useLoan";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import { useCallback } from "react";
import { useState } from "react";
import OneButtonPopup from "components/modals/OneButtonPopup";

const inputStyle = {
  border: "1px solid #6c6c6c",
  padding: "1rem",
  borderRadius: ".5rem",
  width: "100%",
  height: "4rem",
};

const CreditCardLoanSimulatorAditionalData = () => {
  const history = useHistory();
  const initialState = {
    dtNascimento: dateMask(),
    rg: "",
    nomeMae: "",
    nomePai: "",
    cep: cepMask(),
    uf: "",
    endereco: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
  };

  const [data, dispatch] = useReducer(reducer, initialState);
  const { userAditionalData, dispatchUserAditionalData } = useLoan();
  const [cepRequest, setCepRequest] = useState(
    !userAditionalData?.endereco?.cep
  );
  const [dialogOpen, setDialogOpen] = useState(false);

  function reducer(data, action) {
    const { type, payload } = action;
    switch (type) {
      case "address":
        return {
          ...data,
          cep: cepMask(payload.cep),
          uf: payload.uf,
          endereco: payload.logradouro,
          numero: payload.numero,
          bairro: payload.bairro,
          cidade: payload.cidade,
        };
      case "personalData":
        return {
          ...data,
          nome: payload.nome,
          dtNascimento: dateMask(
            payload.dataNascimento?.split("-").reverse().join("/")
          ),
          rg: payload.rg,
          nomeMae: payload.nomeMae,
          nomePai: payload.nomePai,
        };
      default:
        return { ...data, [type]: payload };
    }
  }
  useEffect(() => {
    if (userAditionalData.endereco) {
      dispatch({ type: "address", payload: userAditionalData.endereco });
    }
    if (userAditionalData) {
      dispatch({ type: "personalData", payload: userAditionalData });
    }
  }, [userAditionalData]);
  const onChange = (e, mask) => {
    const {
      target: { name, value },
    } = e;
    if (name === "cep") {
      setCepRequest(true);
    }
    dispatch({ type: name, payload: mask ? mask(value) : value });
  };

  const {
    bairro,
    cep,
    cidade,
    complemento,
    dtNascimento,
    endereco,
    nomeMae,
    nomePai,
    numero,
    rg,
    uf,
  } = data;

  const GetCep = useApi(
    constants.endpoints.findAddressByCep.replace("PARAM_CEP", cep?.unmasked),
    "GET"
  );

  useEffect(() => {
    const getCep = async () => {
      const { data } = await GetCep.callApi();
      const payload = {
        cep: cep?.unmasked ?? data.cep,
        uf: data.uf || "",
        logradouro: data.logradouro || "",
        bairro: data.bairro || "",
        cidade: data.localidade || "",
      };
      dispatch({ type: "address", payload });
    };

    if (cep?.unmasked?.length === 8 && cepRequest) {
      setCepRequest(false);
      getCep();
    }
  }, [GetCep, cep, cepRequest]);

  const validateInputs = () => {
    return (
      !bairro ||
      !cep ||
      !cidade ||
      !nomeMae ||
      !endereco ||
      !uf ||
      !rg ||
      !numero ||
      !dtNascimento
    );
  };

  const novosDados = {
    endereco: {
      bairro,
      cep,
      cidade,
      logradouro: endereco,
      uf,
      numero,
      complemento,
      pais: "Brasil",
    },
    nomeMae,
    nomePai,
    rg,
    dtNascimento,
  };

  const validateUserAge = useCallback(() => {
    const today = new Date();
    const userDate = new Date(
      dtNascimento?.masked?.split("/").reverse().join("-")
    );
    userDate.setDate(userDate.getDate() + 1);
    let age = today.getFullYear() - userDate.getFullYear();
    const month = today.getMonth() - userDate.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < userDate.getDate())) {
      age--;
    }
    return age < 18;
  }, [dtNascimento]);

  const handleNext = () => {
    if (validateUserAge()) {
      setDialogOpen(true);
      return;
    } else {
      dispatchUserAditionalData({
        ...userAditionalData,
        novosDados,
      });
    }
    history.push("/credit-card-loan-simulator-bank-data");
  };
  return (
    <St.SafeView>
      <Layout
        routeBack="/credit-card-loan-simulator-choose-type"
        title="Dados adicionais"
      >
        <St.Container>
          <OneButtonPopup
            text={"O empréstimo não está disponível para menores de 18 anos!"}
            buttonText="Entendi"
            title={"Que pena!"}
            visibility={dialogOpen}
            defaultButton
            onClose={() => history.push("opcoes-acesso")}
            setVisibility={setDialogOpen}
          />
          <St.TopSpace>
            <St.Title>
              Para dar continuidade, precisamos de algumas informações
              adicionais tuas:
            </St.Title>
            <div
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "space-between",
              }}
            >
              <St.Inputs style={{ width: "50%" }}>
                <Input
                  boldLabel
                  style={inputStyle}
                  id="nameLoanCreditCard"
                  label="Data de Nascimento*"
                  value={dtNascimento?.masked}
                  name="dtNascimento"
                  onChange={(e) => onChange(e, dateMask)}
                />
                <Input
                  boldLabel
                  style={inputStyle}
                  id="cpfLoanCreditCard"
                  name="rg"
                  placeholder="00.000.000-0"
                  type="text"
                  value={rg}
                  max={15}
                  onChange={(e) => onChange(e)}
                  label="RG*"
                />
                <Input
                  name="nomeMae"
                  value={nomeMae}
                  onChange={(e) => onChange(e)}
                  boldLabel
                  style={inputStyle}
                  label="Nome da Mãe*"
                />
                <Input
                  boldLabel
                  name="nomePai"
                  style={inputStyle}
                  label="Nome do Pai"
                  max={15}
                  value={nomePai}
                  onChange={(e) => onChange(e)}
                />
              </St.Inputs>
              <St.Inputs style={{ width: "50%" }}>
                <St.DoubleInputContainer>
                  <Input
                    style={inputStyle}
                    label="CEP*"
                    value={cep?.masked}
                    name="cep"
                    onChange={(e) => onChange(e, cepMask)}
                  />

                  <InputSelect
                    style={{ ...inputStyle, padding: ".5rem 1rem" }}
                    name="uf"
                    id="uf"
                    label="UF*"
                    type="select"
                    value={uf}
                    defaultValue={uf}
                    values={optionsUfs}
                    onChange={(e) => onChange(e)}
                  />
                </St.DoubleInputContainer>
                <Input
                  name="endereco"
                  value={endereco}
                  onChange={(e) => onChange(e)}
                  style={inputStyle}
                  label="Endereço*"
                />
                <St.DoubleInputContainer>
                  <Input
                    name="numero"
                    style={inputStyle}
                    label="Número*"
                    max={15}
                    value={numero}
                    onChange={(e) => onChange(e)}
                  />
                  <Input
                    name="complemento"
                    style={inputStyle}
                    label="Complemento"
                    max={15}
                    value={complemento}
                    onChange={(e) => onChange(e)}
                  />
                </St.DoubleInputContainer>
                <St.DoubleInputContainer>
                  <Input
                    name="bairro"
                    style={inputStyle}
                    label="Bairro*"
                    max={15}
                    value={bairro}
                    onChange={(e) => onChange(e)}
                  />
                  <Input
                    name="cidade"
                    style={inputStyle}
                    label="Cidade*"
                    max={15}
                    value={cidade}
                    onChange={(e) => onChange(e)}
                  />
                </St.DoubleInputContainer>
              </St.Inputs>
            </div>

            <St.Footer>
              <Button
                buttonType="primary"
                text={"Avançar"}
                onClick={() => {
                  handleNext();
                }}
                disabled={validateInputs()}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default CreditCardLoanSimulatorAditionalData;
