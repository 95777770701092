import React from "react";
import { useState } from "react";
import * as St from "./styles";
import SliderBar from "components/CustomSliderBar";
import { moneyMask } from "utils/mask";
import RadioButton from "components/RadioCheck";
import InputRadio from "components/InputRadio";
import Input from "components/Input";
import Layout from "components/OldLayout";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import Button from "components/Button";
import Spinner from "components/Spinner";
import themes from "styles";
import Loading from "components/Loading";
import CurrencyInput from "components/CurrencyInput";

const PaymentSimulator = () => {
  const [value, setValue] = useState(150);
  const [flag, setFlag] = useState("TEUCARD");
  const [category, setCategory] = useState("");
  const [rulesData, setRulesData] = useState([]);
  const [callFunction, setCallFunction] = useState(false);

  const RequestSimulateRules = useApi(
    constants.endpoints.getRulesSimulate,
    "GET",
    {},
    {},
    true
  );

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const handleValueInputChange = (value) => {
    setValue(value.toString());
  };

  const handleFlagChange = (event) => {
    setFlag(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const simulateRulesInstallments = async () => {
    setCallFunction(true);
    try {
      const response = await RequestSimulateRules.callApi({
        bandeira: flag,
        valorTotal: value,
        categoria: flag === "TEUCARD" ? category : "PADRAO",
      });
      if (response.status === 200 && response.status < 300) {
        setRulesData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const disabledButton = flag === "TEUCARD" && !category;
  return (
    <St.SafeView>
      <Layout
        routeBack="/paymentSimulator"
        title="Conta para a gente o valor da compra que deseja simular"
      >
        <St.Container>
          <St.TopSpace>
            <St.ValueSimulate>
              <St.Value>{moneyMask(value)}</St.Value>
              <St.Slider>
                <SliderBar
                  value={value}
                  handleChange={handleValueChange}
                  min={150}
                  max={10000}
                  step={10}
                />
              </St.Slider>
              <St.TextContainer></St.TextContainer>
              <St.SimulateEditTitle>
                Ou se preferir, digite o valor:
              </St.SimulateEditTitle>
              <St.InputValue>
                <CurrencyInput
                  valueDefault={moneyMask(value)}
                  valueChange={handleValueInputChange}
                />
              </St.InputValue>
            </St.ValueSimulate>
            <St.InstallmentsSimulate>
              <St.InstallmentsSimulationTitle>
                Selecione o cartão:
              </St.InstallmentsSimulationTitle>
              <St.InstallmentsSimulationContainer>
                <RadioButton value={flag} handleChange={handleFlagChange} />
              </St.InstallmentsSimulationContainer>
            </St.InstallmentsSimulate>
            {flag === "TEUCARD" ? (
              <St.InstallmentsSimulate style={{ height: "auto" }}>
                <St.InstallmentsSimulationTitle>
                  Selecione a categoria:
                </St.InstallmentsSimulationTitle>
                <St.InstallmentsSimulationContainer>
                  <InputRadio
                    checked={category === "PADRAO"}
                    label="Padrão"
                    value="PADRAO"
                    name="category"
                    id="category"
                    onChange={handleCategoryChange}
                  />
                  <InputRadio
                    checked={category === "PLATINUM"}
                    label="Platinum"
                    value="PLATINUM"
                    name="category"
                    id="category"
                    onChange={handleCategoryChange}
                  />
                </St.InstallmentsSimulationContainer>
              </St.InstallmentsSimulate>
            ) : null}

            <St.Footer>
              <Button
                buttonType="primary"
                text={"Simular"}
                onClick={() => simulateRulesInstallments()}
                disabled={disabledButton}
              />
            </St.Footer>
          </St.TopSpace>
          <St.ResultSimulate>
            {callFunction ? (
              RequestSimulateRules.loading ? (
                <Spinner color="#D4313E" />
              ) : (
                rulesData.map((el, index) => {
                  return (
                    <St.Item>
                      <St.TextItem>{el?.parcela}x</St.TextItem>
                      <St.TextItem>
                        de {moneyMask(el?.valorParcela)}
                      </St.TextItem>
                      <St.TextItem>= {moneyMask(el?.valorTotal)}</St.TextItem>
                    </St.Item>
                  );
                })
              )
            ) : (
              <>
                <St.NoContent src={themes.components.png.creditSimulator} />
                <St.SimulateNoContent>Simule</St.SimulateNoContent>
              </>
            )}
          </St.ResultSimulate>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default PaymentSimulator;
