import React, { useContext, useState, useEffect } from "react";

import Heading from "components/Heading";

import * as St from "./styles";
import { useHistory } from "react-router-dom";
import ContainerFlex from "components/ContainerFlex";
import { GlobalContext } from "context/GlobalContext";
import { cpfMask, hiddeCPFNumber } from "utils/mask";
import Repository from "repository";
import { AuthContext } from "context/AuthContext";

function OldLayout({ title, goBack, routeBack, children, icon }) {
  const { user } = useContext(GlobalContext);
  const history = useHistory();
  const { signOut } = useContext(AuthContext);
  const [origem, setOrigem] = useState(false);

  useEffect(() => {
    function getOrigemFunc() {
      const ori = Repository.storage.get(Repository.keys.ID_ORIGEM);

      if (ori) {
        const type = ori?.tipo || "";
        const isStore = type === "LOJA";

        setOrigem(isStore);
      } else {
        setOrigem(false);
      }
    }

    getOrigemFunc();
  }, []);

  const onBackPress = () => {
    if (typeof goBack === "function") return goBack();
    if (goBack) return history.goBack();

    history.push(`${routeBack}`);
  };

  const handleLogout = () => {
    signOut();
  };
  return (
    <St.Container>
      {origem && (
        <ContainerFlex
          align="flex-end"
          end
          marginBottom={15}
          style={{ gap: "0.5rem" }}
        >
          <St.Logout onClick={handleLogout}>Sair</St.Logout>
          <St.Info>
            <span>
              <b>Usuário:</b> {hiddeCPFNumber(cpfMask(user?.cpf).masked)} -
            </span>
            <span>&nbsp;{user?.nomeTitular}</span>
          </St.Info>
          <St.SmallText>
            <span>Versão: {process.env.REACT_APP_VERSAO_APP}</span>
          </St.SmallText>
        </ContainerFlex>
      )}
      <St.Header>
        {(goBack || routeBack) && <St.ButtonBack onClick={onBackPress} />}
        <Heading variation="tertiary">{title}</Heading>
        {icon ? icon : null}
      </St.Header>

      {children}
    </St.Container>
  );
}

export default OldLayout;
