import React, { useState } from "react";
import "./styles.css";
import { ArrowIosBackOutline } from "@styled-icons/evaicons-outline/ArrowIosBackOutline";
import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline/ArrowIosForwardOutline";

export const CarouselItem = ({ children }) => {
  return (
    <div
      className="carousel-item"
      style={{ width: "130px", maxWidth: "130px" }}
    >
      {children}
    </div>
  );
};

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex) => {
    const childCount = React.Children.count(children);
    if (newIndex > childCount && newIndex > activeIndex) {
      newIndex = 0;
    }
    if (newIndex < 0) {
      newIndex = childCount - 1;
    } else if (newIndex >= childCount) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  return (
    <div className="carousel" style={{ width: "170px", maxWidth: "170px" }}>
      <div
        className="inner"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        <>
          {React.Children.map(children, (child, index) => {
            return (
              <>
                <button
                  onClick={() => {
                    updateIndex(activeIndex - 1);
                  }}
                >
                  <ArrowIosBackOutline size={20} />
                </button>
                <CarouselItem children={child} />
                {/* {React.cloneElement(child, { width: "100%" })} */}
                <button
                  onClick={() => {
                    updateIndex(activeIndex + 1);
                  }}
                >
                  <ArrowIosForwardOutline size={20} />
                </button>
              </>
            );
          })}
        </>
      </div>
      <div className="buttons">
        {React.Children.map(children, (child, index) => {
          return (
            <>
              <span
                style={{
                  borderRadius: "50%",
                  width: "10px",
                  height: "10px",
                }}
                onClick={() => {
                  updateIndex(index);
                }}
                className={`${index === activeIndex ? "active" : "not-active"}`}
              ></span>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
