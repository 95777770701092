import styled from "styled-components";
import media from "styled-media-query";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const Title = styled.p`
  font-size: 1.6rem;
  font-weight: 700;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 3rem;
`;

export const Subtitle = styled.h2`
  font-weight: 400;
  text-align: center;
  font-size: 18px;
  width: 80%;
`;

export const MiddleContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  font-size: 1.5rem;
  margin-top: 25rem;
  margin-bottom: 7rem;
`;

export const Spinner = styled.div`
  .text {
    font-size: 35px;
    position: absolute;
    left: 43%;
    top: 56%;
  }
  .lds-spinner {
    color: #f19334;
    display: inline-block;
    position: relative;
    width: 250px; /* Aumente o valor do width */
    height: 160px; /* Aumente o valor do height */
    top: 30%;
  }

  .lds-spinner div {
    transform-origin: 120px 120px; /* Ajuste o valor do transform-origin */
    animation: lds-spinner 1.2s linear infinite;
  }

  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 7px; /* Ajuste o valor do top */
    left: 50px; /* Ajuste o valor do left */
    width: 40px; /* Ajuste o valor do width */
    height: 40px; /* Ajuste o valor do height */
    border-radius: 50%;
    background: #f19334;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const Footer = styled.div`
  padding: 20px;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
`;
