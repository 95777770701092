import styled from "styled-components";
import media from "styled-media-query";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  justify-content: ${(props) => props.justify};
`;

export const Slider = styled.div`
  margin-top: 40px;
  padding: 50px;
`;

export const Value = styled.span`
  font-size: 22px;
  font-weight: 600;
  background: linear-gradient(to right, #e71037, #ed933a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  align-self: center;
  margin-top: 5rem;
`;

export const MiddleContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 7rem;
  padding: 30px;
`;

export const Rounded = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #f57e38;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 3rem;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const Footer = styled.div`
  margin-top: 25px;
  bottom: 0;
  width: 100%;

  @media (max-width: 712px) {
    padding: 20px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`;

export const SimulateEditTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

export const SuccessMessage = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

export const InputValue = styled.div`
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

export const ParcelBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5rem;
  align-items: center;
`;
