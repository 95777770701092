import React from 'react'
import * as S from './styles'

export default function InputRadio({ id, name,type, label, checked, value, onClick, onChange, disabled, setFields }) {

	return (
    <S.Container name={id}>
      <S.RadioLabel htmlFor={name}>
        <S.RadioInput
          type='radio'
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          onClick={onClick}
          checked={checked}
          disabled={disabled}
        />
        <label>{label}</label>
      </S.RadioLabel>
    </S.Container>
	);
}




