import styled from 'styled-components'

export const Input = styled.input`
width: 50%;
height: 30px;
background: #fff;
position: relative;
margin-top: 10px;
border-radius: 5px;
border-width: 1px;
border-color: black;
border-style: solid;
`