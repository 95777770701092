import React, { useState } from "react";
import * as S from "./styles";
import components from "styles/components";

const InputOnboarding = ({
  marginBottom,
  error,
  mask,
  label,
  defaultValue,
  onChange,
  icon = false,
  largeLabel,
  fontWeight,
  color,
  ...rest
}) => {
  const [focusInput, setFocusInput] = useState(false);
  const [showText, setShowText] = useState(false);

  return (
    <S.Container
      marginBottom={marginBottom}
      isFocus={focusInput}
      largeLabel={largeLabel}
    >
      <label style={{ fontWeight: fontWeight ?? "", color: color ?? "" }}>
        {label}
      </label>

      <input
        type={icon ? (showText ? "text" : "password") : "text"}
        onFocus={() => setFocusInput(true)}
        onBlur={() => setFocusInput(false)}
        mask={mask}
        defaultValue={defaultValue}
        onChange={onChange}
        {...rest}
      />
      {icon ? (
        showText ? (
          <img
            src={components.png.visible}
            onClick={() => setShowText(!showText)}
            className="icon"
            style={{ marginTop: 20 }}
          />
        ) : (
          <img
            src={components.png.invisible}
            onClick={() => setShowText(!showText)}
            className="icon"
            style={{ marginTop: 18 }}
          />
        )
      ) : null}

      {!!error && <S.ErrorText>{error}</S.ErrorText>}
    </S.Container>
  );
};

export default InputOnboarding;
