import React, { useState, useRef } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import * as St from "./styles";
import Input from "components/Input";
import { phoneMask } from "utils/mask";

import { ArrowIosBackOutline } from "@styled-icons/evaicons-outline/ArrowIosBackOutline";
import { ArrowIosForwardOutline } from "@styled-icons/evaicons-outline/ArrowIosForwardOutline";

export const CarouselItem = ({
  card,
  onChange,
  onSelect,
  onCardInformation,
  notList,
}) => {
  const [phoneZeuss, setPhoneZeuss] = useState("");

  return (
    <div style={{ width: "100%", height: "600px", flexDirection: "column", gap: "4rem" }}>
      <St.RadioButtonContainer>
        <input
          type="radio"
          id={card.modeloTema}
          name="cardTheme"
          className="radio"
          value={card.modeloTema}
          onChange={() => {
            onChange(card.modeloTema);
            onCardInformation(card);
          }}
        />
        <label for={card.modeloTema}>{card.descricaoTema}</label>
      </St.RadioButtonContainer>
      <St.CarouselItem>    
      <St.CarouselItem>
        <img src={card?.urlImagem} alt="card" style={{width: "280px", height: "180px", borderRadius: 20, boxShadow: "2px 2px 5px #888888", marginTop: 20}} />
      </St.CarouselItem>
      </St.CarouselItem>
      {card.modeloTema === "PLATINUM" && (
        <div style={{marginTop: 25, alignItems: "center"}}>
          <Input
            label="Celular ZEUSS"
            placeholder={"(xx) xxxxx-xxxx"}
            value={phoneMask(phoneZeuss).masked}
            max={14}
            onChange={(e) => {
              setPhoneZeuss(phoneMask(e.target.value).masked);
              onSelect(phoneMask(e.target.value).masked);
            }}
          />
          <span style={{ fontWeight: 700 }}>
            Atenção! o celular zeuss não pode ser compartilhado em outro
            cadastro.
          </span>
        </div>
      )}
    </div>
  );
};
const CarouselCard = ({
  cards,
  onChange,
  onSelect,
  onCardInformation,
  notList,
}) => {
  function Arrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <St.ArrowContainer isEdge={!isEdge}>
          <ArrowIosBackOutline size={50} />
        </St.ArrowContainer>
      ) : (
        <St.ArrowContainer isEdge={!isEdge}>
          <ArrowIosForwardOutline size={50} />
        </St.ArrowContainer>
      );
    return notList ? (<div></div>) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          onClick={onClick}
          disabled={isEdge}
          style={{ width: 60, height: 60 }}
        >
          {pointer}
        </button>
      </div>
    );
  }

  return (
    <Carousel
      itemsPadding={[1, 20]}
      autoTabIndexVisibleItems={true}
      outerSpacing={0}
      itemsToScroll={1}
      focusOnSelect
      renderArrow={Arrow}
      renderPagination={({ pages, activePage, onClick }) => {
        return (
          <St.ContainerDots>
            {pages.map((page) => {
              const isActivePage = activePage === page;
              return (
                <St.Dots
                  key={page}
                  onClick={() => onClick(page)}
                  active={isActivePage}
                />
              );
            })}
          </St.ContainerDots>
        );
      }}
    >
      {cards.map((card) => (
        <CarouselItem
          card={card}
          onChange={(e) => onChange(e)}
          onSelect={onSelect}
          onCardInformation={(e) => onCardInformation(e)}
        />
      ))}
    </Carousel>
  );
};

export default CarouselCard;
