import styled from "styled-components";
// import { Link as RouterLink } from 'react-router-dom'
import { ArrowBack } from "@styled-icons/boxicons-regular/ArrowBack";
import media from "styled-media-query";

export const Wrapper = styled.div`
  padding: 30px;
`;

export const Infos = styled.div``;

export const Signature = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 350px;
  border: 1px solid #e71037;
  border-radius: 2rem;
  background-color: rgb(231 16 55 / 13%);
  padding: 20px;

  margin-bottom: ${({
    theme: {
      spacings: { xsmall },
    },
  }) => xsmall};

  .signatureCanvas {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    background-color: rgba(231, 16, 55, 0.4);
  }
`;

export const Text = styled.p`
  text-align: center;
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular },
    },
  }) => fontWeightRegular};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  margin-bottom: ${({
    theme: {
      spacings: { xxlarge },
    },
  }) => xxlarge};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
  `}
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  .button {
    width: 48%;
    margin: 10px;
  }
`;
export const BoxSignature = styled.button`
  height: 200px;
  width: 100%;
  cursor: pointer;
  border: 1px solid #e71037;
  border-radius: 3px;
  background-color: rgba(231, 16, 55, 0.4);
  border-radius: 1rem;
  padding: 12px;
  margin-bottom: ${({
    theme: {
      spacings: { medium },
    },
  }) => medium};
`;
export const LineSignature = styled.div`
  height: ${(props) => (props.signature ? "90px" : "55px")};
  width: 80%;
  margin: auto;
  border-bottom: 2px dashed #e71037;
`;
export const ImgURL = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 250px;
  max-height: 130px;
`;
