/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import Button from "components/Button";
import Input from "components/Input";
import { dateMask, cepMask, maskDateInput } from "utils/mask";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import { useHistory } from "react-router-dom";
import InputSelect from "components/InputSelect";
import { useLoan } from "hooks/useLoan";
import { useState } from "react";
import OneButtonPopup from "components/modals/OneButtonPopup";
import themes from "styles";
import moment from "moment";

const inputStyle = {
  border: "1px solid #6c6c6c",
  padding: "1.7rem",
  borderRadius: ".5rem",
  width: "100%",
};

const WithDrawAdditionalData = () => {
  const history = useHistory();
  const initialState = {
    motherName: "",
    typeDocument: "",
    numberDocument: "",
    emissionDate: "",
    issuingAgency: "",
  };

  const [data, dispatch] = useReducer(reducer, initialState);
  const { userAditionalData, dispatchUserAditionalData, solicitationData } =
    useLoan();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpenError, setDialogOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const putUpdateAdditionalDataWithdraw = useApi(
    constants.endpoints.putUpdateAdditionalDataWithdraw.replace(
      "ID_LOAN",
      solicitationData.idSimulacao
    ),
    "PUT"
  );

  const typeDocuments = [
    { value: "nenhuma", label: "", hidden: true },
    { value: "RG", label: "RG" },
    { value: "CNH", label: "CNH" },
  ];

  function isValidDate(dateString) {
    const parsedDate = moment(dateString, "DD/MM/YYYY", true);

    if (!parsedDate.isValid()) {
      return false;
    }

    const minDate = moment().subtract(80, "years");
    const maxDate = moment().add(0, "day");

    return parsedDate.isBetween(minDate, maxDate, "day", "[]");
  }

  function reducer(data, action) {
    const { type, payload } = action;
    switch (type) {
      case "personalData":
        return {
          ...data,
          nome: payload.nome,
          dtNascimento: dateMask(
            payload.dataNascimento?.split("-").reverse().join("/")
          ),
          rg: payload.rg,
          nomeMae: payload.motherName,
          nomePai: payload.nomePai,
        };
      default:
        return { ...data, [type]: payload };
    }
  }
  useEffect(() => {
    if (userAditionalData) {
      dispatch({ type: "personalData", payload: userAditionalData });
    }
  }, []);

  const onChange = (e, mask) => {
    const {
      target: { name, value },
    } = e;
    dispatch({ type: name, payload: mask ? mask(value) : value });
  };

  const validateInputs = () => {
    const [firstMotherName, secondMotherName] = motherName?.split(" ");

    return (
      !motherName ||
      !firstMotherName ||
      !secondMotherName ||
      !typeDocument ||
      !numberDocument ||
      !issuingAgency ||
      emissionDate?.unmasked?.length < 8
    );
  };

  const {
    motherName,
    typeDocument,
    numberDocument,
    emissionDate,
    issuingAgency,
  } = data;

  const handleNext = async () => {
    try {
      const isDateValid = isValidDate(emissionDate?.masked);

      if (!isDateValid) {
        setDialogOpenError(true);
        setErrorMessage("Data de emissão é invalida!");
        return;
      }

      const payload = {
        dataEmissaoDocumento: moment(
          data.emissionDate.masked,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        nomeMae: data.motherName,
        numeroDocumento: data.numberDocument,
        orgaoExpedidorDocumento: data.issuingAgency,
        tipoDocumento: data.typeDocument,
      };
      const response = await putUpdateAdditionalDataWithdraw.callApi(payload);
      if (response.status >= 200 && response.status < 300) {
        dispatchUserAditionalData({
          ...userAditionalData,
          data,
        });
        history.push("/with-draw-bank-data");
      }
      if (response.status > 400) {
        setDialogOpenError(true);
        setErrorMessage(response.mensagem);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <St.SafeView>
      <Layout
        routeBack="/simulation-result-by-teu-card"
        title="Dados adicionais"
      >
        <St.Container>
          <OneButtonPopup
            text={"O empréstimo não está disponível para menores de 18 anos!"}
            buttonText="Entendi"
            title={"Que pena!"}
            visibility={dialogOpen}
            defaultButton
            onClose={() => history.push("opcoes-acesso")}
            setVisibility={setDialogOpen}
          />
          <OneButtonPopup
            text={errorMessage}
            buttonText="Entendi"
            title={"Atenção!"}
            visibility={dialogOpenError}
            iconModal={themes.components.png.emojiSad}
            defaultButton
            setVisibility={setDialogOpenError}
            onClose={() => setDialogOpenError(false)}
          />
          <St.TopSpace>
            <St.Title>
              Para continuarmos, precisamos de algumas informações adicionais:
            </St.Title>
            <div
              style={{
                display: "flex",
                gap: "2rem",
                justifyContent: "space-between",
              }}
            >
              <St.Inputs style={{ width: "100%" }}>
                <Input
                  name="motherName"
                  value={motherName}
                  onChange={(e) => onChange(e)}
                  id="motherName"
                  boldLabel
                  style={inputStyle}
                  label="Nome da Mãe*"
                />
                <InputSelect
                  labelFontWeight="bold"
                  type="select"
                  name="typeDocument"
                  values={typeDocuments}
                  value={typeDocument}
                  defaultValue={typeDocument}
                  style={{ ...inputStyle, padding: ".5rem 1rem" }}
                  label="Tipo de documento*"
                  max={15}
                  onChange={(e) => onChange(e)}
                />
                <Input
                  boldLabel
                  style={inputStyle}
                  id="numberDocument"
                  name="numberDocument"
                  placeholder="00.000.000-0"
                  type="text"
                  value={numberDocument}
                  max={15}
                  onChange={(e) => onChange(e)}
                  label="Número*"
                />
                <Input
                  boldLabel
                  name="emissionDate"
                  style={inputStyle}
                  label="Data emissão"
                  max={10}
                  value={emissionDate?.masked}
                  onChange={(e) => onChange(e, maskDateInput)}
                />
                <Input
                  boldLabel
                  style={inputStyle}
                  id="issuingAgency"
                  name="issuingAgency"
                  placeholder=""
                  type="text"
                  value={issuingAgency}
                  max={15}
                  onChange={(e) => onChange(e)}
                  label="Orgão expedidor*"
                />
              </St.Inputs>
            </div>

            <St.Footer>
              <Button
                style={{ width: "100%", justifySelf: "flex-end" }}
                buttonType="primary"
                text={"Avançar"}
                disabled={validateInputs()}
                onClick={() => {
                  handleNext();
                }}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default WithDrawAdditionalData;
