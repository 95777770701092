import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Heading from 'components/Heading'
import Form from 'components/Forms'
import Input from 'components/Input'
import Card from 'components/Card'
import Spinner from 'components/Spinner'
import { proposals } from 'components/Card/data'
import ButtonBack from 'components/ButtonBack'

import { gridTemplateFollowUp } from './constants'
import { cpfMask } from 'utils/mask'

import * as S from './styles'

function FollowUp() {
  const [name, setName] = useState()
  const [ cpf, setCpf ] = useState('')
  const [error] = useState()
  const [isLoading, setLoading] = useState(false)
  const [isCard, setIsCard] = useState(false)

  const history = useHistory()

  const checkConditions = () => name || cpf

  const handleDisableButton = () =>
    !checkConditions() || cpf?.masked.length !== 14 

  const handleSearch = () => {
    try {
      setLoading(true)
      setIsCard(true)
    } catch (error) {
      console.log(error)
    } finally {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }

  return (
    <S.Container>
      <S.Header>
        <ButtonBack onClick={() => history.goBack()}
        />
        <Heading variation="tertiary">Acompanhamento da Proposta</Heading>
      </S.Header>
      <S.TextBold>
        Preencha os campos abaixo com os seus dados pessoais, por favor.
      </S.TextBold>
      <Form gridTemplate={gridTemplateFollowUp}>
        <S.ContentInput>
          <Input
            id="name"
            name="userFullName"
            label="Nome completo"
            placeholder=""
            type="text"
            defaultValue={name}
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={error}
          />

          <Input
            id="cpf"
            name="cpf"
            label="CPF"
            placeholder="000.000.000-00"
            type="text"
            value={cpf?.masked}
            max={14}
            onChange={(e) => setCpf(cpfMask(e.target.value))}
          />
        </S.ContentInput>

        <S.ButtonOne
          buttonType="primary"
          text={isLoading === true ? <Spinner color="#FFF" /> : 'Buscar'}
          onClick={handleSearch}
          disabled={handleDisableButton()}
        />
      </Form>

      {!isCard &&
        proposals.map((proposals) => (
          <Card
            wh={700}
            key={proposals.id}
            value={proposals.value}
            name={proposals.name}
            typist={proposals.typist}
            date={proposals.date}
            status={proposals.status}
          />
        ))}
    </S.Container>
  )
}

export default FollowUp
