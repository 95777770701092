/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from "react";
import useReactRouter from "use-react-router";

import { FaCheck } from "react-icons/fa";

import Button from "components/Button";
import Heading from "components/Heading";
import Spinner from "components/Spinner";
import Form from "components/Forms";
import Input from "components/Input";
import OneButtonPopup from "components/modals/OneButtonPopup";
import ButtonBack from "components/ButtonBack";

import {
  passwordValidator,
  passwordConfirmationValidator,
  isSequencialNumbers,
} from "utils/validators";

import { gridTemplateForgot } from "./constants";
import * as S from "./styles";
import styles from "styles";
import constants from "utils/constants";
import useApi from "hooks/useApi";

const queryString = require("query-string");

const ForgotPassNew = (props) => {
  const parsed = queryString.parse(props.location.search);
  const { history } = useReactRouter();
  const [confirmationPopupVisibility, setOneButtonPopupVisibility] =
    useState(false);
  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confPassWord, setConfPassWord] = useState("");
  const [error, setError] = useState(false);
  const [msgDailog, setMsgDailog] = useState("");
  const [popUpErrorVisibility, setPopUpErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmationPopupVisibilityFeed, setOneButtonPopupVisibilityFeed] =
    useState(false);
  const [buttonIsEnable, setButtonIsEnable] = useState(false);

  function handleValidadeFields(event) {
    const fieldsForm = {
      password: passwordValidator(password).message || null,
      confPassWord:
        passwordConfirmationValidator(password, confPassWord).message || null,
    };

    setError(fieldsForm);
    if (!fieldsForm.password && !fieldsForm.confPassWord) {
      handleSubmit();
    }
  }

  const checkFormSequencialNumber = useMemo(
    () => (password.length ? !isSequencialNumbers(password) : false),
    [password]
  );
  const PostNewPassword = useApi(
    constants.endpoints.defineNewPassword.replace(
      "PARAM_ID_USER",
      parsed.idUser
    ),
    "POST"
  );
  async function handleSubmit() {
    setLoading(true);
    setButtonIsEnable(true);
    try {
      const response = await PostNewPassword.callApi({
        novaSenha: password,
      });

      if (response.status >= 200 && response.status < 300) {
        setOneButtonPopupVisibilityFeed(true);
        setMsgDailog("Senha alterada com sucesso!");
      } else {
        setPopUpErrorVisibility(true);
        setErrorMessage(response.mensagem);
      }
    } catch (err) {
      setMsgDailog(
        err.response.data.mensagem ||
          "Erro ao redefinir a tua senha. Tente novamente mais tarde."
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <S.Wrapper>
      <S.WrapperDivision>
        <S.Header>
          <ButtonBack onClick={() => history.goBack()} />
          <Heading variation="tertiary">Esqueci minha senha</Heading>
        </S.Header>
        <S.Text>Por favor, crie uma nova senha.</S.Text>
        <Form gridTemplate={gridTemplateForgot}>
          <Input
            id="password"
            name="password"
            label="Nova senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            maxLength={6}
            icon
            error={error.password}
          />
          <Input
            id="confPassWord"
            name="confPassWord"
            label="Confirme nova senha"
            value={confPassWord}
            maxLength={6}
            icon
            onChange={(e) => setConfPassWord(e.target.value)}
            error={error.confPassWord}
          />

          <S.CheckForm active={password.length === 6}>
            <div>
              <FaCheck />
            </div>
            <p>Senha de até 6 dígitos</p>
          </S.CheckForm>

          <S.CheckForm active={checkFormSequencialNumber}>
            <div>
              <FaCheck />
            </div>
            <p>Números não sequenciais</p>
          </S.CheckForm>
        </Form>
      </S.WrapperDivision>
      <Button
        text={isLoading === true ? <Spinner color="#FFF" /> : "Continuar"}
        buttonType="primary"
        onClick={handleValidadeFields}
        disabled={buttonIsEnable}
      />
      <OneButtonPopup
        defaultButton
        text={"Nova senha atualizada sucesso!"}
        buttonText="Entendi"
        visibility={confirmationPopupVisibility}
        setVisibility={(val) => {
          setOneButtonPopupVisibility(val);
          history.push("/");
        }}
      />
      <OneButtonPopup
        defaultButton
        img={styles.components.png.warning}
        text={msgDailog}
        buttonText="Entendi"
        visibility={confirmationPopupVisibilityFeed}
        setVisibility={(e) => {
          setOneButtonPopupVisibilityFeed(e);
          history.push("/");
        }}
      />
      <OneButtonPopup
        defaultButton
        img={styles.components.png.warning}
        text={errorMessage}
        buttonText="Entendi"
        visibility={popUpErrorVisibility}
        setVisibility={(e) => {
          setPopUpErrorVisibility(e);
          setButtonIsEnable(false);
        }}
      />
    </S.Wrapper>
  );
};

export default ForgotPassNew;
