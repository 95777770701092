import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Layout from "components/OldLayout";
import Loading from "components/Loading";
import Input from "components/Input";
import InputSelect from "components/InputSelect";
import Button from "components/Button";
import OneButtonPopup from "components/modals/OneButtonPopup";

import useApi from "hooks/useApi";
import constants from "utils/constants";
import Repository from "repository";

import * as S from "./styles";
import { cpfMask, phoneMask } from "utils/mask";

const DetailClientLeads = () => {
  const { state } = useLocation();

  const operador = useState(Repository.storage.get(Repository.keys.USER));
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [popUpMsg, setPopUpMsg] = useState();
  const [popUpIsError, setPopUpIsError] = useState(false);
  const [productType, setProductType] = useState(
    state?.cliente?.productType || "disabled"
  );
  const groupsProduct = [
    { value: "disabled", label: "Selecione" },
    { value: "CARTEIRA_DIGITAL", label: "Carteira Digital" },
  ];

  const history = useHistory();

  const POSTAddLeads = useApi(constants.endpoints.postAddLeads, "POST");

  const handleAddLeadService = async () => {
    setPopUpIsError(false);
    try {
      const payload = {
        celular: state?.cliente?.celular,
        cpf: state?.cliente?.cpf,
        email: state?.cliente?.email,
        nome: state?.cliente?.nome,
        endereco: {
          bairro: state?.cliente?.endereco?.bairro,
          cep: state?.cliente?.endereco?.cep,
          cidade: state?.cliente?.endereco?.cidade,
          complemento: state?.cliente?.endereco?.complemento,
          logradouro: state?.cliente?.endereco?.logradouro,
          numero: state?.cliente?.endereco?.numero,
          pais: state?.cliente?.endereco?.pais,
          uf: state?.cliente?.endereco?.uf,
        },
        operador: {
          idOperador: operador[0]?.id,
          nome: operador[0]?.nomeTitular,
        },
        produto: productType,
        tipoAcao: "SMS",
        tipoEvento: "LEAD",
      };

      const response = await POSTAddLeads.callApi(payload);

      if (response.status >= 200 && response.status < 300) {
        setPopUpMsg("SMS enviado com sucesso!!!");
        setPopUpIsOpen(true);
      } else {
        setPopUpMsg(response.data?.mensagem || "Erro ao enviar SMS!!!");
        setPopUpIsOpen(true);
        setPopUpIsError(true);
      }
    } catch (error) {
      setPopUpMsg("Erro ao enviar SMS!!!");
      setPopUpIsOpen(true);
      setPopUpIsError(true);
      console.error(error);
    }
  };

  const handleOperator = () => {
    const onlyWalet = state?.cliente?.eventos?.filter(
      (e) => e.produto === productType
    );

    return onlyWalet ? onlyWalet[0]?.operador?.nome : "";
  };

  const handleSendLeads = () => {
    const onlyWalet = state?.cliente?.eventos?.filter(
      (e) => e.produto === productType
    );

    return onlyWalet ? onlyWalet[0]?.status : "";
  };

  const buttonDisable = () => {
    return productType !== "disabled" && handleSendLeads() !== "ENVIADA";
  };

  return (
    <S.Container>
      <Loading status={POSTAddLeads.loading} />
      <Layout routeBack="/consult-leads" title="Detalhes do Lead"></Layout>
      <S.ContentInput>
        <S.ContentLeft>
          <Input
            name="nome"
            label="Nome Completo"
            type="text"
            value={state?.cliente?.nome}
            disabled
          />
          <Input
            name="cpf"
            label="CPF"
            type="text"
            value={cpfMask(state?.cliente?.cpf).masked}
            max={14}
            disabled
          />
          <Input
            name="email"
            label="E-mail"
            type="text"
            value={state?.cliente?.email}
            disabled
          />
          <Input
            name="celular"
            label="Telefone"
            type="text"
            value={phoneMask(state?.cliente?.celular).masked}
            disabled
          />
          <Input
            name="uf"
            label="UF"
            type="text"
            value={state?.cliente?.endereco?.uf}
            disabled
          />
          <Input
            name="operador"
            label="Operador"
            type="text"
            value={handleOperator()}
            disabled
          />
          <Input
            name="leadEnviado"
            label="Status lead"
            type="text"
            value={handleSendLeads()}
            disabled
          />
        </S.ContentLeft>
        <S.ContentRight>
          <InputSelect
            name="productType"
            id="productType"
            label="Escolher produto"
            type="select"
            value={productType}
            values={groupsProduct}
            onChange={(e) => setProductType(e.target.value)}
          />
        </S.ContentRight>
      </S.ContentInput>
      <Button
        buttonType="primary"
        text={"Enviar SMS"}
        disabled={!buttonDisable()}
        onClick={() => handleAddLeadService()}
        buttonSize="small"
        style={{ maxWidth: "300px" }}
      />
      <OneButtonPopup
        defaultButton
        text={popUpMsg}
        buttonText="Entendi"
        visibility={popUpIsOpen}
        setVisibility={() => {
          setPopUpIsOpen(false);
          !popUpIsError && history.push("/opcoes-acesso");
        }}
      />
    </S.Container>
  );
};

export default DetailClientLeads;
