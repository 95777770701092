import React from "react";
import { useState } from "react";
import * as St from "./styles";
import { moneyMask } from "utils/mask";
import Layout from "components/OldLayout";
import useApi from "hooks/useApi";
import Button from "components/Button";
import themes from "styles";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useLoan } from "hooks/useLoan";
import constants from "utils/constants";

const CreditCardLoanSimulatorValueResult = () => {
  const [title, setTitle] = useState("");
  const history = useHistory();
  const {
    simulationType,
    simulationData,
    userInitialData,
    dispatchUserAditionalData,
  } = useLoan();

  const {
    components: { png },
  } = themes;

  useEffect(() => {
    if (simulationType.type) {
      switch (simulationType.type) {
        case "limit":
          setTitle(`Valor do Limite Disponível`);
          break;
        case "parcel":
          setTitle(" Valor da Parcela ");

          break;
        case "withdraw":
          setTitle(" Valor do Saque ");

          break;
        default:
          break;
      }
    }
  }, [simulationType]);

  const RequestUserData = useApi(
    constants.endpoints.getClientDataCpf.replace(
      "PARAM_CPF",
      userInitialData?.cpf?.unmasked
    ),
    "GET"
  );

  const handleNext = async () => {
    const data = await RequestUserData.callApi();
    dispatchUserAditionalData(data.data);
    history.push("/credit-card-loan-simulator-aditional-data");
  };

  return (
    <St.SafeView>
      <Layout
        routeBack="/credit-card-loan-simulator-choose-type"
        title={
          <p>
            Resultado da simulação do <b>{title}</b>
          </p>
        }
      >
        <St.Container>
          <St.TopSpace>
            <St.ValueSimulate>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <St.SimulateEditTitle>
                    Tudo pronto! Libere agora esse valor na Tua conta bancária e
                    aproveite.
                  </St.SimulateEditTitle>
                </div>
                <St.Image>
                  <img src={png.star} />
                </St.Image>
                <St.MiddleContainerItems>
                  <St.ValueWrapper>
                    <St.TextItem>Valor Liberado</St.TextItem>
                    <St.TextItemValue>
                      {moneyMask(simulationData?.value_free)}
                    </St.TextItemValue>
                  </St.ValueWrapper>
                  <St.ValueWrapper>
                    <St.TextItem>Parcela</St.TextItem>
                    <St.TextItemValue>
                      12x de{" "}
                      {moneyMask(
                        simulationData?.installment_value ||
                          simulationData?.value_installment
                      )}
                    </St.TextItemValue>
                  </St.ValueWrapper>
                  <St.ValueWrapper>
                    <St.TextItem>Taxa de Juros</St.TextItem>
                    <St.TextItemValue>{`${simulationData?.interest_rate}%`}</St.TextItemValue>
                  </St.ValueWrapper>
                  <St.ValueWrapper>
                    <St.TextItem>Limite necessário no cartão</St.TextItem>
                    <St.TextItemValue>
                      {moneyMask(simulationData?.credit_limit)}
                    </St.TextItemValue>
                  </St.ValueWrapper>
                  <St.ValueWrapper>
                    <St.TextItem
                      style={{
                        width: "35rem",
                        marginTop: 30,
                        color: "#E4003A",
                        fontWeight: 700,
                      }}
                    >
                      ** O vencimento será de acordo com o vencimento da fatura
                      do teu cartão de crédito
                    </St.TextItem>
                  </St.ValueWrapper>
                </St.MiddleContainerItems>
              </div>
            </St.ValueSimulate>
            <St.Footer>
              <Button
                buttonType="secondary"
                style={{ marginBottom: "1rem" }}
                text={"Quero simular novamente"}
                onClick={() =>
                  history.push("/credit-card-loan-simulator-choose-type")
                }
              />
              <Button
                buttonType="primary"
                text={"Quero contratar!"}
                loading={RequestUserData.loading}
                onClick={() => handleNext()}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default CreditCardLoanSimulatorValueResult;
