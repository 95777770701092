/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import CamMaskDesktop from "assets/png/cameraSelfieMaskDesktop.png";
import DocumentMask from "assets/png/documentMask.png";
import RectangleDocument from "assets/png/rectangle_documents.png";
import * as St from "./styles";
import Chronometer from "./components/Chronometer";
import { WhiteFlash } from "./components/WhiteFlash";
import { getDataURI, playClickAudio } from "./helpers";
import Button from "components/Button";

export const FACING_MODES = {
  ENVIRONMENT: "environment",
  USER: "user",
};

if (navigator.mediaDevices.getUserMedia === undefined) {
  navigator.mediaDevices.getUserMedia = function (constraints) {
    var getUserMedia =
      navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

    if (!getUserMedia) {
      return Promise.reject(
        new Error("getUserMedia is not implemented in this browser")
      );
    }

    return new Promise(function (resolve, reject) {
      getUserMedia.call(navigator, constraints, resolve, reject);
    });
  };
}

function CameraComponent(
  {
    open,
    handleClose,
    onTakePhoto,
    facingMode = FACING_MODES.ENVIRONMENT,
    isStartLiveness,
    isLiveness = false,
    mask = false,
    maskDocument = false,
    maskDocumentIncomeAddress = false,
    doNotCloseAfterTakePicture = false,
    certifaceMessagebase64,
    disableCloseButton = false,
    disableChangeCamButton = false,
    disableTakePictureButton = false,
    stopWatch = false,
  },
  ref
) {
  const [cameraType, setCameraType] = useState(facingMode);
  const [isFaceMatch, setIsFaceMatch] = useState(false);
  const [isShowWhiteFlash, setIsShowWhiteFlash] = useState(false);
  const [messageImage, setMessageImage] = useState(
    certifaceMessagebase64 || null
  );

  // conjunto de variaveis utilizadas para Liveness
  const isChronometer = () => stopWatch && isFaceMatch; // Responsavel por colocar o cronometro na tela.

  // Esse ouvite ira execultar o startliveness quando for precionado o btn.
  useEffect(() => {
    if (isFaceMatch) {
      isStartLiveness();
    }
  }, [isFaceMatch]);

  // Verifica se a tela esta em fullscreen, do contrario coloca.
  // Apenas quando isLiveness for true.
  useEffect(() => {
    if (isLiveness && open) {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      }
    }
  }, [isLiveness, open]);

  // Verifica se tem msg para mostrar na camera.
  // seta a msg na cont [messageImage, setMessageImage].
  useEffect(() => {
    if (certifaceMessagebase64) {
      setMessageImage(certifaceMessagebase64);
    }
  }, [certifaceMessagebase64]);

  useEffect(() => {
    if (open) {
      startCamera();
    }
  }, [open]);

  useEffect(() => {
    return () => stopCamera();
  }, []);

  useImperativeHandle(ref, () => ({
    takePicture: () => {
      if (isFaceMatch) takePicture();
    },
    stopCamera: () => {
      if (isFaceMatch) onClickClose();
    },
  }));

  useEffect(() => {
    if (open) {
      startCamera();
    }
  }, [cameraType]);

  const startCamera = () => {
    const facingMode = getFacingMode();

    navigator.mediaDevices
      .getUserMedia(facingMode)
      .then(function (stream) {
        window.localStream = stream;
        const video = document.getElementById("liveness");
        if ("srcObject" in video) {
          video.srcObject = stream;
        } else {
          video.src = window.URL.createObjectURL(stream);
        }

        video.onloadedmetadata = function (e) {
          video.play();
        };
      })
      .catch(function (err) {
        console.error(err);
      });
  };

  const stopCamera = () => {
    if (window.localStream) {
      window.localStream.getTracks().forEach((track) => track.stop());
    }

    const video = document.getElementById("liveness");
    if (video) {
      video.srcObject = null;
    }

    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const onClickClose = () => {
    setIsFaceMatch(false);
    setMessageImage(null);
    stopCamera();
    handleClose();
  };

  const getFacingMode = () => {
    if (cameraType === FACING_MODES.ENVIRONMENT) {
      return {
        video: {
          facingMode: "environment",
          width: { min: 320, ideal: 1024, max: 1920 },
          height: { min: 480, ideal: 768, max: 1080 },
        },
      };
    } else {
      return {
        video: {
          facingMode: "user",
          width: { min: 320, ideal: 1024, max: 1920 },
          height: { min: 480, ideal: 768, max: 1080 },
        },
      };
    }
  };

  // Captura a imagem e envia para a funcao onTakePhoto().
  const takePicture = () => {
    var video = document.getElementById("liveness");

    setIsShowWhiteFlash(true);
    // Alerta sonoro de captura de imagem
    // playClickAudio();
    onTakePhoto(getDataURI(video));

    setTimeout(() => {
      setIsShowWhiteFlash(false);
      if (!doNotCloseAfterTakePicture) {
        onClickClose();
      }
    }, 900);
  };

  const Mask = () => {
    const styles = {
      container: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        zIndex: 999,
      },
      img: {
        height: "100%",
      },
    };

    return (
      <div style={styles.container}>
        <img style={styles.img} src={CamMaskDesktop} alt="mask" />
      </div>
    );
  };

  const MaskDocument = () => {
    const styles = {
      container: {
        height: "80%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        zIndex: 999,
      },
      img: {
        height: "60%",
      },
    };

    return (
      <div style={styles.container}>
        <img style={styles.img} src={DocumentMask} alt="maskDocument" />
      </div>
    );
  };

  const MaskDocumentIncomeAddress = () => {
    const styles = {
      container: {
        height: "80%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        left: 0,
        zIndex: 999,
      },
      img: {
        height: "60%",
      },
    };

    return (
      <div style={styles.container}>
        <img style={styles.img} src={RectangleDocument} alt="maskDocument" />
      </div>
    );
  };

  const changeCamera = () => {
    stopCamera();

    if (cameraType === FACING_MODES.ENVIRONMENT) {
      setCameraType(FACING_MODES.USER);
    } else {
      setCameraType(FACING_MODES.ENVIRONMENT);
    }
  };

  return open ? (
    <>
      {mask && <Mask />}
      {maskDocument && <MaskDocument />}
      {maskDocumentIncomeAddress && <MaskDocumentIncomeAddress />}

      {!disableCloseButton && <St.IconClose size={50} onClick={onClickClose} />}

      {!disableChangeCamButton && (
        <St.IconChangeCamera
          size={50}
          onClick={changeCamera}
          isLiveness={isLiveness}
        />
      )}

      {isLiveness && isFaceMatch === false ? (
        <St.DivBtn>
          <Button
            buttonType="primary"
            text="Estou pronto para iniciar a biometria facial"
            onClick={() => setIsFaceMatch(true)}
            id="button-submit-certiface"
            style={{ alignItems: "center" }}
          />
        </St.DivBtn>
      ) : (
        <></>
      )}

      {isChronometer() && <Chronometer reset={certifaceMessagebase64} />}

      {messageImage && (
        <St.CertifaceMessageSteps
          src={"data:image/png;base64," + messageImage}
        />
      )}

      {!disableTakePictureButton && <St.Button onClick={takePicture} />}

      <WhiteFlash isShowWhiteFlash={isShowWhiteFlash} />

      <St.Camera id="liveness" mirroring={cameraType === FACING_MODES.USER} />
    </>
  ) : (
    <></>
  );
}

export default forwardRef(CameraComponent);
