import styled from "styled-components";
import media from "styled-media-query";

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 37px;
  grid-row-gap: 29px;
  align-items: center;
  justify-items: center;
`;

export const DivCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;

  input[type="checkbox"] {
    width: 22px;
    height: 22px;
    display: inline-block;
    margin-right: 10px;
    filter: hue-rotate(135deg);
    cursor: pointer;
  }

  span {
    width: 35vw;
    font-size: 12px;
  }
  label {
    position: relative;
    margin-right: 5px;
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
    color: ${({
      theme: {
        colors: { grayDark },
      },
    }) => grayDark};

    &::before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 14px;
      border: 1px solid #afafaf;
      margin-right: 13px;
      margin-top: 8px;
      margin-bottom: -3px;
    }
  }

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
  `}
`;

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

export const Select = styled.div`
  width: 70%;
`;

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const MiddleContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: baseline;
  font-size: 1.5rem;
  margin-top: 5rem;
  margin-bottom: 7rem;
`;

export const Rounded = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #f57e38;
`;

export const Footer = styled.div`
  margin-top: 25px;
  position: fixed;
  bottom: 0;
  width: 40%;
  display: flex;

  @media (max-width: 712px) {
    padding: 20px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
`;
