/* eslint-disable consistent-return */
import { useState } from "react";
import Repository from "repository";

function useApi(url, method, body = {}, headers = {}, tryNewWay = false) {
  const [responseApi, setResponseApi] = useState({
    status: null,
    loading: false,
    error: null,
    data: null,
    success: false,
    response: null,
    mensagem: null,
  });
  const ApiMethod = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
  };
  const callApi = (reqBody = {}) => FetchData(reqBody);

  const getOptions = (reqBody = {}, url = "") => {
    const reqHeaders = new Headers();
    const token = Repository.storage.get("@NovaEra:tokenUser");
    const tokenBearer = Repository.storage.get("@NovaEra:tokenBearer");
    const xCode = Repository.session.get(Repository.keys.X_CODE);
    const faceID = Repository.session.get("@NovaEra:faceID");
    const { userAgent } = navigator;
    const ipAdress = Repository.storage.get(Repository.keys.IP_ADRESS);
    const sessionId = Repository.storage.get(Repository.keys.SESSION_ID);
    const codeTwoFactor = Repository.storage.get(
      Repository.keys.CODE_TWO_FACTOR
    );
    const user = Repository.storage.get(Repository.keys.USER);
    reqHeaders.set("Content-Type", "application/json");
    if (token) reqHeaders.set("apikey", `token ${token}`);
    if (tokenBearer) reqHeaders.set("Authorization", `Bearer ${tokenBearer}`);
    if (xCode) reqHeaders.set("xcode", xCode);
    if (faceID) reqHeaders.set("x-code", faceID);
    if (user) reqHeaders.set("Id-User", user?.id);

    if (sessionId) reqHeaders.set("Id-Sessao", sessionId);
    reqHeaders.set("Id-Sistema", `${process.env.REACT_APP_ID_SISTEMA}`);
    if (userAgent) reqHeaders.set("User-Agent", userAgent);
    if (codeTwoFactor) reqHeaders.set("X-Code", codeTwoFactor);
    if (ipAdress) reqHeaders.set("X-Sent-By", ipAdress);

    if (headers) Object.assign(reqHeaders, headers);

    let newBody = reqBody || body;

    if (!Array.isArray(body) && !Array.isArray(reqBody)) {
      newBody = Object.assign(body, reqBody);
    }

    let options = {
      method,
      headers: reqHeaders,
    };

    if (method !== ApiMethod.GET) {
      options = {
        ...options,
        body: JSON.stringify(newBody) || null,
      };
    }

    return options;
  };

  const FetchData = async (reqBody = {}) => {
    setResponseApi({ ...responseApi, loading: true });

    const options = getOptions(reqBody, url);
    let copyResponse;
    let json = null;
    let newUrl = url;
    let res;
    try {
      // transforma um objeto em query params no metodo GET
      if (options.method === ApiMethod.GET && tryNewWay) {
        // exclui parametros vazios, nulos ou undefined
        Object.keys(reqBody).forEach((e) => {
          if (
            reqBody[e] === "" ||
            reqBody[e] === undefined ||
            reqBody[e] === null
          ) {
            delete reqBody[e];
          }
        });
        const bodyParams = new URLSearchParams(reqBody).toString();
        newUrl = `${url}?${bodyParams}`;
        res = await fetch(newUrl, options);
      } else {
        res = await fetch(url, options);
      }
      copyResponse = res.clone();
      const statusResponse = res.status;

      if (res.status === 401 || res.status === 403) {
        localStorage.removeItem("@NovaEra:user");
        localStorage.removeItem("@NovaEra:token");
        localStorage.removeItem("@NovaEra:tokenUser");
        localStorage.removeItem("@NovaEra:tokenCartao");
        localStorage.removeItem("@NovaEra:idOrigem");
      }

      const contentType = res.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        json = await res.json();
      }

      setResponseApi({
        loading: false,
        data: json,
        error: null,
        status: statusResponse,
        success: true,
        response: res,
        mensagem: json ? json.mensagem : "",
      });
    } catch (err) {
      setResponseApi({
        loading: false,
        data: null,
        error: err.message,
        status: copyResponse.status,
        success: false,
        response: null,
        mensagem: null,
      });
    }

    return {
      status: copyResponse.status,
      data: json,
      response: copyResponse,
      mensagem: json ? json.mensagem : "",
      headers: copyResponse.headers,
    };
  };

  return { callApi, ...responseApi };
}

export default useApi;
