import styled from "styled-components";
// import { Link as RouterLink } from 'react-router-dom'
import { ArrowBack } from "@styled-icons/boxicons-regular/ArrowBack";
import media from "styled-media-query";

export const Container = styled.div`
  margin: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  margin: auto;
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
`;

export const Signature = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 350px;
  border: 1px solid #e71037;
  border-radius: 2rem;
  background-color: rgb(231 16 55 / 13%);
  padding: 20px;

  margin-bottom: ${({
    theme: {
      spacings: { xsmall },
    },
  }) => xsmall};

  .signatureCanvas {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    background-color: rgba(231, 16, 55, 0.4);
  }
`;

export const Text = styled.p`
  text-align: left;
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular },
    },
  }) => fontWeightRegular};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  margin-top: 45px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  .button {
    width: 48%;
    margin: 10px;
  }
`;
