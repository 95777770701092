import { useContext } from "react";
import { LoanContext } from "../context/LoanContext";

export function useLoan() {
  const context = useContext(LoanContext);

  if (!context) {
    throw new Error("useLoan must be used within an LoanContext");
  }

  return context;
}
