import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  grid-area: ${({ name }) => name};
  height: 58px;
  margin: 0 10px;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  position: relative;
`

export const RadioInput = styled.input.attrs({
  type: 'radio'
})`
  width: 20px;
  height: 20px;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
  position: relative;

  &::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid #afafaf;
    margin-right: 20px;
    visibility: visible;
    border-radius: ${({
      theme: {
        borderRadius: { borderRadiusCircle }
      }
    }) => borderRadiusCircle};
  }

  &:checked + ::before {
    content: '';
    width: 12px;
    height: 12px;
    visibility: visible;
    display: inline-block;
    position: absolute;
    top: 22px;
    left: 3px;
    background: #e71037;
    border: 1px solid #e71037;
    border-radius: ${({
      theme: {
        borderRadius: { borderRadiusCircle }
      }
    }) => borderRadiusCircle};
  }
`

export const RadioLabel = styled.label`
  font-size: ${({
    theme: {
      sizes: { small }
    }
  }) => small};
  color: ${({
    theme: {
      colors: { grayDark }
    }
  }) => grayDark};
  line-height: ${({
    theme: {
      spacings: { small }
    }
  }) => small};
  display: flex;
  align-items: center;
  position: relative;

  ${media.greaterThan('medium')`
    font-size: ${({
      theme: {
        sizes: { small }
      }
    }) => small};
  `}

  >label {
    margin-left: 5px;
  }
`
