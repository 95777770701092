import React from "react";
import ContainerFlex from "components/ContainerFlex";
import * as S from "./styles";
import { moneyMask } from "utils/mask";
import Dialog from "components/dialog";
import assets from "assets";
import { useState } from "react";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import { useMemo } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { openInNewTab, openPdfInNewWindow } from "utils/functions";

export default function BenefitTable({
  idProposal,
  contractedBenefits,
  canceledBenefits,
  isProposalCompleted,
  isCancel,
  title,
  categoryCard,
}) {
  const [benefitDetailsDialogOpen, setBenefitDetailsDialogOpen] =
    useState(false);
  const [benefitDetailsDialog, setBenefitDetailsDialog] = useState({
    title: null,
    description: null,
    link: null,
    id: null,
  });
  const [url, setUrl] = useState("");
  const [benefitCategory, setBenefitCategory] = useState("");
  const [contractDialogOpen, setContractDialogOpen] = useState(false);
  const [contractDialog, setContractDialog] = useState({ mensagem: "" });

  //contrato sem os dados do cliente
  const handleCheckBenefitLink = (link) => {
    const a = document.createElement("a");
    a.href = link;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
  };

  //contrato com os dados do cliente
  const getBenefitContract = useApi(url, "GET");

  const getPlatinumContract = useApi(url, "GET");

  const handleCheckBenefitContract = useCallback(async () => {
    try {
      const { data, status, mensagem } = await getBenefitContract.callApi();
      if (status >= 200 && status <= 300) {
        //se nao tiver mensagem, o contrato esta pronto
        if (!mensagem) {
          handleCheckBenefitLink(data?.linkContrato);
          setUrl(null);
        } else {
          setContractDialog({ mensagem });
          setContractDialogOpen(true);
          setUrl(null);
        }
      } else {
        setContractDialog({ mensagem });
        setContractDialogOpen(true);
        setUrl(null);
      }
    } catch (err) {
      setContractDialog("Houve um erro, tente novamente mais tarde!");
      setContractDialogOpen(true);
      setUrl(null);
    }
  }, [getBenefitContract, url]);

  const handleCheckPlatinumContract = useCallback(async () => {
    try {
      const response = await getPlatinumContract.callApi();
      if (response.status >= 200 && response.status <= 300) {
        openInNewTab(response.response);
      } else {
        setContractDialog(response.mensagem);
        setContractDialogOpen(true);
        setUrl(null);
      }
    } catch (err) {
      setContractDialog("Houve um erro, tente novamente mais tarde!");
      setContractDialogOpen(true);
      setUrl(null);
    }
  }, [getBenefitContract, url]);

  useEffect(() => {
    if (url && benefitCategory !== "Platinum") {
      handleCheckBenefitContract();
    }
    if (url && benefitCategory === "Platinum") {
      handleCheckPlatinumContract();
    }
  }, [url]);

  return (
    <ContainerFlex align={"normal"} marginTop={10} marginBottom={20}>
      <Dialog
        widthInPercent={30}
        icon={assets.png.exclamation}
        open={contractDialogOpen}
        positiveLabel="Confirmar"
        positiveAction={() => {
          setContractDialogOpen(false);
        }}
        title={""}
      >
        <span
          style={{ fontSize: "1.3em", fontWeight: 600, textAlign: "center" }}
        >
          {contractDialog.mensagem}
        </span>
      </Dialog>
      <Dialog
        widthInPercent={30}
        icon={assets.png.exclamation}
        open={benefitDetailsDialogOpen}
        negativeLabel="Voltar"
        negativeAction={() => setBenefitDetailsDialogOpen(false)}
        positiveLabel="Mais Detalhes"
        positiveAction={() => {
          handleCheckBenefitLink(benefitDetailsDialog.link);
        }}
        title={
          <span style={{ color: "red" }}>{benefitDetailsDialog.title}</span>
        }
      >
        <span
          style={{ fontSize: "1.3em", fontWeight: 600, textAlign: "center" }}
        >
          {benefitDetailsDialog.description}
        </span>
      </Dialog>
      {isCancel ? (
        <>
          {canceledBenefits.length > 0 ? (
            <>
              <S.TableTitle>Beneficios Cancelados</S.TableTitle>
              <S.Table>
                <tr>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th>Parcela (R$)</th>
                  <th>Contrato</th>
                </tr>
                {canceledBenefits.map((benefit, id) => (
                  <tr key={benefit.nome + id.toString()}>
                    <td>{benefit.nome}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <span
                          style={{
                            maxWidth: "46ch",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {benefit.descricao?.replace(",", "")}
                        </span>

                        <img
                          style={{ cursor: "pointer" }}
                          width={15}
                          height={15}
                          src={assets.png.exclamation}
                          alt="informações"
                          onClick={(e) => {
                            setBenefitDetailsDialog({
                              id: benefit.id,
                              title: benefit.nome,
                              description: benefit.descricao,
                              link: benefit.link,
                            });
                            setBenefitDetailsDialogOpen(true);
                          }}
                        />
                      </div>
                    </td>
                    <td>{moneyMask(benefit.valorMensalidade)}</td>
                    <td>
                      <>
                        {isProposalCompleted ? (
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setUrl(
                                constants.endpoints.getBenefitSignedContract
                                  .replace("PARAM_ID_PROPOSAL", idProposal)
                                  .replace("PARAM_ID_BENEFIT", benefit.id)
                              );
                            }}
                          >
                            Visualizar
                          </span>
                        ) : (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              benefit?.beneficioContratoPadrao ||
                              benefit?.linkContrato
                            }
                          >
                            Visualizar
                          </a>
                        )}
                      </>
                    </td>
                  </tr>
                ))}
              </S.Table>
            </>
          ) : null}
        </>
      ) : (
        <>
          {contractedBenefits.length > 0 ? (
            <>
              <S.TableTitle>
                {title ? title : "Beneficios Contratados"}
              </S.TableTitle>
              <S.Table>
                <tr>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th>Parcela (R$)</th>
                  <th>Contrato</th>
                </tr>
                {contractedBenefits.map((benefit, id) => (
                  <tr key={benefit.nome + id.toString()}>
                    <td>{benefit.nome}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <span
                          style={{
                            maxWidth: "46ch",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {benefit?.descricao?.replace(",", "")}
                        </span>

                        <img
                          style={{ cursor: "pointer" }}
                          width={15}
                          height={15}
                          src={assets.png.exclamation}
                          alt="informações"
                          onClick={(e) => {
                            setBenefitDetailsDialog({
                              id: benefit.id,
                              title: benefit.nome,
                              description: benefit.descricao,
                              link: benefit.link,
                            });
                            setBenefitDetailsDialogOpen(true);
                          }}
                        />
                      </div>
                    </td>
                    <td>{moneyMask(benefit.valorMensalidade)}</td>
                    <td>
                      <>
                        {isProposalCompleted ? (
                          categoryCard === "Platinum" &&
                          benefit?.categoria === "Platinum" ? (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setUrl(
                                  constants.endpoints.getPlatinumTerms.replace(
                                    "PARAM_ID_PROPOSAL",
                                    idProposal
                                  )
                                );
                                setBenefitCategory(benefit?.categoria);
                              }}
                            >
                              Visualizar
                            </span>
                          ) : (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setUrl(
                                  constants.endpoints.getBenefitSignedContract
                                    .replace("PARAM_ID_PROPOSAL", idProposal)
                                    .replace("PARAM_ID_BENEFIT", benefit.id)
                                );
                                setBenefitCategory(benefit?.categoria);
                              }}
                            >
                              Visualizar
                            </span>
                          )
                        ) : (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              benefit?.beneficioContratoPadrao ||
                              benefit?.linkContrato
                            }
                          >
                            Visualizar
                          </a>
                        )}
                      </>
                    </td>
                  </tr>
                ))}
              </S.Table>
            </>
          ) : (
            <>
              <S.TableTitle>Beneficios Contratados</S.TableTitle>
              <S.Table>
                <tr>
                  <th>Nome</th>
                  <th>Descrição</th>
                  <th>Parcela (R$)</th>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <S.TableTitle>Nenhum benefício contratado</S.TableTitle>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </S.Table>
            </>
          )}
        </>
      )}
    </ContainerFlex>
  );
}
