/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Layout from "components/OldLayout";
import InputSelectSearch from "components/SelectSearch/inputSelect";
import InputSelect from "components/InputSelect";
import Input from "components/Input";
import Button from "components/Button";
import Loading from "components/Loading";

import { useLoan } from "hooks/useLoan";
import useApi from "hooks/useApi";
import constants from "utils/constants";

import * as St from "./styles";

const inputStyle = {
  border: "1px solid #6c6c6c",
  padding: "1rem",
  borderRadius: ".5rem",
  width: "100%",
  height: "4rem",
};

const BankingDataLoanSeeker = () => {
  const [banks, setBanks] = useState([]);
  const [filteredBanks, setFilteredBanks] = useState(banks);
  const [openSelect, setOpenSelect] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const { bankData, dispatchBankData, userAditionalData } = useLoan();

  const history = useHistory();

  const initialState = {
    bank: "",
    agency: "",
    account: "",
    accountDigit: "",
    accountType: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { bank, agency, account, accountDigit, accountType } = state;

  const GetBanks = useApi(constants.endpoints.getBanksByEasyCredito, "GET");

  function reducer(data, action) {
    const { type, payload } = action;
    return { ...data, [type]: payload };
  }

  const accountTypes = [
    { value: "disabled", label: "Selecione", hidden: true },
    { value: "CONTA_CORRENTE_INDIVIDUAL", label: "Conta Corrente" },
    { value: "CONTA_POUPANCA_INDIVIDUAL", label: "Conta Poupança" },
    { value: "CONTA_CORRENTE_CONJUNTA", label: "Conta Corrente Conjunta" },
    { value: "CONTA_POUPANCA_CONJUNTA", label: "Conta Poupança Conjunta" },
  ];

  const onChange = (e) => {
    const {
      target: { name, value },
    } = e;
    dispatch({ type: name, payload: value });
  };

  const validInputs = () => {
    return !bank || !agency || !account;
  };

  useEffect(() => {
    const getBanks = async () => {
      const { data } = await GetBanks.callApi();
      if (data) {
        const orderBanks = data
          ?.sort((a, b) => Number(b.code) + Number(a.code))
          ?.map((e) => ({
            value: e.code,
            label: e.name,
            name: e.name,
            code: e.code,
          }));
        setBanks(orderBanks);
        setFilteredBanks(orderBanks);
      }
    };
    getBanks();
  }, []);

  const handleFilterBanks = (e) => {
    const { value } = e.target;
    dispatch({ type: "bank", payload: value });
    setFilteredBanks(
      banks.filter((bank) =>
        bank?.label?.toLowerCase().trim().includes(value?.toLowerCase())
      )
    );
  };

  const handleNext = () => {
    setButtonIsLoading(true);
    try {
      dispatchBankData({ ...bankData, state });
      history.push("/simulation-loan-seeker");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <St.SafeView>
      <Layout
        routeBack="/residential-data-loan-seeker"
        title="Informe teus dados bancários"
      />
      <Loading status={GetBanks.loading} />
      <St.Container>
        <St.TopSpace>
          <St.Inputs style={{ width: "100%" }}>
            <InputSelectSearch
              labelFontWeight="bold"
              label="Código e Descrição do Banco*"
              style={inputStyle}
              name="bank"
              options={filteredBanks}
              value={bank?.label}
              id="bank"
              inputTextValue={bank?.label}
              defaultValue={bank?.label}
              onFocus={() => setOpenSelect(true)}
              onChange={handleFilterBanks}
              onClickSelect={(e) => dispatch({ type: "bank", payload: e })}
              open={openSelect}
              setOpen={setOpenSelect}
              placeholder="Selecione"
            />
            <InputSelect
              labelFontWeight="bold"
              label="Tipo de conta*"
              style={{ ...inputStyle, padding: ".5rem 1rem" }}
              values={accountTypes}
              value={accountType}
              defaultValue={accountType}
              type="select"
              name="accountType"
              onChange={(e) => onChange(e)}
            />
            <Input
              label="Agência*"
              boldLabel
              name="agency"
              type="text"
              value={agency}
              style={inputStyle}
              onChange={(e) => onChange(e)}
            />
            <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <div style={{ width: "80%" }}>
                <Input
                  label="Conta*"
                  boldLabel
                  name="account"
                  type="text"
                  value={account}
                  style={inputStyle}
                  onChange={(e) => onChange(e)}
                  max={12}
                />
              </div>
              <div>
                <Input
                  label="Digito"
                  boldLabel
                  name="accountDigit"
                  type="text"
                  value={accountDigit}
                  style={inputStyle}
                  onChange={(e) => onChange(e)}
                  max={1}
                />
              </div>
            </div>
          </St.Inputs>
          <St.MiddleContainer>
            <St.Rounded />
            <p style={{ width: "100%" }}>
              Lembre-se, que estes valores podem variar de acordo com o critério
              de cada instituição financeira!
            </p>
          </St.MiddleContainer>
          <St.Footer>
            <Button
              buttonType="primary"
              text="Avançar"
              style={{ width: "100%", justifySelf: "flex-end" }}
              disabled={validInputs()}
              loading={buttonIsLoading}
              onClick={() => handleNext()}
            />
          </St.Footer>
        </St.TopSpace>
      </St.Container>
    </St.SafeView>
  );
};

export default BankingDataLoanSeeker;
