import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import useReactRouter from "use-react-router";
import SignaturePad from "react-signature-canvas";

import Button from "components/Button";
import Spinner from "components/Spinner";
import Layout from "components/OldLayout";
import TwoButtonPopup from "components/modals/TwoButtonPopup";

import * as S from "./styles";
import BenefitTable from "components/BenefitTable";
import ContainerFlex from "components/ContainerFlex";
import themes from "styles";
import OneButtonPopup from "components/modals/OneButtonPopup";
import Loading from "components/Loading";
import Input from "components/Input";
import moment from "moment";
import constants from "utils/constants";
import useApi from "hooks/useApi";

const SignatureNewBenefit = () => {
  const [isLoading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [openDialog, setopenDialog] = useState(false);
  const [signatureImage, setSignatureImage] = useState("");
  const [isSigning, setIsSigning] = useState(false);
  const [msgDialog, setMsgDialog] = useState("");
  const [dialogIsOpen, setDialogIsOpen] = useState("");
  const [errorDialogMsg, setErrorDialogMsg] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState("");
  const { history } = useReactRouter();
  const sigCanvas = useRef();
  const { state } = useLocation();

  //img tratada pra mandar pro back
  const formattedSignatureImage = signatureImage?.split(",")[1];

  const PostSignProposalBenefit = useApi(
    constants.endpoints.signProposalBenefit,
    "POST"
  );

  const clear = () => {
    sigCanvas.current.clear();
    setDisabledButton(true);
  };

  const save = () => {
    setLoading(true);
    setSignatureImage(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setIsSigning(false);
    setopenDialog(false);
    setLoading(false);
  };

  const handleSignBenefit = async () => {
    const benefitToBeContracted = state.benefit[0];

    const benefitObject = {
      assinada: true,
      idBeneficio: benefitToBeContracted.id,
      idOperador:
        benefitToBeContracted.idOperador ||
        JSON.parse(localStorage.getItem("@NovaEra:user")).id,
    };

    const payload = {
      data: moment(new Date()).format("YYYY-MM-DD"),
      assinaturaImage: formattedSignatureImage,
      id: state.idProposal,
      beneficios: [benefitObject],
      tema: state.themeSelected,
      celularZeuss: state.phone,
    };

    const { status, mensagem } = await PostSignProposalBenefit.callApi(payload);
    if (status >= 200 && status <= 300) {
      setMsgDialog("Benefício aderido com sucesso!");
      setDialogIsOpen(true);
    } else {
      setErrorDialogMsg(mensagem);
      setErrorDialogOpen(true);
    }
  };

  return (
    <S.Wrapper>
      <Layout title="Adesão de benefício" goBack={history.goBack}>
        <Loading status={PostSignProposalBenefit.loading} />
        <TwoButtonPopup
          text="Tem certeza que finalizou a assinatura?"
          visibility={openDialog}
          buttonText="Sim"
          onClick={save}
          buttonCancelText="Não"
          setVisibility={(val) => {
            setopenDialog(val);
          }}
        />
        {/* dialog de sucesso */}
        <OneButtonPopup
          defaultButton
          text={msgDialog}
          onClose={() =>
            history.push("/assinatura-beneficio-completa", {
              email: state.email,
              idProposal: state.idProposal,
              benefit: state.benefit,
            })
          }
          buttonText="Entendi"
          visibility={dialogIsOpen}
          setVisibility={() => setDialogIsOpen(false)}
        />
        {/* dialog de erro */}
        <OneButtonPopup
          defaultButton
          text={errorDialogMsg}
          onClose={() => history.push("/opcoes-acesso")}
          buttonText="Entendi"
          visibility={errorDialogOpen}
          setVisibility={() => setErrorDialogOpen(false)}
        />
        <ContainerFlex marginTop={20} marginBottom={20}>
          <BenefitTable
            idProposal={state.idProposal}
            contractedBenefits={state.benefit}
          />
        </ContainerFlex>
        {!isSigning ? (
          <>
            <S.Text>Toque para assinar o contrato do benefício</S.Text>
            <S.BoxSignature
              onClick={() => setIsSigning(true)}
              // value={signatureImage}
            >
              <S.LineSignature signature={!!signatureImage}>
                {signatureImage ? (
                  <S.ImgURL src={signatureImage} alt="Assinatura Digital" />
                ) : (
                  themes.components.icons.shape
                )}
              </S.LineSignature>
            </S.BoxSignature>
            <Input
              id="dateSignature"
              name="dateSignature"
              label="Data"
              type="text"
              value={moment(new Date()).format("DD/MM/YYYY")}
            />
            <S.Buttons>
              <Button
                buttonType="secondary"
                text="Voltar"
                onClick={history.goBack}
                className="button"
              />
              <Button
                buttonType="primary"
                text={isLoading ? <Spinner color="#FFF" /> : "Concluir"}
                onClick={() => handleSignBenefit()}
                disabled={disabledButton}
                className="button"
              />
            </S.Buttons>
          </>
        ) : null}
        {isSigning ? (
          <>
            <S.Infos>
              <S.Signature>
                <SignaturePad
                  clearOnResize={false}
                  ref={sigCanvas}
                  canvasProps={{
                    className: "signatureCanvas",
                  }}
                  onEnd={() => setDisabledButton(false)}
                  onBegin={() => setDisabledButton(true)}
                />
              </S.Signature>
              <S.Text>Assine dentro da caixa</S.Text>

              {signatureImage ? (
                <img
                  src={signatureImage}
                  alt="Assinatura Digital"
                  style={{
                    display: "none",
                    margin: "0 auto",
                  }}
                />
              ) : null}
            </S.Infos>
            <Input
              id="dateSignature"
              name="dateSignature"
              label="Data"
              type="text"
              value={moment(new Date()).format("DD/MM/YYYY")}
            />
            <S.Buttons>
              <Button
                buttonType="secondary"
                text="Limpar"
                onClick={clear}
                className="button"
              />
              <Button
                buttonType="primary"
                text={isLoading ? <Spinner color="#FFF" /> : "Concluir"}
                onClick={() => setopenDialog(true)}
                disabled={disabledButton}
                className="button"
              />
            </S.Buttons>
          </>
        ) : null}
      </Layout>
    </S.Wrapper>
  );
};

export default SignatureNewBenefit;
