import { phoneMask } from "utils/mask";
export const verifyDisabled = ({
  email,
  nome,
  celular,
  celular2,
  cep,
  logradouro,
  numero,
  cidade,
  bairro,
  uf,
  genero,
  dataNascimento,
  profissao,
  empresa,
  diaVencimento,
  renda,
  setDisabledButton,
  empregoFormal,
  aposentadoPensionista,
  socialName,
  nomeSocial,
  step,
  userPicture,
  documentsPicture1,
  documentsPicture2,
  errorUploadUserPicture,
  errorUploadDocumentFront,
  errorUploadDocumentBack,
  refName,
  refTel,
  refRelacao,
  refNameII,
  refTelII,
  refRelacaoII,
  isMinor,
}) => {
  switch (step) {
    case 2:
      const errors = {};

      if (!nome?.trim().length) {
        errors.nome = "Informe o nome corretamente";
      }

      if (
        !/^[A-Za-z0-9](([_.-]?[a-zA-Z0-9]+)*)@([a-zA-Z0-9]+)(([.-]?[a-zA-Z0-9]+)*)\.([A-Za-z]){2,}$/.test(
          email
        )
      ) {
        errors.email = "Informe um endereço de e-mail válido";
      }

      if (!celular || phoneMask(celular)?.unmasked?.trim().length < 11) {
        errors.celular = "Informe um celular";
      }

      if (!celular2 || phoneMask(celular2)?.unmasked?.trim().length < 11) {
        errors.celular2 = "Informe um celular";
      }

      if (cep?.masked?.trim().length < 9) {
        errors.cep = "Informe o cep corretamente";
      }

      if (!cep?.masked?.trim().length) {
        errors.cep = "Informe o cep corretamente";
      }

      if (
        [
          errorUploadUserPicture,
          errorUploadDocumentFront,
          errorUploadDocumentBack,
        ].includes(true)
      ) {
        errors.pictures = "Faça upload das fotos requisitadas";
      }

      if (!logradouro?.trim().length) {
        errors.logradouro = "Informe o logradouro corretamente";
      }

      if (!numero?.trim().length) {
        errors.numero = "Informe o numero corretamente";
      }

      if (!cidade?.trim().length) {
        errors.cidade = "Informe a cidade corretamente";
      }

      if (!bairro?.trim().length) {
        errors.bairro = "Informe o bairro corretamente";
      }

      if (!genero?.trim().length || genero === "disabled") {
        errors.genero = "Informação obrigatória";
      }

      if (dataNascimento?.masked?.length !== 10) {
        errors.dataNascimento = "Informe a data de nascimento corretamente";
      }

      if (!uf?.trim().length || uf === "disabled") {
        errors.uf = "Informação obrigatória";
      }

      if (!renda || renda <= 0) {
        errors.renda = "Informe a renda corretamente";
      }

      if (empregoFormal !== "true" && empregoFormal !== "false") {
        errors.empregoFormal = "Informe se possui um emprego formal";
      }

      if (empregoFormal === "true" && !profissao?.trim().length) {
        errors.profissao = "Informe sua profissão";
      }

      if (socialName !== "true" && socialName !== "false") {
        errors.socialName = "Informe se possui um nome social";
      }

      if (socialName === "true") {
        if (!nomeSocial?.trim().length) {
          errors.nomeSocial = "Informe o nome social";
        } else if (nomeSocial.toUpperCase() === nome.toUpperCase()) {
          errors.nomeSocial = "O nome social não pode ser igual ao nome";
        }
      }

      if (
        aposentadoPensionista !== "true" &&
        aposentadoPensionista !== "false"
      ) {
        errors.aposentadoPensionista = "Informe se é aposentado ou pensionista";
      }

      if (aposentadoPensionista === "true" && empregoFormal === "true") {
        errors.aposentadoPensionista =
          "Não é possível selecionar as duas opções como SIM";
      }

      if (empregoFormal === "true" && !empresa?.trim().length) {
        errors.empresa = "Informe a empresa que trabalha";
      }

      if (diaVencimento === undefined) {
        errors.diaVencimento = "Escolha uma data de vencimento";
      }

      if (!documentsPicture1 || !documentsPicture2) {
        errors.documentsPicture = "Faça upload dos documentos";
      }

      if (!userPicture) {
        errors.userPicture = "Faça upload de sua foto";
      }

      if (!refName?.trim().length) {
        errors.refName = "Informe o nome de uma referência pessoal";
      }

      if (!refTel || refTel?.unmasked?.trim().length < 11) {
        errors.refTel = "Informe um nr. de telefone da referência";
      }

      if (!refRelacao?.trim().length) {
        errors.refRelacao = "Informação obrigatória";
      }

      if (!refNameII?.trim().length) {
        errors.refNameII = "Informe o nome de uma referência pessoal";
      }

      if (!refTelII || refTelII?.unmasked?.trim().length < 11) {
        errors.refTelII = "Informe um nr. de telefone da referência";
      }

      if (!refRelacaoII?.trim().length) {
        errors.refRelacaoII = "Informação obrigatória";
      }

      if (isMinor) {
        errors.dataNascimento =
          "Menor idade! Não pode continuar a contratação!";
      }

      return errors;
    case 3:
      // if (newEmail === undefined) {
      // 	return setDisabledButton(true);
      // }

      // if(!/(.+)@(.+){2,}\.(.+){2,}/.test(newEmail)){
      //   return setDisabledButton(true);
      // }

      setDisabledButton(false);
      break;
    default:
      break;
  }
};
