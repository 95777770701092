/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Layout from "components/OldLayout";
import BoxCredit from "../components/BoxCredit";
import Button from "components/Button";

import { useLoan } from "hooks/useLoan";

import * as St from "./styles";

const ProposalResultLoanSeeker = () => {
  const [proposals, setProposals] = useState();

  const history = useHistory();

  const { finishResponse, userInitialData } = useLoan();

  useEffect(() => {
    if (finishResponse?.propostas || finishResponse) {
      setProposals(finishResponse?.propostas || finishResponse);
    }
  }, [finishResponse]);

  return (
    <St.SafeView>
      <Layout routeBack="/opcoes-acesso" title="Propostas de crédito" />
      <St.Container>
        <St.TopSpace>
          <St.Title>
            Tudo pronto {userInitialData?.data?.nome} Encontramos essas melhores
            ofertas para você:
          </St.Title>
        </St.TopSpace>
        <St.BoxGrid>
          {proposals?.map((proposal) => (
            <BoxCredit
              proposal={proposal}
              onClick={() =>
                history.push("/request-documents-loan-seeker", {
                  idEmprestimo:
                    proposal.idEmprestimo || finishResponse?.idEmprestimo,
                })
              }
            />
          ))}
        </St.BoxGrid>
        <St.Footer>
          <Button
            buttonType="primary"
            text="Quero visualizar todas as propostas"
            onClick={() =>
              history.push("/all-proposals-loan-seeker", {
                propostas: proposals,
              })
            }
          />
        </St.Footer>
      </St.Container>
    </St.SafeView>
  );
};

export default ProposalResultLoanSeeker;
