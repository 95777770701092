/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import Layout from "components/OldLayout";
import TableProposal from "../components/tableProposals";
import Button from "components/Button";
import Loading from "components/Loading";

import moment from "moment";
import { moneyMask } from "utils/mask";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import { useLoan } from "hooks/useLoan";

import * as St from "./styles";
const AllProposalsLoanSeeker = () => {
  const [data, setData] = useState();

  const { state } = useLocation();

  const history = useHistory();

  const { userInitialData } = useLoan();

  console.log(userInitialData);

  const getProposalsLoan = useApi(
    constants.endpoints.getProposalsByEasyCredito.replace(
      "PARAM_CPF",
      userInitialData?.cpf?.unmasked
    ),
    "GET"
  );

  const getAllProposals = async () => {
    try {
      const response = await getProposalsLoan.callApi();
      if (response.status >= 200 && response.status < 300) {
        setData(BodyProposals(response.data));
      }
    } catch (error) {}
  };

  const headerItens = [
    {
      id: 1,
      value: "Parceiros",
    },
    {
      id: 2,
      value: "Data",
    },
    {
      id: 3,
      value: "Valor",
    },
    {
      id: 4,
      value: "Prazo",
    },
    {
      id: 5,
      value: "Status",
    },
  ];

  const BodyProposals = (data) => {
    const items = [];

    data.forEach((i) => {
      const item = [
        {
          value: i?.produto,
          maxChar: 15,
        },
        {
          value: moment(i?.dataCriacao, "YYYY-MM-DD HH:mm").format(
            "DD/MM/YYYY [ás] HH:mm"
          ),
          textAlign: "center",
        },
        {
          value: moneyMask(i?.valor),
          textAlign: "center",
        },
        {
          value: `${i?.parcelas}x`,
          textAlign: "center",
        },
        {
          value: i?.descricaoUltimoStatus,
          textAlign: "center",
        },
      ];
      items.push(item);
    });

    return items;
  };

  console.log(data);

  useEffect(() => {
    if (userInitialData?.cpf?.unmasked) {
      getAllProposals();
    }
  }, [userInitialData?.cpf?.unmasked]);

  return (
    <St.SafeView>
      <Loading status={getProposalsLoan.loading} />
      <Layout routeBack="/opcoes-acesso" title="Todas as propostas" />
      <St.Container>
        <St.TableProposal>
          <TableProposal header={headerItens} items={data} />
        </St.TableProposal>
        <St.Footer>
          <Button
            buttonType="secondary"
            text="Quero simular novas propostas!"
            onClick={() => history.push("/loan-seeker")}
          />
        </St.Footer>
      </St.Container>
    </St.SafeView>
  );
};

export default AllProposalsLoanSeeker;
