export const optionsUfs = [
  { value: "disabled", label: "Selecione", hidden: true },
  { value: "AC", label: "Acre" },
  { value: "AL", label: "Alagoas" },
  { value: "AP", label: "Amapá" },
  { value: "AM", label: "Amazonas" },
  { value: "BA", label: "Bahia" },
  { value: "CE", label: "Ceará" },
  { value: "ES", label: "Espírito Santo" },
  { value: "GO", label: "Goiás" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PA", label: "Pará" },
  { value: "PB", label: "Paraíba" },
  { value: "PR", label: "Paraná" },
  { value: "PE", label: "Pernambuco" },
  { value: "PI", label: "Piauí" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RN", label: "Rio Grande do Norte" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "RO", label: "Rondônia" },
  { value: "RR", label: "Roraima" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "SE", label: "Sergipe" },
  { value: "TO", label: "Tocantins" },
  { value: "DF", label: "Distrito Federal" },
];

export const typeDocuments = [
  { value: "disabled", label: "Selecione", hidden: true },
  { value: "RG", label: "RG" },
  { value: "CNH", label: "CNH" },
  { value: "PASSAPORTE", label: "Passaporte" },
  { value: "CARTEIRA_OAB", label: "Carteira OAB" },
];

export const issuingAgencyList = [
  { value: "disabled", label: "Selecione", hidden: true },
  { value: "SSP", label: "SSP" },
  { value: "POLICIA_MILITAR", label: "POLÍCIA MILITAR" },
  { value: "POLICIA_CIVIL", label: "POLÍCIA CÍVIL" },
  { value: "FELIX_PACHECO", label: "INSTITUTO FELIX PACHECO" },
  { value: "DETRAN", label: "DETRAN" },
  { value: "MINISTERIO_FAZENDA", label: "MINISTÉRIO DA FAZENDA" },
  { value: "SIS", label: "SIS" },
];

export const typeResidenceList = [
  { value: "disabled", label: "Selecione", hidden: true },
  { value: "ALUGADA", label: "Alugada" },
  { value: "PARENTES", label: "Parentes" },
  { value: "FUNCIONAL", label: "Funcional" },
  { value: "PROPRIA_QUITADA", label: "Própria quitada" },
  { value: "PROPRIA_FINANCIADA", label: "Própria Financiada" },
  { value: "OUTROS", label: "Outros" },
];

export const residenceTimeList = [
  { value: "disabled", label: "Selecione", hidden: true },
  { value: "MENOR_6_MESES", label: "Menos de 6 meses" },
  { value: "MENOR_1_ANO", label: "6 meses a 1 ano" },
  { value: "MENOR_2_ANOS", label: "De 1 a 2 anos" },
  { value: "MAIOR_2_ANOS", label: "Mais de 2 anos" },
];
