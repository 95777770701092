const colors = {
  orange: {
      n1: '#f06f87',
      n2: '#E71037',
  },
  black: {
      n1: '#4A4B4A',
      n2: '#000',
  },
  gray: {
      n1: '#757575',
      n2: '#727272',
      n3: '#B4B4B4',
      n4: '#AFAFAF'
  },
  white: {
      n1: '#FFFFFF',
      n2: '#F9F9F9',
      n3: '#E7E7E7',
      n4: '#DEDEDE'
  },
  red: {
      n1: '#ED4848',
  },
  green: {
      n1: '#00A857'
  }
}

export default colors
