import styled from "styled-components";
import media from "styled-media-query";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContainerItem = styled.div`
  display: flex;
  width: 70%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;

export const Item = styled.span`
  display: flex;
  width: 60%;
  height: 7vh;
  flex-direction: row;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0 10px 26px rgb(0 0 0 / 5%);
  position: relative;
  -webkit-transition: opacity 0.5s, -webkit-box-shadow 0.5s,
    -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-box-shadow 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s;
  transition: opacity 0.5s, box-shadow 0.5s, transform 0.5s,
    -webkit-box-shadow 0.5s, -webkit-transform 0.5s;

  ${media.greaterThan("medium")`
width: 65%;
height: 100px;
`}

  &:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-0.75rem);
    transform: translateY(-0.75rem);
  }
`;

export const ImageItem = styled.img`
  height: 40px;
  object-fit: contain;
  margin-right: 20px;
`;

export const TextItem = styled.span`
  font-size: 14px;
  font-weight: 600;
  text-align: start;
  margin-left: 0px;
`;
