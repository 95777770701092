import styled from "styled-components";

export const Container = styled.div``;

export const Table = styled.table`
  border: 1px solid #eee;
  border-collapse: separate;
  border-spacing: 1px;
  width: 100%;
`;

export const headerTable = styled.thead`
  border-radius: 4px;
  line-height: 30px;
  background: #f9f9f9;
`;

export const headerTitle = styled.th`
  font-size: 1.5rem;
  font-weight: 600;
  padding: 4px;
  color: #495057;
  word-break: break-word;
  width: 20%;
  text-align: center;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
`;

export const bodyTable = styled.tbody`
  line-height: 50px;
`;

export const bodyRow = styled.tr`
  box-shadow: 0px 1px 1px -1px #999;
`;

export const bodyData = styled.td`
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  color: #4a4b4a;
  white-space: nowrap;
  padding: 0.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${({ maxChar }) => (maxChar ? `${maxChar}ch` : "100%")};
  padding-left: 0.5rem;
  text-align: ${(p) => (p.textAlign ? p.textAlign : "left")};
`;
