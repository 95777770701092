/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useState } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import Button from "components/Button";
import Input from "components/Input";
import OneButtonPopup from "components/modals/OneButtonPopup";
import { cpfMask, phoneMask, maskDateInput } from "utils/mask";
import { useHistory } from "react-router-dom";
import { useLoan } from "hooks/useLoan";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import themes from "styles";
import Loading from "components/Loading";

const inputStyle = {
  border: "1px solid #6c6c6c",
  padding: "1rem",
  borderRadius: ".5rem",
  width: "100%",
  height: "40%",
};

const ConfirmDataByTeuCard = () => {
  const initialState = {
    cpf: cpfMask(""),
  };
  const [data, setData] = useState({
    nome: "",
    email: "",
    celular: "",
    dataNascimento: "",
  });
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { cpf, nome, email, celular, dataNascimento } = state;
  const {
    dispatchUserInitialData,
    dispatchSimulationData,
    dispatchSolicitationData,
    userInitialData,
  } = useLoan();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [error, setError] = useState("");
  const [userInfo, setUserInfo] = useState();
  const [validate, setValidate] = useState(true);

  function reducer(state, action) {
    const { type, payload } = action;
    return { ...state, [type]: payload };
  }

  const getClientDetail = useApi(
    constants.endpoints.searchClientByCPFWithdraw.replace(
      "PARAM_CPF",
      cpf.unmasked
    ),
    "GET"
  );

  const PostIncludeLead = useApi(constants.endpoints.postAddLeads, "POST");
  const PostValidateKycFreezeList = useApi(
    constants.endpoints.postValidateKycFreezeList.replace(
      "PARAM_CPF",
      cpf.unmasked
    ),
    "POST"
  );
  const GetPendingLoan = useApi(
    constants.endpoints.getPendingWithDraw.replace("PARAM_CPF", cpf.unmasked),
    "GET"
  );

  const pendingLoan = async () => {
    const body = {
      celular: celular?.unmasked,
      cpf: cpf.unmasked,
      email,
      produto: "EMPRESTIMO",
      tipoEvento: "LEAD",
      nome,
      origemEmprestimo: "CELCOIN",
    };
    try {
      const isValid = await PostValidateKycFreezeList.callApi();
      if (isValid.status === 404) {
        setDialogOpen(true);
        setError(isValid.mensagem);
        return;
      }
      const response = await GetPendingLoan.callApi();
      if (response.status >= 200 && response.status < 300) {
        const responseLead = await PostIncludeLead.callApi(body);

        if (response.data === null && responseLead.data === null) {
          history.push("/with-draw-simulation-by-teu-card");
        } else if (responseLead.data?.status === "PENDENTE") {
          dispatchSimulationData({
            ...responseLead.data,
            credit_limit: responseLead.data?.limiteCredito,
            interest_rate: responseLead.data?.taxaJuros,
            installment_value: responseLead.data?.valorParcela,
            iof_total: responseLead.data?.valorIOF,
            release_amount: responseLead.data?.valorLiberado,
          });
          dispatchSolicitationData({
            idSimulacao: responseLead.data?.id,
          });
          history.push("/with-draw-active-leads");
        } else {
          history.push("/with-draw-confirm-solicitation", {
            data: response.data,
          });
          dispatchSolicitationData({
            idSimulacao: response.data?.id,
          });
        }
      } else {
        setError(response.mensagem);
        setDialogOpen(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  const getClientInfo = async () => {
    try {
      const response = await getClientDetail.callApi();

      if (response.status >= 200 && response.status < 300) {
        const { data } = response;

        if (!data.statusCartao) {
          setValidate(true);
          setDialogOpen(true);
          setUserInfo(null);
          setError(
            "Ops...! No momento não podemos te oferecer o empréstimo com cartão TeuCard. Procure uma de nossas lojas!"
          );
        } else if (data.statusCartao !== "ATIVO") {
          setValidate(true);
          setDialogOpen(true);
          setUserInfo(null);
          setError(
            "Ops...! No momento não podemos te oferecer o empréstimo com cartão TeuCard. Procure uma de nossas lojas!"
          );
        } else {
          setUserInfo(response.data);
          setValidate(false);
        }
      }
      if (response.status >= 300) {
        setValidate(true);
        setDialogOpen(true);
        setUserInfo(null);
        setError(
          "Ops...! No momento não podemos te oferecer o empréstimo com cartão TeuCard. Procure uma de nossas lojas!"
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (cpf?.unmasked.length === 11) {
      getClientInfo();
    }
  }, [cpf.unmasked.length]);

  useEffect(() => {
    if (data) {
      dispatchUserInitialData({ ...state, data });
    }
  }, [data]);

  useEffect(() => {
    if (userInfo) {
      setData((prevData) => ({
        ...prevData,
        nome: userInfo?.nome,
      }));
      setData((prevData) => ({
        ...prevData,
        email: userInfo?.email,
      }));
      setData((prevData) => ({
        ...prevData,
        celular: {
          masked: phoneMask(userInfo?.celular).masked,
          unmasked: userInfo?.celular,
        },
      }));
      setData((prevData) => ({
        ...prevData,
        dataNascimento: {
          masked: maskDateInput(
            userInfo?.dataNascimento.slice(8, 10) +
              userInfo?.dataNascimento.slice(5, 7) +
              userInfo?.dataNascimento.slice(0, 4)
          ).masked,
          unmasked: userInfo?.dataNascimento,
        },
      }));
    }
  }, [userInfo]);

  const onChange = (e, mask) => {
    const {
      target: { name, value },
    } = e;

    dispatchUserInitialData(state);

    dispatch({ type: name, payload: mask ? mask(value) : value });
  };

  return (
    <St.SafeView>
      <Loading status={getClientDetail.loading} />
      <Layout routeBack="/with-draw-by-teu-card" title="Confirme teus dados">
        <OneButtonPopup
          text={error}
          buttonText="Entendi"
          title={"Atenção!"}
          visibility={dialogOpen}
          iconModal={themes.components.png.emojiSad}
          defaultButton
          setVisibility={setDialogOpen}
          onClose={() => setDialogOpen(false)}
        />
        <St.Container>
          <St.TopSpace>
            <St.Title>
              Para simular teu empréstimo Saque Rápido, precisamos que você
              informe alguns dados antes:{" "}
            </St.Title>
            <St.Inputs>
              <Input
                boldLabel
                style={inputStyle}
                id="cpfLoan"
                name="cpf"
                placeholder="000.000.000-00"
                type="text"
                value={cpf?.masked}
                max={14}
                onChange={(e) => onChange(e, cpfMask)}
                label="CPF"
              />
              {userInfo && (
                <Input
                  boldLabel
                  style={inputStyle}
                  id="nameLoan"
                  label="Nome completo"
                  value={data?.nome}
                  name="nome"
                  onChange={(e) => onChange(e)}
                  disabled={true}
                />
              )}
              {userInfo && (
                <Input
                  boldLabel
                  name="dataNascimento"
                  style={inputStyle}
                  label="Data Nascimento"
                  max={10}
                  value={data?.dataNascimento?.masked}
                  onChange={(e) => onChange(e, maskDateInput)}
                  placeholder="00/00/0000"
                  disabled={true}
                />
              )}
              {userInfo && (
                <Input
                  name="email"
                  value={data?.email}
                  onChange={(e) => onChange(e)}
                  boldLabel
                  style={inputStyle}
                  label="E-mail"
                  disabled={true}
                />
              )}
              {userInfo && (
                <Input
                  boldLabel
                  name="celular"
                  style={inputStyle}
                  label="Celular"
                  max={15}
                  value={data?.celular?.masked}
                  placeholder={"(99) 9999-9999"}
                  onChange={(e) => onChange(e, phoneMask)}
                  disabled={true}
                />
              )}
            </St.Inputs>

            <St.Footer>
              <Button
                buttonType="primary"
                text={"Avançar"}
                onClick={() => pendingLoan()}
                loading={GetPendingLoan.loading}
                disabled={validate || GetPendingLoan.loading}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default ConfirmDataByTeuCard;
