import styled from "styled-components";
import media from "styled-media-query";

export const WrapperProposal = styled.div`
  height: 220px;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: ${({
    theme: {
      spacings: { small },
    },
  }) => small};
  margin-bottom: ${({
    theme: {
      spacings: { xsmall },
    },
  }) => xsmall};

  &::-webkit-scrollbar {
    border-radius: ${({
      theme: {
        borderRadius: { borderRadiusSM },
      },
    }) => borderRadiusSM};
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c8c8c8;
    border-radius: 10px;
  }
`;

export const Title = styled.div`
  font-size: ${({
    theme: {
      sizes: { xsmall },
    },
  }) => xsmall};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightSemiBold },
    },
  }) => fontWeightSemiBold};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  margin-bottom: ${({
    theme: {
      spacings: { xsmall },
    },
  }) => xsmall};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
  `}
`;

export const Text = styled.div`
  text-align: justify;
  font-size: ${({
    theme: {
      sizes: { xsmall },
    },
  }) => xsmall};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular },
    },
  }) => fontWeightRegular};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  margin-bottom: ${({
    theme: {
      spacings: { small },
    },
  }) => small};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { small },
      },
    }) => small}; */
  `}
`;

export const Ol = styled.ol`
  padding-left: ${({
    theme: {
      spacings: { small },
    },
  }) => small};
  text-align: justify;
  font-size: ${({
    theme: {
      sizes: { xsmall },
    },
  }) => xsmall};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular },
    },
  }) => fontWeightRegular};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { small },
      },
    }) => small};
  `}
`;
