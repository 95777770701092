/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { GlobalContext } from "context/GlobalContext";

import Button from "components/Button";
import Input from "components/Input";
import InputCheckbox from "components/InputCheckbox";
import InputSelect from "components/InputSelect";
import OneButtonPopup from "components/modals/OneButtonPopup";
import Layout from "components/Layout";
import Grid from "components/Grid";

import * as S from "./styles";
import { cpfMask, phoneMask } from "utils/mask";
import { SignatureContext } from "context/SignatureContext";
import Constants from "utils/constants";
import Repository from "repository";
import { optionsUfs } from "./constants";
import LoadingMessage from "components/LoadingMessage";

import Termos from "services/terms";
import { AuthContext } from "context/AuthContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import ContainerFlex from "components/ContainerFlex";

const CadMinimum = () => {
  const [specialNeeds, setSpecialNeeds] = useState(false);
  const [notLiterate, setNotLiterate] = useState(false);
  const [hearingVisuallyImpaired, setHearingVisuallyImpaired] = useState(false);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [tipoOrigem, setTipoOrigem] = useState(false);
  const [origem, setOrigem] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [checkWhatsApp, setCheckWhatsApp] = useState(false);
  const [cpf, setCpf] = useState({});
  const [phone, setPhone] = useState({});
  const [uf, setUf] = useState();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hostCode, setHostCode] = useState("");
  const [urlType, setUrlType] = useState("");
  const location = useLocation();
  const { history } = useReactRouter();
  const { setImageURL } = useContext(SignatureContext);
  const { signInGuest } = useContext(AuthContext);

  const searchParams = new URLSearchParams(location.search);
  const idAnfitriao = searchParams.get("anfitriao");

  const generateUrl = (codigoAnfitriao) =>
    constants.endpoints.postValidateAnfitriao.replace(
      "PARAM_ID",
      codigoAnfitriao
    );

  const PostValidateAnfitriao = useApi(generateUrl(idAnfitriao), "POST");

  const checkIfUrlIsValid = async () => {
    if (!idAnfitriao) {
      setUrlType("semIdAnfitriao");
      return;
    }
    setHostCode(idAnfitriao);

    const response = await PostValidateAnfitriao.callApi();
    if (response.status !== 200) {
      setUrlType("anfitriaoExpirado");
      return;
    }
    setUrlType("valid");
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const origem = Repository.storage.get(Repository.keys.ID_ORIGEM);
      setOrigem(origem?.tipo);

      if (origem?.tipo !== "LOJA") {
        setTipoOrigem(true);
        await signInGuest(
          Constants.externalAccess.guestUser,
          Constants.externalAccess.guestPassword
        );
        checkIfUrlIsValid();

        setIsLoading(false);
      } else {
        setTipoOrigem(false);
        setCheckWhatsApp(true);
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setImageURL(null);
    return () => {
      setCpf(null);
      setPhone(null);
      setEmail(null);
      setHearingVisuallyImpaired(false);
      setNotLiterate(false);
      setSpecialNeeds(false);
    };
  }, []);

  const checkPersonalConditions = () =>
    specialNeeds || notLiterate || hearingVisuallyImpaired || termsAccepted;

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleDisableButton = () =>
    !checkWhatsApp ||
    cpf.masked?.length !== 14 ||
    phone.masked?.length !== 15 ||
    !validateEmail(email) ||
    (tipoOrigem && !uf) ||
    (tipoOrigem && uf === "disabled") ||
    (tipoOrigem && !validateEmail(email)) ||
    (tipoOrigem && validateEmail(email) === "disabled") ||
    !checkPersonalConditions();

  const addProposalLoading = async () => {
    if (notLiterate || hearingVisuallyImpaired) {
      setPopUpMessage("Ops... Ainda não conseguimos lhe atender.");
      setPopUpIsOpen(true);
      return;
    }
    if (tipoOrigem) {
      if (uf !== "AM" && uf !== "RR" && uf !== "RO") {
        setPopUpMessage(
          "Ops... No momento, não podemos te oferecer um Teucard :("
        );
        setPopUpIsOpen(true);
        return;
      }
      history.push("/push-SMS", {
        cpf: cpf,
        celular: phone,
        email: email.trim(),
        tipoOrigem,
        checkWhatsApp,
        uf,
        idAnfitriao: hostCode,
      });
    } else {
      history.push("/loading-proposta", {
        cpf: cpf,
        celular: phone,
        email: email.trim(),
        tipoOrigem,
        checkWhatsApp,
        idAnfitriao: hostCode,
      });
    }
  };

  const handleTermsConditions = async () => {
    const filePath = Termos.termosCondicoes;

    window.open(filePath);
  };

  const handleTermsPrivacy = async () => {
    const filePath = Termos.termosPrivacidade;

    window.open(filePath);
  };

  if (PostValidateAnfitriao.loading || isLoading) {
    return (
      <LoadingMessage
        title={"Proposta Teucard Loading..."}
        subtitle={"Estamos Processando sua solicitação, aguarde um momento..."}
      />
    );
  }

  if (urlType === "semIdAnfitriao") {
    return (
      <Layout.View
        title={origem !== "LOJA" ? "Proposta Teucard" : "Dados pessoais"}
        host={""}
        routeBack="/opcoes-acesso"
      >
        <ContainerFlex center align="center" space>
          <Layout.Label>
            Que bom que você tem interesse no cartão TeuCard!
          </Layout.Label>
          <Layout.Label>Procure uma de nossas lojas.</Layout.Label>
          <ContainerFlex marginTop="16" width="50" row center>
            <Button
              text="Ok"
              buttonType="primary"
              onClick={() => (window.location = "http://useteucard.com.br/")}
            />
          </ContainerFlex>
        </ContainerFlex>
      </Layout.View>
    );
  }
  if (urlType === "anfitriaoExpirado") {
    return (
      <Layout.View
        title={origem !== "LOJA" ? "Proposta Teucard" : "Dados pessoais"}
        host={""}
        routeBack="/opcoes-acesso"
      >
        <ContainerFlex center align="center" space>
          <Layout.Label>
            Que bom que você tem interesse no cartão TeuCard,{" "}
            <b>mas o teu convite expirou!</b>
          </Layout.Label>
          <Layout.Label>Procure o anfitrião uma de nossas lojas.</Layout.Label>
          <ContainerFlex marginTop="16" width="50" row center>
            <Button
              text="Ok"
              buttonType="primary"
              onClick={() => (window.location = "http://useteucard.com.br/")}
            />
          </ContainerFlex>
        </ContainerFlex>
      </Layout.View>
    );
  }
  return (
    <Layout.View
      title={origem !== "LOJA" ? "Proposta Teucard" : "Dados pessoais"}
      host={hostCode ? hostCode : ""}
      routeBack="/opcoes-acesso"
    >
      <Layout.Label>
        Preencha os campos abaixo com os seus dados pessoais, por favor.
      </Layout.Label>
      <br />
      <section>
        <Input
          id="cpf"
          name="cpf"
          label="CPF"
          placeholder="000.000.000-00"
          value={cpf?.masked}
          max={14}
          onChange={(e) => setCpf(cpfMask(e.target.value))}
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
        />
        <Input
          id="mobilePhone"
          name="mobilePhone"
          label="Celular"
          value={phone?.masked}
          max={15}
          placeholder="(00) 00000-0000"
          onChange={(e) => setPhone(phoneMask(e.target.value))}
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
        />
        <Input
          id="email"
          name="email"
          label="E-mail"
          placeholder="example@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {tipoOrigem === false ? (
          <S.Terms name="termsAccepted">
            <InputCheckbox
              type="checkbox"
              id="specialNeeds"
              name="specialNeeds"
              label="Sem necessidades especiais"
              value={specialNeeds}
              checked={specialNeeds}
              onChange={(status) => {
                setSpecialNeeds(status);
                setNotLiterate(false);
                setHearingVisuallyImpaired(false);
              }}
            />

            <br />

            <InputCheckbox
              type="checkbox"
              id="notLiterate"
              name="notLiterate"
              label="Não alfabetizado"
              value={notLiterate}
              checked={notLiterate}
              onChange={(status) => {
                setSpecialNeeds(false);
                setNotLiterate(status);
                setHearingVisuallyImpaired(false);
              }}
            />

            <br />

            <InputCheckbox
              type="checkbox"
              id="hearingVisuallyImpaired"
              name="hearingVisuallyImpaired"
              label="Com deficiência Auditiva/Visual"
              value={hearingVisuallyImpaired}
              checked={hearingVisuallyImpaired}
              onChange={(status) => {
                setSpecialNeeds(false);
                setNotLiterate(false);
                setHearingVisuallyImpaired(status);
              }}
            />
          </S.Terms>
        ) : (
          <>
            <InputSelect
              name="uf"
              id="uf"
              label="Em qual Estado você reside?"
              type="select"
              value={uf}
              defaultValue={uf}
              values={optionsUfs}
              onChange={(e) => setUf(e.target.value)}
            />
            <InputCheckbox
              type="checkbox"
              id="termsAccepted"
              name="termsAccepted"
              label={
                <>
                  Li e concordo com os
                  <S.ViewTerms onClick={() => handleTermsConditions()}>
                    Termos e condições de uso
                  </S.ViewTerms>
                  e a
                  <S.ViewTerms onClick={() => handleTermsPrivacy()}>
                    Política de privacidade
                  </S.ViewTerms>
                </>
              }
              value={termsAccepted}
              onChange={setTermsAccepted}
            />
            <InputCheckbox
              type="checkbox"
              id="checkWhatsApp"
              name="checkWhatsApp"
              label="Desejo receber comunicação do meu interesse por Whatsapp"
              value={checkWhatsApp}
              onChange={setCheckWhatsApp}
            />
            <br /> <br />
          </>
        )}
      </section>

      <Grid xs={1} md={2} sm={1}>
        <div />
        <div>
          <Button
            text="Continuar"
            buttonType="primary"
            onClick={addProposalLoading}
            disabled={handleDisableButton()}
          />

          <OneButtonPopup
            defaultButton
            text={<div dangerouslySetInnerHTML={{ __html: popUpMessage }} />}
            buttonText="Entendi"
            visibility={popUpIsOpen}
            setVisibility={() => {
              setPopUpIsOpen(false);
              if (tipoOrigem) {
                return (window.location = "http://useteucard.com.br/");
              }
            }}
          />
        </div>
      </Grid>
      {tipoOrigem && (
        <S.DivVersion>
          <S.Version color={process.env.REACT_APP_VERSAO_APP_COLOR}>
            {process.env.REACT_APP_VERSAO_APP}
          </S.Version>
        </S.DivVersion>
      )}
    </Layout.View>
  );
};

export default CadMinimum;
