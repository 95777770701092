import React, { useContext, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Button from "components/Button";
import Layout from "components/OldLayout";
import * as S from "./styles";
import Repository from "repository";
import BenefitTable from "components/BenefitTable";
import Input from "components/Input";
import moment from "moment";
import SignaturePad from "react-signature-canvas";
import Spinner from "components/Spinner";
import TwoButtonPopup from "components/modals/TwoButtonPopup";
import OneButtonPopup from "components/modals/OneButtonPopup";
import { SignatureContext } from "context/SignatureContext";

const UpgradeSignature = () => {
  const history = useHistory();
  const { imageURL, setImageURL } = useContext(SignatureContext);
  const operador = Repository.storage.get(Repository.keys.USER);
  const [disabledButton, setDisabledButton] = useState(true);
  const [openDialog, setopenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [finishMessage, setFinishMessage] = useState("");
  const [finishErrorMessage, setFinishErrorMessage] = useState("");
  const [finishDialog, setFinishDialog] = useState(false);
  const [finishErrorDialog, setFinishErrorDialog] = useState(false);
  const sigCanvas = useRef();
  const { state } = useLocation();

  const clear = () => {
    sigCanvas.current.clear();
    setDisabledButton(true);
  };
  

  const save = () => {
    setIsLoading(true);
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    goToProposal();
    setIsLoading(false);
  };

  const goToProposal = () => {
    history.push("/change-category-signature", {
      ...state,
    })
  }
  

  return (
    <S.Container>
      <Layout routeBack="/change-category-signature" title="Alterar categoria">
        <TwoButtonPopup
          text="Tem certeza que finalizou a assinatura?"
          visibility={openDialog}
          buttonText="Sim"
          onClick={save}
          buttonCancelText="Não"
          setVisibility={(val) => {
            setopenDialog(val);
          }}
        />
        <OneButtonPopup
          defaultButton
          text={finishMessage}
          buttonText="OK"
          slug="/upgrade-category-confirmation"
          onClose={() => history.push("/upgrade-category-confirmation", {...state})}
          visibility={finishDialog}
          setVisibility={() => setFinishDialog(false)}
        />
        <OneButtonPopup
          defaultButton
          text={finishErrorMessage}
          buttonText="OK"
          visibility={finishErrorDialog}
          setVisibility={() => setFinishErrorDialog(false)}
        />
        <S.Content>
          <div>
            <BenefitTable
              isProposalCompleted={false}
              idProposal={state?.data.id}
              contractedBenefits={[state?.upgradeCardInfo] || ""}
              title={"Detalhes"}
            />
            <S.Text>Toque para assinar a alteração do plano</S.Text>
            <S.Signature>
              <SignaturePad
                clearOnResize={false}
                ref={sigCanvas}
                canvasProps={{
                  className: "signatureCanvas",
                }}
                onEnd={() => setDisabledButton(false)}
                onBegin={() => setDisabledButton(true)}
              />
            </S.Signature>
            <S.Text>Assine dentro da caixa</S.Text>

            {imageURL ? (
              <img
                src={imageURL}
                alt="Assinatura Digital"
                style={{
                  display: "none",
                  margin: "0 auto",
                }}
              />
            ) : null}
            <Input
              id="dateSignature"
              name="dateSignature"
              label="Data"
              type="text"
              value={moment(new Date()).format("DD/MM/YYYY")}
            />
          </div>
          <S.Buttons>
            <Button
              buttonType="secondary"
              text="Limpar"
              onClick={clear}
              className="button"
            />
            <Button
              buttonType="primary"
              text={isLoading === true ? <Spinner color="#FFF" /> : "Concluir"}
              onClick={() => setopenDialog(true)}
              disabled={disabledButton}
              className="button"
            />
          </S.Buttons>
        </S.Content>
      </Layout>
    </S.Container>
  );
};

export default UpgradeSignature;
