import styled from "styled-components";
import Popup from "reactjs-popup";
import { VscClose } from "react-icons/vsc";

export const Container = styled(Popup)`
  &-content {
    width: 422px;
    /* height: 251px; */
    padding: 0;
    background-color: #fff;
    border: 1px solid #e71037;

    .content {
      padding: 20px 20px 20px;

      .top-content {
        display: flex;
        justify-content: center;
      }

      .input-container {
        display: flex;
        margin-top: 15px;
        justify-content: space-between;

        .input {
          width: calc(100% / 7);
          outline: auto;
          text-align: center;
          border-width: 0 0 1px 0;
          font-size: 2.2em;
          -webkit-text-security: disc;
          padding: 1.4 rem 0.75 rem;
        }
      }
    }

    hr {
      opacity: 0.5;
      margin-top: 15px;
    }

    .button-container {
      display: flex;
      justify-content: space-between;

      padding: 10px 25px;

      button {
        width: 100%;
        font-size: 1.2em;
        background: #e71037;
        border: 2px solid #e71037;
        border-radius: 5px;
        color: #fff;

        padding: 7px 0px;
      }
    }
  }
`;

export const CloseIcon = styled(VscClose)`
  position: absolute;
  font-size: 1.3em;
  cursor: pointer;

  top: 15px;
  right: 15px;
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  text-align: center;
  color: #e71037;
`;
