import React from "react";

import * as S from "./styles";

export default function cardFatura({ data, onClickSendEmail, onClickPrint }) {
  return (
    <S.Container>
      <S.Content3>
        <S.ContentStatus>
          <S.NamePDF>{`Fatura_${data?.mesReferencia}_${data?.anoReferencia}.pdf`}</S.NamePDF>
        </S.ContentStatus>
      </S.Content3>
      <S.Line />

      <S.Content2>
        <S.ContentStatus>
          <S.HiperLink onClick={() => onClickSendEmail(data)}>
            Enviar no E-mail
          </S.HiperLink>
        </S.ContentStatus>
      </S.Content2>
      <S.Line />

      <S.Content2>
        <S.ContentStatus>
          <S.HiperLink onClick={() => onClickPrint(data)}>Imprimir</S.HiperLink>
        </S.ContentStatus>
      </S.Content2>
      <S.Line />
    </S.Container>
  );
}
