import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Layout from "components/OldLayout";
import Loading from "components/Loading";
import Input from "components/Input";
import InputSelect from "components/InputSelect";
import Button from "components/Button";

import * as S from "./styles";
import { cpfMask, cpfUnmask, phoneMask, phoneUnmask } from "utils/mask";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import Repository from "repository";
import OneButtonPopup from "components/modals/OneButtonPopup";
import { optionsUfs } from "./constants";

const AddClientLeads = () => {
  const [form, setForm] = useState({
    cpf: "",
    nome: "",
    email: "",
    telefone: "",
    productType: "disabled",
  });
  const operador = useState(Repository.storage.get(Repository.keys.USER));
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [popUpMsg, setPopUpMsg] = useState();
  const [popUpIsError, setPopUpIsError] = useState(false);
  const groupsProduct = [
    { value: "disabled", label: "Selecione" },
    { value: "CARTEIRA_DIGITAL", label: "Carteira Digital" },
  ];
  const [uf, setUf] = useState("");

  const history = useHistory();

  const POSTAddLeads = useApi(constants.endpoints.postAddLeads, "POST");

  const handleAddLeadService = async () => {
    setPopUpIsError(false);
    try {
      const payload = {
        celular: phoneUnmask(form?.telefone),
        cpf: cpfUnmask(form?.cpf),
        email: form?.email,
        endereco: {
          bairro: "",
          cep: "",
          cidade: "",
          complemento: "",
          logradouro: "",
          numero: "",
          pais: "",
          uf: uf,
        },
        nome: form?.nome,
        operador: {
          idOperador: operador[0]?.id,
          nome: operador[0]?.nomeTitular,
        },
        produto: form?.productType,
        tipoAcao: "SMS",
        tipoEvento: "LEAD",
      };

      const response = await POSTAddLeads.callApi(payload);

      if (response.status >= 200 && response.status < 300) {
        setPopUpMsg("Lead Salvo e SMS enviado sucesso!!!");
        setPopUpIsOpen(true);
      } else {
        setPopUpMsg(
          response.data?.mensagem || "Erro ao saval e enviar o SMS para Lead!!!"
        );
        setPopUpIsOpen(true);
        setPopUpIsError(true);
      }
    } catch (error) {
      setPopUpMsg("Erro ao saval e enviar o SMS para Lead!!!");
      setPopUpIsOpen(true);
      setPopUpIsError(true);
      console.error(error);
    }
  };

  const buttonDisable = () => {
    return (
      form.productType === "disabled" ||
      form.nome === "" ||
      form.email === "" ||
      cpfUnmask(form.cpf).length !== 11 ||
      phoneUnmask(form.telefone).length !== 11 ||
      uf === "" ||
      uf === "disabled"
    );
  };

  return (
    <S.Container>
      <Loading status={POSTAddLeads.loading} />
      <Layout routeBack="/consult-leads" title="Cadastro de novo Lead"></Layout>
      <S.ContentInput>
        <S.ContentLeft>
          <Input
            name="nome"
            label="Nome Completo"
            type="text"
            defaultValue={form.nome}
            value={form.nome}
            onChange={(e) => setForm({ ...form, nome: e.target.value })}
          />
          <Input
            name="email"
            label="E-mail"
            type="text"
            placeholder="exemplo@exemplo.com"
            defaultValue={form.email}
            value={form.email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
          />
          <InputSelect
            name="uf"
            id="uf"
            label="UF"
            type="select"
            value={uf}
            defaultValue={uf}
            values={optionsUfs}
            onChange={(e) => setUf(e.target.value)}
          />
        </S.ContentLeft>
        <S.ContentRight>
          <Input
            name="cpf"
            label="CPF"
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            placeholder="000.000.000-00"
            value={cpfMask(form.cpf).masked}
            onChange={(e) => setForm({ ...form, cpf: e.target.value })}
            max={14}
          />
          <Input
            label="Telefone"
            name="phone"
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            value={phoneMask(form.telefone).masked}
            onChange={(e) => setForm({ ...form, telefone: e.target.value })}
            placeholder="(99) 99999-9999"
            max={15}
          />
          <InputSelect
            name="productType"
            id="productType"
            label="Escolher produto"
            type="select"
            value={form.productType}
            values={groupsProduct}
            onChange={(e) => setForm({ ...form, productType: e.target.value })}
          />
        </S.ContentRight>
      </S.ContentInput>
      <Button
        buttonType="primary"
        text={"Salvar e Enviar"}
        disabled={buttonDisable()}
        onClick={() => handleAddLeadService()}
        buttonSize="small"
        style={{ maxWidth: "300px" }}
      />
      <OneButtonPopup
        defaultButton
        text={popUpMsg}
        buttonText="Entendi"
        visibility={popUpIsOpen}
        setVisibility={() => {
          setPopUpIsOpen(false);
          !popUpIsError && history.push("/opcoes-acesso");
        }}
      />
    </S.Container>
  );
};

export default AddClientLeads;
