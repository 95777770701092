import React, { useContext } from "react";
import * as S from "./styles";
import BenefitTable from "components/BenefitTable";
import { useHistory, useLocation } from "react-router";
import Layout from "components/OldLayout";
import Button from "components/Button";

import { SignatureContext } from "context/SignatureContext";

const UpgradeCategoryConfirmation = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { setImageURL } = useContext(SignatureContext);

  return (
    <S.Container>
      <Layout routeBack="/upgrade-signature" title="Plano categoria">
        <S.Content>
          <BenefitTable
            isProposalCompleted={true}
            idProposal={state?.data.id}
            contractedBenefits={[state?.upgradeCardInfo] || ""}
            title={"Detalhes"}
            categoryCard={state?.upgradeCardInfo.categoria}
          />
        </S.Content>
        <S.Footer>
          <Button
            buttonType="primary"
            text={"Voltar"}
            onClick={() => {
              setImageURL(null);
              history.push("/opcoes-acesso");
            }}
            className="button"
          />
        </S.Footer>
      </Layout>
    </S.Container>
  );
};

export default UpgradeCategoryConfirmation;
