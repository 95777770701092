import React, { useState } from "react";
import LinkButton from "components/LinkButton";
import Button from "components/Button";

import themes from "styles";
import * as S from "./styles";

export const TwoButtonPopup = ({
  defaultButton,
  imgIcon,
  visibility,
  setVisibility,
  icon,
  text,
  slug,
  onClick,
  onClickCancel,
  buttonText,
  buttonCancelText,
  children,
  width,
  loadingApi,
  buttonDisabledPositive = false,
  closeOnDocumentClick = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const confirm = async () => {
    if (onClick) {
      setIsLoading(true);
      await onClick();
      setIsLoading(false);
    }
  };

  return (
    <S.Container
      width={width}
      open={visibility}
      onClose={() => setVisibility(false)}
      modal
      closeOnDocumentClick={closeOnDocumentClick}
      overlayStyle={{
        background: "rgba(0, 0, 0, 0.7)",
      }}
    >
      {(close) => (
        <>
          <div className="content-container">
            <div
              style={{
                display: "flex",
                alignItems: "left",
                gap: "30px",
              }}
            >
              {themes.components.icons.tick}
              <p>{text}</p>
            </div>

            {children}
          </div>

          <hr />

          {defaultButton ? (
            <div className="button-container">
              <button onClick={confirm} className="button-cancel">
                Entendi
              </button>
            </div>
          ) : (
            <div className="button-container">
              {buttonCancelText && (
                <Button
                  buttonType="secondary"
                  onClick={() =>
                    onClickCancel !== undefined
                      ? onClickCancel()
                      : setVisibility(false)
                  }
                  text={buttonCancelText}
                />
              )}

              <Button
                loading={loadingApi}
                buttonType="primary"
                onClick={confirm}
                text={buttonText}
                disabled={buttonDisabledPositive || isLoading}
              />
            </div>
          )}
        </>
      )}
    </S.Container>
  );
};

export default TwoButtonPopup;
