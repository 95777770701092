import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 240, 255, 0.4);
`

export const Modal = styled.div`
  position: relative;
  border: 5px solid
    ${({
      theme: {
        colors: { primary }
      }
    }) => primary};
  max-width: 80%;

  #close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    border-radius: 50px;
    padding: 5px;
    background-color: #ffff;

    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      background-color: #d3d3d3;
    }
  }
`
