import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  overflow: hidden;
  padding: 30px;
`

export const Content = styled.div`
  margin: auto;
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const Logo = styled.img`
  height: 89px;
  width: 315px;
`

export const Title = styled.p`
  color: #4a4b4a;
  font-size: 1.6em;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  padding: 10% 0 5%;
`

export const Subtitle = styled.p`
  color: #4a4b4a;
  font-size: 1.6em;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  padding: 0 0 10%;
`

export const Header = styled.header`
  width: 100%;
  height: 35px;
`