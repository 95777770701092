import React, { useState } from "react";

import { moneyMask } from "utils/mask";
import moment from "moment";

import * as St from "./styles";
import themes from "styles";
import Dialog from "components/dialog";
const BoxCredit = ({ onClick, proposal }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const creationDate = `${proposal?.dataCriacao?.date?.year}-${proposal?.dataCriacao?.date?.month}-${proposal?.dataCriacao?.date?.day}`;
  const linkProduto = proposal?.linkProduto;
  const handleStatus = (status) => {
    if (status === "REPROVADO") {
      return "#e57373";
    } else if (status === "PRE_APROVADO" || status === "LIBERADO") {
      return "#7fbf7f";
    } else if (
      status === "EM_ANALISE_MANUAL" ||
      status === "EM_ANALISE_OPERACIONAL"
    ) {
      return "#bf9d7f";
    } else {
      return "#7fa3bf";
    }
  };
  //

  return (
    <St.Container color={handleStatus(proposal?.ultimoStatus)}>
      <Dialog
        icon={null}
        widthInPercent={50}
        positiveAction={() => {
          window.open(linkProduto, "_blank");
          setModalOpen(false);
        }}
        negativeAction={() => setModalOpen(false)}
        positiveLabel="Continuar"
        negativeLabel="Voltar"
        title={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <img alt="emoji" src={themes.components.png.emojiSuccess} />
            <span>Detalhes</span>
          </div>
        }
        open={modalOpen}
        message={
          <div
            style={{
              textAlign: "center",
              fontSize: "1.2em",
              fontWeight: "500",
            }}
          >
            <span>Você será direcionado para uma área externa.</span>
            <p>Deseja continuar?</p>
          </div>
        }
      />
      <St.Header color={handleStatus(proposal?.ultimoStatus)}>
        <h1>{proposal?.descricaoUltimoStatus}</h1>
      </St.Header>
      <St.Content>
        <St.Title>{proposal?.produto}</St.Title>
        <St.BoxContent color={handleStatus(proposal?.ultimoStatus)}>
          <St.Subtitle>Valor máximo</St.Subtitle>
          <St.Value>{moneyMask(proposal?.valor)}</St.Value>
          <p>{`Em até ${proposal?.parcelas}x`}</p>
        </St.BoxContent>
        <St.Footer color={handleStatus(proposal?.ultimoStatus)}>
          {proposal?.documentosPendentes?.length > 0 &&
          !proposal?.documentosEnviados ? (
            <button onClick={onClick}>Continuar</button>
          ) : !!linkProduto ? (
            <button onClick={() => setModalOpen(true)}>Detalhes</button>
          ) : (
            <div
              style={{
                display: "flex",
                gap: "1rem",
                paddingBottom: 20,
                alignItems: "center",
              }}
            >
              <img
                src={themes.components.png.information}
                width={15}
                height={15}
                alt="information"
              />
              <span style={{ fontSize: "12px", textAlign: "start" }}>
                {proposal?.mensagemUltimoStatus}
              </span>
            </div>
          )}

          <St.Subtitle>
            <b>Criado em </b>
            {moment(
              proposal?.dataCriacao?.date ? creationDate : proposal.dataCriacao,
              "YYYY-MM-DD"
            ).format("DD/MM/YYYY")}
          </St.Subtitle>
        </St.Footer>
      </St.Content>
    </St.Container>
  );
};

export default BoxCredit;
