import styled from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  grid-area: ${({ name }) => name};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 15px;

  label {
    font-size: 12px;

    ${media.greaterThan("large")`
      font-size: 14px;
    `}
  }

  div {
    position: relative;
    display: flex;
    justify-content: space-between;

    svg {
      color: #e71037;
      width: 25px;
      height: 30px;
      margin-left: -24px;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      border: 0;
      font-size: 16px;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: ${(props) => (props.isFocus ? "#" : "#AFAFAF")};
      color: #4a4b4a;
      height: 32px;
      width: 100%;

      &:focus {
        outline: none;
      }

      option {
        background-color: white;
        padding: 1rem;
        font-size: 1.5rem;
      }
    }
  }
`;

export const ErrorText = styled.span`
  font-size: 12px;
  color: #ed4848;
  margin-top: 2px;
`;
