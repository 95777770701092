import styled from "styled-components";
import media from "styled-media-query";
import Spinner from "components/Spinner";

export const Container = styled.div`
  box-sizing: border-box;
  border: 0.5px solid #e8e8e8;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 7px 0 rgba(201, 201, 201, 0.5);
  display: flex;
  margin: 0.5%;
  ${media.greaterThan("small")`

  `}
`;
export const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  min-height: 50px;
`;

export const Content2 = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 5px;
  min-height: 50px;
  align-items: center;
`;

export const Content3 = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  min-height: 50px;
  align-items: center;
  width: 60%;
`;

export const NamePDF = styled.p`
  text-align: left;
  color: red;
  font-size: 1.7rem;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0 5px;
  overflow-wrap: anywhere;

  ${media.lessThan("medium")`
    font-size: 1rem;
  `}

  ${media.lessThan("small")`
    font-size: .7rem;
  `}
`;

export const HiperLink = styled.h1`
  text-align: left;
  color: red;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0 5px;
  cursor: pointer;
  text-decoration: underline;

  ${media.lessThan("medium")`
    font-size: 1rem;
  `}

  ${media.lessThan("small")`
    font-size: .7rem;
  `}
`;

export const Title = styled.h1`
  text-align: left;
  color: #4a4b4a;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0 5px;

  ${media.lessThan("medium")`
    font-size: 1rem;
  `}

  ${media.lessThan("small")`
    font-size: .7rem;
  `}
`;

export const Subtitle = styled.p`
  text-align: left;
  color: #4a4b4a;
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0 5px;
  overflow-wrap: anywhere;

  ${media.lessThan("medium")`
    font-size: 1rem;
  `}

  ${media.lessThan("small")`
    font-size: .7rem;
  `}
`;

export const Line = styled.div`
  box-sizing: border-box;
  width: 2px;
  border: 1px solid #dcdcdc;
`;

export const ContentStatus = styled.div`
  display: flex;
  flex-flow: column;
`;

export const ContentIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  img {
    cursor: pointer;
  }
`;

export const Search = styled.img`
  position: relative;
`;
export const Writing = styled.img`
  position: relative;
`;
export const Reasons = styled.img`
  position: relative;
`;

export const SpinnerCusmotized = styled(Spinner).attrs((props) => ({
  color: props.theme.colors.primary,
}))``;

export const CardBeneift = styled.div`
  width: 100%;
  background-color: #f9fafb;
  border-radius: 16px;
  padding: 24px 30px;
  margin-bottom: 20px;

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 20px;
    margin-bottom: 16px;

    .title {
      display: flex;
      align-items: center;

      img {
        margin-right: 16px;
      }

      h2 {
        font-size: 18px;
        font-weight: bold;
        color: #4a4b4a;
      }
    }

    .fee {
      .price {
        font-size: 18px;
        font-weight: bold;
        color: #4a4b4a;
      }

      .month {
        font-size: 12px;
        font-weight: normal;
        color: #4a4b4a;
      }
    }
  }
`;

export const DivPaiDesc = styled.div`
  display: flex;
`;

export const DivDesc = styled.div`
  justify-content: space-between;
  align-items: center;
  width: 90%;

  .desc {
    font-size: 14px;
    font-weight: normal;
    color: #4a4b4a;
  }

  .link {
    font-size: 14px;
    font-weight: normal;
    color: red;
    text-decoration: underline;
  }
`;

export const DivCheck = styled.div`
  display: contents;
`;
