import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import useReactRouter from "use-react-router";
import SignaturePad from "react-signature-canvas";

import { SignatureContext } from "context/SignatureContext";

import Button from "components/Button";
import Spinner from "components/Spinner";
import Layout from "components/OldLayout";
import TwoButtonPopup from "components/modals/TwoButtonPopup";

import * as S from "./styles";
import BenefitTable from "components/BenefitTable";
import ContainerFlex from "components/ContainerFlex";
import themes from "styles";
import { AttendanceContext } from "context/AttendanceContext";
import OneButtonPopup from "components/modals/OneButtonPopup";
import { useContext } from "react";
import Loading from "components/Loading";
import Input from "components/Input";
import moment from "moment";

const SignatureCancelBenefit = () => {
  const [isLoading, setLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [openDialog, setopenDialog] = useState(false);
  const [signatureImage, setSignatureImage] = useState("");
  const [isSigning, setIsSigning] = useState(false);
  const [msgDialog, setMsgDialog] = useState("");
  const [dialogMsgCancelOpen, setDialogMsgCancelOpen] = useState("");
  const { history } = useReactRouter();
  const sigCanvas = useRef();
  const { state } = useLocation();
  const { cancelBenefit, loading } = useContext(AttendanceContext);

  //img tratada pra mandar pro back
  const formattedSignatureImage = signatureImage?.split(",")[1];

  const clear = () => {
    sigCanvas.current.clear();
    setDisabledButton(true);
  };

  const save = () => {
    setLoading(true);
    setSignatureImage(
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
    setIsSigning(false);
    setopenDialog(false);
    setLoading(false);
  };

  const handleCancelBenefit = async () => {
    const payload = {
      idProposta: state?.idProposta,
      idBeneficio: state.beneficio[0].id,
      motivoCancelamento: state?.motivoCancelamento,
      assinatura: formattedSignatureImage,
      idUsuario: state?.idUsuario,
    };
    const { status, mensagem } = await cancelBenefit(payload);
    if (status >= 200 && status <= 300) {
      setMsgDialog("Cancelamento realizado com sucesso!");
      setDialogMsgCancelOpen(true);
    } else {
      setMsgDialog(mensagem);
      setDialogMsgCancelOpen(true);
    }
  };

  return (
    <S.Wrapper>
      <Layout title="Cancelamento de benefício" goBack={history.goBack}>
        <Loading status={loading} />
        <TwoButtonPopup
          text="Tem certeza que finalizou a assinatura?"
          visibility={openDialog}
          buttonText="Sim"
          onClick={save}
          buttonCancelText="Não"
          setVisibility={(val) => {
            setopenDialog(val);
          }}
        />
        <OneButtonPopup
          defaultButton
          text={msgDialog}
          onClose={() => history.push("/opcoes-acesso")}
          buttonText="Entendi"
          visibility={dialogMsgCancelOpen}
          setVisibility={() => setDialogMsgCancelOpen(false)}
        />
        <ContainerFlex marginTop={20} marginBottom={20}>
          <BenefitTable
            idProposal={state.idProposta}
            canceledBenefits={state.beneficio}
            isCancel={true}
          />
        </ContainerFlex>
        {!isSigning ? (
          <>
            <S.Text>Toque para assinar o cancelamento</S.Text>
            <S.BoxSignature
              onClick={() => setIsSigning(true)}
              value={signatureImage}
            >
              <S.LineSignature>
                {signatureImage ? (
                  <S.ImgURL src={signatureImage} alt="Assinatura Digital" />
                ) : (
                  themes.components.icons.shape
                )}
              </S.LineSignature>
            </S.BoxSignature>
            <Input
              id="dateSignature"
              name="dateSignature"
              label="Data"
              type="text"
              value={moment(new Date()).format("DD/MM/YYYY")}
            />
            <S.Buttons>
              <Button
                buttonType="secondary"
                text="Voltar"
                onClick={history.goBack}
                className="button"
              />
              <Button
                buttonType="primary"
                text={isLoading ? <Spinner color="#FFF" /> : "Concluir"}
                onClick={() => handleCancelBenefit()}
                disabled={disabledButton}
                className="button"
              />
            </S.Buttons>
          </>
        ) : null}
        {isSigning ? (
          <>
            <S.Infos>
              <S.Signature>
                <SignaturePad
                  clearOnResize={false}
                  ref={sigCanvas}
                  canvasProps={{
                    className: "signatureCanvas",
                  }}
                  onEnd={() => setDisabledButton(false)}
                  onBegin={() => setDisabledButton(true)}
                />
              </S.Signature>
              <S.Text>Assine dentro da caixa</S.Text>

              {signatureImage ? (
                <img
                  src={signatureImage}
                  alt="Assinatura Digital"
                  style={{
                    display: "none",
                    margin: "0 auto",
                  }}
                />
              ) : null}
            </S.Infos>
            <Input
              id="dateSignature"
              name="dateSignature"
              label="Data"
              type="text"
              value={moment(new Date()).format("DD/MM/YYYY")}
            />
            <S.Buttons>
              <Button
                buttonType="secondary"
                text="Limpar"
                onClick={clear}
                className="button"
              />
              <Button
                buttonType="primary"
                text={isLoading ? <Spinner color="#FFF" /> : "Concluir"}
                onClick={() => setopenDialog(true)}
                disabled={disabledButton}
                className="button"
              />
            </S.Buttons>
          </>
        ) : null}
      </Layout>
    </S.Wrapper>
  );
};

export default SignatureCancelBenefit;
