export const gridTemplateFollowUp = (theme) => {
  const {
    screen: { screenXS, screenSM }
  } = theme
  return `
    grid-template: ${`
      "userFullName"
      "cpf"
      "mobilePhone"
      "email"
      "termsAccepted"
    `};

    @media screen {
      @media (min-width: ${screenXS}) {
        grid-template: ${`
        "userFullName "
				"cpf"
				"mobilePhone"
				"email"
				"termsAccepted"
        `};
        grid-template-columns: 1fr;
        justify-content: start;
      }

      @media screen {
        @media (min-width: ${screenSM}) {
          grid-template: ${`
          "userFullName "
					"cpf"
					"mobilePhone"
					"email"
					"termsAccepted"
          `};
          grid-template-columns: 1fr 1fr;
          justify-content: start;
        }
      }
   `
}
