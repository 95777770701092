/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContainerFlex = styled.div`
  height: ${(props) => (props.height ? `${props.height}%` : props.container ? '100%' : 'auto')};
  width: ${(props) => (props.width ? `${props.width}%` : '-webkit-fill-available')};
  max-width: ${(p) => (p.maxWidth ? `${p.maxWidth}%` : 'none')};
  background-color: ${(props) => (props.color ? `${props.color}` : 'transparent')};

  flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};
  -webkit-flex-wrap: ${(props) => (props.wrap ? 'wrap' : 'nowrap')};

  margin-top: ${(props) => (props.marginTop ? `${props.marginTop}px` : '0px')};
  margin-bottom: ${(props) => (props.marginBottom ? `${props.marginBottom}px` : '0px')};
  margin-left: ${(props) => (props.marginLeft ? `${props.marginLeft}px` : '0px')};
  margin-right: ${(props) => (props.marginRight ? `${props.marginRight}px` : '0px')};

  display: flex;
  flex: ${(props) => (props.flex ? props.flex : '1')};
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};

  align-items: ${(props) => (props.align ? props.align : props.center ? 'center' : 'none')};

  padding: ${(props) => (props.padding ? `${props.padding}px` : '0px')};
  position: relative;
  justify-content: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.space
      ? 'space-between'
      : // eslint-disable-next-line no-nested-ternary
      props.center && props.horizontal
      ? 'center'
      : props.end
      ? 'flex-end'
      : props.spaceAround
      ? 'space-around'
      : 'none'};
`;

ContainerFlex.propTypes = {
  container: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  center: PropTypes.bool,
  end: PropTypes.bool,
  align: PropTypes.checkPropTypes('flex-start', 'flex-end', 'center', 'space-between'), //'flex-start' | 'flex-end' | 'center' | 'space-between'
  horizontal: PropTypes.bool,
  space: PropTypes.bool,
  spaceAround: PropTypes.bool,
  padding: PropTypes.number,
  marginTop: PropTypes.number,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  row: PropTypes.bool,
  flex: PropTypes.number,
  wrap: PropTypes.bool,
  color: PropTypes.string
};

ContainerFlex.defaultProps = {
  container: false,
  height: 'auto',
  width: 100,
  center: false,
  end: false,
  align: 'flex-start',
  horizontal: false,
  space: false,
  spaceAround: false,
  padding: 0,
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
  row: false,
  flex: 1,
  wrap: false,
  color: 'white'
}

export default ContainerFlex;
