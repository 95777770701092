import React, { useState } from 'react';

import * as S from './styles';

const InputMoney = ({ 
  marginBottom, className, error, 
  label, name, value, id, placeholder, 
  onChange,defaultValue, ...others 
}) => {
  const [focusInput, setFocusInput] = useState(false);

  return (
    <S.Container marginBottom={marginBottom} isFocus={focusInput} name={id} className={id}>
      <label>{label}</label>

      <input
        name={name}
        id={name}
        onFocus={() => setFocusInput(true)}
        onBlur={() => setFocusInput(false)}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        {...others}
      />
      {!!error && <S.ErrorText>{error}</S.ErrorText>}
    </S.Container>
  );
};

export default InputMoney;




















// import React, { Fragment} from 'react'
// import { useHistory } from 'react-router-dom'
// import InputMask from 'react-input-mask'
// import * as S from './styles'

// export default function Input({ name, label, checked,value, placeholder, min, id, max, onblur, type, disabled, mask, strongs, onChange, error }) {
// 	const history = useHistory();
// 	return (
// 		<S.Container name={id}>
// 		<S.Wrapper name={id} className={type}>
// 			<InputMask
// 				name={name}
// 				id={name}
// 				type={type}
// 				value={value}
// 				placeholder={placeholder}
// 				minLength={min}
// 				maxLength={max}
// 				mask={mask}
// 				checked={checked}
// 				disabled={disabled}
// 				onBlur={() => {
// 					onblur && onblur();
// 				}}
// 				onChange={onChange}
// 			/>
// 				{label && <S.Label  htmlFor={name} >{label}</S.Label>}
// 				{strongs && strongs.map(strong => (
// 				<Fragment>
// 						{strong.type === 'text' && <span>{strong.name}</span>}
// 						{strong.type !== 'text' && <strong onClick={() => history.push(strong.link)}>{strong.name}</strong>}
// 					</Fragment>
// 					))}
// 				{/* {error && <S.Error>{error}</S.Error>} */}
// 		</S.Wrapper>
// 		</S.Container>

// 	);
// }
