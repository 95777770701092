export const gridTemplateForgot = (theme) => {
  const { screen: { screenXS, screenSM }} = theme
  return `
    grid-template: ${`
      "password"
      "confPassWord"
      "checkFormSequencialNumber"
    `};

    @media screen {
      @media (min-width: ${screenXS}) {
        grid-template: ${`
          "password"
          "confPassWord"
          "checkFormSequencialNumber"
        `};
        grid-template-columns: 1fr;
        justify-content: start;
      }

      @media screen {
        @media (min-width: ${screenSM}) {
          grid-template: ${`
            "password"
            "confPassWord"
            "checkFormSequencialNumber"
          `};
          grid-template-columns: 1fr;
          justify-content: start;
        }
      }
   `
};

