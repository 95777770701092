/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import moment from "moment";
import ContainerFlex from "components/ContainerFlex";
import { moneyMask } from "utils/mask";

import * as S from "./styles";
import assets from "assets/index";
import IconWriting from "assets/icons/writing.svg";
import IconTrash from "assets/icons/trash.svg";
import IconSearch from "assets/icons/search.svg";
import ativo from "assets/icons/ativo.svg";
import pendente from "assets/icons/pendente.svg";
import suspenso from "assets/icons/suspenso.svg";
import cancelado from "assets/icons/cancelado.svg";
import permitido from "assets/icons/nova-adesao.svg";
import naoPermitido from "assets/icons/sem-adesao.svg";

export default function listBenefit({
  availableBenefitsNotContracteds,
  contractedBenefits,
  setBenefitSelected,
  setDialogIsOpenDependent,
  setDialogIsOpenDeleteBenefit,
  setDialogJustificationIsOpen,
  setDialogIsOpenDetails,
  setDialogIsOpenCancel,
  setDialogIsOpenCancelBenefitCuida,
  setBenefitDetailsDialogOpen,
  setBenefitDetailsDialog,
  handleAssinar,
  totalValue,
}) {
  const isTeuCardOdonto = (benefit) => benefit.idBeneficioAutorizador === 1;
  const isTeuCardCuida = (benefit) => benefit.idBeneficioAutorizador === 5;

  return (
    <>
      <ContainerFlex row marginBottom={16}>
        <h4>Legenda de Benefícios:</h4>
      </ContainerFlex>
      <ContainerFlex row marginBottom={16}>
        <ContainerFlex row>
          <ContainerFlex row center marginLeft={5}>
            <img src={ativo} height="24" alt="Benefício ativo" />
            <S.LegendLabel>Ativo</S.LegendLabel>
          </ContainerFlex>
          <ContainerFlex row center marginLeft={5}>
            <img src={pendente} height="24" alt="Benefício pendente" />
            <S.LegendLabel>Pendente</S.LegendLabel>
          </ContainerFlex>
          <ContainerFlex row center marginLeft={5}>
            <img src={suspenso} height="24" alt="Benefício suspenso" />
            <S.LegendLabel>Suspenso</S.LegendLabel>
          </ContainerFlex>
          <ContainerFlex row center marginLeft={5}>
            <img src={cancelado} height="24" alt="Benefício cancelado" />
            <S.LegendLabel>Cancelado</S.LegendLabel>
          </ContainerFlex>
          <ContainerFlex row center marginLeft={5}>
            <img src={permitido} height="24" alt="Benefício permitido" />
            <S.LegendLabel>Permitido Adicionar</S.LegendLabel>
          </ContainerFlex>
          <ContainerFlex row center marginLeft={5}>
            <img src={naoPermitido} height="24" alt="Benefício não permitido" />
            <S.LegendLabel>Não permitido adicionar</S.LegendLabel>
          </ContainerFlex>
        </ContainerFlex>
      </ContainerFlex>

      <S.Divider />

      <br />

      <ContainerFlex align={"normal"} marginTop={10}>
        <S.TableTitle>Benefícios Disponíveis</S.TableTitle>
        <S.Table>
          <tr>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Parcela (R$)</th>
            <th>Valor adesão</th>
            <th>Ação</th>
          </tr>
          {availableBenefitsNotContracteds.map((benefit) => (
            <tr key={benefit.id}>
              <td>{benefit.nome}</td>
              <td>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  <span
                    style={{
                      maxWidth: "46ch",
                      height: "1.5rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {benefit.descricao.replace(",", "")}
                  </span>

                  <img
                    style={{ cursor: "pointer" }}
                    width={15}
                    height={15}
                    src={assets.png.exclamation}
                    alt="informações"
                    onClick={(e) => {
                      setBenefitDetailsDialog({
                        title: benefit.nome,
                        description: benefit.descricao,
                        link: benefit.link,
                      });
                      setBenefitDetailsDialogOpen(true);
                    }}
                  />
                </div>
              </td>
              <td>{moneyMask(benefit.valorMensalidade)}</td>
              <td>{moneyMask(benefit.valorAdesao)}</td>
              <td className="icon-table">
                <img
                  width="35"
                  src={assets.icons.emojiNovaAdesao}
                  alt="handshake"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setBenefitSelected(benefit);
                    setDialogJustificationIsOpen(true);
                  }}
                />
              </td>
            </tr>
          ))}
        </S.Table>

        <br />

        <S.TableTitle>Benefícios Contratados</S.TableTitle>
        <S.Table>
          <tr>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Parcela (R$)</th>
            <th>Detalhes</th>
            <th>Data Adesão</th>
            <th>Dependente</th>
            <th>Status</th>
            <th>Assinar</th>
            <th>Cancelar</th>
          </tr>
          {contractedBenefits?.beneficios
            ?.filter((el) => el.statusBeneficio !== "CANCELADO")
            ?.map((benefit, idx) => (
              <tr key={benefit.nome + idx.toString()}>
                <td>{benefit.nome}</td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    <span
                      style={{
                        maxWidth: "46ch",
                        height: "1.5rem",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {benefit.descricao.replace(",", "")}
                    </span>

                    <img
                      style={{ cursor: "pointer" }}
                      width={15}
                      height={15}
                      src={assets.png.exclamation}
                      alt="informações"
                      onClick={() => {
                        setBenefitDetailsDialog({
                          title: benefit.nome,
                          description: benefit.descricao,
                          link: benefit.link,
                        });
                        setBenefitDetailsDialogOpen(true);
                      }}
                    />
                  </div>
                </td>
                <td>
                  {moneyMask(
                    isTeuCardOdonto(benefit)
                      ? totalValue
                      : benefit.valorMensalidade
                  )}
                </td>
                <td>
                  {isTeuCardOdonto(benefit) && (
                    <button
                      onClick={() => {
                        setBenefitSelected(benefit.id);
                        setDialogIsOpenDetails(true);
                      }}
                    >
                      <img width={18} src={IconSearch} />
                    </button>
                  )}
                </td>{" "}
                <td>{moment(benefit.dataAdesao).format("DD/MM/YYYY")}</td>
                <td>
                  {isTeuCardOdonto(benefit) && (
                    <S.BtnPlus
                      onClick={() => {
                        setBenefitSelected(benefit);
                        setDialogIsOpenDependent(true);
                      }}
                    />
                  )}
                </td>
                <td>
                  {benefit.assinada && benefit.statusBeneficio === "ATIVO" ? (
                    <img
                      width="18"
                      src={assets.icons.emojiAtivo}
                      alt="checkGreen"
                    />
                  ) : benefit.statusBeneficio === "SUSPENSO" ? (
                    <img
                      width="18"
                      src={assets.icons.emojiSuspenso}
                      alt="checkOrange"
                    />
                  ) : (
                    <img
                      width="18"
                      src={assets.icons.emojiPendente}
                      alt="checkYellow"
                    />
                  )}
                </td>
                <td>
                  {benefit.assinada ? (
                    <img
                      width="18"
                      src={assets.png.checkGreen}
                      alt="checkGreen"
                    />
                  ) : (
                    <S.Writing
                      width="18"
                      src={IconWriting}
                      onClick={() => {
                        setBenefitSelected(benefit);
                        handleAssinar(benefit);
                      }}
                    />
                  )}
                </td>
                <td>
                  {!benefit?.extraido && (
                    <S.Writing
                      width="18"
                      src={IconTrash}
                      onClick={() => {
                        setBenefitSelected(benefit.id);
                        setDialogIsOpenDeleteBenefit(true);
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
        </S.Table>

        <br />

        <S.TableTitle>Benefícios Cancelados</S.TableTitle>
        <S.Table>
          <tr>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Parcela (R$)</th>
            <th>Data Adesão</th>
            <th>Data Cancelamento</th>
            <th>Status</th>
          </tr>
          {contractedBenefits?.beneficios
            ?.filter((el) => el.statusBeneficio === 'CANCELADO')
            ?.map((benefit, idx) => (
              <tr key={benefit.nome + idx.toString()}>
                <td>{benefit.nome}</td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    <span
                      style={{
                        maxWidth: "46ch",
                        height: "1.5rem",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {benefit.descricao.replace(",", "")}
                    </span>

                    <img
                      style={{ cursor: "pointer" }}
                      width={15}
                      height={15}
                      src={assets.png.exclamation}
                      alt="informações"
                      onClick={(e) => {
                        setBenefitDetailsDialog({
                          title: benefit.nome,
                          description: benefit.descricao,
                          link: benefit.link,
                        });
                        setBenefitDetailsDialogOpen(true);
                      }}
                    />
                  </div>
                </td>
                <td>{moneyMask(benefit.valorMensalidade)}</td>
                <td>{moment(benefit.dataAdesao).format("DD/MM/YYYY")}</td>
                <td>{moment(benefit.dataCancelamento).format("DD/MM/YYYY")}</td>
                <td className="icon-table">
                  <img
                    width="35"
                    src={assets.icons.emojiCancelado}
                    alt="handshake"
                  />
                </td>
              </tr>
            ))}
        </S.Table>
      </ContainerFlex>
    </>
  );
}
