import React, { useReducer } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import Button from "components/Button";
import Input from "components/Input";
import { useHistory } from "react-router-dom";
import InputSelect from "components/InputSelect";
import { useState } from "react";
import { useEffect } from "react";
import { useLoan } from "hooks/useLoan";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import InputSelectSearch from "components/SelectSearch/inputSelect";
import { accountBankMask } from "utils/mask";

const inputStyle = {
  border: "1px solid #6c6c6c",
  padding: "1rem",
  borderRadius: ".5rem",
  width: "100%",
  height: "4rem",
};

const CreditCardLoanSimulatorBankData = () => {
  const {
    userInitialData,
    simulationData,
    dispatchFinishResponse,
    userAditionalData,
  } = useLoan();

  const [checked, setChecked] = useState(false);
  const [banks, setBanks] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);

  const [filteredBanks, setFilteredBanks] = useState(banks);
  const history = useHistory();
  const initialState = {
    banco: "",
    agencia: "",
    conta: "",
    tipoChave: "CPF_CNPJ",
    chave: userInitialData?.cpf?.unmasked,
    numberBank: "",
    tipoConta: "",
  };

  const [data, dispatch] = useReducer(reducer, initialState);
  const { banco, agencia, conta, tipoChave, chave, tipoConta } = data;

  function reducer(data, action) {
    const { type, payload } = action;
    return { ...data, [type]: payload };
  }

  useEffect(() => {
    if (checked) {
      dispatch({ type: "tipoChave", payload: "" });
      dispatch({ type: "chave", payload: "" });
    }
  }, [checked]);

  useEffect(() => {
    if (tipoChave !== "CPF_CNPJ") {
      dispatch({ type: "chave", payload: "" });
    } else {
      dispatch({ type: "chave", payload: userInitialData?.cpf?.unmasked });
    }
  }, [tipoChave]);

  const onChange = (e, mask) => {
    const {
      target: { name, value },
    } = e;
    dispatch({ type: name, payload: mask ? mask(value) : value });
  };

  const validateInputs = () => {
    return (
      !banco ||
      !agencia ||
      !conta?.masked?.includes("-") ||
      (!tipoChave && !checked) ||
      (!chave && !checked)
    );
  };
  const pixType = [
    { value: "nenhuma", label: "", hidden: true },
    { value: "CPF_CNPJ", label: "CPF / CNPJ", selected: true },
    { value: "EMAIL", label: "E-Mail" },
    { value: "PHONE", label: "Celular" },
    { value: "RANDOM", label: "Aleatória" },
  ];
  const accountType = [
    { value: "nenhuma", label: "", hidden: true },
    { value: "CONTA_CORRENTE", label: "Conta Corrente" },
    { value: "CONTA_POUPANCA", label: "Conta Poupança" },
  ];

  const PostFinishLoan = useApi(constants.endpoints.postLoanAttendance, "POST");
  const GetBanks = useApi(constants.endpoints.getBanks, "GET");

  useEffect(() => {
    const getBanks = async () => {
      const { data } = await GetBanks.callApi();
      if (data.bancos) {
        const ordenedBanks = data?.bancos
          ?.sort((a, b) => Number(b.code) + Number(a.code))
          ?.map((e) => ({
            value: e.code,
            label: `${e.code} - ${e.fullName}`,
            name: e.name,
            code: e.code,
          }));
        setBanks(ordenedBanks);
        setFilteredBanks(ordenedBanks);
      }
    };
    getBanks();
  }, []);

  const {
    novosDados: { dtNascimento, endereco, nomeMae, nomePai, rg },
    id,
  } = userAditionalData;

  const { celular, cpf, email, nome } = userInitialData;

  const {
    interest_rate,
    installment_value,
    iof_total,
    credit_limit,
    released_amount,
    value_free,
  } = simulationData;

  const handleSubmitLoan = async () => {
    const body = {
      atendimento: {
        cliente: {
          celular: celular?.unmasked,
          cpf: cpf?.unmasked,
          dataNascimento: dtNascimento?.masked?.split("/").reverse().join("-"),
          endereco: { ...endereco, cep: endereco?.cep?.unmasked },
          email,
          nome,
          nomePai,
          nomeMae,
          rg: rg?.unmasked,
          idCliente: id,
        },
        card_limit: credit_limit,
        installments: 12,
        interest_rate,
        value_installment: installment_value,
        value_iof: iof_total,
        released_amount: released_amount || value_free,
      },
      banco: {
        accountDigit: conta?.masked?.split("-")[1],
        agencyDigit: "",
        numberAccount: conta?.masked?.split("-")[0],
        agencyAccount: agencia?.split("-")[0],
        kind: tipoConta,
        kindPix: checked ? null : tipoChave,
        nameBank: banco?.name,
        numberBank: banco?.code,
        pix: checked ? "" : chave,
      },
    };
    const { status } = await PostFinishLoan.callApi(body);
    dispatchFinishResponse({ error: status !== 200 });
    history.push("/credit-card-loan-simulator-finish");
  };
  const handleFilterBanks = (e) => {
    const { value } = e.target;
    dispatch({ type: "banco", payload: value });
    setFilteredBanks(
      banks.filter((bank) =>
        bank?.label?.toLowerCase().trim().includes(value?.toLowerCase())
      )
    );
  };
  return (
    <St.SafeView>
      <Layout
        routeBack="/credit-card-loan-simulator-aditional-data"
        title="Dados bancários"
      >
        <St.Container>
          <St.TopSpace>
            <St.Title>
              Agora precisamos de informações bancárias (pra onde o teu
              empréstimo vai!):
            </St.Title>
            <St.Inputs>
              <InputSelectSearch
                labelFontWeight="bold"
                name="banco"
                disabled={checked}
                options={filteredBanks}
                value={banco?.label}
                id="banco"
                inputTextValue={banco?.label}
                defaultValue={banco?.label}
                onFocus={() => setOpenSelect(true)}
                style={inputStyle}
                label="Código e Descrição do Banco*"
                onChange={handleFilterBanks}
                onClickSelect={(e) => dispatch({ type: "banco", payload: e })}
                open={openSelect}
                setOpen={setOpenSelect}
              />

              <Input
                boldLabel
                style={inputStyle}
                name="agencia"
                placeholder=""
                type="text"
                value={agencia}
                onChange={(e) => onChange(e)}
                label="Agência (sem dígito)*"
              />
              <Input
                name="conta"
                value={conta?.masked}
                onChange={(e) => onChange(e, accountBankMask)}
                boldLabel
                style={inputStyle}
                label="Conta*"
              />
              <InputSelect
                labelFontWeight="bold"
                type="select"
                name="tipoConta"
                values={accountType}
                value={tipoConta}
                defaultValue={tipoConta}
                style={{ ...inputStyle, padding: ".5rem 1rem" }}
                label="Tipo de conta*"
                max={15}
                onChange={(e) => onChange(e)}
              />

              <InputSelect
                labelFontWeight="bold"
                type="select"
                name="tipoChave"
                disabled={checked}
                values={pixType}
                value={tipoChave}
                defaultValue={tipoChave}
                style={{ ...inputStyle, padding: ".5rem 1rem" }}
                label="Tipo de chave PIX*"
                onChange={(e) => onChange(e)}
              />
              <St.DivCheck>
                <input
                  value={checked}
                  type="checkbox"
                  onChange={(e) => setChecked(e.target.checked)}
                />
                <span>Não possuo chave PIX</span>
              </St.DivCheck>
              <Input
                disabled={checked}
                boldLabel
                name="chave"
                style={inputStyle}
                label="Chave PIX*"
                value={chave}
                onChange={(e) => onChange(e)}
              />
            </St.Inputs>

            <St.Footer>
              <Button
                loading={PostFinishLoan.loading}
                style={{ width: "50%", justifySelf: "flex-end" }}
                buttonType="primary"
                text={"Finalizar"}
                onClick={() => handleSubmitLoan()}
                disabled={validateInputs()}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default CreditCardLoanSimulatorBankData;
