import styled from "styled-components";
import Button from "components/ButtonBack";

export const Container = styled.div`
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-self: center;
  position: relative;

  @media (max-width: 1180px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  line-height: ${({
    theme: {
      lineHeight: { lineHeightUL },
    },
  }) => lineHeightUL};
  justify-content: center;
  align-items: center;
  margin-bottom: ${({
    theme: {
      spacings: { medium },
    },
  }) => medium};
`;

export const SmallText = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-left: 1rem;

    span {
      font-size: 1.4rem;
      white-space: nowrap;
      color: ${(props) => props.color || '#e71037'};
      font-weight: bold;

      @media screen and (max-width: 700px) {
        font-size: 1.3rem;
      }

      @media screen and (max-width: 3500px) {
        font-size: 1.2rem;
      }
    }
`;

export const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  span {
    font-size: 1.6rem;
    white-space: nowrap;

    @media screen and (max-width: 700px) {
      font-size: 1.3rem;
    }

    @media screen and (max-width: 350px) {
      font-size: 1rem;
    }
  }
`;

export const Logout = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  color: ${({
    theme: {
      colors: { primary },
    },
  }) => primary};
  cursor: pointer;
  margin-left: 10px;

  :hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 700px) {
    font-size: 1.3rem;
  }
`;

export const ButtonBack = styled(Button)`
  position: absolute;
  left: 0;
`;
