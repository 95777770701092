import React from "react";

import LinkButton from "components/LinkButton";
import themes from "styles";
import * as S from "./styles";

export const OneButtonPopup = ({
  children,
  defaultButton,
  imgIcon,
  visibility,
  setVisibility,
  title,
  icon,
  iconModal,
  text,
  buttonText,
  slug,
  onClose,
}) => {
  return (
    <S.Container
      open={visibility}
      onClose={() => {
        setVisibility(false);
        onClose && onClose();
      }}
      modal
    >
      {(close) => (
        <>
          <div className="content-container">
            {iconModal ? <img src={iconModal}/> : icon || themes.components.icons.warning}
            <h3>{title}</h3>
            <p>{text}</p>
            {children}
          </div>

          <hr />

          {defaultButton ? (
            <div className="button-container">
              <button onClick={close} className="button-cancel">
                Ok
              </button>
            </div>
          ) : (
            <LinkButton slug={slug} text={buttonText} />
          )}
        </>
      )}
    </S.Container>
  );
};

export default OneButtonPopup;
