/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useEffect } from "react";

import { FiChevronDown } from "react-icons/fi";

import * as S from "./styles";
import Input from "components/Input";

//TODO: deixar dinamico pois só é utilizado no fluxo de emprestimo
const InputSelectSearch = ({
  label,
  id,
  errorMessage,
  placeholder,
  onBlur,
  onChange,
  defaultValue,
  onClickSelect,
  inputTextValue,
  widthOptions,
  options = [],
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const onClickSelectOption = (text) => {
    onClickSelect?.(text, id);
    setOpen?.(false);
  };
  return (
    <S.Container onClick={() => setOpen(!open)}>
      <S.InputContainer>
        <Input
          {...rest}
          label={label}
          boldLabel
          id={id}
          largeLabel
          autoFocusInput={false}
          autoCapitalizeInput={"none"}
          autoCorrectInput={false}
          onBlur={onBlur}
          placeholder={placeholder}
          value={inputTextValue}
          defaultValue={defaultValue}
          error={errorMessage}
          onChange={(text, name) => onChange(text, name)}
        />

        <S.IconContainer errorMessage={errorMessage}>
          <FiChevronDown
            color="#E4003A"
            size={23}
            style={{
              transform: [
                {
                  rotate: open ? "180deg" : "0deg",
                },
              ],
            }}
          />
        </S.IconContainer>
      </S.InputContainer>
      {open && (
        <S.ContainerOptions widthOptions={widthOptions}>
          <div className="option">
            {options?.map((option, index) => (
              <>
                <S.Option
                  key={option?.code}
                  onClick={() => onClickSelectOption(option)}
                >
                  <S.ContainerItems key={option?.code}>
                    <S.OptionLabel>{option?.label}</S.OptionLabel>
                  </S.ContainerItems>
                </S.Option>
                {index !== options?.length - 1 && <S.Divider />}
              </>
            ))}
          </div>
        </S.ContainerOptions>
      )}
    </S.Container>
  );
};

export default InputSelectSearch;
