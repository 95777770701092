import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router";
import useWebSocket from "react-use-websocket";
import Button from "components/Button";
import ButtonBack from "components/ButtonBack";
import Layout from "components/OldLayout";
import * as S from "./styles";
import Repository from "repository";
import BenefitTable from "components/BenefitTable";
import themes from "styles";
import Input from "components/Input";
import moment from "moment";
import { SignatureContext } from "context/SignatureContext";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import OneButtonPopup from "components/modals/OneButtonPopup";
import Spinner from "components/Spinner";
import axios from "axios";
import Loading from "components/Loading";

const ChangeCategorySignature = () => {
  const history = useHistory();
  const [signatureImage, setSignatureImage] = useState("");
  const [finishMessage, setFinishMessage] = useState("");
  const [finishErrorMessage, setFinishErrorMessage] = useState("");
  const [finishDialog, setFinishDialog] = useState(false);
  const [finishErrorDialog, setFinishErrorDialog] = useState(false);
  const { imageURL, setImageURL } = useContext(SignatureContext);
  const [loadingMessage, setLoadingMessage] = useState(
    "Aguarde enquanto criamos os contratos..."
  );
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const { state } = useLocation();
  const [startWebSocket, setStartWebSocket] = useState(false);
  const [loadingWebSocket, setLoadingWebSocket] = useState(false);

  useEffect(() => {
    return () => {
      setImageURL(null);
    };
  }, []);

  useEffect(() => {
    let timeoutId;

    if (startWebSocket) {
      timeoutId = setTimeout(() => {
        if (!finishDialog && !finishErrorDialog) {
          setLoadingWebSocket(false);
          setFinishErrorMessage(
            "Ops! Ocorreu um problema com sua solicitação, por favor entre em contato com o suporte."
          );
          setFinishErrorDialog(true);
        }
      }, 40000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [startWebSocket, finishDialog, finishErrorDialog]);

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      if (startWebSocket) {
        setLoadingWebSocket(true);
        resolve(
          `${process.env.REACT_APP_WEBSOCKET_EVENT}/v1/websockets/clientes/alterar-tema-cartao/${state?.data?.cpf}`
        );
      }
    });
  }, [startWebSocket]);

  const webSocketConfig = {
    onOpen: () => console.log("open"),
    onClose: () => console.log("closed"),
  };

  const { lastMessage } = useWebSocket(getSocketUrl, webSocketConfig);

  useEffect(() => {
    if (lastMessage) {
      try {
        const data = JSON.parse(lastMessage?.data);
        console.log(data, "testando");
        if (data?.status === "SUCESSO") {
          setLoadingWebSocket(false);
          setIsLoadingMessage(false);
          setFinishMessage(data?.mensagem);
          setFinishDialog(true);
        } else {
          setLoadingWebSocket(false);
          setIsLoadingMessage(false);
          setFinishErrorMessage(data?.mensagem);
          setFinishErrorDialog(true);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [lastMessage]);

  const finishEvent = async () => {
    setIsLoadingMessage(true);
    const token = Repository.storage.get(Repository.keys.TOKEN_USER);
    const tokenBearer = Repository.storage.get(Repository.keys.TOKEN_BEARER);
    const config = {
      headers: {
        apikey: `token ${token}`,
        Authorization: `Bearer ${tokenBearer}`,
      },
    };
    try {
      const formData = new FormData();
      formData.append("celularZeuss", state.phone);
      formData.append("tema", state?.themeSelected);
      formData.append("aprovar", true.toString());
      formData.append("idEvento", state?.idEvent);
      formData.append("assinaturaImage", signatureImage?.split(",")[1]);

      const response = await axios.post(
        constants.endpoints.postExecucuteEvent,
        formData,
        config
      );
      if (response.status >= 200 && response.status < 300) {
        setStartWebSocket(true);
      } else {
        setFinishErrorDialog(true);
        setFinishErrorMessage(response.data?.mensagem);
      }
    } catch (err) {
      setIsLoadingMessage(false);
      setFinishErrorDialog(true);
      setFinishErrorMessage(
        err.request.response.split('"mensagem":"')[1].split('"')[0]
      );
    }
  };

  useEffect(() => {
    if (imageURL) {
      setSignatureImage(imageURL);
    }
  }, [imageURL]);

  const LoadingProposal = () => {
    const timer1 = setTimeout(() => {
      setLoadingMessage(
        "Contratos criados! Por favor aguarde enquanto fazemos as assinaturas..."
      );
    }, [5000]);
    const timer2 = setTimeout(() => {
      clearTimeout(timer1);
      setLoadingMessage("Finalizando o processo de assinatura...");
    }, [10000]);
    clearTimeout(timer2);

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        <span>{loadingMessage}</span>
        <Spinner color="#000" />
      </div>
    );
  };

  return (
    <S.Container>
      <Loading
        status={loadingWebSocket}
        firstMessage="Aguarde alguns segundos..."
        secondMessage="Estamos alterando a categoria do cliente..."
        webSocket={loadingWebSocket}
      />
      <OneButtonPopup
        defaultButton
        text={finishErrorMessage}
        buttonText="OK"
        visibility={finishErrorDialog}
        setVisibility={() => setFinishErrorDialog(false)}
        slug="/opcoes-acesso"
        onClose={() => history.push("/opcoes-acesso")}
      />
      <Layout routeBack="/change-category" title="Alterar categoria">
        <S.Content>
          <div>
            <BenefitTable
              isProposalCompleted={false}
              idProposal={state?.data.id}
              contractedBenefits={[state.upgradeCardInfo] || ""}
              title={"Detalhes"}
            />
            <OneButtonPopup
              defaultButton
              text={finishMessage}
              buttonText="OK"
              slug="/upgrade-category-confirmation"
              onClose={() =>
                history.push("/upgrade-category-confirmation", { ...state })
              }
              visibility={finishDialog}
              setVisibility={() => setFinishDialog(false)}
            />
            <S.Text>Toque para assinar a alteração do plano</S.Text>
            <S.BoxSignature
              value={signatureImage}
              onClick={() =>
                history.push("/upgrade-signature", {
                  ...state,
                })
              }
            >
              <S.LineSignature signature={!!signatureImage}>
                {signatureImage ? (
                  <S.ImgURL src={signatureImage} alt="Assinatura Digital" />
                ) : (
                  themes.components.icons.shape
                )}
              </S.LineSignature>
            </S.BoxSignature>
            <Input
              id="dateSignature"
              name="dateSignature"
              label="Data"
              type="text"
              value={moment(new Date()).format("DD/MM/YYYY")}
            />
          </div>
          <S.Footer>
            <Button
              buttonType="primary"
              text={"Li e concordo"}
              loading={isLoadingMessage || loadingWebSocket}
              disabled={!signatureImage || isLoadingMessage || loadingWebSocket}
              onClick={() => finishEvent()}
              className="button"
            />
          </S.Footer>
        </S.Content>
      </Layout>
    </S.Container>
  );
};

export default ChangeCategorySignature;
