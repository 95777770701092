import React, { useState } from "react";
import * as S from "./styles";
import { cepMask } from "utils/mask";
import assets from "assets";

const ProposalCard = (props) => {
  const { date, infos } = props;

  return (
    <S.WrapperProposal style={{ overflow: "auto" }}>
      <S.Title>
        <b>CONTRATO DE EMISSÃO E USO DO CARTÃO TEUCARD PRIVATE LABEL</b>
      </S.Title>

      <S.Text>
        <b>MERCANTIL NOVA ERA LTDA (TEUCARD)</b>, pessoa jurídica de direito
        privado, com sede em Manaus-AM, situada na Av. Torquato Tapajós, 2871,
        CEP: 69.048-010, inscrita no CNPJ nº 04.240.370/0001-57, doravante
        designado “<b>EMISSOR</b>”, e de outro lado a pessoa física, titular de
        um cartão Teucard, devidamente qualificada abaixo, doravante designado “
        <b>PORTADOR TITULAR</b>”.
      </S.Text>
      <S.Text>
        <b>PORTADOR TITULAR:</b> {infos.nome}
        <br />
        <b>CPF:</b> {infos.cpf}
        <br />
        <b>ENDEREÇO:</b> {infos.endereco?.logradouro}, {infos.endereco?.numero}.{" "}
        {infos.endereco?.complemento &&
          `Complemento: ${infos.endereco?.complemento}.`}{" "}
        Bairro: {infos.endereco?.bairro}. CEP:{" "}
        {cepMask(infos.endereco?.cep).masked} <br />
        <b>CIDADE-UF:</b> {infos.endereco?.cidade}-{infos.endereco?.uf}
      </S.Text>

      <S.Ol type="1">
        <li>
          <b>DAS DEFINIÇÕES</b> <br />
          <br />
          Neste contrato, todas as expressões em letra maiúscula, independente
          do gênero, e utilizadas no plural ou no singular, deverão ser
          interpretadas de acordo com o significado a seguir informado:
          <S.Ol type="a">
            <li>
              CARTÃO – modalidade de CARTÃO de uso pessoal e exclusivo do
              PORTADOR TITULAR, no formato físico ou virtual, com as
              características próprias e denominado “Teucard”, sendo utilizado
              para realizar TRANSAÇÕES nos ESTABELECIMENTOS, nas modalidades
              admitidas.
            </li>
            <li>
              CONTA CARTÃO – Todas as informações cadastrais e financeiras do
              PORTADOR TITULAR do CARTÃO.
            </li>
            <li>
              EMISSOR – é o MERCANTIL NOVA ERA LTDA (TEUCARD), qualificado no
              preâmbulo e que executa a emissão do CARTÃO.{" "}
            </li>
            <li>
              ENCARGOS – São os juros e tributos devidos quando houver
              financiamento das despesas realizadas com o CARTÃO ou contratação
              de operações de financiamentos;
            </li>
            <li>
              ESTABELECIMENTO - estabelecimento comercial ou de prestação de
              serviços, credenciado ao EMISSOR, onde o PORTADOR TITULAR poderá
              efetuar TRANSAÇÕES;
            </li>
            <li>
              FATURA – Documento emitido mensalmente quando realizadas
              transações ou quando houver quaisquer cobranças realizadas no
              CARTÃO. A fatura conterá informações acerca do valor total das
              despesas realizadas com o CARTÃO, LIMITE DE CRÉDITO, valores para
              pagamento e demais informações constantes na cláusula 7 do
              presente contrato;
            </li>
            <li>
              JUROS MORATÓRIOS – É uma taxa percentual aplicada em razão do
              atraso no pagamento.
            </li>
            <li>
              LIMITE DE CRÉDITO – é o valor da linha de crédito total, em moeda
              corrente nacional, fixado pelo EMISSOR para a realização de
              TRANSAÇÕES pelo PORTADOR TITULAR por meio da utilização de CARTÃO,
              de modalidade pós-pago;{" "}
            </li>
            <li>
              PAGAMENTO MÍNIMO – Valor mínimo, indicado na FATURA, para
              pagamento das despesas até a data de vencimento da fatura, para
              que não seja caracterizada a inadimplência. Trata-se de um
              pagamento parcial.{" "}
            </li>
            <li>
              PORTADOR TITULAR – Pessoa física contratante do CARTÃO autorizada
              a realizar TRANSAÇÕES, que deverá ser maior, capaz e não poderá
              possuir qualquer restrição junto a Instituições Financeiras,
              Centrais de Informações de Crédito e similares. O PORTADOR TITULAR
              é o responsável pelo integral cumprimento das obrigações aqui
              assumidas, em especial pelo pagamento da FATURA onde são lançadas
              as DESPESAS.
            </li>
            <li>
              SENHA ELETRÔNICA – Código pessoal e intransferível que constitui
              sua identificação e assinatura, quando da realização de TRANSAÇÕES
              nos ESTABELECIMENTOS;{" "}
            </li>
            <li>
              TRANSAÇÃO – Toda operação realizada pelo PORTADOR TITULAR, através
              dos CARTÕES, incluindo o ato de pagar, de aportar, de transferir
              ou de sacar recursos, nos termos deste contrato.{" "}
            </li>
          </S.Ol>
        </li>
        <br />
        <li>
          <b>DA ADESÃO AO CONTRATO</b> <br /> <br />
          <b>2.1</b> A confirmação da adesão a este contrato será realizada de
          forma tácita. Ou seja, por meio de qualquer um dos seguintes atos:
          <S.Ol type="a">
            <li>
              Solicitação do desbloqueio do CARTÃO, realizada por qualquer meio
              disponibilizado, tais como, mas não se limitando a, por meio do
              ESTABELECIMENTO, do SAC - Serviço de Atendimento ao Cliente, do
              sítio eletrônico na Internet ou do aplicativo de dispositivos
              móveis;{" "}
            </li>
            <li>Utilização do CARTÃO em qualquer de suas funções; </li>
            <li>Pagamento da FATURA;</li>
            <li>
              Assinatura deste contrato que poderá ser feita pessoalmente ou por
              meio de confirmação via Internet.{" "}
            </li>
            <li>
              Mediante manifestação expressa de vontade por qualquer meio. 
            </li>
          </S.Ol>
          <b>2.2</b> A adesão ao presente contrato implica em plena concordância
          com todas as cláusulas que o contemplam; <br />
          <b>2.3</b> A emissão do CARTÃO depende da aprovação pelo EMISSOR,
          segundo seus critérios exclusivos de análise cadastral e creditícia.
          <br />
          <b>2.4</b> O CARTÃO é de propriedade do EMISSOR e deverá ser devolvido
          a este quando solicitado.
        </li>
        <br />
        <li>
          <b>DO USO DO CARTÃO</b> <br /> <br />
          <span>
            <b>3.1</b> O CARTÃO é utilizado como instrumento de pagamento para
            compras de bens e/ou serviços a ser utilizado apenas nos
            ESTABELECIMENTOS.{" "}
          </span>
          <br />
          <span>
            <b>3.2</b> O PORTADOR TITULAR do CARTÃO deverá confirmar a TRANSAÇÃO
            com seu CARTÃO através do registro de sua SENHA ELETRÔNICA. Tal
            procedimento representará manifestação de vontade inequívoca,
            ciência, responsabilidade e aceitação da TRANSAÇÃO.{" "}
          </span>
          <br />
          <span>
            <b>3.3</b> O EMISSOR não se responsabilizará pelo preço, qualidade e
            quantidade dos bens/serviços adquiridos/contratados através de
            transações nos CARTÕES. Qualquer pendência relacionada a referidos
            produtos ou serviços deverá ser resolvida junto ao ESTABELECIMENTO.{" "}
          </span>
          <br />
          <span>
            <b>3.4</b> O PORTADOR TITULAR do CARTÃO deverá zelar pela segurança
            de informações transmitidas através de acesso via Internet,
            principalmente quando envolver a utilização do número do CARTÃO e/ou
            SENHA ELETRÔNICA.{" "}
          </span>
          <br />
          <span>
            <b>
              3.5 A SENHA ELETRÔNICA é a assinatura eletrônica pessoal,
              intransferível e confidencial. O PORTADOR TITULAR não deverá
              informar sua SENHA ELETRÔNICA a qualquer pessoa, por mais
              privilegiada que seja, sob pena de rescisão deste contrato, bem
              como responsabilização pela utilização do CARTÃO.
            </b>
          </span>
          <br />
          <span>
            <b>3.6</b> A SENHA ELETRÔNICA também poderá ser utilizada para
            consultas de LIMITE DE CRÉDITO, FATURAS, saldos, extratos etc.{" "}
          </span>
          <br />
          <span>
            <b>3.7</b> O EMISSOR poderá autorizar, a seu critério, novas formas
            de utilização do CARTÃO, estando expressamente proibida, todavia,
            sua utilização por terceiros, de maneira não prevista neste CONTRATO
            ou em estabelecimentos não credenciados.
          </span>
          <br />
          <span>
            <b>3.8</b>
            Em caso de roubo, furto, perda ou extravio do CARTÃO, o PORTADOR
            TITULAR deverá solicitar, de imediato, o bloqueio do mesmo, através
            dos canais eletrônicos (APP e/ou sítio de internet) ou pelo SAC -
            Serviço de Atendimento ao Cliente.
          </span>
          <br />
          <span>
            <b>3.9</b> O CARTÃO é nominativo, intransferível, de uso pessoal e
            exclusivo. Em caso de falecimento do titular, o CARTÃO não mais
            poderá ser utilizado, devendo seus sucessores ou espólio comunicar o
            EMISSOR, no menor prazo possível, para promover seu cancelamento.
          </span>
        </li>
        <br />
        <li>
          <b>DAS FORMAS DE PARCELAMENTO DAS DESPESAS</b> <br /> <br />
          <b>4.1</b>
          Quando realizar TRANSAÇÕES com o CARTÃO, o PORTADOR TITULAR poderá
          efetuar o pagamento de suas despesas à vista ou de forma parcelada. O
          parcelamento de suas despesas se dará através das seguintes
          modalidades: <br />
          <div style={{ marginLeft: 20 }}>
            <b>4.1.1</b> Parcelado sem Juros:
            <S.Ol type="a" style={{ marginLeft: 20 }}>
              <li>
                Corresponde à possibilidade de parcelamento das despesas
                oferecida pelo ESTABELECIMENTO quando da utilização do CARTÃO.
                As parcelas serão lançadas diretamente na FATURA sem a
                incorrência de ENCARGOS.{" "}
              </li>
              <li>
                A somatória de todas as parcelas decorrentes da utilização do
                CARTÃO na modalidade Parcelado sem Juros, será incluída no
                cômputo do LIMITE DE CRÉDITO, e a recomposição do limite de
                crédito ocorrerá proporcionalmente, e somente após, compensado o
                pagamento de cada parcela.{" "}
              </li>
            </S.Ol>
            <b>4.1.2</b> Parcelado com Juros:
            <S.Ol type="a" style={{ marginLeft: 20 }}>
              <li>
                Corresponde ao parcelamento das despesas através de
                financiamento obtido junto ao EMISSOR, sob o qual incidirá
                ENCARGOS e/ou taxa de juros remuneratórios pré-fixada.{" "}
              </li>
              <li>
                As parcelas das despesas serão iguais, mensais, consecutivas e
                descritas na FATURA, e, os ENCARGOS e juros remuneratórios serão
                contados a partir da data da TRANSAÇÃO até a data do vencimento
                de cada parcela e serão cobrados juntamente com as despesas na
                FATURA.{" "}
              </li>
              <li>
                O EMISSOR poderá, mediante prévia comunicação, estabelecer
                condições específicas de pagamento das parcelas do Parcelado com
                Juros, inclusive podendo postergar a data de vencimento de
                algumas parcelas.{" "}
              </li>
              <li>
                Fica estabelecido, ainda, que a somatória de todas as parcelas
                referentes ao pagamento na modalidade Parcelado com Juros será
                incluída no cômputo do LIMITE DE CRÉDITO e a recomposição do
                LIMITE DE CRÉDITO ocorrerá proporcionalmente e somente após
                compensado o pagamento de cada parcela.
              </li>
            </S.Ol>
          </div>
        </li>
        <br />
        <li>
          <b>DA LIQUIDAÇÃO ANTECIPADA</b> <br /> <br />
          <span>
            <b>5.1</b> No caso de financiamento, O PORTADOR TITULAR do CARTÃO
            poderá efetuar o pagamento antecipado de parcelas a vencer mediante
            redução proporcional dos ENCARGOS incidentes. A solicitação de
            antecipação deve ser realizada nos canais de atendimento.{" "}
          </span>
          <br />
          <span>
            <b>5.2</b> Em caso de liquidação antecipada de operações, a taxa
            utilizada para apuração do valor presente será a taxa pactuada na
            contratação da operação.{" "}
          </span>
          <br />
        </li>
        <br />
        <li>
          <b>DO CARTÃO</b> <br /> <br />
          <b>6.1</b> TARIFAS
          <S.Ol type="a">
            <li>
              TARIFA DE ANUIDADE - Poderá ser cobrada TARIFA DE ANUIDADE em sua
              FATURA, a partir da adesão ao CARTÃO. A TARIFA DE ANUIDADE visa
              remunerar o EMISSOR pela prestação de serviços consistentes em
              disponibilizar e manter o CARTÃO.{" "}
            </li>
            <li>
              O PORTADOR TITULAR do CARTÃO será previamente informado caso valor
              da TARIFA DE ANUIDADE seja reajustado pelo EMISSOR, nos termos da
              legislação em vigor.{" "}
            </li>
            <li>
              Caso o PORTADOR TITULAR do CARTÃO não concorde com a cobrança da
              TARIFA DE ANUIDADE, poderá rescindir o presente contrato. A
              renúncia do EMISSOR à cobrança da tarifa prevista nesta cláusula
              representará mera liberalidade e não importará em renúncia a
              outras parcelas ou tarifas devidas.
            </li>
            <li>
              TARIFA DE COBRANÇA – a título de restituir o EMISSOR das despesas
              com serviço de cobrança ao inadimplente, poderá ser cobrada TARIFA
              DE COBRANÇA em sua FATURA em caso de atraso superior a 5(cinco)
              dias.{" "}
            </li>
          </S.Ol>
          <b>6.2</b> DEMAIS TARIFAS
          <S.Ol type="a">
            <li>
              Poderá ainda o EMISSOR realizar a cobrança de outras tarifas por
              outros serviços que serão disponibilizados ao PORTADOR TITULAR por
              intermédio do CARTÃO, sendo certo que tais tarifas serão
              divulgadas previamente de forma a respeitar a legislação e/ou
              regulamentação vigente.{" "}
            </li>
          </S.Ol>
        </li>
        <br />
        <li>
          <b>DA FATURA</b> <br />
          <b>7.1</b> O PORTADOR TITULAR do CARTÃO terá acesso a FATURA somente
          quando incorrer em despesas, na qual constarão as seguintes
          informações:
          <S.Ol type="a">
            <li>Identificação do CARTÃO; </li>
            <li>LIMITE DE CRÉDITO;</li>
            <li>Data de vencimento para pagamento das despesas; </li>
            <li>Descrição das transações realizadas no seu CARTÃO;</li>
            <li>Valor do PAGAMENTO MÍNIMO;</li>
            <li>Valor total das despesas para pagamento; e </li>
            <li>CET – Custo Efetivo Total, conforme legislação em vigor.</li>
          </S.Ol>
          <b>7.2</b> O PORTADOR TITULAR do CARTÃO deverá efetuar o pagamento da
          FATURA na data indicada. Para tanto, terá as opções de:
          <S.Ol type="a">
            <li>Pagar o valor total da FATURA; ou</li>
            <li>
              Pagar qualquer valor entre o PAGAMENTO MÍNIMO e o valor total da
              fatura, financiando o saldo devedor. Nesse caso, referido saldo
              devedor poderá ser objeto de financiamento na modalidade de
              crédito rotativo até o vencimento da fatura subsequente.{" "}
            </li>
          </S.Ol>
          <b>7.3</b> A FATURA será enviada, em meio digital, para o endereço de
          e-mail do PORTADOR TITULAR. Cabendo ao mesmo, manter o endereço de
          e-mail sempre atualizado através do Serviço de Atendimento ao Cliente.{" "}
          <br />
          <span style={{ marginLeft: 20 }}>
            <b>7.3.1</b> Caso o PORTADOR TITULAR do CARTÃO não receba a FATURA
            com até 03 (três) dias de antecedência à data do vencimento, deverá
            entrar em contato com o Serviço de Atendimento ao Cliente para
            verificar o valor devido e o procedimento para o pagamento desses
            valores.{" "}
          </span>{" "}
          <br />
          <b>7.4</b> Verificada qualquer dúvida com relação às despesas
          constantes em sua FATURA, o PORTADOR TITULAR do CARTÃO deverá contatar
          o EMISSOR através dos canais de atendimento, para prestação de todas
          as informações necessárias e providências cabíveis, conforme o caso.
          <br />
          <b>7.5</b> O PORTADOR TITULAR do CARTÃO deverá acessar a sua FATURA
          por meios digitais e/ou pelos canais disponibilizados pelo EMISSOR.{" "}
          <br />
          <b>7.6</b> A FATURA ainda poderá ser utilizada para comunicação prévia
          de:
          <br />
          <S.Ol type="a">
            <li>eventuais cobranças de novas tarifas ou reajustes; </li>
            <li>alterações nas condições e minutas do contrato; e </li>
            <li>promoções ou informações de interesse do PORTADOR TITULAR.</li>
          </S.Ol>
        </li>
        <br />
        <li>
          <b>DAS CONTESTAÇÕES DE DESPESAS</b> <br /> <br />
          <b>8.1</b> O PORTADOR TITULAR do CARTÃO poderá contestar qualquer
          TRANSAÇÃO e/ou despesas constantes na FATURA, no prazo de até 30
          (trinta) dias a contar da data do vencimento da respectiva FATURA.
          Para tanto deverá apresentar a contestação através do SAC - Serviço de
          Atendimento ao Cliente, disponibilizado pelo EMISSOR. <br />
          <b>8.2</b> Ocorrendo a apresentação da contestação, o EMISSOR poderá,
          sem que tal procedimento constitua assunção de nova dívida,
          desconsiderar a TRANSAÇÃO e/ou despesa contestada no momento do
          pagamento da FATURA, ou estorná-la, na hipótese da TRANSAÇÃO e/ou
          despesa contestada já ter sido paga. <br />
          <b>8.3</b> No caso do estorno de TRANSAÇÃO e/ou despesa já paga, o
          valor principal será devolvido em forma de créditos na FATURA
          subsequente.
          <br />
          <b>8.4</b>
          Verificada a improcedência da contestação, o valor da TRANSAÇÃO e/ou
          da despesa indevidamente contestada será reconsiderada na FATURA
          subsequente. Sobre tal valor, incidirão os juros remuneratórios e
          demais ENCARGOS de inadimplência relativos ao financiamento desde a
          data do vencimento da respectiva FATURA contestada.
        </li>
        <br />
        <li>
          <b>DO PAGAMENTO DE COMPRAS E DESPESAS </b> <br /> <br />
          <b>9.1</b> O pagamento de compras e despesas deverá ser realizado na
          forma e na data selecionada pelo PORTADOR TITULAR do CARTÃO quando da
          adesão ao CARTÃO.{" "}
        </li>
        <br />
        <li>
          <b>DO FINANCIAMENTO DE COMPRAS E DESPESAS</b> <br /> <br />
          <b>10.1</b> Ao optar por pagar suas compras ou despesas fazendo uso do
          crédito rotativo ou crédito parcelado, o PORTADOR TITULAR estará,
          automaticamente, autorizando o EMISSOR a contratar, como seu
          procurador, individual ou solidariamente, um financiamento em seu nome
          e por sua conta, junto a instituições financeiras, no valor
          correspondente às compras e despesas realizadas através do CARTÃO, o
          qual deverá ser liquidado pelo  PORTADOR TITULAR, conforme o plano de
          crédito adotado, acrescido dos respectivos ENCARGOS, que lhe serão
          previamente informados através da FATURA e/ou de outros meios de
          comunicação adotados pelo EMISSOR.
          <br />
          <b>10.2</b> Para obtenção dos recursos de financiamento aqui
          previstos, o PORTADOR TITULAR constitui o EMISSOR como seu bastante
          procurador, com poderes especiais para que, em seu nome e por sua
          conta, em conjunto ou separadamente, possam negociar e obter crédito
          junto a instituições financeiras, assinar contratos de financiamento e
          títulos representativos de garantia do débito, acertar condições,
          prazos e ENCARGOS, podendo substabelecer, no todo ou em parte, o
          mandato ora outorgado. <br />
          <b>10.3</b> O EMISSOR, conforme a natureza de cada negociação
          constituir-se-ão em conjunto ou separadamente, fiadores, e/ou
          avalistas, e principais pagadores do financiamento. O PORTADOR
          TITULAR, neste ato, autoriza que, em seu nome, o EMISSOR ceda a
          terceiros os créditos detidos contra o ele, em decorrência da garantia
          ora avençada.
          <br />
          <b>10.4</b> Os valores a serem cobrados do PORTADOR TITULAR, que
          incorreu no financiamento, deverão restringir-se, exclusivamente, ao
          valor dos bens adquiridos ou das despesas realizadas, acrescidos dos
          respectivos ENCARGOS previstos na cláusula décima segunda, inclusive a
          remuneração por garantia prestada, pelo EMISSOR.{" "}
        </li>
        <br />
        <li>
          <b>DO LIMITE DE CRÉDITO</b> <br /> <br />
          <b>11.1</b> O PORTADOR TITULAR do CARTÃO poderá utilizar seu CARTÃO
          até o LIMITE DE CRÉDITO concedido de acordo com a sua elegibilidade.{" "}
          <br />
          <b>11.2</b> O LIMITE DE CRÉDITO mencionado será informado diretamente
          pelo EMISSOR, calculado de acordo com a sua política de crédito com
          base nas informações creditícias obtidas e válido durante o período de
          vigência do contrato.
          <br />
          <b>11.3</b> A realização de TRANSAÇÕES reduzirá o montante dos LIMITES
          estipulados, que serão restabelecidos automaticamente após o efetivo
          pagamento das despesas referentes a cada respectivo limite. <br />
          <b>11.4</b> O LIMITE DE CRÉDITO poderá ser elevado ou reduzido,
          conforme a política de crédito do EMISSOR, devendo o PORTADOR TITULAR
          ser previamente informado através dos canais de comunicação do EMISSOR
          ou através da FATURA.
          <br />
          <div style={{ marginLeft: 20 }}>
            <b>11.4.1</b> O PORTADOR TITULAR do CARTÃO autoriza o EMISSOR a
            aumentar ou diminuir o seu LIMITE DE CRÉDITO a qualquer tempo, de
            acordo com a política de crédito do EMISSOR e a análise de
            elegibilidade do PORTADOR TITULAR.
          </div>
        </li>
        <br />
        <li>
          <b>DO ATRASO E ENCARGOS </b> <br /> <br />
          <b>12.1</b> Na hipótese de não pagamento da FATURA no vencimento e na
          forma pré-estabelecida, poderão incidir os seguintes ENCARGOS:
          <S.Ol type="a">
            <li>
              multa de 2% (dois por cento) calculada sobre o saldo devedor total
              da fatura;{" "}
            </li>
            <li>
              juros remuneratórios indicados na FATURA, mais juros moratórios à
              taxa de 1% (um por cento) ao mês, ambos capitalizados diariamente,
              aplicáveis sobre os valores devidos e não pagos desde a data do
              vencimento até a data do efetivo pagamento; e
            </li>
            <li>tributos devidos na forma da legislação em vigor.</li>
          </S.Ol>
          <b>12.2</b> No caso de atraso no pagamento, a taxa percentual
          correspondente aos juros remuneratórios será:
          <S.Ol type="a">
            <li>
              a taxa da operação do parcelamento, no caso das operações
              realizadas nos termos do art. 2º, da Resolução nº 4.549, de 26 de
              janeiro de 2017;{" "}
            </li>
            <li>
              a taxa de juros da modalidade de crédito rotativo, para os demais
              valores em atraso.{" "}
            </li>
            <li>
              <b>
                Caso o PORTADOR TITULAR do CARTÃO realize o pagamento da sua
                FATURA em atraso, este deverá consultar nos canais digitais qual
                o valor atualizado do saldo devedor (valor total da FATURA +
                multa + juros remuneratórios + juros de moratórios) na data do
                pagamento. Se o PORTADOR TITULAR optar por pagar valor inferior
                ao saldo devedor atualizado, a diferença será financiada pelo
                EMISSOR, estando sujeita à cobrança de ENCARGOS, conforme
                previsto acima.
              </b>
            </li>
          </S.Ol>
          <b>
            12.3 Em caso de atraso ou não pagamento, o PORTADOR TITULAR poderá
            ter o CARTÃO bloqueado, bem como o seu nome inscrito no SPC, Serasa
            e/ou demais órgãos de proteção ao crédito.
          </b>
          <br />
          <b>12.4</b> Na hipótese de atraso, total ou parcial, em relação aos
          pagamentos, o PORTADOR TITULAR do CARTÃO autoriza, desde já, o EMISSOR
          a realizar a compensação entre os valores devidos ao EMISSOR com os
          valores que lhe são devidos, até a quitação integral.
          <div style={{ marginLeft: 20 }}>
            <b>12.4.1</b> A compensação parcial não exonerará o PORTADOR TITULAR
            quanto ao pagamento do saldo remanescente de suas obrigações e
            respectivos acréscimos, até a quitação total dos valores devidos
            decorrentes do presente contrato. <br />
            <b>
              12.4.2 Desde já o PORTADOR TITULAR reconhece e autoriza que o
              EMISSOR, por meio de seus prepostos, terceiros ou funcionários,
              através de contatos telefônicos, correspondências ou quaisquer
              outros meios, entre em contato durante o horário comercial para
              informar eventuais débitos em atraso.{" "}
            </b>
          </div>
          <b>12.5</b> O EMISSOR cobrará as suas tarifas, taxas de juros
          remuneratórios e demais ENCARGOS vigentes incluindo, mas não se
          limitando, o CET – Custo Efetivo Total, conforme legislação em vigor,
          sem prejuízo do disposto.{" "}
        </li>
        <br />
        <li>
          <b>DA RESCISÃO E CANCELAMENTO</b> <br /> <br />
          <b>13.1</b> Este contrato poderá ser rescindido por qualquer das
          partes, a qualquer tempo, mediante prévia comunicação, sem motivo
          expresso ou conforme disposições legais, com o consequente
          cancelamento do CARTÃO e o encerramento da CONTA CARTÃO. <br />
          <b>13.2</b> O EMISSOR poderá rescindir os efeitos deste contrato em
          relação ao PORTADOR TITULAR do CARTÃO em função da violação de
          qualquer disposição neste contida, causando a rescisão imediata deste
          contrato, sem qualquer formalidade adicional, e a suspensão ou o
          cancelamento dos CARTÕES e da CONTA CARTÃO, a critério único e
          exclusivo do EMISSOR, de acordo com sua política e/ou na ocorrência de
          quaisquer dos seguintes fatos:
          <S.Ol type="a">
            <li> o não pagamento da FATURA ou do PAGAMENTO MÍNIMO;</li>
            <li>
              o não pagamento de qualquer das obrigações presentes neste
              contrato, ou de qualquer outra obrigação perante o EMISSOR;{" "}
            </li>
            <li>
              a realização de gastos além dos limites estabelecidos, salvo
              autorização do EMISSOR;{" "}
            </li>
            <li>
              a inclusão do nome do PORTADOR TITULAR em qualquer lista de
              restrição ao crédito e/ou órgãos de proteção ao crédito;
            </li>
            <li>
              a realização de qualquer TRANSAÇÃO fraudulenta, atividades
              consideradas ilícitas como crime de lavagem de dinheiro ou
              ocultação de bens, direitos e valores, ou outras vedadas pela
              legislação;
            </li>
            <li> o falecimento do PORTADOR TITULAR;</li>

            <li>
              movimentação incompatível com a capacidade financeira ou atividade
              desenvolvida;
            </li>

            <li>
              utilização de meios não idôneos, com objetivo de postergar
              pagamentos e/ou cumprimento de obrigações assumidas com o EMISSOR;
            </li>
            <li>
              irregularidade nas informações prestadas, julgadas de natureza
              grave pelo EMISSOR e;
            </li>
            <li>
              Cadastro de Pessoa Física – CPF/MF que não esteja classificado
              como ativo pela Secretaria da Receita Federal.
            </li>
          </S.Ol>
          <b>13.3</b> O cancelamento do CARTÃO não extingue as dívidas vencidas,
          vincendas, e as despesas incorridas após a data da rescisão, que
          deverão ser imediatamente quitadas.
          <br />
          <b>13.4</b> A rescisão deste contrato e/ou o cancelamento do CARTÃO
          extingue automaticamente todos os benefícios colocados à disposição do
          PORTADOR TITULAR. E, o pagamento de todas as despesas, não implicará a
          renovação do contrato, nem ao menos a reativação do CARTÃO.
          <br />
          <b>13.5</b> Em caso de falecimento do PORTADOR TITULAR, o espólio
          responderá pelas DESPESAS realizadas através do CARTÃO, inclusive
          aquelas realizadas entre a data do falecimento e a data da sua
          comunicação. <br />
          <b>13.6</b> A não utilização do CARTÃO, por um período superior a 12
          (doze) meses, contados a partir da última transação ou registro da
          SENHA ELETRÔNICA, poderá implicar no seu cancelamento, sem qualquer
          formalidade adicional.
          <br />
          <b>13.7</b> A utilização do CARTÃO após a rescisão deste contrato
          ficará sujeita às sanções penais previstas em lei, sem prejuízo da
          obrigação de pagar os débitos decorrentes dessa utilização,
          respectivos acréscimos e reembolso de custos.{" "}
        </li>
        <br />
        <li>
          <b style={{ textDecorationLine: "underline" }}>
            LEI GERAL DE PROTEÇÃO DE DADOS (LGPD){" "}
          </b>{" "}
          <br /> <br />
          <b>14.1</b> Por meio desta cláusula, o PORTADOR TITULAR declara
          ciência e seu EXPRESSO CONSENTIMENTO para o EMISSOR coletar e
          armazenar os seus dados quando julgar necessário. <br />
          <b>14.2</b> O PORTADOR TITULAR autoriza a coleta de dados pessoais
          imprescindíveis a execução deste contrato, tendo sido informado quanto
          ao tratamento de dados que será realizado pelo EMISSOR, nos termos da
          Lei n° 13.709/2018, especificamente quanto a coleta dos seguintes
          dados:
          <S.Ol type="a">
            <li>Nome completo;</li>
            <li>Data de nascimento;</li>
            <li>Estado Civil; </li>
            <li>Número e imagem da Carteira de Identidade (RG);</li>
            <li>Número e imagem da Carteira Nacional de Habilitação (CNH);</li>
            <li>Número e imagem do Cadastro de Pessoas Físicas (CPF);</li>
            <li>Endereço completo; </li>
            <li>Números de telefone, WhatsApp e endereços de e-mail; </li>
            <li>Profissão e Renda presumida;</li>
            <li>Fotografia do rosto (autoretrato);</li>
            <li>Nome e telefone de referência(s) pessoal(is).</li>
          </S.Ol>
          <b>14.3</b> <b>Finalidade do tratamento de dados:</b> O PORTADOR
          TITULAR autoriza que o EMISSOR utilize os seus dados pessoais e dados
          pessoais sensíveis listados neste contrato para as seguintes
          finalidades:
          <S.Ol type="a">
            <li>Possibilitar a execução do presente contrato;</li>
            <li>Possibilitar que o EMISSOR identifique o PORTADOR TITULAR; </li>
            <li>
              Possibilitar que o EMISSOR entre em contato com o PORTADOR
              TITULAR, quando necessário;
            </li>
            <li>
              Possibilitar que o EMISSOR elabore contratos comerciais e emita
              cobranças contra o PORTADOR TITULAR;
            </li>
            <li>Proporcionar a melhor experiência e suporte;</li>
            <li>Cumprir as obrigações legais e regulatórias; </li>
            <li>Gerar um maior grau de segurança e autenticidade; </li>
            <li>Aprimoramento dos serviços prestados; </li>
            <li>
              Prevenção e resolução de problemas técnicos ou de segurança;{" "}
            </li>
            <li>Exercício regular de direitos do EMISSOR; </li>
            <li>
              Cumprimento de ordem judicial, de autoridade competente ou de
              órgão fiscalizador;{" "}
            </li>
            <li>Fornecer produtos ou informar promoções;</li>
            <li>
              Reconhecimento facial para identificar e autenticar os contatos e
              TRANSAÇÕES realizadas pelo PORTADOR TITULAR.{" "}
            </li>
          </S.Ol>
          <b>14.4</b> <b> Compartilhamento de dados:</b> Os dados coletados
          poderão ser utilizados para identificação de terrorismo,
          compartilhamento para órgãos de segurança, conforme solicitação legal
          pertinente, compartilhamento com autoridade administrativa e judicial
          no âmbito de suas competências com base no estrito cumprimento do
          dever legal, bem como com os órgãos de proteção ao crédito a fim de
          garantir a adimplência do PORTADOR TITULAR perante o EMISSOR. <br />
          <b>14.5</b> O EMISSOR poderá registrar e gravar todos os dados
          fornecidos em toda comunicação realizada com a sua equipe, seja por
          correio eletrônico, mensagens, telefone ou qualquer outro meio. <br />
          <b>14.6</b> Os dados coletados com base no legítimo interesse do
          PORTADOR TITULAR, bem como dados adicionais utilizados para garantir a
          fiel execução do contrato por parte do EMISSOR, fundamentam-se no
          artigo 7º da LGPD. <br />
          <b>
            14.7 O PORTADOR TITULAR autoriza o compartilhamento de seus dados
            com o banco de dados do programa denominado ZEUSS APP, pois este
            programa controla o benefício denominado cashback disponibilizado ao
            PORTADOR TITULAR. <br />
          </b>
          <div style={{ marginLeft: 20 }}>
            <b>
              14.7.1 ZEUSS APP é um programa de recompensas, fidelidade e
              carteira digital desenvolvido pela Clientar LTDA., pessoa jurídica
              de direito privado inscrita no CNPJ/ME sob nº 35.872.830/0001-93,
              com sede na cidade de Manaus, Estado do Amazonas.
            </b>{" "}
            <br />
            <b>
              14.7.2 Após aceite dos termos do presente contrato, o PORTADOR
              TITULAR concorda com a adesão ao referido programa de recompensas
              (ZEUSS APP).
            </b>
          </div>
          <b>14.8 Responsabilidade pela Segurança dos dados:</b> o EMISSOR se
          responsabiliza por manter medidas de segurança, técnicas e
          administrativas suficientes a proteger os dados pessoais do Titular e
          à Autoridade Nacional de Proteção de Dados (ANPD), comunicando ao
          Titular, caso ocorra algum incidente de segurança que possa acarretar
          risco ou dano relevante, conforme artigo 48 da Lei n° 13.709/2020.{" "}
          <br />
          <b>14.9 Exclusão dos dados:</b> Será efetuada sem que haja prejuízo
          por parte do EMISSOR, para tanto, caso o PORTADOR TITULAR deseje
          efetuar a revogação de algum dado, deverá preencher uma declaração
          neste sentido, ciente que a revogação de determinados dados poderá
          importar em eventuais prejuízos na prestação de serviços. <br />
          <b>14.10 Término do Tratamento de dados:</b> Ao EMISSOR é permitido
          manter e utilizar os dados pessoais do PORTADOR TITULAR durante todo o
          período contratualmente firmado para as finalidades relacionadas nesse
          termo e ainda após o término da contratação, pelo prazo de 05 (cinco)
          anos, para cumprimento de obrigação legal ou impostas por órgãos de
          fiscalização, nos termos do artigo 16 da Lei n° 13.709/2018. Dados
          pessoais anonimizados, sem possibilidade de associação ao indivíduo,
          poderão ser mantidos por período indefinido. <br />
          <b>14.11</b> O PORTADOR TITULAR autoriza, neste mesmo ato, a guarda
          dos documentos (contratos/documentos pessoais ou
          fiscais/notificações/ordens de serviços) - em que pese eles possuam
          dados pessoais - por parte do EMISSOR a fim de que ele cumpra com o
          determinado nas demais normas que regulam o presente contrato, bem
          como para o cumprimento da obrigação legal nos termos do artigo 16,
          inciso I, da Lei Geral de Proteção de Dados.
          <br />
          <b>14.12</b> O EMISSOR informa que a gerência de dados ocorrerá
          através de um sistema que colherá e tratará os dados na forma da lei.
          <br />
          <b>14.13</b> O EMISSOR informa que efetuará a manutenção do registro
          das operações de tratamento de dados pessoais da forma mencionada na
          cláusula anterior.
          <br />
          <b>14.14</b> Rescindido o contrato os dados pessoais coletados serão
          armazenados pelo tempo determinado na cláusula 14.10 <br />
          <b>14.15</b> Passado o termo de guarda pertinente ao EMISSOR se
          compromete a efetuar o descarte dos dados adequadamente.
          <br />
          <b>14.16</b> O PORTADOR TITULAR poderá entrar em contato com o
          encarregado pelos seus dados através do e-mail:{" "}
          <u>sac@useteucard.com.br</u>. <br />
          <b>14.17 Seus direitos:</b> O PORTADOR TITULAR tem direito a obter do
          EMISSOR, em relação aos dados por ele tratados, a qualquer momento, e
          mediante requisição, conforme previsão do art. 18 da LGPD:
          <S.Ol type="a">
            <li>Solicitar confirmação da existência de tratamento; </li>
            <li>Acessar seus dados pessoais; </li>
            <li>
              Solicitar a correção de dados incompletos, inexatos ou
              desatualizados;{" "}
            </li>
            <li>
              Solicitar anonimização, bloqueio ou eliminação de dados
              desnecessários, excessivos ou tratados em desconformidade com o
              disposto na LGPD;{" "}
            </li>
            <li>
              Solicitar a portabilidade dos dados a outro fornecedor de serviço
              ou produto, mediante requisição expressa, de acordo com a
              regulamentação da autoridade nacional, observados os segredos
              comercial e industrial;{" "}
            </li>
            <li>
              Solicitar a eliminação dos dados pessoais tratados com o seu
              consentimento, exceto nas hipóteses previstas neste contrato e no
              artigo 16 da LGPD;{" "}
            </li>
            <li>
              Solicitar informação das autoridades públicas e privadas com as
              quais o controlador realizou uso compartilhado de dados;{" "}
            </li>
            <li>
              Obter informações sobre a possibilidade de não fornecer
              consentimento e sobre as consequências da negativa; e 
            </li>
            <li>
              Revogação do consentimento, nos termos do § 5º do art. 8º da Lei
              nº 13.709, de 2018.{" "}
            </li>
          </S.Ol>
          <b>14.18</b> O consentimento conferido por meio deste instrumento
          particular poderá ser revogado a qualquer momento pelo PORTADOR
          TITULAR, mediante sua manifestação expressa, por meio de solicitação
          via correio eletrônico (e-mail) ou por correspondência encaminhada ao
          EMISSOR, ratificados os tratamentos realizados sob amparo do presente
          consentimento, nos termos do inciso VI do “caput” do art. 18 da Lei nº
          13.709, de 2018.{" "}
        </li>
        <br />
        <br />

        <li>
          <b>DA VIGÊNCIA</b> <br /> <br />
          <b>15.1</b> Este contrato é celebrado por prazo indeterminado, podendo
          ser rescindido por qualquer das partes de forma unilateral, hipótese
          em que ficará automaticamente cancelado o CARTÃO e sua respectiva
          CONTA CARTÃO.{" "}
        </li>
        <br />
        <li>
          <b>DAS DISPOSIÇÕES FINAIS</b> <br /> <br />
          <b>16.1</b> Na hipótese de alteração do presente contrato, o PORTADOR
          TITULAR do CARTÃO será devidamente informado, tendo acesso ao texto na
          íntegra a partir do sítio eletrônico <u>www.useteucard.com.br</u> ou
          nos demais canais disponibilizados pelo EMISSOR. Caso não concorde com
          a alteração, o PORTADOR TITULAR poderá rescindir o contrato. A
          realização de qualquer TRANSAÇÃO através do CARTÃO após recebimento da
          informação sobre a alteração do contrato acima mencionado, implicará
          em concordância automática com os novos termos. <br />
          <b>16.2</b> A tolerância de uma parte para com a outra, relativamente
          ao descumprimento de quaisquer das obrigações ora assumidas, bem como
          o atraso ou omissão por qualquer das partes em exercer qualquer
          direito decorrente do presente contrato não poderão ser considerados
          moratória, novação ou renúncia a qualquer direito, constituindo mera
          liberalidade, que não impedirá a parte tolerante ou omissa de exigir
          da outra o fiel cumprimento deste contrato.
          <br />
          <b>16.3</b> Este contrato obriga, por todos os seus termos e
          condições, as partes, seus herdeiros e sucessores, a qualquer título
          que o sejam. <br />
          <b>
            16.4 O EMISSOR disponibiliza canal de SAC - Serviço de Atendimento
            ao Cliente para solicitações diversas, informações e reclamações,
            através do número 4003-3203 ou pelo endereço de e-mail:
            <u>sac@useteucard.com.br</u>.<br />
          </b>
          <br />
          <b>16.5</b> As partes declaram e concordam que o presente instrumento,
          incluindo todas as páginas de assinatura e eventuais anexos, todas
          formadas por meio digital com o qual expressamente declaram concordar
          e reafirmam seu dever de observar e fazer cumprir as cláusulas aqui
          estabelecidas, representam a integralidade dos termos entre elas
          acordados.
          <br />
          <div style={{ marginLeft: 20 }}>
            <b>16.5.1</b> Nos termos do art. 10, §2º, da Medida Provisória nº
            2.200-2, concordam em utilizar e reconhecem como válida qualquer
            forma de comprovação de anuência aos termos ora acordados em formato
            eletrônico e declaram estarem cientes de que uma via do presente
            contrato será encaminhada para o e-mail informado e cadastrado pelo
            PORTADOR TITULAR do CARTÃO e, assim, poderá gerar a versão impressa
            do mesmo. <br />
            <b>16.5.2</b> Se preferir, o PORTADOR TITULAR poderá entrar em
            contato com o SAC e solicitar a cópia digitalizada do contrato.{" "}
          </div>
          <b>16.6</b> Fica eleito o foro da Comarca de Manaus-AM para dirimir
          quaisquer dúvidas ou controvérsias que venham a surgir em virtude
          deste contrato.
          <br />
          <b>16.7</b> Este contrato passa a vigorar a partir da data do seu
          registro no competente Cartório de Registro de Títulos e Documentos.
          <br />
        </li>
      </S.Ol>
      <br />
      <S.Text>
        As cláusulas desse contrato estão regularmente depositadas no Cartório
        de Registro de Títulos e Documentos de Manaus-Am, sob o nº 00517517, em
        livro B-3266 em 28 de abril de 2023.
        <br />
        <br />
        Manaus-AM, {date}.
      </S.Text>
      <S.Text style={{ textAlign: "center" }}>
        ___________________________________
        <br />
        {infos.nome}
        <br />
        CPF {infos.cpf}
        <br />
        Portador Titular
      </S.Text>

      <br />
      <br />
      <S.Text>TeuCard</S.Text>
    </S.WrapperProposal>
  );
};

export default ProposalCard;
