import styled from "styled-components";

export const TableTitle = styled.h4`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
`;

export const Table = styled.table`
  color: ${({ theme }) => theme.colors.primary};
  border-spacing: 0;
  text-align: center;
  margin-top: 30px;

  th {
    background-color: #f5f5f5;
    padding: 10px 0;
  }

  th,
  td {
    border-bottom: 1px solid gray;
    font-size: 13px;
    &:last-child {
      padding-right: 10px;
    }
  }

  td a {
    text-decoration: underline;
    color: inherit;
  }
`;
