import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import themes from 'styles'
import * as S from './styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}))

export default function InputDate({
  type,
  name,
  id,
  icon,
  label,
  values,
  value,
  onChange,
  error
}) {
  const classes = useStyles()

  return (
    <>
      <S.Wrapper name={id} className={type}>
        <S.Container htmlFor={name}>
          {label && <label>{label}</label>}
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">
              Data de vencimento da fatura
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select-modal"
              value={value}
              native={false}
              onChange={onChange}
              className="modal"
              autoWidth={true}
            >
              <MenuItem className="itemModal" value="1">
                1
              </MenuItem>
              <MenuItem className="itemModal" value="5">
                5
              </MenuItem>
              <MenuItem className="itemModal" value="10">
                10
              </MenuItem>
              <MenuItem className="itemModal" value="15">
                15
              </MenuItem>
              <MenuItem className="itemModal" value="20">
                20
              </MenuItem>
              <MenuItem className="itemModal" value="25">
                25
              </MenuItem>
            </Select>
          </FormControl>
          <span>{themes.components.icons.calendar}</span>
        </S.Container>
        {!!error && <S.ErrorText>{error}</S.ErrorText>}
      </S.Wrapper>
      <style jsx>
        {`
          @media screen and (max-width: 1420px) {
            .MuiPopover-paper {
              left: 25% !important;
            }
          }

          @media screen and (max-width: 400px) {
            .MuiPopover-paper {
              left: 10% !important;
            }
          }
        `}
      </style>
    </>
  )
}
