import React, { useState, useEffect } from "react";

import * as St from "./styles";
const TableProposal = ({ header, items }) => {
  const [data, setData] = useState([]);

  console.log(data);

  useEffect(() => {
    setData(items);
  }, [items]);

  return (
    <St.Table>
      <St.headerTable>
        {header?.map((item) => (
          <St.headerTitle key={item.id}>{item.value}</St.headerTitle>
        ))}
      </St.headerTable>
      <St.bodyTable>
        {data?.map((item) => (
          <St.bodyRow>
            {item.map((item) => {
              return (
                <St.bodyData maxChar={item.maxChar} textAlign={item.textAlign}>
                  {item.value}
                </St.bodyData>
              );
            })}
          </St.bodyRow>
        ))}
      </St.bodyTable>
    </St.Table>
  );
};

export default TableProposal;
