import React, { useState } from "react";
import * as St from "./styles";
import Layout from "components/OldLayout";
import Button from "components/Button";
import { convertDateByBrazillianFormat, moneyMask } from "utils/mask";
import OneButtonPopup from "components/modals/OneButtonPopup";
import { useHistory, useLocation } from "react-router";
import themes from "styles";
import constants from "utils/constants";
import useApi from "hooks/useApi";
import Loading from "components/Loading";

const WithDrawConfirmSolicitation = () => {
  const { state } = useLocation();
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [dataList, setDataList] = useState([
    {
      name: "Valor da parcela",
      value: state?.data?.numeroParcelas + 'x ' + 'de ' + moneyMask(state?.data?.valorParcela),
      font: "current",
    },
    {
      name: "Valor cobrado na próxima fatura",
      value: moneyMask(state?.data?.valorParcela),
      font: "bold",
    },
    {
      name: "Vencimento",
      value: convertDateByBrazillianFormat(
        state?.data?.dataPrimeiroPagamento
      ),
      font: "current",
    },
    {
      name: "Taxa de juros",
      value:
        (state?.data?.taxaJuros * 100)
          .toFixed(2)
          .replace(".", ",") + "% a.m.",
    },
    {
      name: "CET",
      value:
        (state?.data?.taxaJurosEfetivaMensal * 100)
          .toFixed(2)
          .replace(".", ",") + "% a.m.",
    },
  ]);
  
  const PutCancelLoan = useApi(
    constants.endpoints.putCancelWithDraw.replace(
      "PARAM_ID",
      state?.data?.id
    ),
    "PUT"
  );

  const cancelLoan = async () => {
    try {
      const response = await PutCancelLoan.callApi();
      if (response.status >= 200 && response.status < 300) {
        setMessage("Solicitação de saque cancelada com sucesso!");
        setSuccess(true);
        setDialogOpen(true);
      } else {
        setMessage(response.mensagem);
        setDialogOpen(true);
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <St.SafeView>
         <OneButtonPopup
          text={message}
          buttonText="Entendi"
          title={"Atenção!"}
          visibility={dialogOpen}
          iconModal={success ? themes.components.png.emojiSuccess : themes.components.png.emojiSad}
          defaultButton
          setVisibility={setDialogOpen}
          onClose={() => {setDialogOpen(false); history.push("/opcoes-acesso")}}
        />
        <Loading status={PutCancelLoan.loading} />
      <Layout routeBack="/confirm-data-by-teu-card" title="Simulação">
        <St.Container>
          <St.TopSpace>
            <St.ValueSimulate>
              <St.SimulateEditTitle>
                Identificamos que você tem um empréstimo pendente de assinatura. Confira abaixo os valores para continuar:
              </St.SimulateEditTitle>
              <St.TextItemValue>
                {moneyMask(
                  state?.data?.valorLiberado
                )}
              </St.TextItemValue>
            </St.ValueSimulate>
            <St.MiddleContainerItems>
              {dataList.map((item, idx) => {
                return (
                  <St.ValueWrapper>
                    <St.TextItem font={item.font}>{item?.name}</St.TextItem>
                    <St.TextItemValueResult>
                      {item?.value}
                    </St.TextItemValueResult>
                  </St.ValueWrapper>
                );
              })}
            </St.MiddleContainerItems>
            <St.MiddleContainer>
              <St.Rounded />
              <p style={{ fontSize: "16px" }}>
                Será cobrado o valor integral, em parcela única, na tua{" "}
                <b> próxima fatura.</b>
              </p>
            </St.MiddleContainer>
            <St.Footer>
              <Button
                buttonType="secondary"
                text={"Quero cancelar"}
                style={{ marginBottom: "1.5rem" }}
                onClick={() => cancelLoan()}
                loading={PutCancelLoan.loading}
              />
              <Button
                buttonType="primary"
                text={"Quero assinar!"}
                onClick={() => history.push("/with-draw-terms-sign")}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default WithDrawConfirmSolicitation;

